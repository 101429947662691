import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { isAuthenticated, useAuth0Client } from "../lib/auth0";
import { useQuery } from "react-query";

export type UserId = string;

/**
 * LINE のユーザーとしてサインインしているか
 */
export function useIsLineUserSignedIn(): boolean {
  return useQuery(["auth", "is-line-user-signed-in"], async () => {
    return await isAuthenticated();
  }).data!;
}

export function useInitializeAuth() {}

export function useAuth() {
  const auth0 = useAuth0Client();
  const location = useLocation();

  console.log(process.env.REACT_APP_SITE_URL);

  const login = useCallback(async () => {
    await auth0.loginWithRedirect({
      connection: "line",
      // ここ以外に飛ばす場合は auth0 が裏で実行するトークン取得とかも影響受けるかも
      redirect_uri: process.env.REACT_APP_SITE_URL,
      appState: {
        location: location,
      },
    });
    const user = await auth0.getUser();
    console.log("useLoginInWithLine", "logged in", user);
  }, [auth0, location]);

  const logout = useCallback(() => {
    auth0.logout();
  }, [auth0]);

  return {
    login,
    logout,
  };
}

export function useLogin() {
  const { login } = useAuth();

  return useCallback(async () => {
    await login();
  }, [login]);
}

export interface RawUser {
  picture?: string;
  name: string;
}

/**
 * 未ログインなら null
 */
export function useRawUser(): RawUser | null {
  const auth0 = useAuth0Client();

  return useQuery(["auth0", "user"], async () => {
    if (await auth0.isAuthenticated()) {
      return (await auth0.getUser()) as any;
    } else {
      return null;
    }
  }).data!;
}
