import React, { FC } from "react";
import { Help } from "../../common/components/Help";

interface Props {
  level: number | null;
}

/**
 * inline
 */
export const UserLevel: FC<Props> = ({ level }) => {
  const levelText = level === 0 ? "初見さん" : level;
  return (
    <span>
      Lv.{levelText}{" "}
      <Help
        help={
          <>
            <p>レベルは他の参加者に喜んでもらえた量によって上下するよ。</p>
            <p>
              積極的に話しかけたり、質問だけじゃなく、自分のことを伝えたりすると、きっと喜んでもらえるよ！
            </p>
            <p>
              他の参加者のレベルも確認できるから、自分の貢献をアピールしよう！
            </p>
            (最大 5)
          </>
        }
        track="help.user-level"
      />
    </span>
  );
};
