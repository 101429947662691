import React, { FC } from "react";

import moment from "moment";

type DateTimeProps = {
  value?: string;
};

export const DateView: FC<DateTimeProps> = ({ value }) => {
  if (!value) {
    return <></>;
  }

  const dateTime = moment(value).format("M月DD日");

  return <>{dateTime}</>;
};
