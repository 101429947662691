import React, { FC } from "react";
import { Space } from "antd";

type HorizontalSpaceProps = {};

/**
 * @deprecated HorizontalList をつかう
 */
export const HorizontalSpace: FC<HorizontalSpaceProps> = ({ children }) => {
  return <Space wrap>{children}</Space>;
};
