import { FC, useCallback } from "react";
import styled from "styled-components";
import { primaryColor, textColor } from "../../colors";

export type LargeToggleProps = {
  checkedText: string;
  unCheckedText: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
};

export const LargeToggle: FC<LargeToggleProps> = ({
  checkedText,
  unCheckedText,
  checked,
  onChange,
}) => {
  const onClick = useCallback(() => {
    onChange(!checked);
  }, [checked, onChange]);

  return (
    <MyBox checked={checked} onClick={onClick}>
      {checked ? checkedText : unCheckedText}
    </MyBox>
  );
};

type MyBoxProps = {
  checked: boolean;
};

const MyBox = styled.div<MyBoxProps>`
  background-color: ${(props) => (props.checked ? primaryColor : "#f0f0f0")};
  color: ${(props) => (props.checked ? "#fff" : textColor)};
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #cccccc;
  transition: background-color 0.3s;
  box-shadow: 0 0 2px black;
`;
