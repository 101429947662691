/**
 * URL に状態を保持する
 *
 *
 */
import { useHistory, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useQueryParams } from "./useQueryParams";

/**
 * ?state=xxx というような形で状態を url に保持する
 */
export function useUrlState<T extends string>(
  name: string,
  defaultValue: T
): [T, (v: T) => void] {
  const queryValue = (useQueryParams(name) as T | null) ?? defaultValue;
  const [state, setState] = useState(queryValue);

  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    const q = new URLSearchParams(location.search);
    if ((q.get(name) as T | null) === state) {
      return;
    }

    if (state) {
      q.set(name, state);
    } else {
      q.delete(name);
    }

    history.replace({
      ...location,
      search: q.toString(),
    });
  }, [history, location, name, state]);

  return [state, setState];
}
