import { Sex, Type1, Type2, Type3, Type4 } from "./SimpleLiveLpPage/model";
import moment from "moment";
import { AgeType } from "../common/api/types";
import { useState } from "react";

export type Direction = "one-way" | "two-way";

interface ListeningParams {
  listenerName: string;
  message: string;
  talkType: string;
  listenerSex: Sex;
  hostSex: Sex;
  type1: Type1;
  type2: Type2;
  type3?: Type3;
  type4?: Type4;
  hostName: string;
  direction?: Direction;
  ageType: AgeType;
}

export interface Topic {
  title: string;
  question?: string;
  seconds: number;
}

type DateTimeString = string;

interface Listening extends ListeningParams {
  listenedAt: DateTimeString;
}

type History = Listening[];

const KEY = "history";

export function saveStartListening(p: ListeningParams) {
  const history = getListeningHistory();
  history.push({
    ...p,
    listenedAt: moment().toISOString(true),
  });

  localStorage.setItem(KEY, JSON.stringify(history));
}

export function getListeningHistory(): History {
  const json = localStorage.getItem(KEY);
  if (json) {
    return JSON.parse(json);
  }
  return [];
}

export interface StateMachine {}

export interface Reaction {
  text: string;
}

export const musicUrl =
  "https://d3fzg4gd0tu5r2.cloudfront.net/music/Morning.mp3";

const topics: Topic[] = [
  { title: "あなたに愚痴を聞いてほしいな", seconds: 30 },
  { title: "ゆるーくとりとめもない話", seconds: 30 },
  { title: "趣味について話すよ", seconds: 60 },
  { title: "仕事の話", seconds: 60 },
  { title: "寝る準備しながら話", seconds: 60 },
  { title: "今年の抱負を語るよ", seconds: 60 },
  {
    title: "いっぱい褒めるよ",
    question: "褒めてほしい内容を教えてね",
    seconds: 2 * 60,
  },
  {
    title: "応援するよ",
    question: "応援してほしい内容を教えてね",
    seconds: 2 * 60,
  },
  { title: "首都高を語る！", seconds: 60 },
  { title: "プログラミング教えるよ (シリーズ)", seconds: 3 * 60 },
  {
    title: "いっぱい慰めるよ",
    question: "何があったのか教えてね",
    seconds: 2 * 60,
  },
  { title: "歌を歌うよ", question: "リクエストある?", seconds: 2 * 60 },
  { title: "あなたが寝るまでそばにいるよ", seconds: 10 * 60 },
  {
    title: "リクエストする?",
    question: "リクエスト内容を教えてね (○○だから、○○してほしい等)",
    seconds: 60,
  },
];

export async function fetchTopics(): Promise<Topic[]> {
  return Promise.resolve(topics);
}

/**
 *
 */
export function useFirstAgeType(): AgeType | null {
  const [firstAgeType] = useState(() => {
    const h = getListeningHistory();
    return h.find((i) => !!i.ageType)?.ageType;
  });
  return firstAgeType ?? null;
}

/**
 *
 */
export function useFirstSex(): Sex | null {
  const [firstSex] = useState(() => {
    const h = getListeningHistory();
    return h.find((i) => !!i.hostSex)?.hostSex;
  });
  return firstSex ?? null;
}

/**
 *
 */
export function useLastType4(): Type4 | null {
  const [firstAgeType] = useState(() => {
    const h = getListeningHistory();
    return h.reverse().find((i) => !!i.type4)?.type4;
  });
  return firstAgeType ?? null;
}
