import React, { FC } from "react";

import { Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

export type BackButtonProps = {
  backPath?: string;
};

export const BackButton: FC<BackButtonProps> = ({ backPath }) => {
  const history = useHistory();
  return (
    <Button
      icon={<ArrowLeftOutlined />}
      type="text"
      onClick={() => {
        if (backPath) {
          history.push(backPath);
        } else {
          history.goBack();
        }
      }}
    />
  );
};
