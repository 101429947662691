import React, { FC, useEffect } from "react";
import { usePageView } from "../../../common/hooks/usePageView";
import { useMutation } from "react-query";
import { publicApi } from "../../../common/api/api2";
import { PostRequest } from "../../../api";
import { useRemoveSavedRequest, useSavedRequest } from "../../hooks/lp2";
import { Loading } from "../../../common/components/Loading";
import { BeforeLoginPage } from "./BeforeLoginPage";

export const voiceMessageLp2Step2Route = {
  path: "/voice-messages/lpdir/step2",
  makePath: () => {
    return "/voice-messages/lpdir/step2";
  },
};

interface Props {}

export const VoiceMessageLp2Step2Page: FC<Props> = () => {
  usePageView("voice-message-lp2-step2", true);

  return <Impl />;
};

const Impl: FC<Props> = () => {
  const request = useSavedRequest();
  const remove = useRemoveSavedRequest();

  // あえてここで通信する (待たせて、期待させる)
  const mutation = useMutation(
    async (request: PostRequest) => await publicApi.postRequestInLp(request),
    {
      onSuccess() {
        remove();
      },
    }
  );

  const mutate = mutation.mutate;
  useEffect(() => {
    if (!request) {
      return;
    }
    mutate(request);
  }, [mutate, request]);

  if (!request || mutation.isSuccess) {
    return <BeforeLoginPage />;
  }

  if (mutation.isLoading) {
    return <Loading vh100 />;
  }

  return <div>error</div>;
};
