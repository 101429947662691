import React, { FC, useCallback } from "react";
import { Button } from "antd";
import { usePageView } from "../../../common/hooks/usePageView";
import { AppLayout2 } from "../../../common/components/AppLayout2";
import { VerticalList } from "../../../common/components/VerticalList";
import { Section } from "../../../common/components/Section";
import styled from "styled-components";
import { Hero1 } from "../../../common/components/Hero1";
import circleImage from "../../../images/circle.png";
import { Strong } from "../../../common/components/Strong";
import { useGoto } from "../../../lib/hooks/useGoto";
import { track } from "../../../common/track";
import { homeRoute } from "../HomePage/HomePage";

export const vision2Route = {
  path: "/voice-messages/vision2",
  makePath: () => {
    return "/voice-messages/vision2";
  },
};

interface Props {}

export const Vision2Page: FC<Props> = () => {
  usePageView("vision2", true);
  return <Vision2Template />;
};

export type TemplateProps = {};

const url =
  "https://docs.google.com/forms/d/e/1FAIpQLSfoT6QrJcBXEmDcshy4SqDTwYiwf5y2BEJp_OfswSR1RLLsaA/viewform?usp=sf_link";

const Vision2Template: FC<TemplateProps> = () => {
  const gotoCastHome = useGoto(homeRoute.makePath());
  const survey = useCallback(() => {
    track({ event: "cast-survey" });
    window.open(url, "_blank");
  }, []);

  return (
    <AppLayout2 noVerticalMargin>
      <Hero1
        mainMessage={"いまこの瞬間\n自分だけに向けられた\nやさしい声が聴きたい"}
      />
      <Section>
        <VerticalList align="center" textAlign="center" margin={16}>
          <p>夜、さみしくて一人で眠れないことがありませんか</p>

          <p>
            部活や仕事で落ち込んだとき、ただやさしい言葉をかけてほしいときがありませんか
          </p>
        </VerticalList>
      </Section>

      <Section colored>
        <VerticalList align="center" textAlign="center" margin={16}>
          <p>
            Your Radio はユーザー同士が、 <br />
            互いにやさしい <Strong>声のメッセージ</Strong>{" "}
            を贈りあうサービスです
            <br />
          </p>

          <p>
            疲れたときはだれかの <Strong>声</Strong> に癒され、
            <br />
            元気なときは他の誰かに <Strong>声</Strong> を贈る
          </p>
          <p>Your Radioはそんな場所を作りたいと思っています</p>
          <p>
            Your
            Radioは聴くだけでなく、話しかけることによって成立するサービスです
          </p>
          <p>
            まだまだ発展途上のサービスですが、あなたと一緒に素敵な場所を作っていけるとうれしいです
          </p>
        </VerticalList>
      </Section>
      <Section>
        <VerticalList align="center">
          <Circle src={circleImage} width={100} height={100} />
        </VerticalList>
      </Section>
      <Section>
        <VerticalList align="center">
          <Button size="large" onClick={gotoCastHome}>
            話しかける
          </Button>
          <p>
            よりよいサービスを実現するために、よろしければアンケートにご協力おねがいします
          </p>
          <Button onClick={survey}>アンケートに回答する</Button>
        </VerticalList>
      </Section>
    </AppLayout2>
  );
};

const Circle = styled.img`
  width: 80%;
  height: auto;
`;
