import React, { FC } from "react";
import { OtherFullUser } from "../../../../api";
import { VerticalList } from "../../../../common/components/VerticalList";
import { MyUser2 } from "../../../../common/api/api2";
import { AppButton } from "../../../../common/components/AppButton";
import { track } from "../../../../common/track";

interface Props {
  me: MyUser2;
  cast: OtherFullUser;
  showRequest: () => void;
}

/**
 * 「リクエストしてみよう」
 */
export const RequestRequestContent: FC<Props> = ({ me, cast, showRequest }) => {
  return (
    <VerticalList>
      <div>
        {cast.name}さんにリクエストしよう🎵
        <br />
        きっとよろこんでくれるよ☺️
      </div>
      <AppButton
        onClick={() => {
          track({ event: "talk.request", data2: cast.id });
          showRequest();
        }}
      >
        リクエストする
      </AppButton>
    </VerticalList>
  );
};
