import * as React from "react";
import { FC } from "react";
import { PreLine } from "./PreLine";
import styled from "styled-components";

interface Props {
  rows: number;
}

/**
 * 長いテキスト指定した行数表示し、それ以上になる場合は ... とする
 */
export const MultiLineEllipsis: FC<Props> = ({ rows, children }) => {
  return (
    <Root
      style={{
        WebkitLineClamp: rows,
      }}
    >
      <PreLine>{children}</PreLine>
    </Root>
  );
};

const Root = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
