import React, { FC, useState } from "react";

import { Button, Input } from "antd";
import { useMutation } from "react-query";
import { OtherFullUser } from "../../api";
import { useInvalidateTalk } from "../hooks/message";
import { messageApi } from "../../common/api/api2";
import { VerticalList } from "../../common/components/VerticalList";
import { MessageContentNote } from "./help/MessageContentNote";
import { RequestId } from "../../common/api/types";

interface Props {
  requestId: RequestId;
  cast: OtherFullUser;
  onComplete?: () => void;
}

/**
 * 追加でメッセージを行う
 */
export const PostRequestAdditionView: FC<Props> = ({
  requestId,
  cast,
  onComplete,
}) => {
  const invalidate = useInvalidateTalk(cast.id);
  const [message, setMessage] = useState("");

  const doRequest = useMutation(
    async () => {
      await messageApi.postRequestAddition(requestId, {
        message,
      });
    },
    {
      async onSuccess() {
        if (onComplete) {
          await invalidate();
          setMessage("");
          onComplete();
        }
      },
    }
  );

  const canRequest = message.length >= 1;

  return (
    <VerticalList align="stretch" margin={4}>
      <Input.TextArea
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        style={{ width: "100%", fontSize: 16 }}
        rows={3}
      />
      <Button
        size="large"
        disabled={!canRequest}
        loading={doRequest.isLoading}
        onClick={() => doRequest.mutate()}
        type="primary"
      >
        メッセージを送る
      </Button>
      <MessageContentNote />
    </VerticalList>
  );
};
