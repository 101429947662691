import React, { FC, useCallback } from "react";

import { UserTag } from "../../common/api/voicemessage/api";
import CheckableTag from "antd/es/tag/CheckableTag";
import styled from "styled-components";

interface Props {
  value: UserTag[];
  onChange: (value: UserTag[]) => void;
}

export const UserTagsEditor: FC<Props> = ({ value, onChange }) => {
  const onChange2 = useCallback(
    (tag, checked) => {
      if (checked) {
        const newTags = [...value, tag];
        onChange(newTags);
      } else {
        const newTags = value.filter((it) => it !== tag);
        onChange(newTags);
      }
    },
    [onChange, value]
  );

  const allTags = [
    "呼び捨てのがうれしい",
    "寂しい",
    "悲しい",
    "楽しい",
    "不安",
    "学生",
    "中学生",
    "高校生",
    "大学生",
    "社会人",
    "犬派",
    "猫派",
    "アウトドア派",
    "アニメ",
    "漫画好き",
    "映画好き",
    "小説好き",
    "音楽好き",
    "J-POP好き",
    "ボカロ好き",
    "部活！",
    "仕事！",
    "恋したい",
    "受験",
    "就活",
  ];

  return (
    <Tags>
      {allTags.map((it) => {
        const checked = value.indexOf(it) !== -1;
        return (
          <CheckableTag
            key={it}
            checked={checked}
            onChange={(checked) => onChange2(it, checked)}
            style={{
              border: "1px solid #eee",
              margin: 2,
            }}
          >
            {it}
          </CheckableTag>
        );
      })}
    </Tags>
  );
};

const Tags = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
