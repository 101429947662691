import React, { FC, useState } from "react";
import { usePageView } from "../../../common/hooks/usePageView";
import { Section } from "../../../common/components/Section";
import { AppLayout2 } from "../../../common/components/AppLayout2";
import { MyUser } from "../../../common/api/user";
import { useUserStatus } from "../../../common/hooks/user";
import { VerticalList } from "../../../common/components/VerticalList";
import {
  useInvalidateTopics,
  useMyTopics,
  useTopics,
} from "../../../common/api/hooks/voiceMessage";
import { Topic, TopicId } from "../../../common/api/voicemessage/api";
import { TopicView } from "../../components/TopicView";
import { Wrap } from "../../../common/components/layout/Wrap";
import { HorizontalList } from "../../../common/components/HorizontalList";
import { Button, Spin } from "antd";
import { AppPopover } from "../../components/AppPopover";
import { NewTopic } from "./NewTopic";
import styled from "styled-components";
import { HeaderWithBackButton } from "../../../common/components/HeaderWithBackButton";
import { OthersTopicList } from "./OthersTopicList";
import { AppPopConfirm } from "../../../common/components/AppPopConfirm";
import { useMutation } from "react-query";
import { deleteMyTopic, putMyTopic } from "../../../common/api/cast";
import { PlusCircleOutlined, TeamOutlined } from "@ant-design/icons";
import { useRestoreLocation } from "../../../lib/restoreLocation";
import { AppButton } from "../../../common/components/AppButton";
import { track } from "../../../common/track";

export const castTopicRoute = {
  path: "/cast/topic",
  makePath: () => {
    return "/cast/topic";
  },
};

export const CastTopicPage: FC = () => {
  usePageView("cast_topic", true);

  return <Impl />;
};

const Impl: FC = () => {
  const userStatus = useUserStatus();
  const myTopics = useMyTopics();
  const othersTopics = useTopics();

  return (
    <CastTopicTemplate
      userStatus={userStatus}
      myTopics={myTopics}
      othersTopics={othersTopics}
    />
  );
};

export interface CastTopicTemplateProps {
  userStatus: MyUser;
  myTopics: Topic[];
  othersTopics: Topic[];
}

export const CastTopicTemplate: FC<CastTopicTemplateProps> = ({
  myTopics,
  othersTopics,
}) => {
  const goto = useRestoreLocation();

  const [newPopup, setNewPopup] = useState(false);
  const [othersPopup, setOthersPopup] = useState(false);

  const invalidate = useInvalidateTopics();
  const remove = useMutation(
    async (topicId: TopicId) => deleteMyTopic(topicId),
    {
      async onSuccess(_, topicId) {
        track({ event: "cast-topic.remove", data2: topicId });
        await invalidate();
      },
    }
  );

  const add = useMutation(async (topicId: TopicId) => putMyTopic(topicId), {
    onMutate(topicId) {
      // popup 邪魔なので先に閉じる
      setOthersPopup(false);
      track({ event: "cast-topic.add-others", data2: topicId });
    },
    async onSuccess() {
      await invalidate();
    },
  });

  return (
    <AppLayout2
      noVerticalMargin
      header={<HeaderWithBackButton title={"リクエストの受信設定"} />}
    >
      <Section>
        <p>話したい話題を設定してね❗</p>
        <p>設定すると、その話題を聴きたい人からリクエストを受けるよ😊</p>
      </Section>

      <Section colored>
        <HorizontalList margin={8} justifyContent="center">
          <AppPopover
            visible={othersPopup}
            onVisibleChange={setOthersPopup}
            track="cast-topic.show-others"
            content={
              <Scrollable>
                <OthersTopicList
                  topics={othersTopics}
                  onSelect={(topic) => {
                    add.mutate(topic.id);
                  }}
                />
              </Scrollable>
            }
          >
            <AppButton icon={<TeamOutlined />}>既存の話題から選ぶ</AppButton>
          </AppPopover>

          <AppPopover
            visible={newPopup}
            onVisibleChange={setNewPopup}
            track="cast-topic.show-add"
            content={
              <Popup>
                <NewTopic onNew={() => setNewPopup(false)} />
              </Popup>
            }
          >
            <AppButton icon={<PlusCircleOutlined />}>
              新しい話題を追加
            </AppButton>
          </AppPopover>
        </HorizontalList>
      </Section>

      <Section>
        <VerticalList align="center">
          <Spin spinning={add.isLoading || remove.isLoading}>
            <HorizontalList flexWrap="wrap" margin={0} justifyContent="center">
              {myTopics.map((it) => (
                <Wrap margin={2} key={it.id}>
                  <AppPopConfirm
                    title={"削除しますか"}
                    onConfirm={() => remove.mutate(it.id)}
                  >
                    <TopicView topic={it} />
                  </AppPopConfirm>
                </Wrap>
              ))}
            </HorizontalList>
          </Spin>
          <Button type="primary" onClick={goto}>
            完了
          </Button>
        </VerticalList>
      </Section>
    </AppLayout2>
  );
};

const Scrollable: FC = ({ children }) => {
  return (
    <div
      style={{
        overflow: "scroll",
        height: "70vh",
      }}
    >
      {children}
    </div>
  );
};

const Popup = styled.div`
  width: 80vw;
`;
