import React, { FC } from "react";

import { Card } from "antd";
import { UserForAdmin } from "../../../../common/api/admin";
import { Strong } from "../../../../common/components/Strong";
import { useQuery } from "react-query";
import { adminApi } from "../../../../common/api/api2";
import { VerticalList } from "../../../../common/components/VerticalList";
import { RequestSummary } from "./RequestSummary";
import { sortByStringDescending } from "../../../../lib/array";

interface AdminUserRequestSummaryProps {
  user: UserForAdmin;
}

export const AdminUserRequestSummaryInfo: FC<AdminUserRequestSummaryProps> = ({
  user,
}) => {
  const toMessages = useQuery(
    ["admin-user-requests-to", user.id],
    async () => (await adminApi.getAdminUserMessagesToUser(user.id)).data
  ).data!;

  const fromMessages = useQuery(
    ["admin-user-requests-from", user.id],
    async () => (await adminApi.getAdminUserMessagesFromUser(user.id)).data
  ).data!;

  const listenerRequests = sortByStringDescending(
    fromMessages.filter((it) => it.type === "REQUEST"),
    (it) => it.sentAt
  );

  const castRequests = sortByStringDescending(
    toMessages.filter((it) => it.type === "REQUEST"),
    (it) => it.sentAt
  );

  return (
    <>
      <Card>
        <Strong>キャスト行動</Strong>
        <VerticalList margin={12}>
          {castRequests.map((it) => (
            <RequestSummary
              key={it.id}
              user={it.from}
              request={it}
              thanks={toMessages.filter(
                (a) => a.requestId === it.requestId && a.type === "THANKS"
              )}
            />
          ))}
        </VerticalList>
      </Card>

      <Card>
        <Strong>リスナー行動</Strong>
        <VerticalList margin={12}>
          {listenerRequests.map((it) => (
            <RequestSummary
              key={it.id}
              user={it.to}
              request={it}
              thanks={fromMessages.filter(
                (a) => a.requestId === it.requestId && a.type === "THANKS"
              )}
            />
          ))}
        </VerticalList>
      </Card>
    </>
  );
};
