import React, { FC, useState } from "react";
import { Button, Input } from "antd";
import { usePageView } from "../../../common/hooks/usePageView";
import { AppLayout2 } from "../../../common/components/AppLayout2";
import { VerticalList } from "../../../common/components/VerticalList";
import { Section } from "../../../common/components/Section";
import styled from "styled-components";
import { AddLineButton } from "../../../common/components/AddLineButton";
import { Hero1 } from "../../../common/components/Hero1";
import circleImage from "../../../images/circle.png";
import { SectionTitle } from "../../components/SectionTitle";
import { Strong } from "../../../common/components/Strong";
import { RequestTargetSexType } from "../../components/RequestTargetSexType";
import { useQueryParams } from "../../../common/hooks/useQueryParams";
import { Sex2 } from "../../../common/api/types";
import { getQueryParams } from "../../../simplelive/SimpleLiveLpPage/model";
import { NoteBox } from "../../../common/components/NoteBox";
import { useGoto } from "../../../lib/hooks/useGoto";
import { publicRequestRoute } from "../PublicRequestPage/PublicRequestPage";

export const voiceMessageLpRoute = {
  path: "/voice-messages/lp",
  makePath: () => {
    return "/voice-messages/lp";
  },
};

interface Props {}

export const VoiceMessageLpPage: FC<Props> = () => {
  usePageView("voice-message-lp", true);
  const a1 = getTypeA1();
  const a2 = getTypeA2();

  return <VoiceMessageLpTemplate a1={a1} a2={a2} />;
};

export type TemplateProps = {
  a1: TypeA1;
  a2: TypeA2;
};

export const VoiceMessageLpTemplate: FC<TemplateProps> = ({ a1, a2 }) => {
  const [listenerName, setListenerName] = useState("");

  const listenerSex = useListenerSex();
  const [targetSex, setTargetSex] = useState(invertSex(listenerSex));

  const goRequest = useGoto(publicRequestRoute.makePath());

  return (
    <AppLayout2>
      <Hero1
        mainMessage={"いまこの瞬間\n自分だけに向けられた\nやさしい声が聴きたい"}
      />
      <Section>
        <VerticalList align="center" textAlign="center" margin={16}>
          <p>夜、さみしくて一人で眠れないことがありませんか</p>

          <p>
            部活や仕事で落ち込んだとき、ただやさしい言葉をかけてほしいときがありませんか
          </p>
        </VerticalList>
      </Section>

      <Section colored>
        <VerticalList align="center" textAlign="center" margin={16}>
          <p>
            Your Radio はユーザー同士が、 <br />
            互いにやさしい <Strong>声のメッセージ</Strong>{" "}
            を贈りあうサービスです
            <br />
          </p>

          <p>
            疲れたときはだれかの <Strong>声</Strong> に癒され、
            <br />
            元気なときは他の誰かに <Strong>声</Strong> を贈る
          </p>
          <p>Your Radioはそんな場所を作りたいと思っています</p>
          <p>
            まだまだ発展途上のサービスですが、あなたと一緒に素敵な場所を作っていけるとうれしいです
          </p>
        </VerticalList>
      </Section>

      <Section>
        {a1 === "circle" && (
          <VerticalList align="center">
            <Circle src={circleImage} width={100} height={100} />
          </VerticalList>
        )}
      </Section>

      {a2 === "line-login" && (
        <Section colored>
          <Button type="primary" onClick={goRequest}>
            声のメッセージをリクエスト
          </Button>
          <NoteBox>
            声のメッセージの通知を受けるためには LINE アカウントが必要です
          </NoteBox>
        </Section>
      )}

      {a2 === "add-line" && (
        <>
          <Section colored>
            <VerticalList align="center">
              <SectionTitle>声のメッセージをリクエスト</SectionTitle>
              <Input
                placeholder="呼び名 (○○さん)"
                value={listenerName}
                onChange={(e) => setListenerName(e.target.value)}
                style={{ width: "100%", fontSize: 16 }}
              />
              <RequestTargetSexType value={targetSex} onChange={setTargetSex} />

              <p>
                声のメッセージの通知を受けるために Your Radio の LINE
                を友だち追加してね
              </p>
              <p>
                <AddLineButton disabled={listenerName.length === 0} />
              </p>
            </VerticalList>
          </Section>

          <Section>
            <VerticalList align="center">
              <SectionTitle>声のメッセージを送る</SectionTitle>
              <p>Your Radio の LINE を友だち追加して、メニューに従ってね</p>
              <AddLineButton />
            </VerticalList>
          </Section>
        </>
      )}
    </AppLayout2>
  );
};

const Circle = styled.img`
  width: 80%;
  height: auto;
`;

function useListenerSex(): Sex2 {
  const s = useQueryParams("s");
  if (s === "f") {
    return "FEMALE";
  } else if (s === "m") {
    return "MALE";
  } else {
    return "FEMALE";
  }
}

function invertSex(sex: Sex2): Sex2 {
  if (sex === "FEMALE") {
    return "MALE";
  } else {
    return "FEMALE";
  }
}

type TypeA1 = "circle" | "no-circle";

function getTypeA1(): TypeA1 {
  const a = getQueryParams("a1");
  if (a === "1") {
    return "circle";
  } else if (a === "2") {
    return "no-circle";
  } else {
    return "circle";
  }
}

type TypeA2 = "add-line" | "line-login";

function getTypeA2(): TypeA2 {
  const a = getQueryParams("a2");
  if (a === "1") {
    return "add-line";
  } else if (a === "2") {
    return "line-login";
  } else {
    return "add-line";
  }
}
