export function assertIsDefined<T>(val: T): asserts val is NonNullable<T> {
  if (val === undefined || val === null) {
    throw new Error(`Expected 'val' to be defined, but received ${val}`);
  }
}

export function assertIsTrue(val: boolean, message?: string) {
  if (!val) {
    throw new Error(message);
  }
}

export function assertIsFalse(val: boolean, message?: string) {
  assertIsTrue(!val, message);
}
