/**
 * メッセージ内容に関する注意書き
 */
import { FC } from "react";
import { NoteBox } from "../../../common/components/NoteBox";

export const MessageContentNote: FC = () => {
  return (
    <NoteBox>
      個人情報等を記載しないように注意してください
      <br />
      不適切な内容が含まれている場合、運営によってリクエストは削除されます
    </NoteBox>
  );
};
