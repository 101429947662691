/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface OtherListenerUser
 */
export interface OtherListenerUser {
    /**
     * 
     * @type {string}
     * @memberof OtherListenerUser
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof OtherListenerUser
     */
    avatarUrl?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OtherListenerUser
     */
    tags: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OtherListenerUser
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof OtherListenerUser
     */
    type: OtherListenerUserTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof OtherListenerUser
     */
    isCast: boolean;
    /**
     * 
     * @type {string}
     * @memberof OtherListenerUser
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof OtherListenerUser
     */
    personalityId: string;
}

/**
    * @export
    * @enum {string}
    */
export enum OtherListenerUserTypeEnum {
    Full = 'FULL',
    Listener = 'LISTENER'
}



