import { useQuery, useQueryClient } from "react-query";
import {
  ByNameRequestForCast,
  getCastAssignedRequests,
  GetCastAssignedRequestsResult,
  getCastNotAssignedRequests,
  GetCastNotAssignedRequestsResult,
  getCastNotAssignedTopicRequests,
  getCastUserRequests,
  TopicRequestForCast,
} from "../../../common/api/cast";
import { queryParams, usePrefetch } from "../../../common/util/reactQuery";
import { useCallback } from "react";
import { UserId } from "../../../auth/auth";

/**
 * TODO admin じゃない
 */

const assignedRequestsOptions = queryParams(
  ["requests", "assigned"],
  async () => (await getCastAssignedRequests()).data,
  {
    staleTime: 60 * 1000,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  }
);

export function usePrefetchAssignedRequestsQuery() {
  usePrefetch(assignedRequestsOptions);
}

export function useAssignedRequests(): GetCastAssignedRequestsResult {
  return useQuery(...assignedRequestsOptions).data!;
}

export function useAssignedActiveRequests(): GetCastAssignedRequestsResult {
  const requests = useAssignedRequests();
  return {
    randomRequests: requests.randomRequests.filter(
      (it) => it.state === "REQUESTED_CENSOR_OK"
    ),
    byNameRequests: requests.byNameRequests.filter(
      (it) => it.state === "REQUESTED_CENSOR_OK"
    ),
  };
}

const notAssignedActiveRequestsOptions = queryParams(
  ["requests", "not-assigned"],
  async () => (await getCastNotAssignedRequests()).data,
  {
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  }
);

const notAssignedActiveTopicRequestsOptions = queryParams(
  ["requests", "not-assigned-topic"],
  async () => (await getCastNotAssignedTopicRequests()).data,
  {
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  }
);

export function usePrefetchNotAssignedActiveRequestsQuery() {
  usePrefetch(notAssignedActiveRequestsOptions);
}

export function useNotAssignedActiveRequests(): GetCastNotAssignedRequestsResult {
  return useQuery(...notAssignedActiveRequestsOptions).data!;
}

export function useNotAssignedActiveTopicRequests(): TopicRequestForCast[] {
  return useQuery(...notAssignedActiveTopicRequestsOptions).data!;
}

export function useNotAssignedByNameRequests(): ByNameRequestForCast[] {
  const requests = useNotAssignedActiveRequests();
  return requests.byNameRequests;
}

export function useInvalidateRequests() {
  const queryClient = useQueryClient();
  return useCallback(async () => {
    await queryClient.invalidateQueries("requests", {});
  }, [queryClient]);
}

export function useGetUserRequests(
  userId: UserId
): GetCastAssignedRequestsResult {
  return useQuery(
    ["requests", userId],
    async () => (await getCastUserRequests(userId)).data,
    {
      staleTime: 0,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
    }
  ).data!;
}
