import React, { FC } from "react";
import { ApiMessage } from "../../../api";
import { VerticalList } from "../../../common/components/VerticalList";
import { TopicView } from "../TopicView";
import { MultiLineEllipsis } from "../../../common/components/MultiLineEllipsis";
import styled from "styled-components";
import { Small2 } from "../../../common/components/Small2";
import { TalkTypeView } from "../../../common/components/TalkTypeView";
import { Small } from "../../../common/components/Small";

interface Props {
  message: ApiMessage;
  rows?: number;
}

export const TopicRequestContent: FC<Props> = ({ message, rows = 3 }) => {
  return (
    <VerticalList margin={12}>
      <TopicView
        topic={message.requestSupplement?.topic!}
        size="small"
        hideQuestion
      />
      <div>を聴きたいです</div>
      <Message>
        <MultiLineEllipsis rows={rows}>
          <Small2>{message.text}</Small2>
        </MultiLineEllipsis>
      </Message>
      <Small>
        <TalkTypeView value={message.requestSupplement!.talkType} />
      </Small>
    </VerticalList>
  );
};

const Message = styled.div`
  border-radius: 2px;
  background-color: #fafafa;
  padding: 4px;
`;
