import React, { FC, useCallback } from "react";
import { usePageView } from "../../../common/hooks/usePageView";
import { RequireLineSignIn } from "../../../auth/components/RequireLineSignIn";
import { PersonalityId } from "../../../common/api/api2";
import { OthersProfile } from "../../components/OthersProfile/OthersProfile";
import { useOtherUser } from "../../../common/hooks/user";
import { useHistory, useParams } from "react-router-dom";
import { AppLayout2 } from "../../../common/components/AppLayout2";
import { HeaderWithBackButton } from "../../../common/components/HeaderWithBackButton";
import { Section } from "../../../common/components/Section";
import { TopicForUser } from "../../../api";
import { talkRoute } from "../TalkPage/TalkPage";

export const othersProfileRoute = {
  path: "/users/:personalityId",
  makePath: (personalityId: PersonalityId) => {
    return `/users/${personalityId}`;
  },
};

export const OthersProfilePage: FC = () => {
  return (
    <RequireLineSignIn>
      <Impl />
    </RequireLineSignIn>
  );
};

const Impl: FC = () => {
  const { personalityId } = useParams<{ personalityId: string }>();
  usePageView("others-profile", true, personalityId);
  const other = useOtherUser(personalityId);
  const history = useHistory();
  const gotoRequest = useCallback(
    (topic: TopicForUser) => {
      history.push(talkRoute.makePath(personalityId, topic.id));
    },
    [history, personalityId]
  );

  return (
    <AppLayout2
      header={
        <HeaderWithBackButton
          title={other.name}
          // 戻し先が難しい。。
        />
      }
    >
      <Section>
        <OthersProfile
          personalityId={personalityId}
          onSelectTopic={gotoRequest}
        />
      </Section>
    </AppLayout2>
  );
};
