import { FC } from "react";
import styled from "styled-components";

import img from "./line_132.png";

export type LineButtonProps = {
  message?: string;
  onClick: () => void;
};

// https://developers.line.biz/ja/docs/line-login/login-button/#%E3%83%86%E3%82%99%E3%82%B5%E3%82%99%E3%82%A4%E3%83%B3%E3%82%AB%E3%82%99%E3%82%A4%E3%83%88%E3%82%99%E3%83%A9%E3%82%A4%E3%83%B3

export const LineButton: FC<LineButtonProps> = ({ message, onClick }) => {
  const message2 = message ?? "LINEでログイン";

  return (
    <Button onClick={onClick}>
      <Img src={img} />
      <Text>{message2}</Text>
    </Button>
  );
};

const x = 32;

const Button = styled.button`
  outline: none;

  color: #ffffff;
  margin: 0;
  padding: 0;

  background-color: #00c300;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 0;
  appearance: none;

  border-radius: 4px;

  &:active {
    background-color: #00b300;
  }

  // height: 100%;
  // min-width: 8px;
  // margin: 0 0.5px;
  // flex-grow: 1;
  //
  transition: background-color 0.1s;
`;

const Img = styled.img`
  width: ${x}px;
  height: ${x}px;
  border-right: 1px solid #50af33;
`;

const Text = styled.span`
  font-size: 11px;
  font-weight: bold;
  padding-left: ${x}px;
  padding-right: ${x}px;
`;
