import { FC } from "react";
import styled from "styled-components";

/**
 * 備考欄
 *
 * フォントサイズや行間を調整する
 */
export const NoteBox: FC = ({ children }) => {
  return <Root>{children}</Root>;
};

const Root = styled.div`
  font-size: 12px;
`;
