import { FC, ReactNode } from "react";
import styled from "styled-components";
import { HorizontalList } from "./HorizontalList";
import { Spin } from "antd";
import { primaryColor, primaryLightBgColor } from "../../colors";

export interface AppButtonProps {
  icon?: ReactNode;
  onClick?: () => void;
  fullWidth?: boolean;
  loading?: boolean;
  disabled?: boolean;
  type?: "primary";
  size?: "small";
}

export const AppButton: FC<AppButtonProps> = ({
  icon,
  onClick,
  fullWidth,
  loading,
  disabled,
  type,
  size,
  children,
}) => {
  const Root2 = size === "small" ? Small : Root;
  return (
    <Root2
      disabled={disabled || loading}
      onClick={(e) => {
        if (onClick) {
          onClick();
        }
      }}
      style={{
        backgroundColor: type === "primary" ? primaryLightBgColor : undefined,
        borderColor: type === "primary" ? primaryColor : undefined,
        width: fullWidth ? "100%" : undefined,
      }}
    >
      <HorizontalList margin={4} alignItems="center" justifyContent="center">
        {loading && <Spin size="small" />}
        {icon && <Icon>{icon}</Icon>}
        <Text>{children}</Text>
      </HorizontalList>
    </Root2>
  );
};

const Root = styled.button`
  transition: all 0.3s;
  appearance: none;

  font-size: 14px;

  color: black;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 4%) 0 2px 0;

  outline: none;

  padding: 8px;

  text-align: center;

  &:disabled {
    opacity: 0.5;
  }

  &:active {
    opacity: 0.5;
  }
`;

const Small = styled(Root)`
  padding: 2px;
`;

const Icon = styled.div``;

const Text = styled.div``;
