import React, { CSSProperties, FC } from "react";
import styled from "styled-components";

type AppSpaceProps = {
  margin?: number;
  alignItems?: CSSProperties["alignItems"];
  justifyContent?: CSSProperties["justifyContent"];
  flexWrap?: CSSProperties["flexWrap"];
  width?: CSSProperties["width"];
  height?: CSSProperties["height"];
  style?: CSSProperties;
};

export const HorizontalList: FC<AppSpaceProps> = ({
  margin,
  alignItems,
  justifyContent,
  flexWrap,
  width,
  height,
  style,
  children,
}) => {
  return (
    <Space
      margin={margin}
      style={{
        ...style,
        alignItems,
        justifyContent,
        flexWrap,
        width,
        height,
      }}
    >
      {children}
    </Space>
  );
};

type SpaceProps = {
  margin?: number;
};
const Space = styled.div<SpaceProps>`
  display: flex;
  flex-direction: row;
  position: relative;

  & > :not(:last-child) {
    margin-right: ${(p) => (p.margin !== undefined ? `${p.margin}px` : "16px")};
  }
`;
