import React, { FC } from "react";
import { useIsLineUserSignedIn } from "../../../auth/auth";
import {
  useSignOutAsMutation,
  useUserStatus,
} from "../../../common/hooks/user";
import { SignIn } from "../../../auth/components/SignIn";
import { VerticalList } from "../../../common/components/VerticalList";
import { Button } from "antd";

/**
 * Admin ページのルート
 * 適切な権限チェックと、sign in as を使っているときに、 sign out できるようにする
 * @constructor
 */
export const AdminRoot: FC = ({ children }) => {
  const signedIn = useIsLineUserSignedIn();
  const userStatus = useUserStatus();
  const signOutAs = useSignOutAsMutation();

  if (!signedIn) {
    return <SignIn />;
  }

  return (
    <>
      {userStatus.isProxying && (
        <VerticalList align="center">
          <div>
            <div>proxying</div>
            <div>{userStatus.id}</div>
            <div>{userStatus.name}</div>
            <Button
              onClick={() => signOutAs.mutate()}
              loading={signOutAs.isLoading}
            >
              Sign Out As
            </Button>
          </div>
        </VerticalList>
      )}
      {userStatus.isAdmin && children}
    </>
  );
};
