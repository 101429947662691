import { FC, useEffect, useRef } from "react";
import styled from "styled-components";
import { Reaction } from "../model";

export type ReactionBoxProps = {
  reactions: Reaction[];
};

export const ReactionBox: FC<ReactionBoxProps> = ({ reactions }) => {
  const numOfReactions = reactions.length;
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const e = ref.current;
    if (e) {
      // https://stackoverflow.com/questions/13362/scrolling-overflowed-divs-with-javascript
      const scrollHeight = Math.max(e.scrollHeight, e.clientHeight);
      e.scrollTo({
        top: scrollHeight - e.clientHeight,
        behavior: "smooth",
      });
    }
  }, [numOfReactions]);

  return (
    <ReactionList ref={ref}>
      {reactions.map((a, i) => (
        <div key={i}>{a.text}</div>
      ))}
    </ReactionList>
  );
};

const ReactionList = styled.div`
  height: 200px;
  overflow: scroll;
`;
