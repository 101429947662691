import React, { FC } from "react";
import { HelpLink } from "../../../common/components/HelpLink";
import { HorizontalCenter } from "../../../common/components/HorizontalCenter";
import { OpenLineButton } from "../../../common/components/OpenLineButton";

interface Props {}

export const RecordingInquiryHelp: FC<Props> = () => {
  return (
    <HelpLink help={<HelpMessage />} track="help.recording-inquiry">
      お困りのときは
    </HelpLink>
  );
};

const HelpMessage: FC = () => {
  return (
    <div>
      <p>メッセージを贈る相手を間違えてしまいましたか？</p>
      <p>メッセージの贈り方がわからずに困っていますか？</p>
      <p>
        困ったときは、お気軽に Your Radio の LINE
        アカウントにメッセージをいただけると嬉しいです
      </p>
      <HorizontalCenter>
        <OpenLineButton />
      </HorizontalCenter>
    </div>
  );
};
