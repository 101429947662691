import { useCallback, useEffect, useState } from "react";

/**
 * Audio player
 *
 * lazy を指定した場合、 play を呼び出してから load が始まる
 */
export function useAudioPlayer({
  url,
  lazy,
}: {
  url?: string | null;
  lazy?: boolean;
}) {
  const [audio] = useState(new Audio());
  const [playing, setPlaying] = useState(false);
  const [canPlay, setCanPlay] = useState(false);
  const [currentTime, setCurrentTimeState] = useState(0);

  useEffect(() => {
    if (!url) {
      return;
    }

    console.log("useAudioPlayer: setup");

    if (!lazy) {
      audio.src = url;
      audio.load();
    }

    function play() {
      console.log("useAudioPlayer play");
      setPlaying(true);
    }

    function pause() {
      console.log("useAudioPlayer pause");
      setPlaying(false);
    }

    function canPlay() {
      console.log("useAudioPlayer canplay");
      setCanPlay(true);
    }

    function timeupdate() {
      setCurrentTimeState(audio.currentTime);
    }

    audio.addEventListener("play", play);
    audio.addEventListener("pause", pause);
    audio.addEventListener("canplay", canPlay);
    audio.addEventListener("timeupdate", timeupdate);

    return () => {
      audio.src = "";
      audio.removeEventListener("play", play);
      audio.removeEventListener("pause", pause);
      audio.removeEventListener("canplay", canPlay);
      audio.removeEventListener("timeupdate", timeupdate);
    };
  }, [audio, lazy, url]);

  const play = useCallback(() => {
    if (lazy && url && audio.src !== url) {
      audio.src = url;
    }

    audio.muted = false;
    audio.volume = 1;
    audio.play().then();
  }, [audio, lazy, url]);

  const setCurrentTime = useCallback(
    (a: number) => {
      audio.currentTime = a;
    },
    [audio]
  );

  return {
    audio,
    playing,
    canPlay,
    play,
    currentTime,
    setCurrentTime,
  };
}
