import React, { FC } from "react";

import { VerticalList } from "../../../common/components/VerticalList";
import { Section } from "../../../common/components/Section";
import { AppLayout2 } from "../../../common/components/AppLayout2";
import { RequireLineSignIn } from "../../../auth/components/RequireLineSignIn";
import { usePageView } from "../../../common/hooks/usePageView";
import { useUserStatus } from "../../../common/hooks/user";
import { MyUser } from "../../../common/api/user";
import { homeRoute } from "../HomePage/HomePage";
import { HeaderWithBackButton } from "../../../common/components/HeaderWithBackButton";
import { MyProfile } from "../../components/MyProfile";
import {
  useMyTopics,
  usePrefetchMyTopics,
} from "../../../common/api/hooks/voiceMessage";
import { MyTopic } from "../../../api";
import { TopicList } from "../../components/TopicList";
import { useGoto } from "../../../lib/hooks/useGoto";
import { castTopicRoute } from "../CastTopicEditorPage/CastTopicPage";
import { Right } from "../../../common/components/Right";
import { Button } from "antd";

export const myPageRoute = {
  path: "/my",
  makePath: () => {
    return `/my`;
  },
};

export const MyPage: FC = () => {
  usePageView("my", true);

  return (
    <RequireLineSignIn>
      <Impl />
    </RequireLineSignIn>
  );
};

const Impl: FC = () => {
  usePrefetchMyTopics();
  const me = useUserStatus();
  const topics = useMyTopics();

  return <MyPageTemplate me={me} topics={topics} />;
};

interface MyPageTemplateProps {
  me: MyUser;
  topics: MyTopic[];
}

export const MyPageTemplate: FC<MyPageTemplateProps> = ({ me, topics }) => {
  const goTopic = useGoto(castTopicRoute.makePath(), { saveLocation: true });

  return (
    <AppLayout2
      noVerticalMargin
      header={
        <HeaderWithBackButton
          title="マイページ"
          backPath={homeRoute.makePath()}
        />
      }
    >
      <Section>
        <VerticalList margin={16}>
          <MyProfile myUser={me} />

          <div>
            <TopicList topics={topics} size="small" hideQuestion />
            <Right>
              <Button type="link" onClick={goTopic}>
                話題の設定
              </Button>
            </Right>
          </div>
        </VerticalList>
      </Section>
    </AppLayout2>
  );
};
