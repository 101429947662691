/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface PostRequest
 */
export interface PostRequest {
    /**
     * 指定すると特定のキャストに対してリクエストを行います
     * @type {string}
     * @memberof PostRequest
     */
    castId?: string;
    /**
     * キャスト指定時は不要です
     * @type {string}
     * @memberof PostRequest
     */
    castSex?: PostRequestCastSexEnum;
    /**
     * 質問への回答 or 追加のメッセージ
     * @type {string}
     * @memberof PostRequest
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof PostRequest
     */
    listenerName: string;
    /**
     * 
     * @type {string}
     * @memberof PostRequest
     */
    talkType: PostRequestTalkTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof PostRequest
     */
    listenerTags: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PostRequest
     */
    topicId: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PostRequestCastSexEnum {
    Male = 'MALE',
    Female = 'FEMALE'
}
/**
    * @export
    * @enum {string}
    */
export enum PostRequestTalkTypeEnum {
    Polite = 'POLITE',
    NotPolite = 'NOT_POLITE'
}



