import React, { FC } from "react";
import { Badge } from "antd";

interface Props {
  count: number;
}

export const AppBadge: FC<Props> = ({ count, children }) => {
  return (
    <Badge
      count={count}
      style={{
        zIndex: 1,
      }}
    >
      {children}
    </Badge>
  );
};
