import { InViewHookResponse, useInView } from "react-intersection-observer";

type Ref = InViewHookResponse["ref"];

/**
 * スクロール位置が指定した範囲内にあるかどうかによって挙動を変える際に使用します
 * window 最下部が form と to の間に含まれるか
 */
export function useBetween(): [boolean, Ref, Ref] {
  const from = useInView();
  const to = useInView();

  const fromY = from.entry?.target.getBoundingClientRect().top ?? 0;
  const toY = to.entry?.target.getBoundingClientRect().top ?? 0;
  const scrollY = window.innerHeight;

  const isBetween = fromY <= scrollY && scrollY < toY;
  //
  // console.log(scrollY, fromY, toY, isBetween);

  return [isBetween, from.ref, to.ref];
}
