import TagManager from "react-gtm-module";
import { Topic } from "./simplelive/model";

let initialized = false;

export function initializeGtm() {
  const noGtm =
    process.env.NODE_ENV !== "production" ||
    window.location.search.includes("no-gtm");

  if (noGtm) {
    console.log("no gtm");

    TagManager.initialize({
      gtmId: "GTM-N9D765T",
      auth: "0lFl1Pj_YVqGoD8q1aDOGw",
      preview: "env-12",
    });
  } else {
    console.log("gtm");
    TagManager.initialize({
      gtmId: "GTM-N9D765T",
    });
  }
  initialized = true;
}

export function gtmEvent(params: any) {
  if (!initialized) {
    return;
  }
  TagManager.dataLayer({
    dataLayer: params,
  });
}

export function eventClickRegister(where: string) {
  gtmEvent({
    event: "yr.click_register",
    where: where,
  });
}

export function eventStartListening() {
  gtmEvent({
    event: "yr.start_listening",
  });
}

export function eventListenerSurvey() {
  gtmEvent({
    event: "yr.listener_survey",
  });
}

export function eventListenerRegister() {
  gtmEvent({
    event: "yr.listener_register",
  });
}

export function eventListenerVoiceMessageSurvey() {
  gtmEvent({
    event: "yr.listener_voice_message_survey",
  });
}

export function eventLineRegister() {
  gtmEvent({
    event: "yr.line_register",
  });
}

export function eventPartnerRegister() {
  gtmEvent({
    event: "yr.partner_register",
  });
}

export function eventInView(name: string) {
  gtmEvent({
    event: `yr.in_view_${name}`,
  });
}

export function eventPageView(name: string) {
  gtmEvent({
    event: `yr.page_${name}`,
  });
}

export function eventTwitterFollow() {
  gtmEvent({
    event: `yr.twitter_follow`,
  });
}

export function eventLookAtTopic(topic: Topic) {
  gtmEvent({
    event: `yr.look_at_topic`,
    name: topic.title,
  });
}
