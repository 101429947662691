import React, { FC } from "react";

import heroImg from "./naisyo.jpg";
import styled from "styled-components";

export const HeroBox: FC = ({ children }) => {
  return (
    <Root>
      <Hero src={heroImg} alt="" />
      <TextBox>{children}</TextBox>
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  margin: 0 -24px;
  width: 100vw;
  // カクついて次の section が track されるのを避けたい
  min-height: 300px;
  // 画像が出るまでの間
  background-color: #5e473a;
`;

const Hero = styled.img`
  width: 100%;
  filter: brightness(0.5);
`;

const TextBox = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  color: #fff;
`;
