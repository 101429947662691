/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Avatar } from './avatar';

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    isAdmin: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    isCast: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    isAnonymous: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    lineId?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    sex?: UserSexEnum;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    castAvatar?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    castAvatarUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    createdAt?: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    isPseudo: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    pseudoLineId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    isProxying: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    actualUserId?: string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    level: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    point?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    listenerAvatar?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    listenerAvatarUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    isBanned: boolean;
    /**
     * 
     * @type {Set<string>}
     * @memberof User
     */
    features: Set<string>;
    /**
     * 
     * @type {Set<string>}
     * @memberof User
     */
    tags: Set<string>;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    avatarFileId?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    messageForCensor?: string;
    /**
     * 
     * @type {Avatar}
     * @memberof User
     */
    avatarForCensor?: Avatar;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    listenerNameForDoublePersonality?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    listenerId: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    isDisabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    mainUserId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    canBeRequested: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    staff: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    listenerName?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    lineIdConsiderPseudoUser?: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    canReceivePoint: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    needsCensor: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    hasDoublePersonality: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    hasProfile: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    listenerPersonality: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum UserSexEnum {
    Male = 'MALE',
    Female = 'FEMALE'
}



