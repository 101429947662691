import React, { CSSProperties, FC } from "react";
import styled from "styled-components";

interface Props {
  align?: CSSProperties["alignItems"];
  margin?: number;
  textAlign?: string;
  justifyContent?: CSSProperties["justifyContent"];
  width?: CSSProperties["width"];
  height?: CSSProperties["height"];
  onClick?: () => void;
  style?: CSSProperties;
}

export const VerticalList: FC<Props> = ({
  align,
  margin,
  textAlign,
  width,
  height,
  justifyContent,
  style,
  children,
  ...rest
}) => {
  // antd のものは 子供の幅を 100% にするのが難しいので自前で
  return (
    <Space
      align={align}
      margin={margin}
      textAlign={textAlign}
      justifyContent={justifyContent}
      style={{
        ...style,
        width,
        height,
      }}
      {...rest}
    >
      {children}
    </Space>
  );
};

const Space = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: ${(p) => p.align};
  text-align: ${(p) => p.textAlign};
  justify-content: ${(p) => p.justifyContent};

  & > :not(:last-child) {
    margin-bottom: ${(p) =>
      p.margin !== undefined ? `${p.margin}px` : "16px"};
  }
`;
