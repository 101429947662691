import React, { FC } from "react";

interface Props {}

export const Vision: FC<Props> = () => {
  return (
    <div>
      Your Radioは、互いにやさしい声のメッセージを贈りあう場所だよ！
      <br />
      <br />
      明るい人、おとなしい人、口下手な人 いろんな人がいるよ
      <br />
      <br />
      そんなみんなが、 やさしさでつながる場を一緒に作っていこうっ
    </div>
  );
};
