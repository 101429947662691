import React, { FC } from "react";

import logo from "../../../images/logo_icon.png";

type LogoProps = {
  width?: number;
};

export const LogoIcon: FC<LogoProps> = ({ width }) => {
  const width2 = width ?? 100;
  return (
    <img
      src={logo}
      alt=""
      style={{
        width: width2,
      }}
    />
  );
};
