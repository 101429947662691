import { useQuery, useQueryClient, UseQueryResult } from "react-query";
import { getMyTopics } from "../cast";
import { Topic } from "../voicemessage/api";
import { getTopics } from "../topic";
import { queryParams, usePrefetch } from "../../util/reactQuery";
import { Sex3, topicApi } from "../api2";
import { TopicForUser } from "../../../api";

export function useTopics(): Topic[] {
  return useQuery(["topics"], async () => (await getTopics()).data).data!;
}

export function useTopicsForRequestQuery(
  sex: Sex3
): UseQueryResult<Array<TopicForUser>, unknown> {
  return useQuery(
    ["topics-for-requests", sex],
    async () => (await topicApi.getTopicsForRequest(sex)).data,
    {
      suspense: false,
    }
  );
}

const myTopicsOptions = queryParams(
  ["topics", "my-topics"],
  async () => (await getMyTopics()).data
);

export function usePrefetchMyTopics() {
  usePrefetch(myTopicsOptions);
}

export function useMyTopics(): Topic[] {
  return useQuery(...myTopicsOptions).data!;
}

export function useInvalidateTopics(): () => Promise<void> {
  const queryClient = useQueryClient();
  return async () => {
    await queryClient.invalidateQueries(["topics"]);
  };
}
