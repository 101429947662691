import React, { FC } from "react";

import { Button } from "antd";
import { CaretRightOutlined, PauseOutlined } from "@ant-design/icons";

type PlayButtonProps = {
  onPlay: () => void;
  onPause: () => void;
  playing: boolean;
};

export const PlayButton: FC<PlayButtonProps> = ({
  playing,
  onPause,
  onPlay,
}) => {
  return (
    <>
      {playing ? (
        <Button icon={<PauseOutlined />} onClick={onPause}>
          停止
        </Button>
      ) : (
        <Button icon={<CaretRightOutlined />} onClick={onPlay}>
          再生
        </Button>
      )}
    </>
  );
};
