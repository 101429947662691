import { UserId } from "../../auth/auth";
import {
  AdminVoiceMessage,
  HttpSuccess,
  NotFound,
  Sex2,
  VoiceMessageId,
} from "./types";
import axios from "axios";
import { apiUrl } from "./api";
import { UserTag, VoiceMessageState } from "./voicemessage/api";
import { User, UserProfileResponse } from "../../api";

export type UserForAdmin = User;
export type AdminLineUser = UserProfileResponse;

export interface VoiceMessageRequestForAdmin {
  cast?: UserForAdmin;
  name: string;
  talkType: string;
  targetSex: Sex2;
  request: string;
  message?: string;
  situation?: string;
  listenerTags: UserTag[];
}

export async function getAdminVoiceMessages(
  state?: VoiceMessageState
): Promise<HttpSuccess<AdminVoiceMessage[]>> {
  const res = await axios.get(`${apiUrl}/admin/voice-messages`, {
    params: {
      state: state,
    },
  });
  console.log(res);
  return { kind: "ok", data: res.data };
}

export async function deleteAdminVoiceMessage(
  voiceMessageId: VoiceMessageId
): Promise<void> {
  const res = await axios.delete(
    `${apiUrl}/admin/voice-messages/${voiceMessageId}`
  );
  console.log(res);
}

export async function postAdminVoiceMessageIgnore(
  voiceMessageId: VoiceMessageId
): Promise<void> {
  const res = await axios.post(
    `${apiUrl}/admin/voice-messages/${voiceMessageId}/ignore`
  );
  console.log(res);
}

export async function getAdminCasts(): Promise<HttpSuccess<UserForAdmin[]>> {
  const res = await axios.get(`${apiUrl}/admin/casts`);
  console.log(res);
  return { kind: "ok", data: res.data };
}

export async function postAdminVoiceMessageAssign(params: {
  voiceMessageId: VoiceMessageId;
  castId: UserId;
}): Promise<HttpSuccess<void>> {
  const res = await axios.post(
    `${apiUrl}/admin/voice-messages/${params.voiceMessageId}/assign`,
    {
      castId: params.castId,
    }
  );
  console.log(res);
  return { kind: "ok", data: res.data };
}

export async function getAdminLineUser(
  lineId: string
): Promise<HttpSuccess<AdminLineUser> | NotFound> {
  try {
    const res = await axios.get(`${apiUrl}/admin/line-users/${lineId}`);
    console.log(res);
    return { kind: "ok", data: res.data };
  } catch (e) {
    if (e.response?.status === 404) {
      return {
        kind: "not-found",
      };
    } else {
      throw e;
    }
  }
}

export async function postAdminSignInAsUser(asUserId: UserId) {
  const res = await axios.post(`${apiUrl}/admin/sign-in-as/${asUserId}`);
  console.log(res);
  return { kind: "ok", data: res.data };
}

export async function postAdminPseudoUser() {
  const res = await axios.post(`${apiUrl}/admin/pseudo-users`);
  console.log(res);
  return { kind: "ok", data: res.data };
}

export async function postAdminUserPoint(p: { userId: UserId; diff: number }) {
  const res = await axios.post(`${apiUrl}/admin/users/${p.userId}/points`, {
    diff: p.diff,
  });
  console.log(res);
  return { kind: "ok", data: res.data };
}
