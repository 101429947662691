import React, { FC, useState } from "react";

import { useScrollToTopOnMount } from "../../../../common/hooks/useScrollToTopOnMount";
import { RequireAdmin } from "../../components/RequireAdmin";
import { Button, Input, Select } from "antd";
import { VerticalList } from "../../../../common/components/VerticalList";
import { AppPopConfirm } from "../../../../common/components/AppPopConfirm";
import { useMutation } from "react-query";
import {
  NotificationLink,
  postStaffNotification,
} from "../../../../common/api/staff";
import { useAdminUsers } from "../../hooks/adminUser";
import { AppLayout2 } from "../../../../common/components/AppLayout2";
import { compareString } from "../../../../common/util/string";

export const adminSendNotificationRoute = {
  path: "/admin/notifications",
  makePath(lineId: string) {
    return `/admin/notifications`;
  },
};

export const AdminSendNotificationPage: FC = () => {
  return (
    <RequireAdmin>
      <Impl />
    </RequireAdmin>
  );
};

export const Impl: FC = () => {
  useScrollToTopOnMount();
  const [userId, setUser] = useState<string>();
  const [message, setMessage] = useState("");
  const [link, setLink] = useState("NONE" as NotificationLink);
  const users = useAdminUsers().sort((a, b) => compareString(a.id, b.id));

  const send = useMutation(
    async () =>
      await postStaffNotification({
        userId: userId!,
        message,
        link,
      })
  );

  return (
    <AppLayout2>
      <VerticalList align="stretch">
        <Select
          value={userId}
          onChange={(v) => setUser(v)}
          placeholder="送信先ユーザー"
          showSearch
        >
          {users.map((it) => (
            <Select.Option key={it.id} value={it.id}>
              {it.name} {it.id}
            </Select.Option>
          ))}
        </Select>
        <Input.TextArea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          rows={10}
        />
        <Select value={link} onChange={(v) => setLink(v)}>
          <Select.Option value="NONE">NONE</Select.Option>
          <Select.Option value="CAST">CAST</Select.Option>
          <Select.Option value="LISTENER">LISTENER</Select.Option>
        </Select>
        <AppPopConfirm
          title="送信してもよろしいですか"
          onConfirm={() => send.mutate()}
        >
          <Button loading={send.isLoading}>送信</Button>
        </AppPopConfirm>
      </VerticalList>
    </AppLayout2>
  );
};
