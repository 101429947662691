/**
 * VoiceMessageLp2 用
 */
import { useCallback, useEffect, useState } from "react";
import { PostRequest } from "../../api";
import { listenerApi } from "../../common/api/api2";
import { useMutation } from "react-query";
import { useInvalidateUserStatus } from "../../common/hooks/user";

const LP2_REQUEST_KEY = "lp2-request";

export function useSaveRequest() {
  return useCallback((request: PostRequest) => {
    localStorage.setItem(LP2_REQUEST_KEY, JSON.stringify(request));
  }, []);
}

export function useSavedRequest(): PostRequest | null {
  const [a] = useState(() => {
    const a = localStorage.getItem(LP2_REQUEST_KEY);
    if (!a) {
      return null;
    }
    return JSON.parse(a);
  });
  return a;
}

export function useRemoveSavedRequest() {
  return useCallback(() => {
    localStorage.removeItem(LP2_REQUEST_KEY);
  }, []);
}

export function useRequestActually() {
  const request = useSavedRequest();
  const invalidate = useInvalidateUserStatus();
  const [isLoading, setLoading] = useState(true);

  const { mutate } = useMutation(
    async (request: PostRequest) => await listenerApi.postRequest(request),
    {
      retry: 5,
      retryDelay: (attempt) => attempt * 1000,
      async onSuccess() {
        localStorage.removeItem(LP2_REQUEST_KEY);
        await invalidate();
        setLoading(false);
      },
    }
  );

  // リクエストが残っているならリクエストする
  useEffect(() => {
    if (!request) {
      setLoading(false);
      return;
    }
    mutate(request);
  }, [mutate, request]);
  return isLoading;
}
