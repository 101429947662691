import {
  ApiMessage,
  ApiPointLog,
  OtherFullUser,
  OtherUser,
  Talk,
} from "../../../api";
import { compareString } from "../../../common/util/string";
import { RequestId } from "../../../common/api/types";
import moment from "moment";
import { MyUser2 } from "../../../common/api/api2";
import { TopicId } from "../../../common/api/voicemessage/api";
import { useUserTopicsQuery } from "../../hooks/topic";

export type FrontMessage =
  | UserMessage
  | PointMessage
  | RequestRequest
  | RequestThanks
  | RequestGivePoint
  | RequestResponse
  | RequestRequestAddition
  | RequestViewProfile;

interface UserMessage {
  type: "USER_MESSAGE";
  key: string;
  message: ApiMessage;
  sentAt: string;
}

interface PointMessage {
  type: "POINT_MESSAGE";
  key: string;
  sentAt: string;
  log: ApiPointLog;
}

interface RequestRequest {
  type: "REQUEST_REQUEST";
  key: string;
  cast: OtherFullUser;
  sentAt: string;
  defaultRequestedTopicId: TopicId | null;
}

interface RequestThanks {
  type: "REQUEST_THANKS";
  key: string;
  cast: OtherFullUser;
  numOfThanksLeft: number;
  requestId: RequestId;
  sentAt: string;
}

export interface RequestGivePoint {
  type: "REQUEST_GIVE_POINT";
  key: string;
  other: OtherUser;
  numOfLeft: number;
  requestId: RequestId;
  sentAt: string;
}

interface RequestResponse {
  type: "REQUEST_RESPONSE";
  key: string;
  listener: OtherUser;
  requestId: RequestId;
  sentAt: string;
}

interface RequestRequestAddition {
  type: "REQUEST_REQUEST_ADDITION";
  key: string;
  cast: OtherFullUser;
  numOfRequestAdditionsLeft: number;
  requestId: RequestId;
  sentAt: string;
}

interface RequestViewProfile {
  type: "REQUEST_VIEW_PROFILE";
  key: string;
  other: OtherUser;
  sentAt: string;
}

export function useFrontMessages(
  talk: Talk,
  me: MyUser2,
  defaultRequestedTopicId: TopicId | null,
  pointLogs: ApiPointLog[]
): FrontMessage[] {
  const othersTopicsQuery = useUserTopicsQuery(talk.toUser.personalityId, {
    enabled: talk.toUser.isCast,
  });

  const systemMessages = getSystemMessages(
    talk,
    me,
    defaultRequestedTopicId,
    othersTopicsQuery.isSuccess && othersTopicsQuery.data.length >= 1,
    pointLogs
  );
  const userMessages: FrontMessage[] = talk.messages.map((it) => ({
    type: "USER_MESSAGE",
    key: it.id,
    message: it,
    sentAt: it.sentAt,
  }));

  const pointMessages: FrontMessage[] = pointLogs.map((it) => ({
    type: "POINT_MESSAGE",
    key: "point-" + it.createdAt,
    log: it,
    sentAt: it.createdAt,
  }));

  return [...systemMessages, ...userMessages, ...pointMessages].sort((a, b) =>
    compareString(a.sentAt, b.sentAt)
  );
}

/**
 * とりあえずシステムメッセージはフロントでやる
 *
 * なぜなら、サーバーが関わることはほとんどないので、シンプルにしたいため
 * (native だとまた別)
 */
function getSystemMessages(
  talk: Talk,
  me: MyUser2,
  defaultRequestedTopicId: TopicId | null,
  hasTopics: boolean,
  pointLogs: ApiPointLog[]
): FrontMessage[] {
  const result: FrontMessage[] = [];
  const messagesInNewestOrder = [...talk.messages].sort((a, b) =>
    compareString(b.sentAt, a.sentAt)
  );
  const pointLogsInNewestOrder = [...pointLogs].sort((a, b) =>
    compareString(b.createdAt, a.createdAt)
  );

  result.push(...requestRequestAddition(talk, messagesInNewestOrder));

  result.push(...castSystemMessage(talk, messagesInNewestOrder));
  result.push(...requestViewProfile(messagesInNewestOrder, talk));

  // プロフィールを見てみるより後
  result.push(...requestThanks(messagesInNewestOrder, talk));
  result.push(
    ...requestGivePoint(messagesInNewestOrder, talk, pointLogsInNewestOrder)
  );

  result.push(
    ...requestRequest(
      messagesInNewestOrder,
      talk,
      defaultRequestedTopicId,
      hasTopics
    )
  );

  return result;
}

function requestThanks(
  messagesInNewestOrder: ApiMessage[],
  talk: Talk
): FrontMessage[] {
  const cast = talk.toUser as OtherFullUser;

  const lastOthersResponse = [...messagesInNewestOrder].find(
    (it) =>
      it.type === "RESPONSE" && it.from.id === talk.toUser.id && it.expiredAt
  );

  if (!lastOthersResponse) {
    return [];
  }

  const lastMyThanksList = messagesInNewestOrder.filter(
    (it) =>
      it.sentAt >= lastOthersResponse.sentAt &&
      it.type === "THANKS" &&
      it.from.id !== talk.toUser.id
  );
  const numOfThanksLeft = 2 - lastMyThanksList.length;
  if (numOfThanksLeft <= 0) {
    return [];
  }

  return [
    {
      type: "REQUEST_THANKS",
      key: "REQUEST_THANKS",
      cast,
      numOfThanksLeft,
      requestId: lastOthersResponse.requestId!!,
      sentAt: moment(lastOthersResponse.sentAt).add(1, "second").toISOString(),
    },
  ];
}

function requestGivePoint(
  messagesInNewestOrder: ApiMessage[],
  talk: Talk,
  pointLogsInNewestOrder: ApiPointLog[]
): FrontMessage[] {
  const cast = talk.toUser as OtherFullUser;

  const lastOthersResponse = [...messagesInNewestOrder].find(
    (it) =>
      it.type === "RESPONSE" && it.from.id === talk.toUser.id && it.expiredAt
  );

  if (!lastOthersResponse) {
    return [];
  }

  const lastMyGivePointList = pointLogsInNewestOrder.filter(
    (it) =>
      it.createdAt >= lastOthersResponse.sentAt && it.from.id !== talk.toUser.id
  );
  const numOfLeft = 3 - lastMyGivePointList.length;
  if (numOfLeft <= 0) {
    return [];
  }

  return [
    {
      type: "REQUEST_GIVE_POINT",
      key: "REQUEST_GIVE_POINT",
      other: cast,
      numOfLeft,
      requestId: lastOthersResponse.requestId!!,
      sentAt: moment(lastOthersResponse.sentAt).add(1, "second").toISOString(),
    },
  ];
}

function requestViewProfile(
  messagesInNewestOrder: ApiMessage[],
  talk: Talk
): FrontMessage[] {
  const firstOthersMessage = [...messagesInNewestOrder]
    .reverse()
    .find((it) => it.from.id === talk.toUser.id);

  if (firstOthersMessage) {
    return [
      {
        type: "REQUEST_VIEW_PROFILE",
        key: "REQUEST_VIEW_PROFILE",
        other: talk.toUser,
        sentAt: moment(firstOthersMessage.sentAt)
          .add(1, "second")
          .toISOString(),
      },
    ];
  }

  return [];
}

function requestRequest(
  messagesInNewestOrder: ApiMessage[],
  talk: Talk,
  defaultRequestedTopicId: TopicId | null,
  hasTopics: boolean
): FrontMessage[] {
  if (!hasTopics) {
    return [];
  }

  if (!talk.toUser.isCast) {
    return [];
  }

  const cast = talk.toUser as OtherFullUser;

  return [
    {
      type: "REQUEST_REQUEST",
      key: "REQUEST_REQUEST",
      cast,
      sentAt: moment().toISOString(),
      defaultRequestedTopicId,
    },
  ];
}

function requestRequestAddition(talk: Talk, messages: ApiMessage[]) {
  const result: FrontMessage[] = [];
  const cast = talk.toUser as OtherFullUser;

  const lastMyRequest = messages.find(
    (it) => it.type === "REQUEST" && it.from.id !== talk.toUser.id
  );

  if (lastMyRequest) {
    const lastOtherResponses = messages.filter(
      (it) =>
        it.sentAt >= lastMyRequest.sentAt &&
        it.type === "RESPONSE" &&
        it.from.id === talk.toUser.id
    );

    if (lastOtherResponses.length === 0) {
      const lastMyAdditions = messages.filter(
        (it) =>
          it.sentAt >= lastMyRequest.sentAt &&
          it.type === "REQUEST_ADDITION" &&
          it.from.id !== talk.toUser.id
      );
      const numOfRequestAdditionsLeft = 1 - lastMyAdditions.length;
      if (numOfRequestAdditionsLeft >= 1) {
        result.push({
          type: "REQUEST_REQUEST_ADDITION",
          key: "REQUEST_REQUEST_ADDITION",
          cast,
          numOfRequestAdditionsLeft,
          requestId: lastMyRequest.requestId!!,
          sentAt: moment(lastMyRequest.sentAt).add(1, "second").toISOString(),
        });
      }
    }
  }

  return result;
}

function castSystemMessage(talk: Talk, messages: ApiMessage[]) {
  const result: FrontMessage[] = [];

  const lastOthersRequest = messages.find(
    (it) => it.type === "REQUEST" && it.from.id === talk.toUser.id
  );

  if (lastOthersRequest) {
    const lastMyResponses = messages.filter(
      (it) =>
        it.sentAt >= lastOthersRequest.sentAt &&
        it.type === "RESPONSE" &&
        it.from.id !== talk.toUser.id
    );
    if (lastMyResponses.length === 0) {
      result.push({
        type: "REQUEST_RESPONSE",
        key: "REQUEST_RESPONSE",
        listener: talk.toUser,
        requestId: lastOthersRequest.requestId!!,
        sentAt: moment().toISOString(),
      });
    }
  }
  return result;
}
