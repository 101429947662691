import React, { FC, useCallback } from "react";
import { Button } from "antd";
import styled from "styled-components";
import { postMessage } from "../../common/api/api";
import { eventListenerSurvey } from "../../gtm";
import { usePageView } from "../../common/hooks/usePageView";
import { Follow } from "react-twitter-widgets";
import { Host } from "../../common/api/types";

const surveyUrl =
  "https://docs.google.com/forms/d/e/1FAIpQLSeGWNlTw_9NCODlX3EGFCjRGDWWPrvO-_vJhtBVOn1iU5iPuQ/viewform?usp=sf_link";

export type ClosedProps = {
  sessionId: string;
  host: Host;
};

export const Closed: FC<ClosedProps> = ({ sessionId, host }) => {
  usePageView("closed");
  const survey = useCallback(() => {
    eventListenerSurvey();
    postMessage(sessionId, "アンケートをクリックしたよ").then();
    window.open(surveyUrl, "_blank");
  }, [sessionId]);

  return (
    <CenterDescriptionBox>
      <Paragraph>配信が終わりました</Paragraph>

      <HostBox>
        <Paragraph>
          今回のキャストは {host.displayName} でした
          <br />
        </Paragraph>
        {host.twitterName && (
          <>
            <Paragraph>よかったら Twitter もフォローしてくださいね</Paragraph>
            <FollowBox>
              <Follow username={host.twitterName} />
            </FollowBox>
          </>
        )}
      </HostBox>

      <Paragraph>
        もしよろしければ、配信者へのメッセージとアンケートにご協力いただけないでしょうか
      </Paragraph>
      <Button type="primary" onClick={survey}>
        キャストへお礼のメッセージを送る
      </Button>
    </CenterDescriptionBox>
  );
};

const margin = "20px";

const CenterDescriptionBox = styled.div`
  margin: 0 ${margin};
  text-align: center;
`;

const FollowBox = styled.div`
  margin: 8px 0;
`;

const HostBox = styled.div`
  margin: 16px 0;
`;

const Paragraph = styled.div`
  font-size: 12px;
  margin: 0 0 12px;
`;
