import { useCallback, useRef, useState } from "react";
import { v4 } from "uuid";
import moment, { Moment } from "moment";

export interface Voice {
  id: string;
  datetime: Moment;
  blob: Blob;
}

/**
 * 録音した結果の blob を溜め込むリスト
 */
export function useVoiceList() {
  const [voices, setVoices] = useState<Voice[]>([]);
  const id = useRef(1);

  const add = useCallback((newBlob: Blob) => {
    setVoices((a) => [
      {
        id: v4(),
        datetime: moment(),
        blob: newBlob,
      },
      ...a,
    ]);
    id.current++;
  }, []);

  return {
    voices,
    add,
  };
}
