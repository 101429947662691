import React, { FC } from "react";
import { OtherUser } from "../../../../api";
import styled from "styled-components";
import { AppAvatar } from "../../../components/AppAvatar";
import { HorizontalList } from "../../../../common/components/HorizontalList";
import { DateView } from "../../../../common/components/DateView";
import { VerticalList } from "../../../../common/components/VerticalList";
import { Small } from "../../../../common/components/Small";
import { Right } from "../../../../common/components/Right";
import { MessageWrap } from "./MessageWrap";
import { Strong } from "../../../../common/components/Strong";
import { MyUser2 } from "../../../../common/api/api2";

interface Props {
  me: MyUser2;
  /**
   * 未指定の場合はシステムからのメッセージ
   */
  user?: OtherUser;
  fromName?: string;
  sentAt?: string;
}

/**
 * メッセージを表示する
 */
export const MessageView: FC<Props> = ({
  me,
  user,
  sentAt,
  fromName,
  children,
}) => {
  const mine = user?.id === me.id || user?.id === me.listenerId;

  return (
    <MessageWrap mine={mine}>
      <HorizontalList margin={4}>
        {!mine && (
          <AppAvatar src={user?.avatarUrl} system={!user} size="small" />
        )}
        <VerticalList margin={0}>
          {fromName && (
            <div>
              <Strong>{fromName}</Strong>
            </div>
          )}

          <Box>{children}</Box>
          <Right>
            <Small>
              <DateView value={sentAt} />
            </Small>
          </Right>
        </VerticalList>
        {mine && <AppAvatar src={user?.avatarUrl} size="small" />}
      </HorizontalList>
    </MessageWrap>
  );
};

const Box = styled.div`
  background-color: white;
  padding: 8px;

  border-radius: 4px 4px 4px 4px;
  box-shadow: rgb(0 0 0 / 4%) 0 1px 0;
`;
