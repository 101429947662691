/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { MyTopic } from '../model';
// @ts-ignore
import { PostCastTopic } from '../model';
// @ts-ignore
import { PutCastVoiceMessageRequest } from '../model';
/**
 * CastApi - axios parameter creator
 * @export
 */
export const CastApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} topicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCastTopic: async (topicId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'topicId' is not null or undefined
            assertParamExists('deleteCastTopic', 'topicId', topicId)
            const localVarPath = `/cast/@me/topics/{topicId}`
                .replace(`{${"topicId"}}`, encodeURIComponent(String(topicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCastTopics: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/cast/@me/topics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostCastTopic} postCastTopic 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCastTopic: async (postCastTopic: PostCastTopic, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postCastTopic' is not null or undefined
            assertParamExists('postCastTopic', 'postCastTopic', postCastTopic)
            const localVarPath = `/cast/@me/topics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postCastTopic, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POST だけど冪等。 PUT にする。。
         * @summary キャストが自分にボイスメッセージをアサインする
         * @param {string} voiceMessageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postVoiceMessageAssignToMe: async (voiceMessageId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'voiceMessageId' is not null or undefined
            assertParamExists('postVoiceMessageAssignToMe', 'voiceMessageId', voiceMessageId)
            const localVarPath = `/cast/voice-messages/{voiceMessageId}/assign-to-me`
                .replace(`{${"voiceMessageId"}}`, encodeURIComponent(String(voiceMessageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} topicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCastTopic: async (topicId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'topicId' is not null or undefined
            assertParamExists('putCastTopic', 'topicId', topicId)
            const localVarPath = `/cast/@me/topics/{topicId}`
                .replace(`{${"topicId"}}`, encodeURIComponent(String(topicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} voiceMessageId 
         * @param {PutCastVoiceMessageRequest} putCastVoiceMessageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCastVoiceMessage: async (voiceMessageId: string, putCastVoiceMessageRequest: PutCastVoiceMessageRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'voiceMessageId' is not null or undefined
            assertParamExists('putCastVoiceMessage', 'voiceMessageId', voiceMessageId)
            // verify required parameter 'putCastVoiceMessageRequest' is not null or undefined
            assertParamExists('putCastVoiceMessage', 'putCastVoiceMessageRequest', putCastVoiceMessageRequest)
            const localVarPath = `/cast/voice-messages/{voiceMessageId}`
                .replace(`{${"voiceMessageId"}}`, encodeURIComponent(String(voiceMessageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putCastVoiceMessageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CastApi - functional programming interface
 * @export
 */
export const CastApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CastApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} topicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCastTopic(topicId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCastTopic(topicId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCastTopics(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Set<MyTopic>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCastTopics(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostCastTopic} postCastTopic 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCastTopic(postCastTopic: PostCastTopic, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyTopic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCastTopic(postCastTopic, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POST だけど冪等。 PUT にする。。
         * @summary キャストが自分にボイスメッセージをアサインする
         * @param {string} voiceMessageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postVoiceMessageAssignToMe(voiceMessageId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postVoiceMessageAssignToMe(voiceMessageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} topicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCastTopic(topicId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCastTopic(topicId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} voiceMessageId 
         * @param {PutCastVoiceMessageRequest} putCastVoiceMessageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCastVoiceMessage(voiceMessageId: string, putCastVoiceMessageRequest: PutCastVoiceMessageRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCastVoiceMessage(voiceMessageId, putCastVoiceMessageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CastApi - factory interface
 * @export
 */
export const CastApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CastApiFp(configuration)
    return {
        /**
         * 
         * @param {string} topicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCastTopic(topicId: string, options?: any): AxiosPromise<string> {
            return localVarFp.deleteCastTopic(topicId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCastTopics(options?: any): AxiosPromise<Set<MyTopic>> {
            return localVarFp.getCastTopics(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostCastTopic} postCastTopic 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCastTopic(postCastTopic: PostCastTopic, options?: any): AxiosPromise<MyTopic> {
            return localVarFp.postCastTopic(postCastTopic, options).then((request) => request(axios, basePath));
        },
        /**
         * POST だけど冪等。 PUT にする。。
         * @summary キャストが自分にボイスメッセージをアサインする
         * @param {string} voiceMessageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postVoiceMessageAssignToMe(voiceMessageId: string, options?: any): AxiosPromise<void> {
            return localVarFp.postVoiceMessageAssignToMe(voiceMessageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} topicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCastTopic(topicId: string, options?: any): AxiosPromise<string> {
            return localVarFp.putCastTopic(topicId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} voiceMessageId 
         * @param {PutCastVoiceMessageRequest} putCastVoiceMessageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCastVoiceMessage(voiceMessageId: string, putCastVoiceMessageRequest: PutCastVoiceMessageRequest, options?: any): AxiosPromise<string> {
            return localVarFp.putCastVoiceMessage(voiceMessageId, putCastVoiceMessageRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CastApi - object-oriented interface
 * @export
 * @class CastApi
 * @extends {BaseAPI}
 */
export class CastApi extends BaseAPI {
    /**
     * 
     * @param {string} topicId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CastApi
     */
    public deleteCastTopic(topicId: string, options?: any) {
        return CastApiFp(this.configuration).deleteCastTopic(topicId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CastApi
     */
    public getCastTopics(options?: any) {
        return CastApiFp(this.configuration).getCastTopics(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostCastTopic} postCastTopic 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CastApi
     */
    public postCastTopic(postCastTopic: PostCastTopic, options?: any) {
        return CastApiFp(this.configuration).postCastTopic(postCastTopic, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POST だけど冪等。 PUT にする。。
     * @summary キャストが自分にボイスメッセージをアサインする
     * @param {string} voiceMessageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CastApi
     */
    public postVoiceMessageAssignToMe(voiceMessageId: string, options?: any) {
        return CastApiFp(this.configuration).postVoiceMessageAssignToMe(voiceMessageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} topicId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CastApi
     */
    public putCastTopic(topicId: string, options?: any) {
        return CastApiFp(this.configuration).putCastTopic(topicId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} voiceMessageId 
     * @param {PutCastVoiceMessageRequest} putCastVoiceMessageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CastApi
     */
    public putCastVoiceMessage(voiceMessageId: string, putCastVoiceMessageRequest: PutCastVoiceMessageRequest, options?: any) {
        return CastApiFp(this.configuration).putCastVoiceMessage(voiceMessageId, putCastVoiceMessageRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
