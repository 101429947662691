// ES Modules, e.g. transpiling with Babel
import { v4 } from "uuid";

function generateUsername(): string {
  return v4();
}

const USERNAME_KEY = "userName";

export function getGuestId() {
  let username = localStorage.getItem(USERNAME_KEY);
  if (username) {
    return username;
  }

  username = generateUsername();

  localStorage.setItem(USERNAME_KEY, username);
  return username;
}
