import React, { FC, useState } from "react";

import { VoiceRecorder } from "../../../components/VoiceRecorder/VoiceRecorder";
import { useMutation } from "react-query";
import { Select } from "antd";
import { VoiceMessageId } from "../../../../common/api/types";
import styled from "styled-components";
import { DateTime } from "../../../../common/components/DateTime";
import { useVoiceList } from "../../../hooks/useVoiceList";
import { SmartRecordedVoice } from "../../../components/SmartRecordedVoice";
import { AudioFileId } from "../../../../common/api/audiofile";
import { VerticalList } from "../../../../common/components/VerticalList";
import { RequireLineSignIn } from "../../../../auth/components/RequireLineSignIn";
import { putCastVoiceMessage } from "../../../../common/api/cast";
import { useAssignedActiveRequests } from "../../hooks/request";
import { compareString } from "../../../../common/util/string";
import { VoiceMessageRequestView } from "../../components/VoiceMessageRequestView";

export const adminVoiceRecorderRoute = {
  path: "/admin/voice-recorder/:id",
  makePath: (id: string) => {
    return `/admin/voice-recorder/${id}`;
  },
};

export const AdminVoiceRecorderPage: FC = () => {
  return (
    <RequireLineSignIn>
      <VoiceRecorderPageImpl />
    </RequireLineSignIn>
  );
};

const VoiceRecorderPageImpl: FC = () => {
  const [voiceMessageId, setVoiceMessageId] = useState<VoiceMessageId | null>(
    null
  );

  const requestSet = useAssignedActiveRequests();
  const requests = [...requestSet.randomRequests, ...requestSet.byNameRequests]
    .filter((it) => it.state === "REQUESTED_CENSOR_OK")
    .sort((a, b) => compareString(a.requestedAt, b.requestedAt));

  const { voices, add } = useVoiceList();

  const sendToListener = useMutation(
    async (
      params: [voiceMessageId: VoiceMessageId, audioFileId: AudioFileId]
    ) => await putCastVoiceMessage(...params)
  );

  const voiceMessage = requests.find((a) => a.id === voiceMessageId);
  return (
    <VerticalList align="center">
      <ListenerBox>
        {" "}
        <Select
          style={{ width: "100%" }}
          value={voiceMessageId ?? undefined}
          onChange={(e) => setVoiceMessageId(e)}
        >
          {requests.map((v) => (
            <Select.Option key={v.id} value={v.id}>
              {v.id}
              <br />
              {v.listenerName}
              <br />
              <DateTime value={v.requestedAt} />
            </Select.Option>
          ))}
        </Select>
        {voiceMessage && <VoiceMessageRequestView value={voiceMessage} />}
      </ListenerBox>

      <VoiceRecorder onRecorded={add} saving={false} />

      <div>
        {voices.map((v) => (
          <SmartRecordedVoice
            key={v.id}
            voice={v}
            fileName={voiceMessage?.id ?? "voice"}
            sendToListenerDisabled={!voiceMessageId}
            onSendToListener={(id) =>
              sendToListener.mutate([voiceMessageId!, id])
            }
          />
        ))}
      </div>
    </VerticalList>
  );
};

const ListenerBox = styled.div`
  margin: 12px;
  width: 100%;
`;
