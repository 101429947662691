import React, { FC, useCallback, useState } from "react";
import { UploadVoice } from "./UploadVoice";
import { P } from "../../common/components/P";
import { MessageTypeEnum, OtherUser } from "../../api";
import { AppButton } from "../../common/components/AppButton";
import { SectionTitle } from "./SectionTitle";
import { VerticalList } from "../../common/components/VerticalList";
import { useMutation } from "react-query";
import { messageApi } from "../../common/api/api2";
import { RequestId } from "../../common/api/types";
import { useInvalidateTalk } from "../hooks/message";
import iphone2 from "../../images/iphone-voice-2.png";
import iphone3 from "../../images/iphone-voice-3.png";
import styled from "styled-components";
import { Section } from "../../common/components/Section";

export interface UploadVoiceUiProps {
  targetUser: OtherUser;
  messageType: MessageTypeEnum;
  requestId: RequestId;
}

/**
 * 声をファイルとしてアップロードする
 */
export const UploadVoiceUi: FC<UploadVoiceUiProps> = ({
  targetUser,
  messageType,
  requestId,
}) => {
  const [fileId, setFileId] = useState<string | null>(null);
  const invalidate = useInvalidateTalk(targetUser.personalityId);
  const [complete, setComplete] = useState(false);

  const onUploadComplete = useCallback((id) => {
    setFileId(id);
  }, []);

  const cancel = useCallback(() => {
    setFileId(null);
  }, []);

  const { mutate, isLoading } = useMutation(
    async () =>
      await messageApi.postMessage1({
        requestId,
        audioFileId: fileId!,
        messageType: messageType as any,
      }),
    {
      async onSuccess() {
        await invalidate();
        setFileId(null);
        setComplete(true);
      },
    }
  );

  return (
    <VerticalList margin={40} align="center" textAlign="center">
      <P>
        他の方法でうまくいかない場合、ここから音声ファイルをアップロードしてね
      </P>
      {complete ? (
        <div>送信したよ！</div>
      ) : fileId ? (
        <>
          <VerticalList width="100%" align="stretch" textAlign="center">
            <div>{targetUser.name}さんに送信する？</div>
            <AppButton type="primary" onClick={mutate} loading={isLoading}>
              送信する
            </AppButton>
            <AppButton onClick={cancel}>キャンセル</AppButton>
          </VerticalList>
        </>
      ) : (
        <UploadVoice onComplete={onUploadComplete} />
      )}

      <Section>
        <VerticalList textAlign="center" align="center">
          <SectionTitle>参考: iPhoneで音声ファイルを作るには</SectionTitle>
          <P>まずはiPhoneのボイスメモ等を使って音声を録音しよう</P>
          <P>ボイスメモの場合は、「ファイルに保存」する必要があるよ</P>
          <P>「...」をタップして</P>
          <P>
            <MyImg src={iphone2} />
          </P>
          <P>「"ファイルに保存"」を選んで好きな場所へ保存しよう</P>
          <P>
            <MyImg src={iphone3} />
          </P>
          <P>これでブラウザからアップロードできるようになったよ！</P>
        </VerticalList>
      </Section>
    </VerticalList>
  );
};

const MyImg = styled.img`
  width: 80%;
  border: 1px solid black;
`;
