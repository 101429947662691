import React, { FC } from "react";

import styled from "styled-components";

type CardListProps = {};

/**
 * card を縦に並べるときに利用する
 *
 * いい感じの余白をあける
 * @deprecated VerticalList でいい
 */
export const CardList: FC<CardListProps> = ({ children }) => {
  return <Root>{children}</Root>;
};

const Root = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
`;
