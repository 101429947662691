import React, { FC } from "react";
import { usePageView } from "../../../common/hooks/usePageView";
import { RequireLineSignIn } from "../../../auth/components/RequireLineSignIn";
import { AppLayout2 } from "../../../common/components/AppLayout2";
import { VerticalList } from "../../../common/components/VerticalList";
import { Section } from "../../../common/components/Section";
import { NoteBox } from "../../../common/components/NoteBox";
import { ProfileEditor } from "../../components/ProfileEditor";
import { RawUser, useRawUser } from "../../../auth/auth";

export const voiceMessageLp2Step3Route = {
  path: "/voice-messages/lpdir/step3",
  makePath: () => {
    return "/voice-messages/lpdir/step3";
  },
};

interface Props {}

export const VoiceMessageLp2Step3Page: FC<Props> = () => {
  usePageView("voice-message-lp2-step3", true);

  return (
    <RequireLineSignIn>
      <Impl />
    </RequireLineSignIn>
  );
};

const Impl: FC<Props> = () => {
  const rawUser = useRawUser()!;

  return <VoiceMessageLp2Step3PageTemplate rawUser={rawUser} />;
};

interface VoiceMessageLp2Step3PageTemplateProps {
  rawUser: RawUser;
}

const VoiceMessageLp2Step3PageTemplate: FC<VoiceMessageLp2Step3PageTemplateProps> = ({
  rawUser,
}) => {
  return (
    <AppLayout2>
      <Section>
        <VerticalList align="center" textAlign="center">
          <NoteBox>
            <p>
              リクエストはYour Radioを利用している他のユーザーに送信され、
              答えたいと思ったユーザーからお返事がくる仕組みになっているよ！
            </p>

            <p>お返事を返したくなるようにアバターや自己紹介を設定しよう！</p>
          </NoteBox>

          <ProfileEditor defaultAvatar={rawUser?.picture} />
        </VerticalList>
      </Section>
    </AppLayout2>
  );
};
