import React, { FC } from "react";
import { Button } from "antd";
import { Item } from "./Item";
import {
  useAdminPostPseudoUserMutation,
  useAdminUsers,
} from "../../hooks/adminUser";
import { AdminRoot } from "../../components/AdminRoot";
import { UserForAdmin } from "../../../../common/api/admin";
import { AppLayout2 } from "../../../../common/components/AppLayout2";
import { VerticalList } from "../../../../common/components/VerticalList";
import { Section } from "../../../../common/components/Section";
import { AppButton } from "../../../../common/components/AppButton";

export const adminUserListRoute = {
  path: "/admin/users",
  makePath: () => {
    return `/admin/users`;
  },
};

export const AdminUserListPage: FC = () => {
  return (
    <AdminRoot>
      <AdminUserListPageImpl />
    </AdminRoot>
  );
};

function userWeight(user: UserForAdmin): number {
  if (user.isAdmin) {
    return 10000;
  } else if (user.isPseudo) {
    return 1000;
  } else if (user.listenerNameForDoublePersonality) {
    return 100;
  } else if (user.isCast) {
    return 10;
  } else if (user.name) {
    return 5;
  } else {
    return 1;
  }
}

const AdminUserListPageImpl: FC = () => {
  const users = useAdminUsers().sort((a, b) => userWeight(b) - userWeight(a));

  const postPseudoUser = useAdminPostPseudoUserMutation();

  return (
    <AppLayout2>
      <Section>
        <AppButton
          onClick={() => postPseudoUser.mutate()}
          loading={postPseudoUser.isLoading}
        >
          Add Pseudo User
        </AppButton>
      </Section>

      <Section>
        <VerticalList>
          {users.map((it) => (
            <Item key={it.id} user={it} />
          ))}
        </VerticalList>
      </Section>
    </AppLayout2>
  );
};
