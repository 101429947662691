import { gtmEvent } from "../gtm";
import { EventType } from "./api/types";
import { trackApi } from "./api/api2";

export interface TrackingEvent {
  event: EventType;
  data?: string;
  data2?: string;
}

export function track({ event, data, data2 }: TrackingEvent) {
  gtmEvent({
    event: `yr.${event}`,
  });

  (async () => {
    try {
      await trackApi.track({ event, data, data2 });
    } catch (e) {}
  })();
}
