const musicBaseUrl = "https://d3fzg4gd0tu5r2.cloudfront.net/music/";

export type MusicId = string;

export interface Music {
  id: MusicId;
  url: string;
  name: string;
  description: string;
}

export function getMusic(id: MusicId): Music {
  return musics.find((a) => a.id === id)!;
}

function music(params: {
  id: MusicId;
  name: string;
  description: string;
  path?: string;
}): Music {
  return {
    ...params,
    url: musicBaseUrl + (params.path ?? `${params.id}.mp3`),
  };
}

const musics = [
  music({ id: "1", name: "パステルハウス", description: "楽しい。穏やか" }),
  music({ id: "2", name: "Morning", description: "楽しい。穏やか" }),
  music({
    id: "4",
    name: "ジェットペンギン３号",
    description: "かっこいい。爽やか",
  }),
  music({
    id: "5",
    name: "昼下がり気分",
    description: "楽しい。穏やか。平和",
  }),
  music({ id: "6", name: "ensolarado", description: "穏やか。おしゃれ" }),
  music({ id: "7", name: "Light Up Delight", description: "穏やか。ジャズ" }),
  music({ id: "8", name: "自然の中でゆったりと", description: "穏やか" }),
];

export function getMusics() {
  return musics;
}
