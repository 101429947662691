import { FC } from "react";
import styled from "styled-components";
import { Button } from "antd";
import { VerticalList } from "../../common/components/VerticalList";
import { Listener } from "./Listener";

export type ConnectedProps = {
  listenerName: string;
  message: string;
  onStart: () => void;
};

export const Connected: FC<ConnectedProps> = ({
  listenerName,
  message,
  onStart,
}) => {
  return (
    <Root>
      <VerticalList align="center">
        <Listener listenerName={listenerName} message={message} />

        <Button type="primary" onClick={onStart}>
          配信開始
        </Button>
      </VerticalList>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;
