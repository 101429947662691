import React, { FC } from "react";
import styled from "styled-components";

/**
 * 完全な 100 にはなっていない
 * @constructor
 */
export const Vh100: FC = ({ children }) => {
  return <Root>{children}</Root>;
};

const Root = styled.div`
  height: 80vh;
  width: 100%;
`;
