import React, { FC, useState } from "react";
import { VerticalList } from "../../../common/components/VerticalList";
import { Button, Input } from "antd";
import { Topic } from "../../../common/api/voicemessage/api";
import { useMutation } from "react-query";
import { postCastTopic } from "../../../common/api/cast";
import { useInvalidateTopics } from "../../../common/api/hooks/voiceMessage";
import { Small } from "../../../common/components/Small";
import { track } from "../../../common/track";

interface Props {
  onNew: (topic: Topic) => void;
}

/**
 * 追加ボタンを押すと、保存して ID を割り振った状態になる
 */
export const NewTopic: FC<Props> = ({ onNew }) => {
  const [content, setContent] = useState("");
  const [question, setQuestion] = useState("");
  const invalidate = useInvalidateTopics();

  const add = useMutation(
    async () =>
      (
        await postCastTopic({
          title: content,
          question,
        })
      ).data,
    {
      async onSuccess(data) {
        track({ event: "cast-topic.add-new", data2: data.id });
        await invalidate();
        onNew(data);
        setContent("");
        setQuestion("");
      },
    }
  );

  return (
    <VerticalList>
      <div>
        <Small>話したい話題</Small>
        <Input
          placeholder="例: 悩み相談に乗るよ"
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />
      </div>
      <div>
        <Small>相手に事前に聞きたいこと (あるなら)</Small>
        <Input value={question} onChange={(e) => setQuestion(e.target.value)} />
        <Small>
          例: 話したい話題が「悩み相談」なら「お悩み内容を教えてね」
          <br />
          話題が「応援するよ！」なら「応援してほしい内容を教えてね」
          <br />
          等、話題を話すときに、リスナーさんに事前に教えてほしい事があったら記載してね
        </Small>
      </div>

      <Button
        type="primary"
        disabled={content.length === 0}
        onClick={() => add.mutate()}
        loading={add.isLoading}
      >
        追加
      </Button>
    </VerticalList>
  );
};
