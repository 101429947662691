import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from "react-query";
import {
  postAdminPseudoUser,
  postAdminSignInAsUser,
  postAdminUserPoint,
  UserForAdmin,
} from "../../../common/api/admin";
import { UserId } from "../../../auth/auth";
import { useInvalidateUserStatus } from "../../../common/hooks/user";
import { LineUserId } from "../../../common/api/voicemessage/api";
import { adminApi } from "../../../common/api/api2";

export function useAdminUsers(): UserForAdmin[] {
  const users = useQuery(
    ["admin", "users"],
    async () => (await adminApi.getUsers()).data
  );
  return users.data!;
}

export function useInvalidateAdminUsers() {
  const queryClient = useQueryClient();
  return async () => {
    await queryClient.invalidateQueries(["admin", "users"]);
  };
}

export function useAdminUserByLineId(lineId: LineUserId): UserForAdmin {
  const users = useAdminUsers();
  return users.find((it) => it.lineId === lineId)!;
}

export function useGetAdminUser(userId: UserId): UserForAdmin {
  const users = useAdminUsers();
  return users.find((it) => it.id === userId)!;
}

export function useAdminSignInAsUserMutation(): UseMutationResult<
  { data: any; kind: string },
  unknown,
  UserId
> {
  const invalidate = useInvalidateUserStatus();
  return useMutation(
    async (asUserId: UserId) => await postAdminSignInAsUser(asUserId),
    {
      onSuccess: invalidate,
    }
  );
}

export function useAdminPostPseudoUserMutation(): UseMutationResult<
  { data: any; kind: string },
  unknown,
  void,
  unknown
> {
  const invalidate = useInvalidateAdminUsers();
  return useMutation(async () => await postAdminPseudoUser(), {
    onSuccess: invalidate,
  });
}

export function usePostAdminUserPoint(userId: UserId, diff: number) {
  const invalidate = useInvalidateAdminUsers();
  return useMutation(
    async () =>
      await postAdminUserPoint({
        userId,
        diff,
      }),
    {
      onSuccess: invalidate,
    }
  );
}
