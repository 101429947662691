import React, { FC, useState } from "react";
import { Button, Input } from "antd";
import { usePageView } from "../../../common/hooks/usePageView";
import { AppLayout2 } from "../../../common/components/AppLayout2";
import { VerticalList } from "../../../common/components/VerticalList";
import { Section } from "../../../common/components/Section";
import styled from "styled-components";
import { AddLineButton } from "../../../common/components/AddLineButton";
import { Hero1 } from "../../../common/components/Hero1";
import circleImage from "../../../images/circle.png";
import { SectionTitle } from "../../components/SectionTitle";
import { Strong } from "../../../common/components/Strong";
import { RequestTargetSexType } from "../../components/RequestTargetSexType";
import { useQueryParams } from "../../../common/hooks/useQueryParams";
import { Sex2 } from "../../../common/api/types";
import { getQueryParams } from "../../../simplelive/SimpleLiveLpPage/model";
import { NoteBox } from "../../../common/components/NoteBox";
import { useGoto } from "../../../lib/hooks/useGoto";
import { homeRoute } from "../HomePage/HomePage";

export const voiceMessageCastLpRoute = {
  path: "/voice-messages/cast-lp",
  makePath: () => {
    return "/voice-messages/cast-lp";
  },
};

interface Props {}

export const VoiceMessageCastLpPage: FC<Props> = () => {
  usePageView("voice-message-cast-lp", true);
  const a1 = getTypeA1();
  const a2 = getTypeA2();

  return <VoiceMessageCastLpTemplate a1={a1} a2={a2} />;
};

interface TemplateProps {
  a1: TypeA1;
  a2: TypeA2;
}

/**
 * TODO 作りかけ
 * @param a1
 * @param a2
 * @constructor
 */
export const VoiceMessageCastLpTemplate: FC<TemplateProps> = ({ a1, a2 }) => {
  const [listenerName, setListenerName] = useState("");

  const listenerSex = useListenerSex();
  const [targetSex, setTargetSex] = useState(invertSex(listenerSex));

  const goRequest = useGoto(homeRoute.makePath());

  return (
    <AppLayout2 noVerticalMargin>
      <Hero1
        mainMessage={"いまこの瞬間\n自分だけに向けられた\nやさしい声が聴きたい"}
      />
      <Section>
        <VerticalList align="center" textAlign="center" margin={16}>
          <p>夜、さみしくて一人で眠れないことがありませんか</p>

          <p>
            部活や仕事で落ち込んだとき、ただやさしい言葉をかけてほしいときがありませんか
          </p>
        </VerticalList>
      </Section>

      <Section colored>
        <VerticalList align="center" textAlign="center" margin={16}>
          <p>
            Your Radio はユーザー同士が、 <br />
            互いにやさしい <Strong>声のメッセージ</Strong>{" "}
            を贈りあうサービスです
            <br />
          </p>

          <p>
            疲れたときはだれかの <Strong>声</Strong> に癒され、
            <br />
            元気なときは他の誰かに <Strong>声</Strong> を贈る
          </p>
          <p>Your Radioはそんな世界を作りたいと思っています</p>

          {a1 === "circle" && (
            <Section>
              <VerticalList align="center">
                <Circle src={circleImage} width={100} height={100} />
              </VerticalList>
            </Section>
          )}

          {a2 === "line-login" && (
            <>
              <Button type="primary" onClick={goRequest}>
                声のメッセージをリクエスト
              </Button>
              <NoteBox>
                声のメッセージの通知を受けるためには LINE アカウントが必要です
              </NoteBox>
            </>
          )}
        </VerticalList>
      </Section>

      {a2 === "add-line" && (
        <Section colored>
          <SectionTitle>声のメッセージをリクエスト</SectionTitle>
          <VerticalList align="center">
            <Input
              placeholder="呼び名 (○○さん)"
              value={listenerName}
              onChange={(e) => setListenerName(e.target.value)}
              style={{ width: "100%", fontSize: 16 }}
            />
            <RequestTargetSexType value={targetSex} onChange={setTargetSex} />

            <p>
              声のメッセージの通知を受けるために Your Radio の LINE
              を友だち追加してくださいね
            </p>
            <p>
              <AddLineButton disabled={listenerName.length === 0} />
            </p>
          </VerticalList>
        </Section>
      )}
    </AppLayout2>
  );
};

const Circle = styled.img`
  width: 80%;
  height: auto;
  border-radius: 20px;
`;

function useListenerSex(): Sex2 {
  const s = useQueryParams("s");
  if (s === "f") {
    return "FEMALE";
  } else if (s === "m") {
    return "MALE";
  } else {
    return "FEMALE";
  }
}

function invertSex(sex: Sex2): Sex2 {
  if (sex === "FEMALE") {
    return "MALE";
  } else {
    return "FEMALE";
  }
}

type TypeA1 = "circle" | "no-circle";

function getTypeA1(): TypeA1 {
  const a = getQueryParams("a1");
  if (a === "1") {
    return "circle";
  } else if (a === "2") {
    return "no-circle";
  } else {
    return "circle";
  }
}

type TypeA2 = "add-line" | "line-login";

function getTypeA2(): TypeA2 {
  const a = getQueryParams("a2");
  if (a === "1") {
    return "add-line";
  } else if (a === "2") {
    return "line-login";
  } else {
    return "add-line";
  }
}
