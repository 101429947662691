import React, { FC } from "react";

import { PauseCircleOutlined, PlayCircleOutlined } from "@ant-design/icons";
import { VerticalList } from "../../../common/components/VerticalList";
import { AudioSeekBar } from "../../../common/components/AudioSeekBar";
import { useModel } from "./model";
import { DateTime } from "../../../common/components/DateTime";
import { NoteBox } from "../../../common/components/NoteBox";
import { AppButton } from "../../../common/components/AppButton";
import moment from "moment";

interface Props {
  /**
   * legacy の場合は requestId, 新しい場合は messageId
   */
  messageId: string;
  onGetVoiceMessageSuccess?: () => void;
  expiredAt?: string;
}

/**
 * 声の再生機能
 * - ajax によりデータを取得
 * - 再生ボタンとシークバー
 * - 視聴時間などの注意書き
 */
export const AjaxVoicePlayer: FC<Props> = ({
  messageId,
  onGetVoiceMessageSuccess,
  expiredAt,
}) => {
  const {
    audio,
    playing,
    loading,
    play,
    pause,
    currentTime,
    setCurrentTime,
  } = useModel(messageId, onGetVoiceMessageSuccess);

  return (
    <VerticalList align="center" margin={16}>
      {playing ? (
        <AppButton
          icon={<PauseCircleOutlined />}
          type="primary"
          loading={loading}
          onClick={pause}
        >
          停止する
        </AppButton>
      ) : (
        <AppButton
          icon={<PlayCircleOutlined />}
          type="primary"
          loading={loading}
          onClick={play}
        >
          再生する
        </AppButton>
      )}

      <AudioSeekBar
        audio={audio}
        currentTime={currentTime}
        onChange={setCurrentTime}
      />

      <NoteBox>
        {expiredAt && (
          <p>
            <DateTime value={expiredAt} /> まで視聴できます
          </p>
        )}
        <p>本メッセージを不特定多数に公開するのはお控えください</p>
      </NoteBox>
    </VerticalList>
  );
};
