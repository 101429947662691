import React, { FC } from "react";
import { Spin } from "antd";
import styled from "styled-components";
import { textColor } from "../../colors";
import { usePageView } from "../../common/hooks/usePageView";
import { useScrollToTopOnMount } from "../../common/hooks/useScrollToTopOnMount";

type State =
  | "waiting-host-connection" // host 受け入れ待ち
  | "waiting-host-preparation" // host 準備中
  | "waiting-data"; // データ受信待ち

export type ConnectingProps = {
  state: State;
};

export const Connecting: FC<ConnectingProps> = ({ state }) => {
  useScrollToTopOnMount();
  usePageView(`connecting_${state}`);

  return (
    <Root>
      {state === "waiting-host-connection" && (
        <CenterDescriptionBox>
          いま空いているキャストを探しているよ
          <SpinWrap>
            <Spin />
          </SpinWrap>
        </CenterDescriptionBox>
      )}
      {state === "waiting-host-preparation" && (
        <CenterDescriptionBox>
          キャストがみつかったよ
          <br />
          キャストが準備中なのでもう少し待っていてね
          <br />
          (1分くらいかかるかも。ちょっと待っててね)
          <SpinWrap>
            <Spin />
          </SpinWrap>
        </CenterDescriptionBox>
      )}
      {state === "waiting-data" && (
        <CenterDescriptionBox>
          キャストの準備が終わったよ
          <br />
          データを受信中だよ
          <br />
          (この状態から進まない場合、再表示してみてね)
          <SpinWrap>
            <Spin />
          </SpinWrap>
        </CenterDescriptionBox>
      )}
    </Root>
  );
};

const Root = styled.div`
  height: 80vh;
  vertical-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CenterDescriptionBox = styled.div`
  text-align: center;
  color: ${textColor};
`;

const SpinWrap = styled.div`
  margin: 20px;
`;
