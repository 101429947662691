import React, { FC } from "react";
import { NoteBox } from "../../../common/components/NoteBox";
import { VerticalList } from "../../../common/components/VerticalList";
import { Topic } from "../../../common/api/voicemessage/api";
import { TopicList } from "../../components/TopicList";

interface Props {
  topics: Topic[];
  onSelect?: (topic: Topic) => void;
}

export const OthersTopicList: FC<Props> = ({ topics, onSelect }) => {
  return (
    <VerticalList align="center">
      <NoteBox>話題を選択すると、その話題をコピーするよ</NoteBox>
      <TopicList topics={topics} onSelect={onSelect} />
    </VerticalList>
  );
};
