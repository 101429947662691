import { MeetingSession } from "amazon-chime-sdk-js";
import { useCallback, useEffect, useState } from "react";

/**
 * ミュート状態の切り替え機能
 */
export function useChimeMute(meetingSession?: MeetingSession) {
  const [muted, setMuted] = useState(false);

  const audioVideo = meetingSession?.audioVideo;

  useEffect(() => {
    if (!audioVideo) {
      return;
    }

    audioVideo.realtimeSubscribeToMuteAndUnmuteLocalAudio((muted) => {
      setMuted(muted);
    });

    audioVideo.realtimeSubscribeToMuteAndUnmuteLocalAudio((muted) => {
      setMuted(muted);
    });
  }, [audioVideo]);

  useEffect(() => {
    if (!audioVideo) {
      return;
    }

    if (muted) {
      audioVideo.realtimeMuteLocalAudio();
    } else {
      audioVideo.realtimeUnmuteLocalAudio();
    }
  }, [audioVideo, meetingSession, muted]);

  return {
    muted,
    setMuted,
  };
}

/**
 * デバイスの選択機能
 */
export function useChimeDevice(meetingSession?: MeetingSession) {
  const audioVideo = meetingSession?.audioVideo;

  const [audioInputs, setAudioInputs] = useState<MediaDeviceInfo[]>([]);
  const [activeAudioInputId, setActiveAudioInputId] = useState<string>();

  const chooseAudioInput = useCallback(
    async (deviceId: string) => {
      if (!audioVideo) {
        return;
      }

      await audioVideo.chooseAudioInputDevice(deviceId);
      setActiveAudioInputId(deviceId);
    },
    [audioVideo]
  );

  const chooseDefaultAudioInput = useCallback(async () => {
    if (!audioVideo) {
      return;
    }

    if (audioInputs.length >= 1) {
      await chooseAudioInput(audioInputs[0].deviceId);
    }
  }, [audioInputs, audioVideo, chooseAudioInput]);

  useEffect(() => {
    (async () => {
      if (!audioVideo) {
        return;
      }

      // マイクの許可だけ取る
      // https://aws.github.io/amazon-chime-sdk-js/modules/faqs.html
      audioVideo.setDeviceLabelTrigger(
        async (): Promise<MediaStream> => {
          return await navigator.mediaDevices.getUserMedia({
            audio: true,
            video: false,
          });
        }
      );

      setAudioInputs(await audioVideo.listAudioInputDevices());

      audioVideo.addDeviceChangeObserver({
        audioInputsChanged(freshAudioInputDeviceList?: MediaDeviceInfo[]) {
          console.log("audioInputsChanged");
          if (freshAudioInputDeviceList) {
            setAudioInputs(freshAudioInputDeviceList);
          }
        },
        audioInputStreamEnded(deviceId?: string) {
          console.log("audioInputStreamEnded", deviceId);
        },
      });
    })();
  }, [audioVideo]);

  return {
    audioInputs,
    activeAudioInputId,
    chooseDefaultAudioInput,
    chooseAudioInput,
  };
}
