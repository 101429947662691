import { useQuery } from "react-query";
import { getAdminCasts, UserForAdmin } from "../../../common/api/admin";
import { UserId } from "../../../auth/auth";

export function useAdminCasts() {
  return useQuery(["casts"], async () => (await getAdminCasts()).data).data!;
}

/**
 */
export function useAdminCast(castId?: UserId | null): UserForAdmin | null {
  const casts = useAdminCasts();
  if (castId) {
    return casts.find((it) => it.id === castId)!;
  } else {
    return null;
  }
}
