import React, { FC } from "react";

import { Tabs } from "antd";
import { OtherFullUser } from "../../../api";
import { RequestId } from "../../../common/api/types";
import { PostThanksByText } from "./PostThanksByText";
import { PostThanksByVoice } from "./PostThanksByVoice";
import { FileTextOutlined, SoundOutlined } from "@ant-design/icons";

interface Props {
  requestId: RequestId;
  onSent: () => void;
  cast: OtherFullUser;
}

export const PostThanks: FC<Props> = ({ requestId, onSent, cast }) => {
  return (
    <Tabs centered>
      <Tabs.TabPane
        tab={
          <span>
            <FileTextOutlined />
            テキストでお礼
          </span>
        }
        key="1"
      >
        <PostThanksByText requestId={requestId} onSent={onSent} cast={cast} />
      </Tabs.TabPane>
      <Tabs.TabPane
        tab={
          <span>
            <SoundOutlined />
            声でお礼
          </span>
        }
        key="2"
      >
        <PostThanksByVoice targetUser={cast} requestId={requestId} />
      </Tabs.TabPane>
    </Tabs>
  );
};
