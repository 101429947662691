import React, { FC } from "react";
import { useGotoIf } from "../../lib/hooks/useGotoIf";
import { homeRoute } from "./HomePage/HomePage";

/**
 * ボイスメッセージ視聴ページ
 */
export const VoiceMessagePage: FC = () => {
  useGotoIf(homeRoute.makePath(), true);

  return <div />;
};
