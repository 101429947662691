import React, { FC, useCallback, useState } from "react";

import { Topic } from "../model";
import styled from "styled-components";
import { primaryColor } from "../../colors";
import { Button, Input, Popover } from "antd";
import { VerticalList } from "../../common/components/VerticalList";
import { eventLookAtTopic } from "../../gtm";
import { Time } from "./Time";
import { Strong } from "../../common/components/Strong";

export type TopicSelector2Props = {
  topics: Topic[];
  canListen: boolean;
  loading: boolean;
  onListen: (message: string) => void;
};

export const TopicSelector2: FC<TopicSelector2Props> = ({
  topics,
  canListen,
  loading,
  onListen,
}) => {
  return (
    <Root>
      <Strong>聴きたい内容を選んでね</Strong>
      <TopicList>
        {topics.map((t) => (
          <Popover
            key={t.title}
            content={
              <Popup
                topic={t}
                loading={loading}
                canListen={canListen}
                onListen={onListen}
              />
            }
            onVisibleChange={(visible) => {
              if (visible) {
                eventLookAtTopic(t);
              }
            }}
            title={t.title}
            trigger="click"
          >
            <TopicElem>
              {t.title}(<Time seconds={t.seconds} />)
            </TopicElem>
          </Popover>
        ))}
      </TopicList>
    </Root>
  );
};

type PopupProps = {
  topic: Topic;
  canListen: boolean;
  loading: boolean;
  onListen: (message: string) => void;
};

const Popup: FC<PopupProps> = ({ loading, canListen, onListen, topic }) => {
  const [question, setQuestion] = useState("");

  const onClick = useCallback(() => {
    onListen(`${topic.title}(${question})`);
  }, [onListen, question, topic.title]);

  const onChange = useCallback((e: any) => {
    setQuestion(e.target.value);
  }, []);

  return (
    <PopupRoot>
      <VerticalList align="center">
        {topic.question && (
          <MyTextArea
            placeholder={topic.question}
            value={question}
            onChange={onChange}
          />
        )}
        <Button
          size="large"
          disabled={!canListen || (!!topic.question && !question)}
          onClick={onClick}
          loading={loading}
          type="primary"
        >
          聴いてみる (<Time seconds={topic.seconds} />)
        </Button>
      </VerticalList>
    </PopupRoot>
  );
};

const Root = styled.div`
  text-align: center;
`;

const TopicList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

const TopicElem = styled.div`
  border-radius: 4px;
  background-color: white;
  margin: 4px;
  padding: 4px;
  font-size: 14px;
  border: 1px solid ${primaryColor};
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
`;

const PopupRoot = styled.div``;

const MyTextArea = styled(Input.TextArea)`
  min-width: 70vw;
  font-size: 16px;
`;
