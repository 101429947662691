import React, { useCallback } from "react";
import { Layout } from "antd";

import styled from "styled-components";
import { Logo } from "../../components/Logo/Logo";

import requestImg from "./request.png";
import listenerScreenImg from "./listener-screen.png";

import { Merit } from "./Merit";
import { SectionTitle } from "./SectionTitle";
import { primaryColor, primaryLightBgColor, textColor } from "../../../colors";
import { HeroBox } from "./HeroBox";
import { PrimaryButton } from "../../components/PrimaryButton";
import { eventClickRegister } from "../../../gtm";
import { Section } from "./Section";
import { useBetween } from "../../hooks/useBetween";
import useScrollIntoView from "../../hooks/useScrollIntoView";

const { Header, Content, Footer } = Layout;

const fromUrl =
  "https://docs.google.com/forms/d/e/1FAIpQLSd27pA6KGvJk3r9FQ01BLF35-G3IEWPdI7RJxglQpqDEqLEyA/viewform?usp=sf_link";

export function PersonalityLpPage() {
  const [showsHoverButton, from, to] = useBetween();
  const [scrollToRef, scrollToRegister] = useScrollIntoView();

  const registerHover = useCallback(() => {
    scrollToRegister();
  }, [scrollToRegister]);

  const registerBottom = useCallback(() => {
    eventClickRegister("bottom");
    window.open(fromUrl, "_blank");
  }, []);

  return (
    <Layout>
      <MyHeader>
        <Logo />
      </MyHeader>

      <Content>
        <SiteLayoutContent>
          <HeroBox>
            <HeroTextWrap>
              <CatchphraseWrap>
                <Catchphrase>もっと心をゆさぶる配信を</Catchphrase>
                <Catchphrase>もっとよい対価で</Catchphrase>
              </CatchphraseWrap>

              <HeroDescriptionBox>
                <Paragraph>
                  Your Radioは配信者がたった一人のリスナーのために
                  <br />
                  ラジオのライブ配信を行うサービスです
                </Paragraph>
                <Paragraph>
                  配信時間に応じて現金に還元可能な報酬が得られます
                </Paragraph>
              </HeroDescriptionBox>
            </HeroTextWrap>
          </HeroBox>

          <Section odd>
            <SectionTitle>報酬の内容</SectionTitle>

            <DescriptionBox>
              <Paragraph>
                Your
                Radioでは、配信者にはランクがあり、配信をするたびに、ランクに応じた報酬が得られます
              </Paragraph>

              <Paragraph>
                ランクは、ファンになったリスナーの数やリスナーからの評価等によって上下します
              </Paragraph>

              <Paragraph>
                <Strong>各ランクでの報酬イメージ</Strong>
              </Paragraph>

              <Paragraph>
                <MyTable>
                  <tbody>
                    <MyTr>
                      <RankTd colSpan={2}>人気の配信者 (上位約10%)</RankTd>
                    </MyTr>
                    <MyTr>
                      <MyTd>5分間の配信</MyTd>
                      <MyTd>約 ¥150 〜 ¥2,000</MyTd>
                    </MyTr>
                    <MyTr>
                      <MyTd>時給換算</MyTd>
                      <MyTd>約 ¥1,800 〜 ¥24,000 </MyTd>
                    </MyTr>
                  </tbody>
                </MyTable>
              </Paragraph>

              <Paragraph>※ 報酬の内容は今後変更になることがあります</Paragraph>
              <Paragraph>
                ※ いま登録していただいた配信者の方には、報酬の優遇があります
              </Paragraph>
            </DescriptionBox>
          </Section>

          <Section odd>
            <div ref={from} />
            <SectionTitle>
              Your Radioで配信する
              <br />
              4つのメリット
            </SectionTitle>

            <Merit
              title1="メリット 1"
              title2="リスナーの心を動かすことができる"
              odd
            >
              <Paragraph>
                Your Radioの各配信は、一人のリスナーのみへ配信されます
              </Paragraph>
              <Paragraph>「○○さん おはよう！」</Paragraph>
              <Paragraph>「○○さんは△△好きですか？」</Paragraph>
              <Paragraph>
                そんな、一人のリスナーに向けられた言葉は、リスナーの心を強く動かすことができます
              </Paragraph>
            </Merit>

            <Merit title1="メリット 2" title2="配信時間に応じて報酬が得られる">
              <Paragraph>
                Your Radioは配信する毎に、その配信時間に応じて報酬 (後述)
                が得られます
              </Paragraph>

              <Paragraph>報酬は換金可能なポイントとして支払われます</Paragraph>
              <Paragraph>
                いま登録していただいた配信者の方には、報酬の優遇があります
              </Paragraph>
            </Merit>

            <Merit title1="メリット 3" title2="お手軽な配信">
              <Paragraph>Your Radioは数分の短い配信で大丈夫</Paragraph>
              <Paragraph>
                「家事をしながらその日の些細な出来事を配信する」
              </Paragraph>
              <Paragraph>
                そんな日常感のある配信をリスナーも楽しんでいます
              </Paragraph>
            </Merit>

            <Merit title1="メリット 4" title2="心理的な安心感" odd>
              <Paragraph>
                Your Radioで話す内容は、配信者が自由に決められます
              </Paragraph>
              <Paragraph>
                リスナーからのコミュニケーションはいいねやスタンプ等の定型文のみ
              </Paragraph>
              <Paragraph>
                だから、話したくない内容を話すことも、コメントでつらい思いをすることもありません
              </Paragraph>
            </Merit>
          </Section>

          <Section>
            <SectionTitle>配信方法はカンタン</SectionTitle>

            <SectionSubTitle>1. 事前に用意するもの</SectionSubTitle>

            <DescriptionBox>
              <Paragraph>
                スマートフォンにYour Radio配信用アプリをいれれば準備は完了
              </Paragraph>
              <Paragraph>特別な機材は必要ありません</Paragraph>
            </DescriptionBox>

            <SectionSubTitle>2. 配信内容を登録する</SectionSubTitle>

            <DescriptionBox>
              <Paragraph>
                あなたが配信できる時に、配信したい内容と配信の長さをYour
                Radioに登録します
              </Paragraph>

              <Paragraph>
                <Strong>配信内容の例</Strong>
              </Paragraph>

              <TalkList>
                <Talk>あなたが寝落ちするまでお話するよ (30分間)</Talk>
                <Talk>今日あった嫌なことを聴いてよ (10分間)</Talk>
                <Talk>部屋の掃除をしながら雑談するよ (10分間)</Talk>
                <Talk>声劇「○△✕」 (5分間)</Talk>
                <Talk>あなたのことを全力で励ますよ (5分間)</Talk>
                <Talk>「○△✕」を歌うよ (5分間)</Talk>
                <Talk>「○△✕」を朗読するよ (5分間)</Talk>
              </TalkList>
            </DescriptionBox>

            <SectionSubTitle>3. リスナーからのリクエストをまつ</SectionSubTitle>

            <DescriptionBox>
              <Paragraph>
                配信内容を登録したら、リスナーから配信リクエストを受けるまで、お掃除やお仕事など、好きなことをしていましょう
              </Paragraph>

              <Paragraph>
                あなたが登録した内容は、各リスナーの画面に表示されます
              </Paragraph>

              <ScreenImageBox>
                <figcaption>
                  リスナーの画面に表示された配信可能な配信者の情報
                </figcaption>
                <ScreenImage src={listenerScreenImg} alt="" />
              </ScreenImageBox>

              <Paragraph>
                リスナーがあなたの登録した配信内容を選択すると、あなた宛に配信リクエストが通知されます
              </Paragraph>

              <ScreenImageBox>
                <ScreenImage src={requestImg} alt="" />
              </ScreenImageBox>

              <Paragraph>
                この配信リクエストを承諾すると、そのリスナーとの1対1の配信がはじまります
              </Paragraph>
            </DescriptionBox>

            <SectionSubTitle>
              4. リクエストを承諾して配信をはじめる
            </SectionSubTitle>

            <DescriptionBox>
              <Paragraph>
                配信中は、配信者から音声のみで一方向の配信を行います
              </Paragraph>

              <Paragraph>
                リスナーは「いいね！」「ありがとう！」「おやすみ！」等の定型文でのみリアクションを行うことができます
              </Paragraph>

              <Paragraph>
                事前に登録した時間が経つと、配信は終了します
              </Paragraph>
            </DescriptionBox>

            <SectionSubTitle>5. 報酬を得る</SectionSubTitle>

            <DescriptionBox>
              <Paragraph>
                配信が完了すると、配信内容に応じた報酬を得ます
              </Paragraph>
            </DescriptionBox>
          </Section>

          {/* 1ページに収まるように。 スクロールしてきた時にボタンが表示されるように。 */}
          <Section>
            <div ref={scrollToRef} />
            <SectionTitle>事前登録</SectionTitle>

            <DescriptionBox>
              <Paragraph>読んでいただいてありがとうございます</Paragraph>

              <Paragraph>
                現在Your Radioはサービス開始に向けて準備を行っており、
                サービス開始時の初期配信者の方を募集しています
              </Paragraph>

              <Paragraph>
                <Strong>
                  誰もが気軽に配信できて、その配信でリスナーを幸せに、
                  そしてその対価を得られる
                </Strong>
              </Paragraph>

              <Paragraph>そんな世界を作りたいと思っています</Paragraph>

              <Paragraph>
                Your Radioの目指す世界やサービス内容に共感いただけましたら、
                初期配信者としてご登録いただけるとうれしいです！
              </Paragraph>

              <Paragraph>
                <Strong>
                  いま登録していただいた方には、初期ランクの優遇等をさせていただきます
                </Strong>
              </Paragraph>

              <div ref={to} />

              <PrimaryButtonWrap>
                <PrimaryButton onClick={registerBottom}>
                  配信者として事前登録 (無料)
                  <br />
                  今なら高ランク保証！
                </PrimaryButton>
              </PrimaryButtonWrap>
            </DescriptionBox>
          </Section>
        </SiteLayoutContent>

        <HoverButtonBox show={showsHoverButton}>
          <PrimaryButton onClick={registerHover}>
            配信者として事前登録 (無料) <br />
            今なら高ランク保証！
          </PrimaryButton>
        </HoverButtonBox>
      </Content>
      <Footer style={{ textAlign: "center" }}>
        ©Your Radio, Inc. All rights reserved.
      </Footer>
    </Layout>
  );
}

const SiteLayoutContent = styled.div`
  min-height: 280px;
  padding: 0 24px 32px;
  background: #fff;

  font-size: 14px;

  color: ${textColor};

  // https://anyushu.com/web/fonts-osusume/
  font-family: "Lato", "Noto Sans JP", "ヒラギノ角ゴ ProN",
    "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "ＭＳ Ｐゴシック",
    "MS PGothic", sans-serif;
`;

const margin = "20px";

const SectionSubTitle = styled.h4`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin: 32px ${margin} 24px;
`;

const ScreenImageBox = styled.figure`
  width: 100%;
  padding: 20px;
`;

const ScreenImage = styled.img`
  width: 100%;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5);
`;

const Paragraph = styled.div`
  margin: 12px 0;
`;

const DescriptionBox = styled.div`
  margin: 0 ${margin};
`;

const HeroTextWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const CatchphraseWrap = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Catchphrase = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 28px;
`;

const HeroDescriptionBox = styled.div`
  margin: 0 32px;
  font-weight: bold;
  text-align: center;
  font-size: 14px;
  //position: absolute;
  //bottom: 12px;
`;

const TalkList = styled.ul`
  padding: 0;
`;
const Talk = styled.li`
  list-style: none;
  display: block;
  margin: 8px 0;
  border-radius: 8px;
  padding: 4px;
  background-color: ${primaryLightBgColor};
`;

const MyHeader = styled(Header)`
  background-color: #fff;
  text-align: center;
  padding-left: 10px;
`;

const PrimaryButtonWrap = styled.div`
  margin: 32px 0;
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Strong = styled.span`
  font-weight: bold;
`;

interface HoverButtonBoxProps {
  readonly show: boolean;
}

const HoverButtonBox = styled.div<HoverButtonBoxProps>`
  visibility: ${(props) => (props.show ? "visible" : "hidden")};
  opacity: ${(props) => (props.show ? 1 : 0)};

  transition: visibility, opacity 0.5s ease-out;

  position: fixed;
  bottom: 20px;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

const MyTable = styled.table`
  width: 100%;
`;

const MyTr = styled.tr``;

const MyTd = styled.td`
  border: 1px solid ${primaryColor};
  padding: 4px;
`;

const RankTd = styled.td`
  border: 1px solid ${primaryColor};
  background-color: ${primaryColor};
  color: #fff;
  font-weight: bold;
  padding: 4px;
  text-align: center;
`;
