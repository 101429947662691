import React, { FC } from "react";

import { UrlString } from "../../../common/api/types";
import { useAudioPlayer } from "../../../common/hooks/useAudioPlayer";
import { MiniPlayButton } from "./MiniPlayButton";
import { AudioSeekBar } from "../../../common/components/AudioSeekBar";
import { VerticalList } from "../../../common/components/VerticalList";

interface Props {
  url?: UrlString | null;
}

export const VoiceMessagePlayer: FC<Props> = ({ url }) => {
  const { audio, playing, play, currentTime, setCurrentTime } = useAudioPlayer({
    url,
    lazy: true,
  });

  return (
    <>
      {url && (
        <VerticalList margin={4}>
          <MiniPlayButton
            onPlay={play}
            onPause={() => audio.pause()}
            playing={playing}
            canPlay={true}
          />
          <AudioSeekBar
            audio={audio}
            currentTime={currentTime}
            onChange={setCurrentTime}
          />
        </VerticalList>
      )}
    </>
  );
};
