import React, { FC } from "react";

import styled from "styled-components";
import { LogoIcon } from "../../components/LogoIcon/LogoIcon";
import { textColor } from "../../../colors";

export const SectionTitle: FC = ({ children }) => {
  return (
    <>
      <Title>
        <LogoBox>
          <LogoIcon width={40} />
        </LogoBox>

        {children}
      </Title>
    </>
  );
};

const LogoBox = styled.div`
  text-align: center;
`;

const Title = styled.h3`
  margin: 64px 0 32px;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  color: ${textColor};
`;
