import React, { FC } from "react";
import { FrontMessage } from "../useSystemMessages";
import { MessageView } from "./MessageView";
import { RequestRequestContent } from "./RequestRequestContent";
import { RequestThanksContent } from "./RequestThanksContent";
import { RequestRequestAdditionContent } from "./RequestRequestAdditionContent";
import { RequestResponseContent } from "./RequestResponseContent";
import { UserMessageContent } from "./UserMessageContent";
import { RequestViewProfileContent } from "./RequestViewProfileContent";
import { MyUser2 } from "../../../../common/api/api2";
import { OtherUser, TopicForUser } from "../../../../api";
import { PointMessageContent } from "./PointMessageContent";
import { RequestGivePointContent } from "./RequestGivePointContent";

interface Props {
  me: MyUser2;
  message: FrontMessage;
  onGetVoiceMessageSuccess?: () => void;
  showRequest: (topic?: TopicForUser) => void;
  systemUser: OtherUser;
}

export const MessageSwitch: FC<Props> = ({
  me,
  message,
  onGetVoiceMessageSuccess,
  showRequest,
  systemUser,
}) => {
  if (message.type === "USER_MESSAGE") {
    return (
      <MessageView
        me={me}
        user={message.message.from}
        fromName={
          message.message.fromName && message.message.fromName + " (呼び名)"
        }
        sentAt={message.sentAt}
      >
        <UserMessageContent
          me={me}
          message={message.message}
          onGetVoiceMessageSuccess={onGetVoiceMessageSuccess}
        />
      </MessageView>
    );
  }

  if (message.type === "POINT_MESSAGE") {
    return (
      <MessageView me={me} user={message.log.from} sentAt={message.sentAt}>
        <PointMessageContent me={me} log={message.log} />
      </MessageView>
    );
  }

  return (
    <MessageView me={me} user={systemUser}>
      {message.type === "REQUEST_REQUEST" && (
        <RequestRequestContent
          me={me}
          cast={message.cast}
          showRequest={showRequest}
        />
      )}

      {message.type === "REQUEST_THANKS" && (
        <RequestThanksContent
          requestId={message.requestId}
          cast={message.cast}
          numOfThanksLeft={message.numOfThanksLeft}
        />
      )}

      {message.type === "REQUEST_RESPONSE" && (
        <RequestResponseContent
          listener={message.listener}
          requestId={message.requestId}
        />
      )}

      {message.type === "REQUEST_REQUEST_ADDITION" && (
        <RequestRequestAdditionContent
          requestId={message.requestId}
          cast={message.cast}
          numOfRequestAdditionsLeft={message.numOfRequestAdditionsLeft}
        />
      )}
      {message.type === "REQUEST_VIEW_PROFILE" && (
        <RequestViewProfileContent
          other={message.other}
          showRequest={showRequest}
        />
      )}
      {message.type === "REQUEST_GIVE_POINT" && (
        <RequestGivePointContent me={me} message={message} />
      )}
    </MessageView>
  );
};
