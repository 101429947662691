async function loadImage(url: string): Promise<HTMLImageElement> {
  const img = new Image();
  img.crossOrigin = "anonymous";
  img.src = url;
  return new Promise((resolve, reject) => {
    img.addEventListener("load", () => {
      console.log("loaded");
      resolve(img);
    });

    img.addEventListener("error", (ev) => {
      console.log("error");
      reject(ev.error);
    });
  });
}

export async function getBlobFromImage(p: {
  url: string;
  width?: number;
  height?: number;
}): Promise<Blob> {
  const image = await loadImage(p.url);
  const canvas = document.createElement("canvas");
  if (p.width && p.height) {
    canvas.width = p.width;
    canvas.height = p.height;
  } else {
    canvas.width = image.naturalWidth;
    canvas.height = image.naturalHeight;
  }

  const context = canvas.getContext("2d")!;

  context.drawImage(image, 0, 0, canvas.width, canvas.height);

  return new Promise((resolve, reject) => {
    canvas.toBlob(function (blob) {
      if (blob) {
        resolve(blob);
      } else {
        reject(new Error("toBlob エラー"));
      }
    }, "image/jpeg");
  });
}
