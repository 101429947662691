import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import {
  useRestoreLocation,
  useSaveLocationToRestore,
} from "../restoreLocation";

/**
 * condition が true になると、指定した path に遷移する
 */
export function useGotoIf(
  path: string,
  condition: boolean,
  options?: {
    saveLocation?: boolean;
    restoreLocation?: boolean;
    replace?: boolean;
  }
): void {
  const saveLocation = options?.saveLocation;
  const restoreLocation = options?.restoreLocation;
  const replace = options?.replace;

  const history = useHistory();
  const save = useSaveLocationToRestore();
  const restore = useRestoreLocation();
  useEffect(() => {
    if (!condition) {
      return;
    }

    if (saveLocation) {
      save();
    }
    if (restoreLocation) {
      restore();
    } else {
      if (replace) {
        history.replace(path);
      } else {
        history.push(path);
      }
    }
  }, [
    history,
    path,
    condition,
    saveLocation,
    save,
    restoreLocation,
    restore,
    replace,
  ]);
}
