import { useCallback } from "react";
import { track } from "../track";

export function useRegisterPartner() {
  const url =
    "https://docs.google.com/forms/d/e/1FAIpQLSc28wHLu_khaxsdedz33Mu0bTWVL45_k40YjsUbjJGJ2flW7g/viewform?usp=sf_link";

  const registerPartner = useCallback(() => {
    track({
      event: "partner_register",
      data: "",
    });

    window.open(url, "_blank");
  }, []);
  return {
    registerPartner,
  };
}
