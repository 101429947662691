import React, { FC } from "react";

import { Progress } from "antd";

type AudioProgressProps = {
  audio: HTMLAudioElement;
  currentTime: number;
};

export const AudioProgress: FC<AudioProgressProps> = ({
  audio,
  currentTime,
}) => {
  const percent = audio.duration ? (currentTime / audio.duration) * 100 : 0;

  return <Progress percent={percent} size="small" showInfo={false} />;
};
