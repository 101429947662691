import React, { FC } from "react";

import { Button, Select, Tag } from "antd";
import {
  AdminVoiceMessage,
  CensorResult,
  VoiceMessageId,
} from "../../../common/api/types";
import { useMutation } from "react-query";
import {
  postAdminVoiceMessageAssign,
  UserForAdmin,
} from "../../../common/api/admin";
import { AppPopConfirm } from "../../../common/components/AppPopConfirm";
import { UserId } from "../../../auth/auth";
import { IgnoreButton } from "./IgnoreButton";
import { useInvalidateRequests } from "../hooks/request";
import {
  postStaffVoiceMessageSend,
  putStaffVoiceMessageCensor,
  putStaffVoiceMessagePriority,
} from "../../../common/api/staff";
import { CensorView } from "../pages/AdminVoiceMessageListPage/CensorView";
import { DeleteButton } from "./DeleteButton";
import { HorizontalSpace } from "../../../common/components/HorizontalSpace";
import { SetRecommendedCastControl } from "../pages/AdminVoiceMessageListPage/SetRecommendedCastControl";
import { VoiceMessagePlayer } from "./VoiceMessagePlayer";
import { TopicControls } from "../pages/AdminVoiceMessageListPage/TopicControls";
import { VerticalList } from "../../../common/components/VerticalList";
import { LinkRequestAndUserControl } from "./LinkRequestAndUserControl";
import { isOrphanRequest } from "../../helper/user";

type Props = {
  voiceMessage: AdminVoiceMessage;
  casts: UserForAdmin[];
};

/**
 * 管理者によるボイスメッセージに対する操作群
 */
export const AdminVoiceMessageControls: FC<Props> = ({
  voiceMessage,
  casts,
}) => {
  const invalidate = useInvalidateRequests();

  const sendToListener = useMutation(
    async () => await postStaffVoiceMessageSend(voiceMessage.id),
    {
      onSuccess: invalidate,
    }
  );

  const assignCast = useMutation(
    async (params: { voiceMessageId: VoiceMessageId; castId: UserId }) => {
      await postAdminVoiceMessageAssign(params);
    },
    {
      onSuccess: invalidate,
    }
  );

  const censorRequest = useMutation(
    async (params: { voiceMessageId: VoiceMessageId; result: CensorResult }) =>
      await putStaffVoiceMessageCensor(params),
    {
      onSuccess: invalidate,
    }
  );

  const setPriority = useMutation(
    async (params: { voiceMessageId: VoiceMessageId; priority: number }) =>
      await putStaffVoiceMessagePriority(params),
    {
      onSuccess: invalidate,
    }
  );

  const cast = casts.find((it) => it.id === voiceMessage.castId);

  if (isOrphanRequest(voiceMessage)) {
    return (
      <div>
        <LinkRequestAndUserControl request={voiceMessage} />
        <IgnoreButton voiceMessage={voiceMessage} />
      </div>
    );
  }

  return (
    <VerticalList>
      <HorizontalSpace>
        {voiceMessage.state === "REQUESTED" && (
          <CensorView
            name="リクエスト内容"
            voiceMessage={voiceMessage}
            mutation={censorRequest}
          />
        )}
        {voiceMessage.state === "REQUESTED_CENSOR_OK" && (
          <>
            {voiceMessage.castId ? (
              <Tag>アサイン済 {cast && `(${cast.name})`}</Tag>
            ) : (
              <>
                <Select
                  placeholder="アサイン"
                  value={voiceMessage.castId}
                  style={{ flexGrow: 1 }}
                  onChange={(v) =>
                    assignCast.mutate({
                      voiceMessageId: voiceMessage.id,
                      castId: v,
                    })
                  }
                  loading={assignCast.isLoading}
                  disabled={assignCast.isSuccess}
                >
                  {casts.map((it) => (
                    <Select.Option key={it.id} value={it.id}>
                      {it.name}
                    </Select.Option>
                  ))}
                </Select>
                <SetRecommendedCastControl
                  voiceMessage={voiceMessage}
                  casts={casts}
                />
              </>
            )}
            <div>
              <Select
                value={voiceMessage.priority}
                onChange={(a) =>
                  setPriority.mutate({
                    voiceMessageId: voiceMessage.id,
                    priority: a,
                  })
                }
                loading={setPriority.isLoading}
                disabled={setPriority.isLoading}
              >
                <Select.Option value={-1}>-1</Select.Option>
                <Select.Option value={0}>0</Select.Option>
                <Select.Option value={1}>1</Select.Option>
                <Select.Option value={2}>2</Select.Option>
              </Select>
            </div>
          </>
        )}
        {voiceMessage.state === "IGNORED" && (
          <DeleteButton voiceMessage={voiceMessage} />
        )}
        {voiceMessage.state === "RECORDED" && (
          <AppPopConfirm
            title="送信してもよろしいですか"
            onConfirm={() => sendToListener.mutate()}
          >
            <Button
              loading={sendToListener.isLoading}
              disabled={sendToListener.isSuccess}
            >
              送信
            </Button>
          </AppPopConfirm>
        )}
      </HorizontalSpace>

      <VoiceMessagePlayer url={voiceMessage.url} />

      <TopicControls request={voiceMessage} casts={casts} />

      <div>
        <IgnoreButton voiceMessage={voiceMessage} />
      </div>
    </VerticalList>
  );
};
