import * as React from "react";
import { FC } from "react";

interface Props {}

export const PreLine: FC<Props> = ({ children }) => {
  return (
    <span
      style={{
        whiteSpace: "pre-line",
      }}
    >
      {children}
    </span>
  );
};
