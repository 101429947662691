// https://coolors.co/d8dcff-37419e-c38d94-ea4c89-31afb4

// export const primaryColor = "#87C4C7";
// 浅葱色
export const primaryColor = "#00A4AC";

export const primaryColor3 = "#ED6EA0";

// 薄浅葱色
export const primaryColor4 = "#A2D7DD";

export const talkColor = "#90BBBF";
export const readColor = "#ABD4CB";
export const singColor = "#77BBC7";

export const primaryLightBgColor = "#DBE9EA";
export const primaryColor2 = "#B2D7D7";

export const gray5 = "#555";

export const textColor = "#555";

export const borderColor = "#dadde1";

export const bgColor2 = "#f7f7f7";
