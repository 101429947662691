import { useCallback, useEffect, useState } from "react";

/**
 * マイク等の音声入力 & デバイスセレクト機能
 */
export function useAudioInput2() {
  const [mediaStream, setStream] = useState<MediaStream | null>(null);
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [messages, setMessages] = useState<string[]>([]);
  const [tracks, setTracks] = useState<MediaStreamTrack[]>([]);
  const [actualDevice, setActualDevice] = useState<string>();

  async function updateDeviceList() {
    const devices = await navigator.mediaDevices.enumerateDevices();
    setDevices(devices.filter((d) => d.kind === "audioinput"));
  }

  function log(m: string) {
    setMessages((s) => [...s, m]);
  }

  useEffect(() => {
    (async () => {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });

      setStream(mediaStream);

      navigator.mediaDevices.addEventListener("devicechange", async (ev) => {
        await updateDeviceList();
        log(ev.type);
      });
    })();
  }, []);

  // stream 変更時
  useEffect(() => {
    if (!mediaStream) {
      return;
    }

    console.log("useAudioInput: stream changed");
    setTracks(mediaStream.getAudioTracks());

    updateDeviceList().then();
  }, [mediaStream]);

  const setDevice = useCallback(async (deviceId: string) => {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: {
        deviceId,
      },
    });
    setActualDevice(deviceId);
    setStream(stream);
  }, []);

  return {
    devices,
    messages,
    tracks,
    device: actualDevice,
    setDevice,
    mediaStream,
  };
}
