import { useQuery } from "react-query";
import { compareString } from "../../../common/util/string";
import { AdminVoiceMessage } from "../../../common/api/types";
import { UserId } from "../../../auth/auth";
import { adminApi } from "../../../common/api/api2";

export function useAdminAllVoiceMessages(): AdminVoiceMessage[] {
  return useQuery(["requests", "admin-all-voice-messages"], async () =>
    (await adminApi.getVoiceMessages()).data.sort((a, b) =>
      compareString(a.requestedAt, b.requestedAt)
    )
  ).data!;
}

/**
 * LINE ID => リクエスト数 な map を返す
 */
export function useLineIdToCount(): Map<string, number> {
  const voiceMessages = useAdminAllVoiceMessages();

  const lineIdToCount = new Map<string, number>();
  for (let v of voiceMessages) {
    const c = lineIdToCount.get(v.tmpLineUserId!) ?? 0;
    lineIdToCount.set(v.tmpLineUserId!, c + 1);
  }
  return lineIdToCount;
}

/**
 *
 */
export function useVoiceMessageFrom(castId: UserId): AdminVoiceMessage[] {
  const voiceMessages = useAdminAllVoiceMessages();

  return voiceMessages.filter((it) => it.castId === castId);
}
