import React, { FC, useCallback, useEffect, useState } from "react";

import { VerticalList } from "../../../common/components/VerticalList";
import {
  PostRequest,
  PostRequestTalkTypeEnum,
  TopicForUser,
  UserSexEnum,
} from "../../../api";
import { MyUser2, Sex3 } from "../../../common/api/api2";
import { TopicList } from "../TopicList";
import { RequestName } from "../RequestName";
import { RequestTargetSexType } from "../RequestTargetSexType";
import useScrollIntoView from "../../../common/hooks/useScrollIntoView";
import { Section } from "../../../common/components/Section";
import { Checkbox, Skeleton } from "antd";
import { RequestListenerTags } from "../RequestListenerTags";
import { useTopicsForRequestQuery } from "../../../common/api/hooks/voiceMessage";
import { useMutation } from "react-query";
import { AppTextArea } from "../../../common/components/AppTextArea";
import { AppButton } from "../../../common/components/AppButton";
import { Hearing } from "../../../common/components/icons";
import { RequestContentHelp } from "../help/RequestContentHelp";
import { Right } from "../../../common/components/Right";
import { SectionTitle } from "../SectionTitle";
import { Small2 } from "../../../common/components/Small2";
import { track } from "../../../common/track";
import { PublicRequestNote } from "../help/PublicRequestNote";
import { shuffle } from "../../../lib/array";
import { Link } from "../../../common/components/Link";

type Me = Pick<MyUser2, "listenerName" | "tags">;

interface Props {
  me: Me;
  onRequest?: (params: PostRequest) => Promise<void>;
  maxTopics?: number;
  defaultCastGender?: Sex3;
}

export const PostPublicRequest: FC<Props> = ({
  me,
  onRequest,
  maxTopics,
  defaultCastGender = UserSexEnum.Female,
}) => {
  const [, setRerender] = useState(1);
  const [sex, setSex] = useState<Sex3>(defaultCastGender);

  const topicsQuery = useTopicsForRequestQuery(sex);

  const topics = topicsQuery.data
    ? shuffle(topicsQuery.data).slice(0, maxTopics ?? 100)
    : null;

  const showOtherTopics = useCallback(() => {
    track({ event: "public-request.show-other-topics" });
    setRerender((it) => it + 1);
  }, []);

  return (
    <PostPublicRequestTemplate
      me={me}
      topics={topics}
      sex={sex}
      onCastSexChanged={setSex}
      onRequest={onRequest}
      onShowOtherTopics={maxTopics ? showOtherTopics : undefined}
    />
  );
};

export interface PostPublicRequestTemplateProps {
  me: Me;
  /**
   * 読み込み中では null
   */
  topics: TopicForUser[] | null;
  sex: Sex3;
  onCastSexChanged?: (sex: Sex3) => void;
  onRequest?: Props["onRequest"];
  onShowOtherTopics?: () => void;
}

export const PostPublicRequestTemplate: FC<PostPublicRequestTemplateProps> = ({
  me,
  topics,
  sex,
  onCastSexChanged,
  onRequest,
  onShowOtherTopics,
}) => {
  const [name, setName] = useState(me.listenerName ?? "");
  const [topic, setTopic] = useState<TopicForUser | null>(null);
  const [tags, setTags] = useState(me.tags);
  const [notPolite, setNotPolite] = useState(false);
  const [message, setMessage] = useState("");

  const request = useMutation(async () => {
    const params: PostRequest = {
      listenerTags: tags,
      talkType: notPolite
        ? PostRequestTalkTypeEnum.NotPolite
        : PostRequestTalkTypeEnum.Polite,
      listenerName: name,
      topicId: topic!.id,
      message: message,
      castSex: sex as any,
    };
    if (onRequest) {
      await onRequest(params);
    }
  });

  const onSelectSex = useCallback(
    (s) => {
      if (onCastSexChanged) {
        onCastSexChanged(s);
      }
      track({ event: "public-request.select-gender", data: s });
    },
    [onCastSexChanged]
  );

  useEffect(() => {
    setTopic(null);
  }, [sex]);

  const onSelectTopic = useCallback((t: TopicForUser) => {
    track({
      event: "public-request.select-topic",
      data: t.id,
      data2: t.title,
    });
    setTopic(t);
  }, []);

  const [ref, scroll] = useScrollIntoView();
  useEffect(() => {
    if (!topic) {
      return;
    }
    scroll();
  }, [scroll, topic]);

  const canRequest = name && topic && (!topic.question || message);

  return (
    <>
      <Section>
        <VerticalList margin={32} align="center">
          <RequestName
            value={name}
            onChange={setName}
            userName={me.listenerName}
          />
          <RequestTargetSexType value={sex} onChange={onSelectSex} />

          <Checkbox
            checked={notPolite}
            onChange={(e) => setNotPolite(e.target.checked)}
          >
            タメ口OK
          </Checkbox>

          <div>
            <SectionTitle textAlign="center">
              聴きたい話題を選んでね
            </SectionTitle>
            {topics !== null ? (
              <TopicList
                topics={topics}
                hideQuestion
                onSelect={onSelectTopic}
                value={topic}
              />
            ) : (
              <Skeleton />
            )}
            {onShowOtherTopics && (
              <Right>
                <Link onClick={onShowOtherTopics}>他の話題を見る</Link>
              </Right>
            )}
          </div>
        </VerticalList>
        <div ref={ref} />
      </Section>

      {topic && (
        <Section>
          <VerticalList align="center" margin={32}>
            <VerticalList width="100%" margin={8}>
              <SectionTitle textAlign="center">
                {topic.question ?? "なにか伝えたいことがあったら記載してね"}
              </SectionTitle>

              <AppTextArea rows={5} value={message} onChange={setMessage} />
              <Right>
                <RequestContentHelp />
              </Right>
            </VerticalList>

            <RequestListenerTags value={tags} onChange={setTags} center />

            <VerticalList width="100%" align="center">
              <AppButton
                icon={<Hearing />}
                type="primary"
                disabled={!canRequest}
                onClick={() => request.mutate()}
                loading={request.isLoading}
                fullWidth
              >
                声をリクエスト
              </AppButton>
              <Small2>
                声のメッセージを受け取るために LINE アカウントが必要です
              </Small2>
            </VerticalList>

            <PublicRequestNote />
          </VerticalList>
        </Section>
      )}
    </>
  );
};
