import { FC } from "react";
import styled from "styled-components";
import { VerticalList } from "../../common/components/VerticalList";

export type ListenerProps = {
  listenerName: string;
  message: string;
};

export const Listener: FC<ListenerProps> = ({ listenerName, message }) => {
  return (
    <Root>
      <VerticalList align="center">
        <div>{listenerName}</div>
        <div>{message}</div>
      </VerticalList>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;
