import React, { FC } from "react";

import { DateTime } from "../../../common/components/DateTime";
import {
  ByNameRequestForCast,
  RandomRequestForCast,
} from "../../../common/api/cast";
import { Pre } from "../../../common/components/Pre";
import { VerticalList } from "../../../common/components/VerticalList";

export type VoiceMessageRequestViewProps = {
  value: ByNameRequestForCast | RandomRequestForCast;
  /**
   * 過去互換のために nullable
   */
  requestedAt?: string;
};

export const VoiceMessageRequestView: FC<VoiceMessageRequestViewProps> = ({
  value,
  requestedAt,
}) => {
  return (
    <VerticalList align="center">
      <div>{value.listenerName}</div>

      <Pre>{value.request}</Pre>

      <Pre>{(value as ByNameRequestForCast).message}</Pre>
      <Pre>{(value as RandomRequestForCast).situation}</Pre>

      <div>{value.talkType}</div>

      <div>
        <DateTime value={requestedAt} />
      </div>
    </VerticalList>
  );
};
