import React, { FC } from "react";
import { VerticalList } from "../../common/components/VerticalList";
import styled from "styled-components";
import { PointIcon } from "./PointIcon";

export interface PointProps {
  point: number;
}

export const Point: FC<PointProps> = ({ point }) => {
  const numOfMini = point % 10;
  const numOfNormal = Math.floor((point / 10) % 10);
  const numOfLarge = Math.floor(point / 100);

  return (
    <VerticalList align="normal" margin={1}>
      {numOfLarge >= 1 && (
        <LargeLine>
          {[...Array(numOfLarge)].map((_, i) => (
            <PointIcon key={i} type="large" />
          ))}
        </LargeLine>
      )}

      {numOfNormal >= 1 && (
        <Line>
          {[...Array(numOfNormal)].map((_, i) => (
            <PointIcon key={i} type="normal" />
          ))}
        </Line>
      )}

      {numOfMini >= 1 && (
        <MiniLine>
          {[...Array(numOfMini)].map((_, i) => (
            <PointIcon key={i} type="small" />
          ))}
        </MiniLine>
      )}
    </VerticalList>
  );
};

const LargeLine = styled.div`
  line-height: 1;
`;

const Line = styled.div`
  line-height: 1;
`;

const MiniLine = styled.div`
  line-height: 1;
`;
