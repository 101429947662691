import React, { FC, useCallback, useState } from "react";

import { RequireLineSignIn } from "../../../auth/components/RequireLineSignIn";
import { usePageView } from "../../../common/hooks/usePageView";
import { AppLayout2 } from "../../../common/components/AppLayout2";
import { HeaderWithBackButton } from "../../../common/components/HeaderWithBackButton";
import { useParams } from "react-router-dom";
import { VerticalList } from "../../../common/components/VerticalList";
import { Section } from "../../../common/components/Section";
import { useScrollToTopOnMount } from "../../../common/hooks/useScrollToTopOnMount";
import { ApiPointLog, OtherUser, Talk, TopicForUser } from "../../../api";
import { useMe, useSystemUser } from "../../../common/hooks/user";
import useScrollIntoView from "../../../common/hooks/useScrollIntoView";
import { useFrontMessages } from "./useSystemMessages";
import { MessageSwitch } from "./message/MessageSwitch";
import { useInvalidateTalk, useTalk } from "../../hooks/message";
import { MyUser2, PersonalityId } from "../../../common/api/api2";
import { homeRoute } from "../HomePage/HomePage";
import { AppHeadroom } from "../../../common/components/AppHeadroom";
import { TopicId } from "../../../common/api/voicemessage/api";
import { StringParam, useQueryParam } from "use-query-params";
import { RequestBottomSheet } from "./message/RequestBottomSheet";
import { useUserPointLogs, usePrefetchUserPointLogs } from "../../hooks/point";

export const talkRoute = {
  path: "/users/:personalityId/talk",
  makePath: (personalityId: PersonalityId, defaultTopicId?: TopicId) => {
    const query = defaultTopicId ? `?default-topic-id=${defaultTopicId}` : "";
    return `/users/${personalityId}/talk${query}`;
  },
};

/**
 * 特定のユーザーとのやりとり
 */
export const TalkPage: FC = () => {
  return (
    <RequireLineSignIn>
      <Impl />
    </RequireLineSignIn>
  );
};

const Impl: FC = () => {
  const { personalityId } = useParams<{ personalityId: string }>();
  usePageView("talk", true, personalityId);
  const [defaultTopicId] = useQueryParam("default-topic-id", StringParam);
  const me = useMe();
  usePrefetchUserPointLogs(personalityId);
  const talk = useTalk(personalityId);
  const pointLogs = useUserPointLogs(personalityId);
  const systemUser = useSystemUser();

  return (
    <TalkTemplate
      talk={talk}
      pointLogs={pointLogs}
      me={me}
      defaultTopicId={defaultTopicId ?? null}
      systemUser={systemUser}
    />
  );
};

interface TalkTemplateProps {
  me: MyUser2;
  talk: Talk;
  defaultTopicId: TopicId | null;
  pointLogs: ApiPointLog[];
  systemUser: OtherUser;
}

const TalkTemplate: FC<TalkTemplateProps> = ({
  me,
  talk,
  defaultTopicId,
  pointLogs,
  systemUser,
}) => {
  useScrollToTopOnMount();
  const [ref] = useScrollIntoView({ auto: true });
  const messages = useFrontMessages(talk, me, defaultTopicId, pointLogs);
  const invalidateTalk = useInvalidateTalk(talk.toUser.id);

  /**
   * 再生ボタンを押した後にお礼を出すなどの処理のために
   */
  const onGetVoiceMessageSuccess = useCallback(async () => {
    await invalidateTalk();
  }, [invalidateTalk]);

  const [defaultTopicId2, setDefaultTopicId2] = useState(defaultTopicId);
  const [visible, setVisible] = useState(!!defaultTopicId2);

  const showRequest = useCallback((topic?: TopicForUser) => {
    if (topic) {
      setDefaultTopicId2(topic.id);
    }
    setVisible(true);
  }, []);

  return (
    <AppLayout2
      noVerticalMargin
      header={
        <AppHeadroom>
          <HeaderWithBackButton
            title={talk.toUser.name}
            backPath={homeRoute.makePath()}
          />
        </AppHeadroom>
      }
    >
      <Section colored>
        <VerticalList align="stretch" margin={12}>
          {messages.map((it) => (
            <MessageSwitch
              key={it.key}
              me={me}
              message={it}
              onGetVoiceMessageSuccess={onGetVoiceMessageSuccess}
              showRequest={showRequest}
              systemUser={systemUser}
            />
          ))}
        </VerticalList>
        <div ref={ref} />
      </Section>

      {talk.toUser.isCast && (
        <RequestBottomSheet
          me={me}
          cast={talk.toUser}
          visible={visible}
          setVisible={setVisible}
          defaultTopicId={defaultTopicId2}
        />
      )}
    </AppLayout2>
  );
};
