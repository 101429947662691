import React, { FC } from "react";

import { RequireLineSignIn } from "../../../auth/components/RequireLineSignIn";
import { usePageView } from "../../../common/hooks/usePageView";
import { useScrollToTopOnMount } from "../../../common/hooks/useScrollToTopOnMount";
import { ProfileEditor } from "../../components/ProfileEditor";
import { AppLayout2 } from "../../../common/components/AppLayout2";
import { Section } from "../../../common/components/Section";
import { HeaderWithBackButton } from "../../../common/components/HeaderWithBackButton";

export const castProfileEditorRoute = {
  path: "/cast/profile",
  makePath: () => {
    return `/cast/profile`;
  },
};

export const CastProfilePage: FC = () => {
  return (
    <RequireLineSignIn>
      <Impl />
    </RequireLineSignIn>
  );
};

const Impl: FC = () => {
  useScrollToTopOnMount();
  usePageView("cast_profile", true);

  return (
    <AppLayout2 header={<HeaderWithBackButton title="プロフィール設定" />}>
      <Section>
        <ProfileEditor />
      </Section>
    </AppLayout2>
  );
};
