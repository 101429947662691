import React, { FC } from "react";
import { Section } from "../../../common/components/Section";
import { VerticalList } from "../../../common/components/VerticalList";
import { RecordingContentHelp } from "../../components/help/RecordingContentHelp";
import { HorizontalCenter } from "../../../common/components/HorizontalCenter";
import { MyUser2 } from "../../../common/api/api2";
import { MessageFrame } from "../../components/MessageFrame";
import { RequestMessageContent } from "../../components/message/RequestMessageContent";
import { AssignToMeButton } from "../../components/AssignToMeButton";
import { ApiMessage } from "../../../api";

interface Props {
  me: MyUser2;
  messages: ApiMessage[];
}

export const RequestsForPublic: FC<Props> = ({ me, messages }) => {
  return (
    <>
      <Section textAlign="center">
        <p>気になるリクエストに、声のメッセージ💌を贈って、仲良くなろう🎶</p>
        <p>お返事が返ってきたり、あなた指名のリクエストをくれるかも😊</p>
        <p>
          ここでは明るい人、おとなしい人、口下手な人、いろんな人がお話しているよ
        </p>
        <p>
          だから、最初はドキドキするけど、あなたもリラックスしてお話してみてね✨
        </p>

        <HorizontalCenter>
          <RecordingContentHelp />
        </HorizontalCenter>
      </Section>

      <Section colored>
        <VerticalList>
          {messages.map((it) => (
            <MessageFrame
              key={it.id}
              user={it.from}
              // あえていまはシステムアバター. アバターが設定されていないわけではないんだよと言うのを見せたい
              systemAvatar
              profileLink
              sentAt={it.sentAt}
              blurredDate
              castGender={it.requestSupplement?.castGender as any}
            >
              <VerticalList>
                <RequestMessageContent message={it} talkLink={false} me={me} />
                <AssignToMeButton
                  requestId={it.requestId!}
                  listenerId={it.from.personalityId}
                />
              </VerticalList>
            </MessageFrame>
          ))}
        </VerticalList>
      </Section>
    </>
  );
};
