/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiMessage } from '../model';
// @ts-ignore
import { PostAdminUserPoint } from '../model';
// @ts-ignore
import { PostRequestListener } from '../model';
// @ts-ignore
import { PostVoiceMessageAssign } from '../model';
// @ts-ignore
import { User } from '../model';
// @ts-ignore
import { UserProfileResponse } from '../model';
// @ts-ignore
import { VoiceMessageData } from '../model';
/**
 * AdminControllerApi - axios parameter creator
 * @export
 */
export const AdminControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} voiceMessageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminVoiceMessage: async (voiceMessageId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'voiceMessageId' is not null or undefined
            assertParamExists('deleteAdminVoiceMessage', 'voiceMessageId', voiceMessageId)
            const localVarPath = `/admin/voice-messages/{voiceMessageId}`
                .replace(`{${"voiceMessageId"}}`, encodeURIComponent(String(voiceMessageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminUserMessagesFromUser: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAdminUserMessagesFromUser', 'userId', userId)
            const localVarPath = `/admin/users/{userId}/messages-from-user`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminUserMessagesToUser: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAdminUserMessagesToUser', 'userId', userId)
            const localVarPath = `/admin/users/{userId}/messages-to-user`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCasts: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/casts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} lineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLineUser: async (lineId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'lineId' is not null or undefined
            assertParamExists('getLineUser', 'lineId', lineId)
            const localVarPath = `/admin/line-users/{lineId}`
                .replace(`{${"lineId"}}`, encodeURIComponent(String(lineId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'REQUESTED' | 'REQUESTED_CENSOR_OK' | 'REQUESTED_CENSOR_NG' | 'RECORDED' | 'RECORDED_CENSOR_NG' | 'SENT' | 'IGNORED' | 'DELETED' | 'DUPLICATED'} [state] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVoiceMessages: async (state?: 'REQUESTED' | 'REQUESTED_CENSOR_OK' | 'REQUESTED_CENSOR_NG' | 'RECORDED' | 'RECORDED_CENSOR_NG' | 'SENT' | 'IGNORED' | 'DELETED' | 'DUPLICATED', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/voice-messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} asUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminSignInAs: async (asUserId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'asUserId' is not null or undefined
            assertParamExists('postAdminSignInAs', 'asUserId', asUserId)
            const localVarPath = `/admin/sign-in-as/{asUserId}`
                .replace(`{${"asUserId"}}`, encodeURIComponent(String(asUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {PostAdminUserPoint} postAdminUserPoint 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminUserPoint: async (userId: string, postAdminUserPoint: PostAdminUserPoint, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('postAdminUserPoint', 'userId', userId)
            // verify required parameter 'postAdminUserPoint' is not null or undefined
            assertParamExists('postAdminUserPoint', 'postAdminUserPoint', postAdminUserPoint)
            const localVarPath = `/admin/users/{userId}/points`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postAdminUserPoint, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} voiceMessageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminVoiceMessageIgnore: async (voiceMessageId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'voiceMessageId' is not null or undefined
            assertParamExists('postAdminVoiceMessageIgnore', 'voiceMessageId', voiceMessageId)
            const localVarPath = `/admin/voice-messages/{voiceMessageId}/ignore`
                .replace(`{${"voiceMessageId"}}`, encodeURIComponent(String(voiceMessageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} voiceMessageId 
         * @param {PostVoiceMessageAssign} postVoiceMessageAssign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAssignRequestToCast: async (voiceMessageId: string, postVoiceMessageAssign: PostVoiceMessageAssign, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'voiceMessageId' is not null or undefined
            assertParamExists('postAssignRequestToCast', 'voiceMessageId', voiceMessageId)
            // verify required parameter 'postVoiceMessageAssign' is not null or undefined
            assertParamExists('postAssignRequestToCast', 'postVoiceMessageAssign', postVoiceMessageAssign)
            const localVarPath = `/admin/voice-messages/{voiceMessageId}/assign`
                .replace(`{${"voiceMessageId"}}`, encodeURIComponent(String(voiceMessageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postVoiceMessageAssign, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPseudoUser: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/pseudo-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {PostRequestListener} postRequestListener 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRequestListener: async (requestId: string, postRequestListener: PostRequestListener, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            assertParamExists('postRequestListener', 'requestId', requestId)
            // verify required parameter 'postRequestListener' is not null or undefined
            assertParamExists('postRequestListener', 'postRequestListener', postRequestListener)
            const localVarPath = `/admin/requests/{requestId}/listener`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postRequestListener, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminControllerApi - functional programming interface
 * @export
 */
export const AdminControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} voiceMessageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAdminVoiceMessage(voiceMessageId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAdminVoiceMessage(voiceMessageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminUserMessagesFromUser(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiMessage>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminUserMessagesFromUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminUserMessagesToUser(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiMessage>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminUserMessagesToUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCasts(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCasts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} lineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLineUser(lineId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLineUser(lineId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'REQUESTED' | 'REQUESTED_CENSOR_OK' | 'REQUESTED_CENSOR_NG' | 'RECORDED' | 'RECORDED_CENSOR_NG' | 'SENT' | 'IGNORED' | 'DELETED' | 'DUPLICATED'} [state] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVoiceMessages(state?: 'REQUESTED' | 'REQUESTED_CENSOR_OK' | 'REQUESTED_CENSOR_NG' | 'RECORDED' | 'RECORDED_CENSOR_NG' | 'SENT' | 'IGNORED' | 'DELETED' | 'DUPLICATED', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VoiceMessageData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVoiceMessages(state, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} asUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminSignInAs(asUserId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminSignInAs(asUserId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {PostAdminUserPoint} postAdminUserPoint 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminUserPoint(userId: string, postAdminUserPoint: PostAdminUserPoint, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminUserPoint(userId, postAdminUserPoint, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} voiceMessageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminVoiceMessageIgnore(voiceMessageId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminVoiceMessageIgnore(voiceMessageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} voiceMessageId 
         * @param {PostVoiceMessageAssign} postVoiceMessageAssign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAssignRequestToCast(voiceMessageId: string, postVoiceMessageAssign: PostVoiceMessageAssign, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAssignRequestToCast(voiceMessageId, postVoiceMessageAssign, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPseudoUser(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPseudoUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} requestId 
         * @param {PostRequestListener} postRequestListener 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRequestListener(requestId: string, postRequestListener: PostRequestListener, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRequestListener(requestId, postRequestListener, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminControllerApi - factory interface
 * @export
 */
export const AdminControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} voiceMessageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminVoiceMessage(voiceMessageId: string, options?: any): AxiosPromise<string> {
            return localVarFp.deleteAdminVoiceMessage(voiceMessageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminUserMessagesFromUser(userId: string, options?: any): AxiosPromise<Array<ApiMessage>> {
            return localVarFp.getAdminUserMessagesFromUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminUserMessagesToUser(userId: string, options?: any): AxiosPromise<Array<ApiMessage>> {
            return localVarFp.getAdminUserMessagesToUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCasts(options?: any): AxiosPromise<Array<User>> {
            return localVarFp.getCasts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} lineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLineUser(lineId: string, options?: any): AxiosPromise<UserProfileResponse> {
            return localVarFp.getLineUser(lineId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(options?: any): AxiosPromise<Array<User>> {
            return localVarFp.getUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'REQUESTED' | 'REQUESTED_CENSOR_OK' | 'REQUESTED_CENSOR_NG' | 'RECORDED' | 'RECORDED_CENSOR_NG' | 'SENT' | 'IGNORED' | 'DELETED' | 'DUPLICATED'} [state] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVoiceMessages(state?: 'REQUESTED' | 'REQUESTED_CENSOR_OK' | 'REQUESTED_CENSOR_NG' | 'RECORDED' | 'RECORDED_CENSOR_NG' | 'SENT' | 'IGNORED' | 'DELETED' | 'DUPLICATED', options?: any): AxiosPromise<Array<VoiceMessageData>> {
            return localVarFp.getVoiceMessages(state, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} asUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminSignInAs(asUserId: string, options?: any): AxiosPromise<string> {
            return localVarFp.postAdminSignInAs(asUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {PostAdminUserPoint} postAdminUserPoint 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminUserPoint(userId: string, postAdminUserPoint: PostAdminUserPoint, options?: any): AxiosPromise<void> {
            return localVarFp.postAdminUserPoint(userId, postAdminUserPoint, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} voiceMessageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminVoiceMessageIgnore(voiceMessageId: string, options?: any): AxiosPromise<string> {
            return localVarFp.postAdminVoiceMessageIgnore(voiceMessageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} voiceMessageId 
         * @param {PostVoiceMessageAssign} postVoiceMessageAssign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAssignRequestToCast(voiceMessageId: string, postVoiceMessageAssign: PostVoiceMessageAssign, options?: any): AxiosPromise<void> {
            return localVarFp.postAssignRequestToCast(voiceMessageId, postVoiceMessageAssign, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPseudoUser(options?: any): AxiosPromise<void> {
            return localVarFp.postPseudoUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {PostRequestListener} postRequestListener 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRequestListener(requestId: string, postRequestListener: PostRequestListener, options?: any): AxiosPromise<void> {
            return localVarFp.postRequestListener(requestId, postRequestListener, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminControllerApi - object-oriented interface
 * @export
 * @class AdminControllerApi
 * @extends {BaseAPI}
 */
export class AdminControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} voiceMessageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public deleteAdminVoiceMessage(voiceMessageId: string, options?: any) {
        return AdminControllerApiFp(this.configuration).deleteAdminVoiceMessage(voiceMessageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getAdminUserMessagesFromUser(userId: string, options?: any) {
        return AdminControllerApiFp(this.configuration).getAdminUserMessagesFromUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getAdminUserMessagesToUser(userId: string, options?: any) {
        return AdminControllerApiFp(this.configuration).getAdminUserMessagesToUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getCasts(options?: any) {
        return AdminControllerApiFp(this.configuration).getCasts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} lineId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getLineUser(lineId: string, options?: any) {
        return AdminControllerApiFp(this.configuration).getLineUser(lineId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getUsers(options?: any) {
        return AdminControllerApiFp(this.configuration).getUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'REQUESTED' | 'REQUESTED_CENSOR_OK' | 'REQUESTED_CENSOR_NG' | 'RECORDED' | 'RECORDED_CENSOR_NG' | 'SENT' | 'IGNORED' | 'DELETED' | 'DUPLICATED'} [state] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getVoiceMessages(state?: 'REQUESTED' | 'REQUESTED_CENSOR_OK' | 'REQUESTED_CENSOR_NG' | 'RECORDED' | 'RECORDED_CENSOR_NG' | 'SENT' | 'IGNORED' | 'DELETED' | 'DUPLICATED', options?: any) {
        return AdminControllerApiFp(this.configuration).getVoiceMessages(state, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} asUserId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public postAdminSignInAs(asUserId: string, options?: any) {
        return AdminControllerApiFp(this.configuration).postAdminSignInAs(asUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {PostAdminUserPoint} postAdminUserPoint 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public postAdminUserPoint(userId: string, postAdminUserPoint: PostAdminUserPoint, options?: any) {
        return AdminControllerApiFp(this.configuration).postAdminUserPoint(userId, postAdminUserPoint, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} voiceMessageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public postAdminVoiceMessageIgnore(voiceMessageId: string, options?: any) {
        return AdminControllerApiFp(this.configuration).postAdminVoiceMessageIgnore(voiceMessageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} voiceMessageId 
     * @param {PostVoiceMessageAssign} postVoiceMessageAssign 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public postAssignRequestToCast(voiceMessageId: string, postVoiceMessageAssign: PostVoiceMessageAssign, options?: any) {
        return AdminControllerApiFp(this.configuration).postAssignRequestToCast(voiceMessageId, postVoiceMessageAssign, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public postPseudoUser(options?: any) {
        return AdminControllerApiFp(this.configuration).postPseudoUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {PostRequestListener} postRequestListener 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public postRequestListener(requestId: string, postRequestListener: PostRequestListener, options?: any) {
        return AdminControllerApiFp(this.configuration).postRequestListener(requestId, postRequestListener, options).then((request) => request(this.axios, this.basePath));
    }
}
