import React, { FC } from "react";

import { VerticalList } from "../../../common/components/VerticalList";
import { RecordingMethod1Help } from "../help/RecordingMethod1Help";
import { RecordingMethod2Help } from "../help/RecordingMethod2Help";
import { UploadVoiceUiLink } from "../UploadVoiceUiLink";
import { MessageTypeEnum, OtherUser } from "../../../api";
import { RequestId } from "../../../common/api/types";

interface Props {
  targetUser: OtherUser;
  requestId: RequestId;
}

export const PostThanksByVoice: FC<Props> = ({ targetUser, requestId }) => {
  return (
    <VerticalList align="center">
      <RecordingMethod2Help />
      <RecordingMethod1Help />
      <UploadVoiceUiLink
        targetUser={targetUser}
        messageType={MessageTypeEnum.Thanks}
        requestId={requestId}
      />
    </VerticalList>
  );
};
