/**
 * メッセージ内容に関する注意書き
 */
import { FC } from "react";
import { NoteBox } from "../../../common/components/NoteBox";

export const RecordingNote: FC = () => {
  return (
    <NoteBox>
      あなたの個人情報や、相手の個人情報を聞き出すようなメッセージ、
      アダルトや勧誘等不快になるようなメッセージは送らないでください
      <br />
      <br />
      問題のあるメッセージは運営によって削除、悪質な場合はアカウントを停止いたします
    </NoteBox>
  );
};
