import React, { FC } from "react";
import { MyUser } from "../../common/api/user";
import { AppAvatar } from "./AppAvatar";
import { VerticalList } from "../../common/components/VerticalList";
import { HorizontalList } from "../../common/components/HorizontalList";
import { UserTags } from "./UserTags";
import { Button } from "antd";
import { useGoto } from "../../lib/hooks/useGoto";
import { castProfileEditorRoute } from "../pages/CastProfileEditorPage/CastProfilePage";
import { Strong } from "../../common/components/Strong";
import { Right } from "../../common/components/Right";

interface Props {
  myUser: MyUser;
}

export const MyProfile: FC<Props> = ({ myUser }) => {
  const gotoProfileEditor = useGoto(castProfileEditorRoute.makePath());
  return (
    <VerticalList margin={16}>
      <HorizontalList alignItems="center" margin={4}>
        <AppAvatar src={myUser.avatarUrl!!} />
        <VerticalList margin={4}>
          <div>
            <Strong> {myUser.name}</Strong>
          </div>
          {myUser.tags.length === 0 ? (
            <UserTags tags={["タグ未設定"]} />
          ) : (
            <UserTags tags={myUser.tags} />
          )}
        </VerticalList>
      </HorizontalList>
      <div>{myUser.noteForListener || "メッセージ未設定"}</div>
      <Right>
        <Button type="link" onClick={gotoProfileEditor}>
          プロフィール設定
        </Button>
      </Right>
    </VerticalList>
  );
};
