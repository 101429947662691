/**
 * メッセージ内容に関する注意書き
 */
import { FC } from "react";
import { NoteBox } from "../../../common/components/NoteBox";

export const PublicRequestNote: FC = () => {
  return (
    <NoteBox>
      <br />
      リクエストはYour Radioを利用している他のユーザーに送信され、
      答えたいと思ったユーザーによってお返事がくる仕組みになっています。
      <br />
      そのため、返信にお時間をいただいたり、内容によっては返信が来ない場合があります。
      <br />
      <br />
      不適切な内容が含まれている場合、運営によってリクエストは削除されます。
    </NoteBox>
  );
};
