import { FC } from "react";
import { useIsLineUserSignedIn } from "../auth";
import { SignIn } from "./SignIn";

export type SignInProps = {
  message?: string;
};

export const RequireLineSignIn: FC<SignInProps> = ({ message, children }) => {
  const signedIn = useIsLineUserSignedIn();

  if (signedIn) {
    return <>{children}</>;
  }

  return <SignIn message={message} />;
};
