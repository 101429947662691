import axios from "axios";
import { apiUrl } from "./api";
import { HttpSuccess, UrlString } from "./types";

export type ImageFileId = string;

export interface ImageFile {
  id: ImageFileId;
  url: UrlString;
}

export async function postImageFile(params: {
  blob: Blob;
  mime: string;
}): Promise<HttpSuccess<ImageFile>> {
  let formData = new FormData();

  formData.append("file", params.blob);
  formData.append("mime", params.mime);

  const res = await axios.post(`${apiUrl}/images`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return { kind: "ok", data: res.data };
}
