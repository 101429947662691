import React, { FC, useCallback } from "react";

import { RequireLineSignIn } from "../../../auth/components/RequireLineSignIn";
import { usePageView } from "../../../common/hooks/usePageView";
import { AppLayout2 } from "../../../common/components/AppLayout2";
import { VerticalList } from "../../../common/components/VerticalList";
import { HeaderWithBackButton } from "../../../common/components/HeaderWithBackButton";
import { useHistory } from "react-router-dom";
import { requestedRoute } from "../RequestedPage/RequestedPage";
import { useScrollToTopOnMount } from "../../../common/hooks/useScrollToTopOnMount";
import { useUserStatus } from "../../../common/hooks/user";
import { MyUser } from "../../../common/api/user";
import { Section } from "../../../common/components/Section";
import { MeAndCast } from "../../components/MeAndCast";
import { PostPublicRequest } from "../../components/PostPublicRequest/PostPublicRequest";
import { PostRequest } from "../../../api";
import { listenerApi } from "../../../common/api/api2";
import { homeRoute } from "../HomePage/HomePage";

export const publicRequestRoute = {
  path: "/voice-messages/public-request",
  makePath() {
    return "/voice-messages/public-request";
  },
};

export const PublicRequestPage: FC = () => {
  return (
    <RequireLineSignIn>
      <Impl />
    </RequireLineSignIn>
  );
};

const Impl: FC = () => {
  usePageView("random_request", true);
  useScrollToTopOnMount();
  const me = useUserStatus();
  const history = useHistory();

  const onComplete = useCallback(() => {
    history.push(requestedRoute.makePath());
  }, [history]);

  return <Template me={me} onComplete={onComplete} />;
};

interface TemplateProps {
  me: MyUser;
  onComplete: () => void;
}

const Template: FC<TemplateProps> = ({ me, onComplete }) => {
  const post = useCallback(
    async (params: PostRequest) => {
      await listenerApi.postRequest(params);
      onComplete();
    },
    [onComplete]
  );

  return (
    <AppLayout2
      header={
        <HeaderWithBackButton
          title="だれかに声をリクエスト"
          backPath={homeRoute.makePath()}
        />
      }
      noVerticalMargin
    >
      <Section colored>
        <VerticalList align="stretch">
          <MeAndCast me={me} cast={null} />
        </VerticalList>
      </Section>

      <PostPublicRequest me={me} onRequest={post} />
    </AppLayout2>
  );
};
