import React, { FC, useEffect, useState } from "react";

import { Checkbox } from "antd";
import { VerticalList } from "../../../common/components/VerticalList";
import { useMutation } from "react-query";
import { RequestNote } from "../RequestNote";
import { OtherUser, PostRequestTalkTypeEnum, TopicForUser } from "../../../api";
import { listenerApi, MyUser2 } from "../../../common/api/api2";
import { TopicId } from "../../../common/api/voicemessage/api";
import { useInvalidateTalk } from "../../hooks/message";
import { useUserTopics } from "../../hooks/topic";
import { TopicList } from "../TopicList";
import { Strong } from "../../../common/components/Strong";
import useScrollIntoView from "../../../common/hooks/useScrollIntoView";
import { HorizontalCenter } from "../../../common/components/HorizontalCenter";
import { Section } from "../../../common/components/Section";
import { AppTextArea } from "../../../common/components/AppTextArea";
import { AppButton } from "../../../common/components/AppButton";
import { RequestName } from "../RequestName";
import { Hearing } from "../../../common/components/icons";
import { RequestContentHelp } from "../help/RequestContentHelp";
import { Right } from "../../../common/components/Right";

interface Props {
  me: MyUser2;
  cast: OtherUser;
  onComplete?: () => void;
  defaultTopicId?: TopicId | null;
}

/**
 * 指名リクエストを行うコンポーネント
 */
export const PostByNameRequest: FC<Props> = ({
  me,
  cast,
  onComplete,
  defaultTopicId,
}) => {
  const topics = useUserTopics(cast.id);

  return (
    <PostByNameRequestTemplate
      me={me}
      cast={cast}
      onComplete={onComplete}
      defaultTopicId={defaultTopicId}
      topics={topics}
    />
  );
};

export interface PostByNameRequestTemplateProps {
  me: MyUser2;
  cast: OtherUser;
  onComplete?: () => void;
  defaultTopicId?: TopicId | null;
  topics: TopicForUser[];
}

/**
 * 指名リクエストを行う
 */
export const PostByNameRequestTemplate: FC<PostByNameRequestTemplateProps> = ({
  me,
  cast,
  onComplete,
  topics,
}) => {
  const invalidate = useInvalidateTalk(cast.id);
  const [topic, setTopic] = useState<TopicForUser | null>(null);
  const [notPolite, setNotPolite] = useState(false);
  const [message, setMessage] = useState("");
  const [name, setName] = useState(me.listenerName ?? "");

  // この処理使いづらい
  // useEffect(() => {
  //   const topic = topics.find((it) => it.id === defaultTopicId);
  //   setTopic(topic ?? null);
  // }, [defaultTopicId, topics]);

  const request = useMutation(
    async () =>
      await listenerApi.postRequest({
        castId: cast.id,
        listenerTags: me.tags,
        talkType: notPolite
          ? PostRequestTalkTypeEnum.NotPolite
          : PostRequestTalkTypeEnum.Polite,
        listenerName: name,
        topicId: topic!.id,
        message: message,
      }),
    {
      async onSuccess() {
        await invalidate();
        if (onComplete) {
          onComplete();
        }
      },
    }
  );

  const [ref, scroll] = useScrollIntoView();
  useEffect(() => {
    scroll();
  }, [scroll, topic]);

  const canRequest = name && topic && (!topic.question || message);

  return (
    <VerticalList align="stretch">
      <VerticalList align="center">
        <RequestName
          value={name}
          onChange={setName}
          userName={me.listenerName}
        />
        <Checkbox
          checked={notPolite}
          onChange={(e) => setNotPolite(e.target.checked)}
        >
          タメ口OK
        </Checkbox>

        <div>
          <HorizontalCenter>
            <Strong>聴きたい話題を選んでね</Strong>
          </HorizontalCenter>
          <TopicList
            topics={topics}
            hideQuestion
            onSelect={setTopic}
            value={topic}
          />
        </div>

        {topic && (
          <Section>
            <VerticalList align="center">
              <div ref={ref} style={{ width: "100%" }}>
                <HorizontalCenter>
                  <Strong>
                    {topic.question ?? "なにかメッセージがあったら記載してね"}
                  </Strong>
                </HorizontalCenter>

                <AppTextArea rows={5} value={message} onChange={setMessage} />
                <Right>
                  <RequestContentHelp />
                </Right>
              </div>

              <AppButton
                icon={<Hearing />}
                type="primary"
                disabled={!canRequest}
                onClick={() => request.mutate()}
                loading={request.isLoading}
              >
                声をリクエスト
              </AppButton>

              <RequestNote />
            </VerticalList>
          </Section>
        )}
      </VerticalList>
    </VerticalList>
  );
};
