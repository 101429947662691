import { CensorResult, HttpSuccess, VoiceMessageId } from "./types";
import axios from "axios";
import { apiUrl } from "./api";
import { UserId } from "../../auth/auth";

export type NotificationLink = "NONE" | "CAST" | "LISTENER";

export async function putStaffVoiceMessageCensor(params: {
  voiceMessageId: VoiceMessageId;
  result: CensorResult;
}): Promise<HttpSuccess<void>> {
  const res = await axios.put(
    `${apiUrl}/staff/voice-messages/${params.voiceMessageId}/censor`,
    {
      result: params.result,
    }
  );
  console.log(res);
  return { kind: "ok", data: res.data };
}

export async function postStaffVoiceMessageSend(
  voiceMessageId: VoiceMessageId
): Promise<HttpSuccess<void>> {
  const res = await axios.post(
    `${apiUrl}/staff/voice-messages/${voiceMessageId}/send`
  );
  console.log(res);
  return { kind: "ok", data: res.data };
}

export async function putStaffVoiceMessageThanksCensor(params: {
  voiceMessageId: VoiceMessageId;
  result: CensorResult;
}): Promise<HttpSuccess<void>> {
  const res = await axios.put(
    `${apiUrl}/staff/voice-messages/${params.voiceMessageId}/thanks/censor`,
    {
      result: params.result,
    }
  );
  console.log(res);
  return { kind: "ok", data: res.data };
}

export async function putStaffUserProfileCensor(params: {
  userId: UserId;
  result: CensorResult;
}): Promise<HttpSuccess<void>> {
  const res = await axios.put(
    `${apiUrl}/staff/users/${params.userId}/profile/censor`,
    {
      result: params.result,
    }
  );
  console.log(res);
  return { kind: "ok", data: res.data };
}

export async function putStaffVoiceMessagePriority(params: {
  voiceMessageId: VoiceMessageId;
  priority: number;
}): Promise<HttpSuccess<void>> {
  const res = await axios.put(
    `${apiUrl}/staff/voice-messages/${params.voiceMessageId}/priority`,
    {
      priority: params.priority,
    }
  );
  console.log(res);
  return { kind: "ok", data: res.data };
}

export async function putStaffVoiceMessageRecommendedCast(params: {
  voiceMessageId: VoiceMessageId;
  recommendedCastId: UserId | null;
}): Promise<HttpSuccess<void>> {
  const res = await axios.put(
    `${apiUrl}/staff/voice-messages/${params.voiceMessageId}/recommended-cast`,
    {
      recommendedCastId: params.recommendedCastId,
    }
  );
  console.log(res);
  return { kind: "ok", data: res.data };
}

export async function postStaffNotification(params: {
  userId: UserId;
  message: string;
  link: NotificationLink;
}): Promise<HttpSuccess<void>> {
  const res = await axios.put(`${apiUrl}/staff/notifications`, {
    userId: params.userId,
    message: params.message,
    link: params.link,
  });
  console.log(res);
  return { kind: "ok", data: res.data };
}
