import { FC, useEffect, useState } from "react";
import moment, { Duration, Moment } from "moment";

export type StopWatchProps = {
  startTime?: Moment;
};

export const StopWatch: FC<StopWatchProps> = ({ startTime }) => {
  const [duration, setDuration] = useState<Duration>(moment.duration(0));

  useEffect(() => {
    if (!startTime) {
      return;
    }
    const id = setInterval(() => {
      const a = moment.duration(moment().diff(startTime));
      setDuration(a);
    }, 100);

    return () => clearInterval(id);
  }, [startTime]);

  return (
    <span>
      {zeroPad(duration.minutes())}:{zeroPad(duration.seconds())}
    </span>
  );
};

function zeroPad(n: number): string {
  return n.toString().padStart(2, "0");
}
