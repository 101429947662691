/**
 * 複製を生成してシャッフルする
 */
import { compareString } from "../common/util/string";

export function shuffle<T>(a: T[]): T[] {
  return [...a].sort(() => Math.random() - 0.5);
}

/**
 * 複製を生成してソートする
 */
export function sortByNumber<T>(array: T[], f: (v: T) => number): T[] {
  return [...array].sort((a, b) => f(a) - f(b));
}

/**
 * 複製を生成してソートする
 */
export function sortByNumberDescending<T>(
  array: T[],
  f: (v: T) => number
): T[] {
  return [...array].sort((a, b) => f(b) - f(a));
}

/**
 * 複製を生成してソートする
 */
export function sortByString<T>(array: T[], f: (v: T) => string): T[] {
  return [...array].sort((a, b) => compareString(f(a), f(b)));
}

/**
 * 複製を生成してソートする
 */
export function sortByStringDescending<T>(
  array: T[],
  f: (v: T) => string
): T[] {
  return [...array].sort((a, b) => compareString(f(b), f(a)));
}

/**
 * 順番は維持しないかもしれない
 */
export function distinctBy<T, U>(array: T[], f: (a: T) => U): T[] {
  const map = new Map<U, T>();
  for (let t of array) {
    map.set(f(t), t);
  }
  return Array.from(map.values());
}
