import React, { FC } from "react";

import { useHistory } from "react-router-dom";
import { useSaveLocationToRestore } from "../../lib/restoreLocation";
import styled from "styled-components";
import { primaryColor } from "../../colors";

type LinkProps = {
  to?: string;
  saveLocation?: boolean;
  onClick?: () => void;
};

export const Link: FC<LinkProps> = ({
  to,
  saveLocation,
  onClick,
  children,
}) => {
  const save = useSaveLocationToRestore();
  const history = useHistory();

  return (
    <MyA
      href={to}
      onClick={(e) => {
        if (saveLocation) {
          save();
        }
        if (onClick) {
          onClick();
        }
        if (to) {
          history.push(to);
        }

        e.preventDefault();
      }}
    >
      {children}
    </MyA>
  );
};

const MyA = styled.a`
  color: ${primaryColor};

  &:active,
  &:hover {
    color: ${primaryColor};
  }
`;
