import React, { FC } from "react";
import { Point } from "./Point";
import { PointIcon } from "./PointIcon";

export interface PointBoxProps {
  point: number;
}

/**
 * 自分の保有するポイントを表示する
 * @param point
 * @constructor
 */
export const MyPointBox: FC<PointBoxProps> = ({ point }) => {
  return (
    <div>
      あなたの保有
      <PointIcon />
      {point === 0 ? <div>なし</div> : <Point point={point} />}
    </div>
  );
};
