import { Spin } from "antd";
import React, { FC } from "react";
import { Vh100 } from "./Vh100";
import styled from "styled-components";
import { HorizontalCenter } from "./HorizontalCenter";

interface Props {
  vh100?: boolean;
}

export const Loading: FC<Props> = ({ vh100 }) => {
  if (vh100)
    return (
      <Vh100>
        <Root>
          <Spin />
        </Root>
      </Vh100>
    );
  return (
    <div>
      <HorizontalCenter>
        <Spin />
      </HorizontalCenter>
    </div>
  );
};

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
`;
