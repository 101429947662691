/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PostRequest } from '../model';
/**
 * ListenerApi - axios parameter creator
 * @export
 */
export const ListenerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 自分のリクエストを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyRequests: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/@me/my-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 話題に対してリクエストします
         * @param {PostRequest} postRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRequest: async (postRequest: PostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postRequest' is not null or undefined
            assertParamExists('postRequest', 'postRequest', postRequest)
            const localVarPath = `/requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ListenerApi - functional programming interface
 * @export
 */
export const ListenerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ListenerApiAxiosParamCreator(configuration)
    return {
        /**
         * 自分のリクエストを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyRequests(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyRequests(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 話題に対してリクエストします
         * @param {PostRequest} postRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRequest(postRequest: PostRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRequest(postRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ListenerApi - factory interface
 * @export
 */
export const ListenerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ListenerApiFp(configuration)
    return {
        /**
         * 自分のリクエストを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyRequests(options?: any): AxiosPromise<string> {
            return localVarFp.getMyRequests(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 話題に対してリクエストします
         * @param {PostRequest} postRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRequest(postRequest: PostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.postRequest(postRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ListenerApi - object-oriented interface
 * @export
 * @class ListenerApi
 * @extends {BaseAPI}
 */
export class ListenerApi extends BaseAPI {
    /**
     * 自分のリクエストを取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListenerApi
     */
    public getMyRequests(options?: any) {
        return ListenerApiFp(this.configuration).getMyRequests(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 話題に対してリクエストします
     * @param {PostRequest} postRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ListenerApi
     */
    public postRequest(postRequest: PostRequest, options?: any) {
        return ListenerApiFp(this.configuration).postRequest(postRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
