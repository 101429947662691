import React, { FC } from "react";
import { IconButton } from "../../components/IconButton";
import { AudioOutlined } from "@ant-design/icons";
import { useGoto } from "../../../lib/hooks/useGoto";
import { requestsRoute } from "../CastNotAssignedRequestsPage/RequestsPage";
import { IconButtonList } from "../../components/IconButtonList";
import { AppPopover } from "../../components/AppPopover";
import { Hearing, People, Public } from "../../../common/components/icons";
import { relationshipRoute } from "../RelationshipPage/RelationshipPage";
import { publicRequestRoute } from "../PublicRequestPage/PublicRequestPage";

interface Props {}

export const TopNavigation: FC<Props> = () => {
  const goInbox = useGoto(requestsRoute.makePath());

  return (
    <IconButtonList>
      <AppPopover
        content={ListenerMenu}
        placement="bottom"
        track="home.listener-menu.popup"
      >
        <IconButton icon={<Hearing />}>声をリクエストする</IconButton>
      </AppPopover>

      <IconButton icon={<AudioOutlined />} onClick={goInbox}>
        声のリクエストに答える
      </IconButton>
    </IconButtonList>
  );
};

const ListenerMenu: FC = () => {
  const goPublic = useGoto(publicRequestRoute.makePath());
  const goRelation = useGoto(relationshipRoute.makePath());
  return (
    <IconButtonList>
      <IconButton icon={<People />} onClick={goRelation}>
        つながりからリクエスト
      </IconButton>
      <IconButton icon={<Public />} onClick={goPublic}>
        だれかにリクエスト
      </IconButton>
    </IconButtonList>
  );
};
