import { useCallback, useEffect, useRef, useState } from "react";

const mimes = [`audio/mp4`, `audio/webm`];

/**
 * stop すると recordingData に値が設定される
 *
 * mac Mojave safari で start イベントが発生しなかったので、それを考慮した作りになっている
 */
export function useMediaRecorder(input: MediaStream | null) {
  const [recorder, setRecorder] = useState<MediaRecorder | null>(null);
  const audioChunks = useRef<Blob[]>([]);
  const [recordingData, setRecordingData] = useState<Blob>();
  const [recordingState, setRecordingState] = useState<RecordingState>(
    "inactive"
  );

  const startRecording = useCallback(() => {
    recorder?.start();
    setRecordingState("recording");
  }, [recorder]);

  useEffect(() => {
    if (!input) {
      return;
    }

    const mime = mimes.find((m) => MediaRecorder.isTypeSupported(m));

    console.log("useMediaRecorder: new");
    const recorder2 = new MediaRecorder(input, {
      mimeType: mime,
    });
    setRecorder(recorder2);

    recorder2.addEventListener("dataavailable", (event) => {
      console.log("useMediaRecorder: dataavailable", event.data.size);
      audioChunks.current.push(event.data);
    });

    recorder2.addEventListener("error", (e) => {
      // TODO
      console.log("error", e);
    });

    // mac Mojave safari で発生しなかった
    // recorder2.addEventListener("start", () => {
    //   console.log("useMediaRecorder: start");
    //   setRecordingState("recording");
    //   setRecordingData(undefined);
    // });

    recorder2.addEventListener("stop", () => {
      console.log("useMediaRecorder: stop", recorder2.mimeType);
      const blob = new Blob(audioChunks.current, {
        type: recorder2.mimeType,
      });
      setRecordingData(blob);
      audioChunks.current = [];

      setRecordingState("inactive");
    });

    return () => {
      console.log("useMediaRecorder: cleanup");
      if (recorder2.state !== "inactive") {
        recorder2.stop();
      }
      setRecorder(null);
    };
  }, [input]);

  return {
    startRecording,
    recorder,
    recordingState,
    recordingData,
  };
}
