import React, { CSSProperties, FC } from "react";

interface Props {
  margin?: number;
  flexGrow?: CSSProperties["flexGrow"];
}

/**
 * 子供の配置調整用にいろいろスタイルを適用できる
 */
export const Wrap: FC<Props> = ({ margin, flexGrow, children }) => {
  return <div style={{ margin, flexGrow }}>{children}</div>;
};
