import React, { FC } from "react";
import styled from "styled-components";
import { Strong } from "../../../common/components/Strong";
import iosVoiceMemoShare from "../../../images/ios-voice-memo-share.png";
import { HelpLink } from "../../../common/components/HelpLink";

interface Props {}

export const RecordingMethod2Help: FC<Props> = () => {
  return (
    <HelpLink help={<Help />} scroll track="help.recording-method1">
      メッセージの贈り方 (おすすめ 高音質編)
    </HelpLink>
  );
};

const Help: FC = () => {
  return (
    <>
      <Strong>お好きなツールでレコーディング</Strong>

      <p>
        iOS のボイスメモや Android
        のレコーダー等、好きなツールを使ってボイスメモをレコーディングできるよ
      </p>
      <p>
        音質を良くしたり、 BGM
        をつけたり、こだわりの方法でレコーディングしよう！
      </p>
      <p>
        レコーディングができたら、共有機能を使って LINE の Your Radio
        公式アカウントに送信してね
      </p>
      <p>
        <MyImg src={iosVoiceMemoShare} />
      </p>
      <p>
        送信後、しばらく待つと、 LINE
        上で相手の一覧が表示されるので、送りたい相手を選んでね
      </p>
      <p>これで送信は完了だよ</p>
    </>
  );
};

const MyImg = styled.img`
  width: 40%;
`;
