import { useEffect, useState } from "react";
import { createAudioContext } from "../util/audio";

export function useAudioContext(): AudioContext | null {
  const [context, setContext] = useState<AudioContext | null>(null);

  useEffect(() => {
    console.log("audio context new");
    const context2 = createAudioContext();
    setContext(context2);
    return () => {
      console.log("audio context close ");
      context2.close().then();
      setContext(null);
    };
  }, []);

  return context;
}
