import React, { FC } from "react";
import { Button } from "antd";
import { VerticalList } from "../../../../common/components/VerticalList";
import { castProfileEditorRoute } from "../../CastProfileEditorPage/CastProfilePage";
import { useGoto } from "../../../../lib/hooks/useGoto";

interface Props {}

export const SetProfile: FC<Props> = () => {
  const go = useGoto(castProfileEditorRoute.makePath(), {
    saveLocation: true,
  });

  return (
    <VerticalList>
      <div>プロフィールを設定して、リクエスト率・返信率をあげよう！</div>
      <Button onClick={go}>プロフィール設定</Button>
    </VerticalList>
  );
};
