export const routes = {
  listenerSimpleLive: {
    // TODO 変えたい
    path: "/listener/simple-live/:sessionId",
    makePath: (sessionId: string) => {
      return `/listener/simple-live/${sessionId}`;
    },
  },
  hostSimpleLive: {
    path: "/simple-live/host/:sessionId",
    makePath: (sessionId: string) => {
      return `/simple-live/host/${sessionId}`;
    },
  },

  webRtcTester: {
    path: "/web-rtc-tester",
    makePath: () => {
      return `/web-rtc-tester`;
    },
  },
};
