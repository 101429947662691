import React, { FC } from "react";

export const mediaRecorderRoute = {
  path: "/dev/media-recorder",
  makePath() {
    return "/dev/media-recorder";
  },
};

const mimes = [
  `audio/webm;codecs="opus"`,
  `video`,
  `video`,
  `video/webm`,
  `video/webm;`,
  `video/webm;codecs`,
  `video/webm;codecs=`,
  `video/webm;codecs="`,
  `video/webm;codecs=""`,
  `video/webm;codecs=","`,
  `audio/webm;aaacodecsbbb=opus"`,
  `audio/webm;codecs="vp8"`,
  `audio/mp4;codecs="avc1.4d001e"`,
  `audio/mp4;codecs="vorbis"`,
  `audio/webm;codecs="mp4a.40.2"`,
  `video/mp4;codecs="vp8"`,
  `video/mp4;codecs="vorbis"`,
  `video/webm;codecs="mp4a.40.2"`,
  `audio/mp4`,
  `audio/mp4;codecs="mp4a"`,
  `audio/mp4;codecs="mp4a.40"`,
  `audio/mp4;codecs="mp4a.40."`,
  `audio/mp4;codecs="mp4a.67.3"`,
  `video/webm;codecs="vp8"`,
  `video/webm;codecs="vorbis"`,
  `video/webm;codecs="vp8,vorbis"`,
  `video/webm;codecs="vorbis, vp8"`,
  `audio/webm;codecs="vorbis"`,
  `AUDIO/WEBM;CODECS="vorbis"`,
  `audio/webm;codecs=vorbis;test="6"`,
  `audio/webm;codecs="opus"`,
  `video/webm;codecs="opus"`,
  `video/mp4;codecs="avc1.4d001e"`,
  `video/mp4;codecs="avc1.42001e"`,
  `audio/mp4;codecs="mp4a.40.2"`,
  `audio/mp4;codecs="mp4a.40.5"`,
  `audio/mp4;codecs="mp4a.67"`,
  `video/mp4;codecs="mp4a.40.2"`,
  `video/mp4;codecs="avc1.4d001e,mp4a.40.2"`,
  `video/mp4;codecs="mp4a.40.2 , avc1.4d001e "`,
  `video/mp4;codecs="avc1.4d001e,mp4a.40.5"`,
  `audio/mp4;codecs="opus"`,
  `video/mp4;codecs="opus"`,
  `audio/oga`,
  `audio/mp3`,
  `audio/mov`,
  `audio/wav`,
];

export const MediaRecorderPage: FC = () => {
  return (
    <div>
      {mimes
        .filter((mime) => MediaRecorder.isTypeSupported(mime))
        .map((mime) => (
          <div>{mime}</div>
        ))}
    </div>
  );
};
