import { useCallback, useDebugValue, useEffect, useState } from "react";

import { useAudioPlayer } from "../../../common/hooks/useAudioPlayer";
import { useQuery } from "react-query";
import { messageApi } from "../../../common/api/api2";
import { track } from "../../../common/track";

export type State = "idle" | "loading" | "ready";

/**
 *
 * @param messageId
 * @param onGetVoiceSuccess キャッシュを使った場合などは呼ばれないこともある
 */
export function useModel(messageId: string, onGetVoiceSuccess?: () => void) {
  const [state, setState] = useState<State>("idle");

  const apiDataEnabled = state !== "idle";
  const voiceQuery = useQuery(
    ["voice-message", messageId],
    async () => {
      const res = await messageApi.getVoiceMessage(messageId);
      return res.data;
    },
    {
      // 再更新が走ると URL が代わり再生が止まるため長めにする
      staleTime: 60 * 60 * 1000,
      enabled: apiDataEnabled,
      suspense: false,
      onSuccess() {
        setState("ready");
        if (onGetVoiceSuccess) {
          onGetVoiceSuccess();
        }
      },
    }
  );

  useDebugValue(state);

  const {
    audio,
    playing,
    play: playImpl,
    currentTime,
    setCurrentTime,
  } = useAudioPlayer({ url: voiceQuery.data?.url });

  const ready = state === "ready";
  useEffect(() => {
    if (!ready) {
      return;
    }
    track({ event: "voice-message.start", data: messageId });
    playImpl();
  }, [messageId, playImpl, ready]);

  const play = useCallback(async () => {
    track({ event: "voice-message.play", data: messageId });
    if (voiceQuery.isSuccess || state === "ready") {
      // isSuccess は遷移時にはすでに成功しているようなケース
      track({ event: "voice-message.start", data: messageId });
      playImpl();
    } else if (state === "idle") {
      setState("loading");
    }
  }, [voiceQuery.isSuccess, state, messageId, playImpl]);

  const pause = useCallback(async () => {
    if (state !== "ready") {
      return;
    }
    track({ event: "voice-message.pause", data: messageId });
    audio.pause();
  }, [audio, messageId, state]);

  return {
    play,
    pause,
    audio,
    playing,
    loading: state === "loading",
    currentTime,
    setCurrentTime,
    expiredAt: voiceQuery.data?.expiredAt,
  };
}
