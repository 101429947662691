import React, { CSSProperties, FC, ReactNode } from "react";

import styled from "styled-components";
import { primaryColor, primaryLightBgColor, textColor } from "../../../colors";

type MeritProps = {
  title1: ReactNode;
  title2: ReactNode;
  odd?: boolean;
};

export const Merit: FC<MeritProps> = ({ title1, title2, odd, children }) => {
  const boxStyle: CSSProperties = odd
    ? {
        marginLeft: -8,
        marginRight: 28,
      }
    : {
        marginLeft: 28,
        marginRight: -8,
      };

  return (
    <Box style={boxStyle}>
      <Title1>{title1}</Title1>
      <Title2>{title2}</Title2>
      {children}
    </Box>
  );
};

const Box = styled.div`
  border-radius: 40px;
  padding: 16px;
  border: 1px solid ${primaryColor};
  margin: 32px 8px;
  background-color: ${primaryLightBgColor};
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);

  color: ${textColor};
`;

const Title1 = styled.h4`
  margin-bottom: 0;

  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: ${primaryColor};
`;

const Title2 = styled.h4`
  margin-bottom: 24px;

  font-size: 24px;
  font-weight: bold;
  text-align: center;
`;
