import React, { FC } from "react";

import styled from "styled-components";
import { UserTag } from "../../common/api/voicemessage/api";

interface Props {
  tags: UserTag[];
}

export const UserTags: FC<Props> = ({ tags }) => {
  return (
    <Root>
      {tags.map((it) => (
        <Tag
          key={it}
          style={{
            margin: "0 4px",
          }}
        >
          #{it}
        </Tag>
      ))}
    </Root>
  );
};

const Tag = styled.span`
  font-size: 11px;
`;

const Root = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: #777;
`;
