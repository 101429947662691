import React, { FC } from "react";
import { Link } from "../../common/components/Link";
import { adminUserInfoRoute } from "../admin/pages/AdminUserInfoPage/AdminUserInfoPage";
import { Small } from "../../common/components/Small";
import { hasFeature } from "../../common/hooks/feature";

export interface ByNameRequestProps {
  isAdmin: boolean;
  userId: string;
}

/**
 * リクエストにつく管理者用リンク
 * @param value
 * @constructor
 */
export const AdminRequestInfo: FC<ByNameRequestProps> = ({
  isAdmin,
  userId,
}) => {
  return (
    <>
      {isAdmin && hasFeature("SHOW_ID") && (
        <div>
          <Small>
            <Link to={adminUserInfoRoute.makePath(userId)}>{userId}</Link>
          </Small>
        </div>
      )}
    </>
  );
};
