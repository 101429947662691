import React, { FC } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { Listening } from "./Listening";
import { Connecting } from "./Connecting";
import { Closed } from "./Closed";
import { AppLayout } from "../../common/components/AppLayout";
import { NoHost } from "./NoHost";

import {
  connectionQualityState,
  logsState,
  mutedState,
  useSetup2,
} from "./model/setup";
import { Ready } from "./Ready";
import { RecoilRoot, useRecoilState, useRecoilValue } from "recoil";
import { useFirstAgeType, useFirstSex, useLastType4 } from "../model";

export const SimpleLiveListenerPage: FC = () => {
  return (
    <RecoilRoot>
      <Main />
    </RecoilRoot>
  );
};

const Main: FC = () => {
  const { sessionId } = useParams<{ sessionId: string }>();
  const {
    serverState,
    subState,
    play,
    host,
    sendMessage,
    bgmVolume,
    setBgmVolume,
  } = useSetup2();
  const connectionQuality = useRecoilValue(connectionQualityState);
  const [muted, setMuted] = useRecoilState(mutedState);
  const logs = useRecoilValue(logsState);
  const lastType4 = useLastType4();
  const firstAgeType = useFirstAgeType();
  const firstSex = useFirstSex();

  return (
    <AppLayout>
      {serverState === "initial" &&
        (subState === "no-host" ? (
          <NoHost
            sessionId={sessionId}
            lastType4={lastType4}
            firstAgeType={firstAgeType}
            firstHostSex={firstSex}
          />
        ) : (
          <Connecting state="waiting-host-connection" />
        ))}
      {serverState === "connected" && (
        <Connecting state="waiting-host-preparation" />
      )}
      {subState === "server-started" && <Connecting state="waiting-data" />}
      {subState === "error" && <Connecting state="waiting-data" />}
      {(subState === "client-started" || subState === "will-play") && (
        <Ready play={play} subState={subState} />
      )}
      {subState === "playing" && (
        <Listening
          sessionId={sessionId}
          onSendMessage={sendMessage}
          bgmVolume={bgmVolume}
          setBgmVolume={setBgmVolume}
          connectionQuality={connectionQuality!}
          muted={muted}
          setMuted={setMuted}
          logs={logs}
        />
      )}
      {serverState === "closed" && (
        <Closed sessionId={sessionId} host={host!} />
      )}
      <Player>
        <div>{/*<Video ref={videoRef} playsInline controls />*/}</div>
        <audio id="audio" controls />
      </Player>
    </AppLayout>
  );
};

const Player = styled.div`
  visibility: hidden;
  width: 10px;
  height: 10px;
`;
