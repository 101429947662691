import React, { FC } from "react";

import { RequireLineSignIn } from "../../../auth/components/RequireLineSignIn";
import { usePageView } from "../../../common/hooks/usePageView";
import { Section } from "../../../common/components/Section";
import { AppLayout2 } from "../../../common/components/AppLayout2";
import { compareString } from "../../../common/util/string";
import { HeaderWithBackButton } from "../../../common/components/HeaderWithBackButton";
import { NoteBox } from "../../../common/components/NoteBox";
import { useMe } from "../../../common/hooks/user";
import { VerticalList } from "../../../common/components/VerticalList";
import { RecordingInquiryHelp } from "../../components/help/RecordingInquiryHelp";
import { ApiMessage } from "../../../api";
import { useMessagesToMe } from "../../hooks/message";
import { MyUser2 } from "../../../common/api/api2";
import { MessageFrame } from "../../components/MessageFrame";
import { RequestMessageContent } from "../../components/message/RequestMessageContent";
import { RespondButton } from "../../components/RespondButton";
import { MessageTypeTag } from "../../components/message/MessageTypeTag";
import { homeRoute } from "../HomePage/HomePage";

export const castAssignedActiveRequestsRoute = {
  path: "/cast/assigned-active-requests",
  makePath: () => {
    return "/cast/assigned-active-requests";
  },
};

/**
 * 自分にアサインさせたアクティブなリクエストを見るためのページ
 * @constructor
 */
export const CastAssignedActiveRequestsPage: FC = () => {
  return (
    <RequireLineSignIn>
      <Impl />
    </RequireLineSignIn>
  );
};

const Impl: FC = () => {
  usePageView("cast_assigned_active_requests", true);

  const userStatus = useMe();
  const requests = useMessagesToMe().filter(
    (it) =>
      it.type === "REQUEST" && it.requestSupplement?.requestState === "ASSIGNED"
  );

  return <Template me={userStatus} requests={requests} />;
};

export interface TemplateProps {
  me: MyUser2;
  requests: ApiMessage[];
}

export const Template: FC<TemplateProps> = ({ me, requests }) => {
  const requests2 = requests.sort((a, b) => compareString(a.sentAt, b.sentAt));
  return (
    <AppLayout2
      noVerticalMargin
      header={
        <HeaderWithBackButton
          title="収録待ちのリクエスト"
          backPath={homeRoute.makePath()}
        />
      }
    >
      <Section colored>
        {requests2.length === 0 && (
          <div>
            <NoteBox>収録待ちのリクエストはまだありません</NoteBox>
          </div>
        )}

        <VerticalList>
          <RecordingInquiryHelp />

          {requests.length >= 1 &&
            requests.map((it) => (
              <MessageFrame
                key={it.id}
                user={it.from}
                sentAt={it.sentAt}
                profileLink
                label={<MessageTypeTag me={me} message={it} />}
              >
                <VerticalList>
                  <RequestMessageContent
                    message={it}
                    talkLink={false}
                    me={me}
                  />
                  <RespondButton request={it} />
                </VerticalList>
              </MessageFrame>
            ))}
        </VerticalList>
      </Section>
    </AppLayout2>
  );
};
