import { Conflict, HttpSuccess, VoiceMessageId } from "./types";
import axios from "axios";
import { apiUrl } from "./api";
import { UserId } from "../../auth/auth";
import { AudioFileId } from "./audiofile";
import {
  UserTag,
  TalkType,
  VoiceMessageState,
  Topic,
  TopicNoId,
  TopicId,
} from "./voicemessage/api";
import { PersonalityId, RequestTargetType } from "./api2";

export interface ListenerForCast {
  id: UserId;
  avatarUrl?: string;
  personalityId: PersonalityId;
}

export interface Thanks {
  message: string;
  thankedAt: string;
}

export interface BaseRequestForCast {
  id: VoiceMessageId;
  state: VoiceMessageState;
  isAssigned: boolean;

  talkType: TalkType;

  adminUserId?: string;
  requestedAt?: string;
  userLevel: number;
  listenerTags: UserTag[];
}

export interface NonAnonymousRequestForCast extends BaseRequestForCast {
  request: string;
  listenerName: string;
  listener: ListenerForCast;
}

export interface AnonymousRandomRequestForCast extends BaseRequestForCast {
  request: string;
  situation: string;
}

export interface RandomRequestForCast
  extends AnonymousRandomRequestForCast,
    NonAnonymousRequestForCast {
  listener: ListenerForCast;
  thanks?: Thanks;
}

export interface ByNameRequestForCast extends NonAnonymousRequestForCast {
  listener: ListenerForCast;
  message: string;
  thanks?: Thanks;
  requestTargetType: RequestTargetType;
}

export function isRandomRequest(
  request: BaseRequestForCast
): request is RandomRequestForCast {
  return (
    (request as any).situation !== undefined && (request as any).listenerName
  );
}

export function isByNameRequest(
  request: BaseRequestForCast
): request is ByNameRequestForCast {
  return (request as any).message !== undefined;
}

export interface GetCastNotAssignedRequestsResult {
  randomRequests: AnonymousRandomRequestForCast[];
  byNameRequests: ByNameRequestForCast[];
}

export interface GetCastAssignedRequestsResult {
  randomRequests: RandomRequestForCast[];
  byNameRequests: ByNameRequestForCast[];
}

export interface TopicRequestForCast extends BaseRequestForCast {
  listenerName: string;
  listener: ListenerForCast;
}

export async function putCastVoiceMessage(
  voiceMessageId: VoiceMessageId,
  audioFileId: AudioFileId
): Promise<void> {
  const res = await axios.put(
    `${apiUrl}/cast/voice-messages/${voiceMessageId}`,
    {
      audioFileId,
    }
  );
  console.log(res);
}

export async function postCastVoiceMessageAssignToMe(
  voiceMessageId: VoiceMessageId
): Promise<HttpSuccess<any> | Conflict> {
  try {
    const res = await axios.post(
      `${apiUrl}/cast/voice-messages/${voiceMessageId}/assign-to-me`
    );
    console.log(res);
    return { kind: "ok", data: res.data };
  } catch (e) {
    if (e.response?.status === 409) {
      return {
        kind: "conflict",
      };
    } else {
      throw e;
    }
  }
}

export async function getCastNotAssignedRequests(): Promise<
  HttpSuccess<GetCastNotAssignedRequestsResult>
> {
  const res = await axios.get<GetCastNotAssignedRequestsResult>(
    `${apiUrl}/cast/@me/not-assigned-requests`
  );
  console.log(res);

  return { kind: "ok", data: res.data };
}

export async function getCastNotAssignedTopicRequests(): Promise<
  HttpSuccess<TopicRequestForCast[]>
> {
  const res = await axios.get<TopicRequestForCast[]>(
    `${apiUrl}/cast/@me/not-assigned-topic-requests`
  );
  console.log(res);

  return { kind: "ok", data: res.data };
}

export async function getCastAssignedRequests(): Promise<
  HttpSuccess<GetCastAssignedRequestsResult>
> {
  const res = await axios.get(`${apiUrl}/cast/@me/assigned-requests`);
  console.log(res);

  return { kind: "ok", data: res.data };
}

export async function getCastUserRequests(
  userId: UserId
): Promise<HttpSuccess<GetCastAssignedRequestsResult>> {
  const res = await axios.get(`${apiUrl}/cast/@me/users/${userId}/requests`);
  console.log(res);

  return { kind: "ok", data: res.data };
}

export async function postCastTopic(
  topic: TopicNoId
): Promise<HttpSuccess<Topic>> {
  const res = await axios.post<Topic>(`${apiUrl}/cast/@me/topics`, topic);
  console.log(res);

  return { kind: "ok", data: res.data };
}

export async function getMyTopics(): Promise<HttpSuccess<Topic[]>> {
  const res = await axios.get<Topic[]>(`${apiUrl}/cast/@me/topics`);
  return { kind: "ok", data: res.data };
}

export async function putMyTopic(topicId: TopicId): Promise<HttpSuccess<void>> {
  const res = await axios.put(`${apiUrl}/cast/@me/topics/${topicId}`);
  return { kind: "ok", data: res.data };
}

export async function deleteMyTopic(
  topicId: TopicId
): Promise<HttpSuccess<void>> {
  const res = await axios.delete(`${apiUrl}/cast/@me/topics/${topicId}`);
  return { kind: "ok", data: res.data };
}
