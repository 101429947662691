import React, { FC } from "react";

import { Select } from "antd";

type DeviceSelectProps = {
  device?: string;
  onChange: (device: string) => void;
  devices: MediaDeviceInfo[];
};

export const DeviceSelect: FC<DeviceSelectProps> = ({
  devices,
  onChange,
  device,
}) => {
  return (
    <Select value={device} onChange={onChange} style={{ width: "100%" }}>
      {devices.map((d) => (
        <Select.Option value={d.deviceId} key={d.deviceId}>
          {d.label}
        </Select.Option>
      ))}
    </Select>
  );
};
