import React, { FC } from "react";
import styled from "styled-components";

interface Props {
  mine: boolean;
}

/**
 * 右とか左に寄せる
 */
export const MessageWrap: FC<Props> = ({ mine, children }) => {
  return (
    <Root mine={mine}>
      <Inner>{children}</Inner>
    </Root>
  );
};

const Root = styled.div<Props>`
  width: 100%;

  ${(p) => (p.mine ? "justify-content: flex-end;" : "")}

  display: flex;
`;

const Inner = styled.div`
  max-width: 85%;
`;
