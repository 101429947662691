import React, { FC, useCallback, useState } from "react";
import { Button, Input, Radio } from "antd";

import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { postSession } from "../../common/api/api";
import { routes } from "../../routes";
import { eventStartListening } from "../../gtm";
import { VerticalList } from "../../common/components/VerticalList";
import {
  invertSex,
  Type1,
  Type2,
  Type3,
  Type4,
  useListenerSex,
  useType1,
  useType2,
  useType3,
  useType4,
} from "./model";
import { usePageView } from "../../common/hooks/usePageView";
import {
  Direction,
  fetchTopics,
  getListeningHistory,
  saveStartListening,
} from "../model";
import { useQuery } from "react-query";
import { TopicSelector2 } from "./TopicSelector2";
import { AgeType } from "../../common/api/types";
import { Link } from "../../common/components/Link";
import { visionRoute } from "../../voicemessage/pages/VisionPage/VisionPage";

import { Hero1WithSubMessage } from "../../common/components/Hero1WithSubMessage";
import { Section } from "../../common/components/Section";
import { NoteBox } from "../../common/components/NoteBox";
import { AppLayout2 } from "../../common/components/AppLayout2";

/**
 * simple をつけないのは、 Facebook にアカウント停止されたときと URL を変えるため
 */
export const liveRoute = {
  path: "/lp/live2",
  makePath: () => {
    return "/lp/live2";
  },
};

export type SimpleLiveLpPageProps = {
  hostName: string;
};

export const SimpleLiveLpPage: FC<SimpleLiveLpPageProps> = ({ hostName }) => {
  const type1 = useType1();
  const type2 = useType2();
  const type3 = useType3();
  const type4 = useType4();

  return (
    <Template
      hostName={hostName}
      type1={type1}
      type2={type2}
      type3={type3}
      type4={type4}
    />
  );
};

export type TemplateProps = {
  hostName: string;
  type1: Type1;
  type2: Type2;
  type3: Type3;
  type4: Type4;
};

export const Template: FC<TemplateProps> = ({
  hostName,
  type1,
  type2,
  type3,
  type4,
}) => {
  usePageView("simple_live_lp");
  const topics = useQuery("topics", async () => await fetchTopics()).data!;

  const [listenerName, setListenerName] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [talkType, setTalkType] = useState("ていねい");
  const [ageType, setAgeType] = useState<AgeType>("age2");
  const listenerSex = useListenerSex();
  const [hostSex, setHostSex] = useState(invertSex(listenerSex));
  const [direction] = useState("one-way" as Direction);
  const [sing] = useState(false);

  const canListen =
    type3 === "topic-text"
      ? message.length >= 1 && listenerName.length >= 1
      : listenerName.length >= 1;

  const history = useHistory();
  const startListening = useCallback(
    async (message: string) => {
      setLoading(true);
      const hostName2 = sing ? "_relmn" : hostName;
      const ua = window.navigator.userAgent;
      try {
        eventStartListening();
        saveStartListening({
          listenerName: listenerName,
          message: message,
          talkType: talkType,
          listenerSex: listenerSex,
          hostSex: hostSex,
          hostName: hostName2,
          type1: type1,
          type2: type2,
          type3: type3,
          type4: type4,
          direction: direction,
          ageType: ageType,
        });
        const listeningHistory = getListeningHistory();
        const res = await postSession({
          listenerName: listenerName,
          message: [
            message,
            talkType,
            listenerSex,
            hostSex,
            ageType,
            listeningHistory.length + "回目",
            "初回:" + listeningHistory[0].listenedAt,
            direction,
            sing ? "歌" : "",
            ua,
          ].join("|"),
          hostName: hostName2,
        });
        history.push(routes.listenerSimpleLive.makePath(res.sessionId));
      } catch (e) {
        setLoading(false);
      }
    },
    [
      ageType,
      direction,
      history,
      hostName,
      hostSex,
      listenerName,
      listenerSex,
      sing,
      talkType,
      type1,
      type2,
      type3,
      type4,
    ]
  );

  return (
    <AppLayout2 noVerticalMargin>
      <Hero1WithSubMessage
        subMessage={
          "Your Radio はキャストが\n" +
          "あなただけに語りかけるサービスです\n" +
          "あなたは聴くだけだからリラックスして聴いてみてね"
        }
      />

      <Section colored>
        <VerticalList align="center">
          <Input
            placeholder="呼び名 (○○さん)"
            value={listenerName}
            onChange={(e) => setListenerName(e.target.value)}
            style={{ width: "100%", fontSize: 16 }}
          />
          {type3 === "topic-text" && (
            <MyTextArea
              placeholder="リクエスト等ありますか (○○だから励ましてほしい/○○だから慰めてほしい/雑談してほしい etc.)"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              style={{ width: "100%", fontSize: 16 }}
            />
          )}
          <Radio.Group
            options={[
              { label: "ていねい", value: "ていねい" },
              { label: "タメ口", value: "タメ口" },
            ]}
            onChange={(e) => {
              setTalkType(e.target.value);
            }}
            value={talkType}
            optionType="button"
          />
          <Radio.Group
            size="small"
            options={[
              { label: `男性の声がききたい`, value: "male" },
              { label: `女性の声がききたい`, value: "female" },
            ]}
            onChange={(e) => {
              setHostSex(e.target.value);
            }}
            value={hostSex}
            optionType="button"
          />
          <Radio.Group
            size="small"
            options={[
              { label: `中高生の声がききたい`, value: "age1" },
              { label: `大人の声がききたい`, value: "age2" },
            ]}
            onChange={(e) => {
              setAgeType(e.target.value);
            }}
            value={ageType}
            optionType="button"
          />
          {type3 === "topic-text" && (
            <PrimaryButtonWrap>
              <Button
                size="large"
                disabled={!canListen}
                onClick={() => startListening(message)}
                loading={loading}
                type="primary"
              >
                聴いてみる (3分間)
              </Button>
            </PrimaryButtonWrap>
          )}
          {type3 === "topic-selector" && (
            <TopicSelectorWrap>
              <TopicSelector2
                topics={topics}
                canListen={canListen}
                loading={loading}
                onListen={startListening}
              />
            </TopicSelectorWrap>
          )}
          <Paragraph>
            <Link to={visionRoute.makePath()}>
              キャストとして配信・開発等に協力する
            </Link>
          </Paragraph>
        </VerticalList>
      </Section>

      <Section>
        <NoteBox>
          <Paragraph>iPhone の方は標準ブラウザをご利用ください</Paragraph>
          <Paragraph>本サービスは開発中のサービスです</Paragraph>
          <Paragraph>
            あなたは聞き専 &
            アダルトな話題等はないので、安心して聴いてくださいね
          </Paragraph>
          <Paragraph>あなたからキャストへは定型文のみ送信できます</Paragraph>
        </NoteBox>
      </Section>
    </AppLayout2>
  );
};

const Paragraph = styled.div`
  font-size: 12px;
  margin: 12px 0;
`;

const PrimaryButtonWrap = styled.div`
  margin: 32px 0;
`;

const MyTextArea = styled(Input.TextArea)`
  width: 100%;
`;

const TopicSelectorWrap = styled.div`
  margin: 20px 0;
`;
