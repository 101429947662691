import React, { FC } from "react";
import { MyUser } from "../../common/api/user";
import { AppAvatar } from "./AppAvatar";
import { Host } from "../../common/api/types";
import styled from "styled-components";
import { VerticalList } from "../../common/components/VerticalList";
import { Small } from "../../common/components/Small";
import { Strong } from "../../common/components/Strong";
import { UserLevel } from "./UserLevel";
import { ArrowRightOutlined } from "@ant-design/icons";
import { castProfileEditorRoute } from "../pages/CastProfileEditorPage/CastProfilePage";
import { Link } from "../../common/components/Link";

interface Props {
  me: MyUser;
  cast: Host | null;
}

/**
 * 自分とキャストとその関係性を表示する
 */
export const MeAndCast: FC<Props> = ({ me, cast }) => {
  return (
    <Root2>
      <Root>
        <User>
          <VerticalList align="center" textAlign="center" margin={0}>
            <AppAvatar src={me.listenerAvatarUrl} />
            <Name>
              <Strong>{me.listenerName ?? "あなた"}</Strong>{" "}
              {me.level !== null && (
                <Small>
                  <UserLevel level={me.level} />
                </Small>
              )}
            </Name>
            <Link to={castProfileEditorRoute.makePath()} saveLocation>
              {me.hasProfile
                ? "プロフィール設定"
                : "プロフィールを設定して返信率をあげよう！"}
            </Link>
          </VerticalList>
        </User>

        <Arrow>
          <ArrowRightOutlined />
          <Small>リクエスト</Small>
        </Arrow>

        <User>
          <VerticalList align="center" textAlign="center" margin={0}>
            <AppAvatar src={cast?.avatarUrl ?? null} />
            <Name>
              <Strong>{cast?.displayName ?? "だれか"}</Strong>{" "}
              {me.level !== null && cast && (
                <Small>
                  <UserLevel level={cast.level} />
                </Small>
              )}
            </Name>
          </VerticalList>
        </User>
      </Root>
    </Root2>
  );
};

const Root2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Root = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
`;

const User = styled.div`
  width: 40%;
`;

const Name = styled.div``;

const Arrow = styled.div`
  font-size: 20px;
  align-self: center;
  display: flex;
  flex-direction: column;
  //flex-grow: 1;
`;
