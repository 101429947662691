import { FC } from "react";
import styled from "styled-components";

export const P: FC = ({ children }) => {
  return <MyP>{children}</MyP>;
};

const MyP = styled.p`
  margin: 0;
`;
