import React, { FC } from "react";

import { OtherUser } from "../../../api";
import { PointIcon } from "../PointIcon";
import { VerticalList } from "../../../common/components/VerticalList";
import { MyPointBox } from "../MyPointBox";
import { MyUser2, pointApi } from "../../../common/api/api2";
import { AppButton } from "../../../common/components/AppButton";
import { Divider } from "antd";
import { useMutation } from "react-query";
import { getListenerId } from "../../helper/user";
import * as uuid from "uuid";
import { useInvalidateUserStatus } from "../../../common/hooks/user";
import { useInvalidatePointLogs } from "../../hooks/point";
import { Link } from "../../../common/components/Link";
import { relationshipRoute } from "../../pages/RelationshipPage/RelationshipPage";

interface Props {
  me: MyUser2;
  other: OtherUser;
  onSent: () => void;
}

export const GivePoint: FC<Props> = ({ me, other, onSent }) => {
  const invalidate = useInvalidateUserStatus();
  const invalidate2 = useInvalidatePointLogs(other.personalityId);

  const { mutate: send, isLoading } = useMutation(
    async () => {
      const clientRequestToken = uuid.v4();
      return (
        await pointApi.postPointTransaction({
          clientRequestToken,
          points: 10,
          fromUserId: getListenerId(me),
          toUserId: other.personalityId,
        })
      ).data;
    },
    {
      async onSuccess() {
        await invalidate();
        await invalidate2();
        onSent();
      },
    }
  );

  return (
    <VerticalList margin={32}>
      <AppButton
        type="primary"
        onClick={send}
        loading={isLoading}
        disabled={(me.point ?? 0) < 10}
      >
        <PointIcon />
        を贈る
      </AppButton>

      <div>
        <MyPointBox point={me.point ?? 0} />

        <Divider />

        <div>
          <PointIcon />
          は感謝を表すためにユーザー同士で贈りあうことができるよ
          <br />
          贈らないと腐っちゃうことがあるから、どんどん贈りあおう！
          <br />
          <br />
          これまでにもらった
          <PointIcon />は<Link to={relationshipRoute.makePath()}>つながり</Link>
          で確認できるよ
        </div>
      </div>
    </VerticalList>
  );
};
