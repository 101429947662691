import React, { FC, useCallback, useState } from "react";
import Headroom from "react-headroom";

interface Props {}

export const AppHeadroom: FC<Props> = ({ children }) => {
  const [fixed, setFixed] = useState(false);

  const onUnpin = useCallback(() => {
    setFixed(true);
  }, []);

  const onPin = useCallback(() => {}, []);

  const onUnfix = useCallback(() => {
    setFixed(false);
  }, []);

  return (
    <Headroom onUnpin={onUnpin} onPin={onPin} onUnfix={onUnfix}>
      <div
        style={{
          boxShadow: fixed ? "0 2px 2px 0 rgba(0, 0, 0, 0.3)" : undefined,
        }}
      >
        {children}
      </div>
    </Headroom>
  );
};
