import { useEffect } from "react";
import { track } from "../track";

/**
 * landing を記録する
 */
export function useLanding() {
  useEffect(() => {
    track({
      event: "landing",
      data: window.location.href,
    });
  }, []);
}
