import React, { FC } from "react";

import { Layout } from "antd";
import styled from "styled-components";
import { Logo } from "./Logo/Logo";
import { textColor } from "../../colors";
import { AppFooter } from "./AppFooter";

const { Header, Content } = Layout;

export const AppLayout: FC = ({ children }) => {
  return (
    <Layout>
      <MyHeader>
        <Logo />
      </MyHeader>

      <Content>
        <SiteLayoutContent>{children}</SiteLayoutContent>
      </Content>
      <AppFooter />
    </Layout>
  );
};

const SiteLayoutContent = styled.div`
  min-height: 280px;
  padding: 0 24px 32px;
  background: #fff;

  font-size: 14px;

  color: ${textColor};

  // https://anyushu.com/web/fonts-osusume/
  font-family: "Lato", "Noto Sans JP", "ヒラギノ角ゴ ProN",
    "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "ＭＳ Ｐゴシック",
    "MS PGothic", sans-serif;
`;

const MyHeader = styled(Header)`
  background-color: #fff;
  text-align: center;
  padding-left: 10px;
`;
