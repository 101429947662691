import React, { FC } from "react";
import { usePageView } from "../../../common/hooks/usePageView";
import { AppLayout2 } from "../../../common/components/AppLayout2";
import { PostPublicRequestInLp } from "./PostPublicRequestInLp";
import styled from "styled-components";
import useTrackScroll from "../../../common/hooks/useTrackScroll";
import { useQueryParams } from "../../../common/hooks/useQueryParams";
import { Sex3 } from "../../../common/api/api2";
import { UserSexEnum } from "../../../api";
import { getQueryParams } from "../../../simplelive/SimpleLiveLpPage/model";
import { ShortServiceDescription2 } from "./ShortServiceDescription2";
import { ShortServiceDescription3 } from "./ShortServiceDescription3";

export const voiceMessageLp2Route = {
  path: "/voice-messages/lpdir",
  makePath: () => {
    return "/voice-messages/lpdir";
  },
};

interface Props {}

/**
 * 直接ここからリクエストできるのが売りだよ
 * @constructor
 */
export const VoiceMessageLp2Page: FC<Props> = () => {
  usePageView("voice-message-lp2", true);
  useTrackScroll("lp2.scroll");
  const castGender = invertSex(useListenerSex());
  const a1 = getTypeA1();

  return <VoiceMessageLp2Template castGender={castGender} a1={a1} />;
};

type VoiceMessageLp2TemplateProps = {
  castGender: Sex3;
  a1: TypeA1;
};

export const VoiceMessageLp2Template: FC<VoiceMessageLp2TemplateProps> = ({
  castGender,
  a1,
}) => {
  return (
    <AppLayout2 noVerticalMargin>
      {a1 === "yasasii" && <ShortServiceDescription2 />}
      {a1 === "isei" && <ShortServiceDescription3 />}

      <LargeLabel>無料で声をリクエストする</LargeLabel>

      <PostPublicRequestInLp castGender={castGender} />
    </AppLayout2>
  );
};

const LargeLabel = styled.div`
  margin: 24px 0 0;
  font-size: 20px;
  font-weight: 800;
  text-align: center;
  color: #999;
`;

function useListenerSex(): Sex3 {
  const s = useQueryParams("s");
  if (s === "f") {
    return UserSexEnum.Female;
  } else if (s === "m") {
    return UserSexEnum.Male;
  } else {
    return UserSexEnum.Female;
  }
}

function invertSex(sex: Sex3): Sex3 {
  if (sex === "FEMALE") {
    return UserSexEnum.Male;
  } else {
    return UserSexEnum.Female;
  }
}

type TypeA1 =
  | "yasasii"
  // 絶賛開発中！ を出さない
  | "isei";

function getTypeA1(): TypeA1 {
  const a = getQueryParams("a1");
  if (a === "ya") {
    return "yasasii";
  } else if (a === "is") {
    return "isei";
  } else {
    return "yasasii";
  }
}
