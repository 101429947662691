import React, { FC, useCallback, useEffect, useState } from "react";

import { Button, Select } from "antd";
import { VolumeSlider } from "../../../common/components/VolumeSlider";
import { PlayButton } from "./PlayButton";
import { useAudioWithGain } from "../../../common/hooks/useAudioWithGain";
import { getMusics } from "../../../common/data/music";
import { DeviceSelect } from "./DeviceSelect";
import { Gauge } from "../../../common/components/Gauge";
import { StopWatchView } from "./StopWatchView";
import { useAudioInput2 } from "../../../common/hooks/useAudioInput2";
import { useStopWatch } from "./useStopWatch";
import { useAudioMerge } from "./useAudioMerge";
import { useMediaRecorder } from "./useMediaRecorder";
import { useMediaStreamAudioSourceNode } from "./useMediaStreamAudioSourceNode";
import { useGain } from "./useGain";
import { LargeToggle } from "../../../common/components/LargeToggle";
import { useVolume2 } from "../../../common/util/audio";
import { useAudioContext } from "../../../common/hooks/useAudioContext";

type VoiceRecorderProps = {
  onRecorded: (blob: Blob) => void;
  saving: boolean;
};

/**
 * 録音機能
 */
export const VoiceRecorder: FC<VoiceRecorderProps> = ({
  onRecorded,
  saving,
}) => {
  if (!window.MediaRecorder) {
    throw new Error("iOS では実験的な機能を有効にする必要があります");
  }

  const musics = getMusics();
  const [music, setMusic] = useState(musics[0].id);

  const currentMusic = musics.find((m) => m.id === music)!!;

  const audioContext = useAudioContext();
  const bgm = useAudioWithGain({
    src: currentMusic.url,
    initialGain: 0.08,
    context: audioContext,
  });

  const { mediaStream, devices, setDevice, device } = useAudioInput2();

  const voiceInputNode = useMediaStreamAudioSourceNode({
    mediaStream,
    audioContext,
  });

  const gain = useGain({ inputNode: voiceInputNode, audioContext });
  const volume = useVolume2({ audioContext, inputNode: gain.gainNode });

  const merged = useAudioMerge({
    context: audioContext,
    input0: gain.gainNode,
    input1: bgm.outputNode,
  });

  const {
    recorder,
    startRecording,
    recordingState,
    recordingData,
  } = useMediaRecorder(merged.output);

  const {
    startStopWatch,
    stopStopWatch,
    timeAfterStartMillis,
  } = useStopWatch();

  const startRecording2 = useCallback(async () => {
    if (!audioContext) {
      return;
    }
    startStopWatch();
    await audioContext.resume();

    startRecording();
  }, [audioContext, startRecording, startStopWatch]);

  const stopRecording = useCallback(() => {
    stopStopWatch();
    recorder?.stop();
  }, [recorder, stopStopWatch]);

  useEffect(() => {
    if (recordingData) {
      onRecorded(recordingData);
    }
  }, [onRecorded, recordingData]);

  return (
    <div>
      <div>
        BGM (録音開始するとハウリング防止のために停止)
        <Select onChange={(v) => setMusic(v)} value={music}>
          {musics.map((m) => (
            <Select.Option value={m.id} key={m.id}>
              {m.name} - {m.description}
            </Select.Option>
          ))}
        </Select>
        <PlayButton
          onPlay={bgm.play}
          onPause={bgm.pause}
          playing={bgm.playing}
        />
        <VolumeSlider value={bgm.gain} onChange={bgm.setGain} />
      </div>

      <div>
        <div>
          <DeviceSelect
            device={device}
            onChange={setDevice}
            devices={devices}
          />
        </div>
        {recordingState}

        <Gauge value={volume} />
        {recorder?.state === "recording" || saving ? (
          <Button type="primary" onClick={stopRecording} loading={saving}>
            録音停止
          </Button>
        ) : (
          <Button type="primary" onClick={startRecording2} disabled={!device}>
            録音開始
          </Button>
        )}
        <StopWatchView timeMills={timeAfterStartMillis} />
        <LargeToggle
          checkedText="ミュートする"
          unCheckedText="ミュート中"
          checked={gain.gain !== 0}
          onChange={(checked) => {
            if (checked) {
              gain.setGain(1);
            } else {
              gain.setGain(0);
            }
          }}
        />
      </div>
    </div>
  );
};
