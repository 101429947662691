import { useEffect, useRef, useState } from "react";

export function createAudioContext(): AudioContext {
  const AudioContext =
    window.AudioContext || // Default
    (window as any).webkitAudioContext; // Safari and old versions of Chrome
  return new AudioContext();
}

type Cleanup = () => void;
type AsyncCleanup = () => Promise<void>;

/**
 * 指定した stream の音量を取得する
 *
 * 変化するたびに値は変化する
 * @deprecated したい
 */
export function useVolume(
  context: AudioContext | null,
  stream?: MediaStream | null
) {
  const [volume, setVolume] = useState(0);
  const lastTime = useRef(Date.now());

  useEffect(() => {
    if (!stream || !context) {
      return;
    }

    const cleanup = createVolumeProcessorForStream(
      stream,
      context,
      (volume) => {
        const now = Date.now();
        if (now - lastTime.current < 100) {
          return;
        }
        lastTime.current = now;
        setVolume(volume);
      }
    );

    return () => {
      cleanup().then();
    };
  }, [context, stream]);

  return volume;
}

/**
 * 指定した node の音量を取得する
 *
 * 変化するたびに値は変化する
 */
export function useVolume2({
  audioContext,
  inputNode,
}: {
  audioContext: AudioContext | null;
  inputNode: AudioNode | null;
}) {
  const [volume, setVolume] = useState(0);

  useEffect(() => {
    if (!inputNode || !audioContext) {
      return;
    }

    console.log("useVolume: init");

    const cleanup = createVolumeProcessorForNode(
      inputNode,
      audioContext,
      (volume) => {
        setVolume(volume);
      }
    );

    return () => {
      console.log("useVolume: cleanup");
      cleanup().then();
    };
  }, [audioContext, inputNode]);

  return volume;
}

function createVolumeProcessorForNode(
  inputNode: AudioNode,
  context: AudioContext,
  f: (volume: number) => void
): AsyncCleanup {
  const cleanup = createVolumeProcessor(
    context,
    inputNode,
    context.destination,
    f
  );

  return async () => {
    cleanup();
  };
}

/**
 * @deprecated したい
 */
function createVolumeProcessorForStream(
  input: MediaStream,
  context: AudioContext,
  f: (volume: number) => void
): AsyncCleanup {
  const inputNode = context.createMediaStreamSource(input);
  const cleanup = createVolumeProcessor(
    context,
    inputNode,
    context.destination,
    f
  );

  return async () => {
    cleanup();
  };
}

function createVolumeProcessor(
  context: AudioContext,
  from: AudioNode,
  to: AudioNode,
  f: (volume: number) => void
): Cleanup {
  const analyser = context.createAnalyser();

  from.connect(analyser);
  // analyser.connect(to);

  // const buffer = new Float32Array(1024);
  // let value = 0;
  //
  // function getVolume(): number {
  //   analyser.getFloatTimeDomainData(buffer);
  //
  //   for (let i = buffer.length - 1; i >= 0; i--) {
  //     value = Math.max(value, Math.abs(buffer[i]));
  //   }
  //
  //   value -= 0.025;
  //   if (value < 0.0) value = 0.0;
  //   if (value > 1.0) value = 1.0;
  //   return value;
  // }

  const array = new Uint8Array(analyser.frequencyBinCount);

  function getVolume() {
    analyser.getByteFrequencyData(array);

    let max = 0;
    const length = array.length;

    for (let i = 0; i < length; i++) {
      max = Math.max(max, array[i]);
    }

    return max / 255;
  }

  const id = setInterval(() => {
    f(getVolume());
  }, 50);

  return () => {
    clearInterval(id);
  };
}
