import { useQuery, UseQueryResult } from "react-query";
import { topicApi } from "../../common/api/api2";
import { UserId } from "../../auth/auth";
import { TopicForUser } from "../../api";

export function useUserTopicsQuery(
  userId: UserId,
  p?: {
    enabled: boolean;
  }
): UseQueryResult<Array<TopicForUser>, unknown> {
  const enabled = p?.enabled ?? true;
  return useQuery(
    ["other-user", userId],
    async () => (await topicApi.getUserTopics(userId)).data,
    {
      enabled,
      suspense: false,
    }
  );
}

export function useUserTopics(userId: UserId): Array<TopicForUser> {
  return useQuery(
    ["other-user", userId],
    async () => (await topicApi.getUserTopics(userId)).data
  ).data!;
}
