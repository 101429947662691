import * as React from "react";
import { FC, useCallback, useState } from "react";
import { Tooltip } from "antd";
import { CopyOutlined } from "@ant-design/icons";

export type ToClipboardProps = {
  data?: string | null;
};

/**
 * children がない場合は data を表示する
 * @param data
 * @param children
 * @constructor
 */
export const ToClipboard: FC<ToClipboardProps> = ({ data, children }) => {
  const [open, setOpen] = useState(false);

  const copy = useCallback(() => {
    (async () => {
      if (!data) {
        return;
      }
      await navigator.clipboard.writeText(data);
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
      }, 1000);
    })();
  }, [data]);

  const children2 = children ?? data;

  if (!children2) {
    return <></>;
  }

  return (
    <Tooltip visible={open} title="コピーしました">
      <span onClick={copy}>
        {children2} <CopyOutlined />
      </span>
    </Tooltip>
  );
};
