/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface AudioFileResponse
 */
export interface AudioFileResponse {
    /**
     * 
     * @type {string}
     * @memberof AudioFileResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AudioFileResponse
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof AudioFileResponse
     */
    status: AudioFileResponseStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AudioFileResponseStatusEnum {
    Error = 'ERROR',
    Processing = 'PROCESSING',
    Finished = 'FINISHED'
}



