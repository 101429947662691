import React, { FC } from "react";
import { SexTag } from "../../components/SexTag";
import { UserForAdmin } from "../../../../common/api/admin";
import { SignInAsButton } from "../../components/SignInAsButton";
import { AppAvatar } from "../../../components/AppAvatar";
import { UserLink } from "../../components/UserLink";
import { VerticalList } from "../../../../common/components/VerticalList";
import { Col, Row } from "antd";
import { Small } from "../../../../common/components/Small";

interface Props {
  user: UserForAdmin;
}

export const Item: FC<Props> = ({ user }) => {
  return (
    <Row
      style={{
        opacity: user.isDisabled ? 0.5 : undefined,
      }}
    >
      <Col xs={12}>
        <Small>
          <UserLink user={user.id} />
        </Small>
      </Col>

      <Col xs={12}>
        <Small>{user.lineId}</Small>
      </Col>

      <Col xs={3}>
        <SexTag value={user.sex} />
      </Col>
      <Col xs={3}>
        <AppAvatar src={user.castAvatarUrl} size="small" />
      </Col>
      <Col xs={6}>{user.name}</Col>
      <Col xs={4}>{user.isPseudo ? "Pseudo" : ""}</Col>
      <Col xs={4}>
        <SignInAsButton user={user} />
      </Col>
    </Row>
  );
};
