import React, { FC } from "react";
import { ApiMessage } from "../../../../api";
import { MultiLineEllipsis } from "../../../../common/components/MultiLineEllipsis";
import { TalkLinkBox } from "../../../components/TalkLinkBox";
import styled from "styled-components";
import { VerticalList } from "../../../../common/components/VerticalList";
import { useGoto } from "../../../../lib/hooks/useGoto";
import { talkRoute } from "../../TalkPage/TalkPage";
import { Hearing } from "../../../../common/components/icons";
import { MyUser2 } from "../../../../common/api/api2";
import { TopicRequestContent } from "../../../components/message/TopicRequestContent";
import { AppButton } from "../../../../common/components/AppButton";
import { RespondButton } from "../../../components/RespondButton";
import { hasFeature } from "../../../../common/hooks/feature";
import { UserLink } from "../../../admin/components/UserLink";
import { Small } from "../../../../common/components/Small";

interface Props {
  me: MyUser2;
  message: ApiMessage;
}

export const UserMessage: FC<Props> = ({ message }) => {
  const action = useAction(message);
  const content = useContent(message);

  return (
    <Root>
      <VerticalList>
        {content}
        {action}
        {hasFeature("SHOW_ID") && (
          <Small>
            <UserLink user={message.from.personalityId} />
          </Small>
        )}
      </VerticalList>
    </Root>
  );
};

function useContent(message: ApiMessage) {
  if (message.requestSupplement?.topic) {
    return <TopicRequestContent message={message} />;
  } else {
    if (message.text) {
      return <MultiLineEllipsis rows={5}>{message.text}</MultiLineEllipsis>;
    }
  }
}

function useAction(message: ApiMessage) {
  const goTalk = useGoto(talkRoute.makePath(message.from.id));

  if (message.type === "REQUEST") {
    if (message.requestSupplement?.requestState !== "RESPONDED") {
      return <RespondButton request={message} />;
    } else {
      return <TalkLinkBox personalityId={message.from.id} />;
    }
  }

  if (message.type === "RESPONSE") {
    return (
      <AppButton onClick={goTalk} icon={<Hearing />}>
        声のメッセージを聴く
      </AppButton>
    );
  }

  if (message.type === "THANKS") {
    if (!message.text) {
      return (
        <AppButton onClick={goTalk} icon={<Hearing />}>
          声のお礼を聴く
        </AppButton>
      );
    }
  }

  return <TalkLinkBox personalityId={message.from.id} />;
}

const Root = styled.div``;
