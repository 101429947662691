import React, { FC } from "react";
import { useGotoIf } from "../../lib/hooks/useGotoIf";
import { homeRoute } from "./HomePage/HomePage";

export const listenerHomeRoute = {
  path: "/voice-messages",
};

/**
 * このページはリンク対象なので、しばらく残すこと
 * @constructor
 */
export const ListenerHomePage: FC = () => {
  useGotoIf(homeRoute.makePath(), true, { replace: true });

  return <div />;
};
