import React, { FC } from "react";
import { Topic } from "../../common/api/voicemessage/api";
import { TopicView } from "./TopicView";
import { Wrap } from "../../common/components/layout/Wrap";
import { HorizontalList } from "../../common/components/HorizontalList";
import { TopicForUser } from "../../api";

interface Props {
  topics: Topic[];
  onSelect?: (topic: Topic) => void;
  hideQuestion?: boolean;
  size?: "small";
  value?: TopicForUser | null;
}

export const TopicList: FC<Props> = ({
  topics,
  onSelect,
  hideQuestion,
  size,
  value,
}) => {
  return (
    <HorizontalList flexWrap="wrap" margin={0} justifyContent="center">
      {topics.map((it) => (
        <Wrap margin={4} key={it.id}>
          <TopicView
            topic={it}
            onClick={onSelect}
            hideQuestion={hideQuestion}
            size={size}
            active={it === value}
          />
        </Wrap>
      ))}
    </HorizontalList>
  );
};
