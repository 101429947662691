import React, { FC } from "react";
import { AppLayout2 } from "../../../common/components/AppLayout2";
import { PublicRequestNote } from "../../components/help/PublicRequestNote";
import { Section } from "../../../common/components/Section";
import { VerticalList } from "../../../common/components/VerticalList";
import { AddLineButton } from "../../../common/components/AddLineButton";

interface Props {}

export const BeforeLoginPage: FC<Props> = () => {
  return (
    <AppLayout2>
      <Section>
        <VerticalList align="center" textAlign="center">
          <p>リクエストを受け付けました！</p>
          {/* あとは受信するだけなんだよっていうニュアンス */}
          <p>
            声のメッセージを受信するために、LINEのYour
            Radio公式アカウントを友達追加してね
          </p>
          <AddLineButton />
          <PublicRequestNote />
        </VerticalList>
      </Section>
    </AppLayout2>
  );
};
