import React, { FC } from "react";

import { Input } from "antd";
import { Small } from "../../common/components/Small";
import { Right } from "../../common/components/Right";
import { VerticalList } from "../../common/components/VerticalList";
import { track } from "../../common/track";

type RequestNameProps = {
  value: string;
  onChange: (value: string) => void;
  userName?: string | null;
};

export const RequestName: FC<RequestNameProps> = ({
  value,
  userName,
  onChange,
}) => {
  return (
    <VerticalList width="100%" margin={0}>
      <Input
        placeholder="呼び名 (◯◯さん、◯◯ちゃん)"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onFocus={() => {
          track({ event: "request.name.focus" });
        }}
        style={{ flexGrow: 1, fontSize: 16 }}
      />

      {userName && (
        <Right>
          <Small>
            呼んでほしい名前をいれてね。ユーザー名は "{userName}"
            として相手に伝わるよ
          </Small>
        </Right>
      )}
    </VerticalList>
  );
};
