import { FC } from "react";
import styled from "styled-components";

type ListenerOnlineStatusProps = {
  listenerPresence: boolean;
};

export const ListenerOnlineStatus: FC<ListenerOnlineStatusProps> = ({
  listenerPresence,
}) => {
  return (
    <>
      {listenerPresence ? (
        <Online>リスナーが聴いています</Online>
      ) : (
        <Offline>リスナーがいません</Offline>
      )}
    </>
  );
};

const Online = styled.span`
  color: #13c2c2;
  font-weight: bold;
`;

const Offline = styled.span`
  color: #ff7875;
  font-weight: bold;
`;
