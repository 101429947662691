import { useHistory } from "react-router-dom";
import { useCallback } from "react";
import { useSaveLocationToRestore } from "../restoreLocation";

export function useGoto(
  path: string,
  options?: {
    saveLocation?: boolean;
  }
): () => void {
  const saveLocation = options?.saveLocation;
  const history = useHistory();
  const save = useSaveLocationToRestore();

  return useCallback(() => {
    if (saveLocation) {
      save();
    }

    history.push(path);
  }, [history, path, save, saveLocation]);
}
