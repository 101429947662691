import React, { FC, useCallback } from "react";
import { track } from "../track";

export const lineUrl = "https://lin.ee/9IDFMJO";

type Props = {
  disabled?: boolean;
};

export const AddLineButton: FC<Props> = ({ disabled }) => {
  const line = useCallback(() => {
    track({ event: "line_register" });
    window.open(lineUrl, "_blank");
  }, []);

  const src = "https://scdn.line-apps.com/n/line_add_friends/btn/ja.png";

  if (disabled) {
    return (
      <img
        style={{ filter: "brightness(0.5)" }}
        src={src}
        alt="友だち追加"
        height="36"
      />
    );
  }

  return <img onClick={line} src={src} alt="友だち追加" height="36" />;
};
