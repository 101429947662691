import React, { FC } from "react";

import { Button } from "antd";
import { CaretRightOutlined, PauseOutlined } from "@ant-design/icons";

type MiniPlayButtonProps = {
  onPlay: () => void;
  onPause: () => void;
  playing: boolean;
  canPlay: boolean;
};

export const MiniPlayButton: FC<MiniPlayButtonProps> = ({
  onPause,
  onPlay,
  playing,
  canPlay,
}) => {
  return (
    <>
      {playing ? (
        <Button shape="circle" icon={<PauseOutlined />} onClick={onPause} />
      ) : (
        <Button
          shape="circle"
          icon={<CaretRightOutlined />}
          onClick={onPlay}
          loading={!canPlay}
        />
      )}
    </>
  );
};
