import { MutableRefObject, useCallback, useEffect, useRef } from "react";
import smoothscroll from "smoothscroll-polyfill";

smoothscroll.polyfill();

/**
 * polyfill だと位置指定が効かないので注意
 */
export default function useScrollIntoView(p?: {
  auto: boolean;
}): [MutableRefObject<any>, (options?: ScrollIntoViewOptions) => void] {
  const auto = p?.auto;

  const ref = useRef<HTMLElement>(null);

  const scroll = useCallback((options?: ScrollIntoViewOptions) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", ...options });
    }
  }, []);

  useEffect(() => {
    if (auto) {
      scroll({ behavior: undefined });
    }
  }, [auto, scroll]);

  return [ref, scroll];
}
