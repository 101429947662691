import React, { FC } from "react";

import { useScrollToTopOnMount } from "../../../../common/hooks/useScrollToTopOnMount";
import { RequireAdmin } from "../../components/RequireAdmin";
import { useGoto } from "../../../../lib/hooks/useGoto";
import { adminUserListRoute } from "../AdminUserListPage/AdminUserListPage";
import { adminVoiceMessagesRoute } from "../AdminVoiceMessageListPage/AdminVoiceMessageListPage";
import { adminVoiceRecorderRoute } from "../AdminVoiceRecorderPage/AdminVoiceRecorderPage";
import { Button } from "antd";
import { VerticalList } from "../../../../common/components/VerticalList";
import { adminSendNotificationRoute } from "../AdminSendNotificationPage/AdminSendNotificationPage";

export const adminRoute = {
  path: "/admin",
  makePath(lineId: string) {
    return `/admin`;
  },
};

export const AdminPage: FC = () => {
  const goUserList = useGoto(adminUserListRoute.makePath());
  const goRequests = useGoto(adminVoiceMessagesRoute.makePath());
  const goRecorder = useGoto(adminVoiceRecorderRoute.makePath("x"));
  const goSendNotification = useGoto(adminSendNotificationRoute.makePath("x"));

  useScrollToTopOnMount();
  return (
    <RequireAdmin>
      <VerticalList align="center">
        <Button onClick={goUserList}>user list</Button>
        <Button onClick={goRequests}>request list</Button>
        <Button onClick={goRecorder}>recorder</Button>
        <Button onClick={goSendNotification}>send notification</Button>
      </VerticalList>
    </RequireAdmin>
  );
};
