import { useEffect, useState } from "react";

/**
 * input に GainNode をつけ、 gain 操作できるようにする
 */
export function useGain({
  inputNode,
  audioContext,
}: {
  inputNode: AudioNode | null;
  audioContext: AudioContext | null;
}) {
  const [gain, setGain] = useState(1);
  const [gainNode, setGainNode] = useState<GainNode | null>(null);

  useEffect(() => {
    if (audioContext === null || inputNode === null) {
      return;
    }
    const gainNode2 = audioContext.createGain();
    inputNode.connect(gainNode2);
    setGainNode(gainNode2);
  }, [audioContext, inputNode]);

  useEffect(() => {
    if (gainNode) {
      gainNode.gain.value = Math.min(gain, gainNode.gain.maxValue);
    }
  }, [gain, gainNode]);

  return {
    gain,
    setGain,
    gainNode,
  };
}
