import React, { FC } from "react";
import Icon from "@ant-design/icons";
import { ReactComponent as FavoriteSvg } from "../../images/favorite_black_24dp.svg";
import { ReactComponent as FavoriteBorderSvg } from "../../images/favorite_border_black_24dp.svg";

type Type = "small" | "normal" | "large";

export interface PointIconProps {
  type?: Type;
}

export const PointIcon: FC<PointIconProps> = ({ type }) => {
  const component = type === "small" ? FavoriteBorderSvg : FavoriteSvg;

  const size =
    type === "small"
      ? 8
      : type === "normal"
      ? 12
      : type === "large"
      ? 20
      : undefined; // 文脈による

  const color =
    type === "small"
      ? smallColor
      : type === "normal"
      ? normalColor
      : type === "large"
      ? largeColor
      : normalColor;

  return (
    <Icon
      component={component}
      style={{
        color: color,
        fontSize: size,
      }}
    />
  );
};

const smallColor = "hsl(342,64%,69%)";

// #E4809E
const normalColor = "hsl(342,64%,69%)";

// #E84575
const largeColor = "hsl(342,77%,59%)";
