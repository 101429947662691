import React, { FC } from "react";
import { OtherUser } from "../../../../api";
import { Divider } from "antd";
import { VerticalList } from "../../../../common/components/VerticalList";
import { BottomSheet } from "../../../../common/components/BottomSheet";
import { PostByNameRequest } from "../../../components/PostByNameRequest/PostByNameRequest";
import { MyUser2 } from "../../../../common/api/api2";
import { OthersProfile } from "../../../components/OthersProfile/OthersProfile";
import { TopicId } from "../../../../common/api/voicemessage/api";

interface Props {
  me: MyUser2;
  cast: OtherUser;
  defaultTopicId?: TopicId | null;
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

export const RequestBottomSheet: FC<Props> = ({
  me,
  cast,
  defaultTopicId,
  visible,
  setVisible,
}) => {
  return (
    <BottomSheet visible={visible} onClose={setVisible} height="60vh">
      <VerticalList>
        <OthersProfile personalityId={cast.personalityId} noTalk noTopics />
        <Divider />
        <PostByNameRequest
          me={me}
          cast={cast}
          onComplete={() => setVisible(false)}
          defaultTopicId={defaultTopicId}
        />
      </VerticalList>
    </BottomSheet>
  );
};
