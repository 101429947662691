import React, { FC, useCallback } from "react";

import { Input } from "antd";

interface Props {
  placeholder?: HTMLTextAreaElement["placeholder"];
  value?: HTMLTextAreaElement["value"];
  onChange?: (value: string) => void;
  rows?: HTMLTextAreaElement["rows"];
}

export const AppTextArea: FC<Props> = ({
  placeholder,
  value,
  onChange,
  rows,
}) => {
  const onChange2 = useCallback(
    (e) => {
      if (onChange) {
        onChange(e.target.value);
      }
    },
    [onChange]
  );

  return (
    <Input.TextArea
      placeholder={placeholder}
      value={value}
      onChange={onChange2}
      style={{ width: "100%", fontSize: 16 }}
      rows={rows}
    />
  );
};
