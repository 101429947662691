/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiPointLog } from '../model';
// @ts-ignore
import { PostPointTransaction } from '../model';
/**
 * PointApi - axios parameter creator
 * @export
 */
export const PointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 対象ユーザーとのポイントの履歴を取得します
         * @param {string} otherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPointLogs: async (otherId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'otherId' is not null or undefined
            assertParamExists('getUserPointLogs', 'otherId', otherId)
            const localVarPath = `/points/{otherId}`
                .replace(`{${"otherId"}}`, encodeURIComponent(String(otherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ポイントを送ります
         * @param {PostPointTransaction} postPointTransaction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPointTransaction: async (postPointTransaction: PostPointTransaction, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postPointTransaction' is not null or undefined
            assertParamExists('postPointTransaction', 'postPointTransaction', postPointTransaction)
            const localVarPath = `/points/transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postPointTransaction, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PointApi - functional programming interface
 * @export
 */
export const PointApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PointApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 対象ユーザーとのポイントの履歴を取得します
         * @param {string} otherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserPointLogs(otherId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiPointLog>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserPointLogs(otherId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ポイントを送ります
         * @param {PostPointTransaction} postPointTransaction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPointTransaction(postPointTransaction: PostPointTransaction, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPointTransaction(postPointTransaction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PointApi - factory interface
 * @export
 */
export const PointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PointApiFp(configuration)
    return {
        /**
         * 
         * @summary 対象ユーザーとのポイントの履歴を取得します
         * @param {string} otherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPointLogs(otherId: string, options?: any): AxiosPromise<Array<ApiPointLog>> {
            return localVarFp.getUserPointLogs(otherId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ポイントを送ります
         * @param {PostPointTransaction} postPointTransaction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPointTransaction(postPointTransaction: PostPointTransaction, options?: any): AxiosPromise<void> {
            return localVarFp.postPointTransaction(postPointTransaction, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PointApi - object-oriented interface
 * @export
 * @class PointApi
 * @extends {BaseAPI}
 */
export class PointApi extends BaseAPI {
    /**
     * 
     * @summary 対象ユーザーとのポイントの履歴を取得します
     * @param {string} otherId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointApi
     */
    public getUserPointLogs(otherId: string, options?: any) {
        return PointApiFp(this.configuration).getUserPointLogs(otherId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ポイントを送ります
     * @param {PostPointTransaction} postPointTransaction 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointApi
     */
    public postPointTransaction(postPointTransaction: PostPointTransaction, options?: any) {
        return PointApiFp(this.configuration).postPointTransaction(postPointTransaction, options).then((request) => request(this.axios, this.basePath));
    }
}
