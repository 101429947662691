import React, { FC } from "react";

import { Select } from "antd";
import {
  AdminVoiceMessage,
  VoiceMessageId,
} from "../../../../common/api/types";
import { useMutation } from "react-query";
import { UserForAdmin } from "../../../../common/api/admin";
import { UserId } from "../../../../auth/auth";
import { useInvalidateRequests } from "../../hooks/request";
import { putStaffVoiceMessageRecommendedCast } from "../../../../common/api/staff";

interface Props {
  voiceMessage: AdminVoiceMessage;
  casts: UserForAdmin[];
}

export const SetRecommendedCastControl: FC<Props> = ({
  voiceMessage,
  casts,
}) => {
  const invalidate = useInvalidateRequests();

  const setRecommendedCast = useMutation(
    async (params: {
      voiceMessageId: VoiceMessageId;
      recommendedCastId: UserId | null;
    }) => await putStaffVoiceMessageRecommendedCast(params),
    {
      onSuccess: invalidate,
    }
  );

  return (
    <Select
      placeholder="おすすめキャスト"
      value={voiceMessage.recommendedCastId}
      onChange={(a) =>
        setRecommendedCast.mutate({
          voiceMessageId: voiceMessage.id,
          recommendedCastId: a ?? null,
        })
      }
      loading={setRecommendedCast.isLoading}
      disabled={setRecommendedCast.isLoading}
      allowClear
    >
      {casts.map((it) => (
        <Select.Option key={it.id} value={it.id}>
          {it.name}
        </Select.Option>
      ))}
    </Select>
  );
};
