import React, { FC, useCallback } from "react";
import { Button, notification } from "antd";
import styled from "styled-components";
import { textColor } from "../../colors";
import { usePageView } from "../../common/hooks/usePageView";
import { useScrollToTopOnMount } from "../../common/hooks/useScrollToTopOnMount";
import { ConnectionQuality } from "./model/setup";
import { AudioMutedOutlined } from "@ant-design/icons";
import { VolumeSlider } from "../../common/components/VolumeSlider";

const MESSAGES = [
  "聴こえないよ",
  "BGMだけ聴こえる",
  "聴こえるよ",
  "もう少し大きな声で",
  "そうだよ",
  "違うよ",
  "うんうん",
  "1番",
  "2番",
  "3番",
  "ありがとう",
  "すごい！",
  "好き！",
  "うれしい！",
  "楽しい！",
  "かなしい",
  "さみしい",
  "もう少し聴きたい",
];

export type ListeningProps = {
  sessionId: string;
  onSendMessage: (message: string) => void;
  bgmVolume: number;
  setBgmVolume: (volume: number) => void;
  connectionQuality: ConnectionQuality;
  muted: boolean;
  setMuted: (muted: boolean) => void;
  logs: string[];
};

export const Listening: FC<ListeningProps> = ({
  onSendMessage,
  bgmVolume,
  setBgmVolume,
  connectionQuality,
  muted,
  setMuted,
}) => {
  usePageView("listening");
  useScrollToTopOnMount();

  const reaction = useCallback(
    (reaction: string) => {
      (async () => {
        notification.open({
          message: reaction,
        });
        onSendMessage(reaction);
      })();
    },
    [onSendMessage]
  );

  return (
    <div>
      {connectionQuality === "poor" && <div>通信品質低下中</div>}
      {muted && (
        <Button
          type="primary"
          danger
          onClick={() => setMuted(false)}
          icon={<AudioMutedOutlined />}
        >
          音声ミュート中
        </Button>
      )}
      <div>BGM ボリューム</div>
      <VolumeSlider onChange={setBgmVolume} value={bgmVolume} />

      <ReactionMargin />
      <ReactionTitle>リアクションしてみよう</ReactionTitle>
      <ReactionBox>
        {MESSAGES.map((m) => (
          <Reaction key={m} onClick={() => reaction(m)}>
            {m}
          </Reaction>
        ))}
      </ReactionBox>
    </div>
  );
};

const ReactionTitle = styled.h2`
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  color: ${textColor};
`;

const ReactionMargin = styled.div`
  height: 10vh;
`;

const ReactionBox = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Reaction = styled(Button)`
  margin: 4px 4px;
`;
