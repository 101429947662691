import React, { FC } from "react";
import { Button } from "antd";
import { VerticalList } from "../../../../common/components/VerticalList";
import { useGoto } from "../../../../lib/hooks/useGoto";
import { castTopicRoute } from "../../CastTopicEditorPage/CastTopicPage";

interface Props {}

export const SetTopic: FC<Props> = () => {
  const go = useGoto(castTopicRoute.makePath(), {
    saveLocation: true,
  });
  return (
    <VerticalList>
      <div>
        話したい話題を設定しよう
        <br />
        <br />
        話題を設定すると、その話題を聞きたいユーザーから声のリクエストが来るよ
      </div>
      <Button onClick={go}>話題の設定</Button>
    </VerticalList>
  );
};
