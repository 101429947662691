import React, { FC } from "react";

import { Button } from "antd";
import {
  AdminVoiceMessage,
  CensorResult,
  VoiceMessageId,
} from "../../../../common/api/types";
import { AppPopConfirm } from "../../../../common/components/AppPopConfirm";
import { UseMutationResult } from "react-query";

type Props = {
  name: string;
  voiceMessage: AdminVoiceMessage;
  mutation: UseMutationResult<
    unknown,
    unknown,
    { voiceMessageId: VoiceMessageId; result: CensorResult }
  >;
};

export const CensorView: FC<Props> = ({ name, voiceMessage, mutation }) => {
  return (
    <>
      <AppPopConfirm
        title="OK でよろしいですか"
        onConfirm={() =>
          mutation.mutate({
            voiceMessageId: voiceMessage.id,
            result: "OK",
          })
        }
      >
        <Button
          type="primary"
          loading={mutation.isLoading}
          disabled={mutation.isSuccess}
        >
          {name} OK
        </Button>
      </AppPopConfirm>

      <AppPopConfirm
        title="NG でよろしいですか"
        onConfirm={() =>
          mutation.mutate({
            voiceMessageId: voiceMessage.id,
            result: "NG",
          })
        }
      >
        <Button loading={mutation.isLoading} disabled={mutation.isSuccess}>
          {name} NG
        </Button>
      </AppPopConfirm>
    </>
  );
};
