/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface MyUser
 */
export interface MyUser {
    /**
     * 
     * @type {string}
     * @memberof MyUser
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MyUser
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof MyUser
     */
    avatarUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MyUser
     */
    isAdmin: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MyUser
     */
    isCast: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MyUser
     */
    isProxying: boolean;
    /**
     * 
     * @type {string}
     * @memberof MyUser
     */
    sex?: MyUserSexEnum;
    /**
     * 
     * @type {number}
     * @memberof MyUser
     */
    level?: number;
    /**
     * 
     * @type {number}
     * @memberof MyUser
     */
    point?: number;
    /**
     * 
     * @type {string}
     * @memberof MyUser
     */
    listenerAvatarUrl?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MyUser
     */
    features: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MyUser
     */
    tags: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MyUser
     */
    noteForListener?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MyUser
     */
    isAvatarCensoring: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MyUser
     */
    isMessageCensoring: boolean;
    /**
     * 
     * @type {string}
     * @memberof MyUser
     */
    latestMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof MyUser
     */
    latestAvatarFileId?: string;
    /**
     * 
     * @type {string}
     * @memberof MyUser
     */
    latestAvatarUrl?: string;
    /**
     * リスナーとして利用する名前。二重人格ユーザーではない場合、キャストと同じ名前です
     * @type {string}
     * @memberof MyUser
     */
    listenerName?: string;
    /**
     * 
     * @type {string}
     * @memberof MyUser
     */
    listenerId: string;
    /**
     * プロフィールをちゃんと設定しているかどうか
     * @type {boolean}
     * @memberof MyUser
     */
    hasProfile: boolean;
    /**
     * 
     * @type {string}
     * @memberof MyUser
     */
    createdAt?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MyUser
     */
    hasDoublePersonality: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum MyUserSexEnum {
    Male = 'MALE',
    Female = 'FEMALE'
}



