import React, { FC } from "react";
import styled from "styled-components";
import { Hero1 } from "./Hero1";

export type Hero1WithSubMessageProps = {
  mainMessage?: string;
  subMessage: string;
};

export const Hero1WithSubMessage: FC<Hero1WithSubMessageProps> = ({
  mainMessage,
  subMessage,
}) => {
  const subMessage2 = subMessage.split("\n");
  return (
    <Root>
      <Hero1 mainMessage={mainMessage} />
      <MessageBox>
        {subMessage2.map((m) => (
          <MyParagraph key={m}>{m}</MyParagraph>
        ))}
      </MessageBox>
    </Root>
  );
};

const Root = styled.div``;

const MessageBox = styled.div`
  margin: 20px;
  font-size: 12px;

  text-align: center;

  & *:not(:last-child) {
    margin-bottom: 4px;
  }
`;

const MyParagraph = styled.p`
  margin: 0;
`;
