import { useQueryParams } from "../../common/hooks/useQueryParams";
import { atom, useRecoilValue } from "recoil";

export type Type1 =
  | "host"
  | "cast"
  | "the-opposite-sex"
  | "master1"
  | "master2";

export type Type2 = "anime" | "real";

export type Type3 = "topic-text" | "topic-selector";

export type Type4 = "survey" | "line";

export function getQueryParams(name: string): string | null {
  return new URLSearchParams(window.location.search).get(name);
}

function getType1(): Type1 {
  const t = getQueryParams("t1");
  if (t === "1") {
    return "host";
  } else if (t === "2") {
    return "cast";
  } else if (t === "3") {
    return "the-opposite-sex";
  } else if (t === "4") {
    return "master1";
  } else if (t === "5") {
    return "master2";
  } else {
    return "cast";
  }
}

function getType2(): Type2 {
  const t = getQueryParams("t2");
  if (t === "1") {
    return "anime";
  } else if (t === "2") {
    return "real";
  } else {
    return "anime";
  }
}

function getType3(): Type3 {
  const t = getQueryParams("t3");
  if (t === "1") {
    return "topic-text";
  } else if (t === "2") {
    return "topic-selector";
  } else {
    return "topic-text";
  }
}

function getType4(): Type4 {
  const t = getQueryParams("t4");
  if (t === "1") {
    return "survey";
  } else if (t === "2") {
    return "line";
  } else {
    return "survey";
  }
}

const type1State = atom({
  key: "type1",
  default: getType1(),
});

export function useType1(): Type1 {
  return useRecoilValue(type1State);
}

const type2State = atom({
  key: "type2",
  default: getType2(),
});

export function useType2(): Type2 {
  return useRecoilValue(type2State);
}

const type3State = atom({
  key: "type3",
  default: getType3(),
});

export function useType3(): Type3 {
  return useRecoilValue(type3State);
}

const type4State = atom({
  key: "type4",
  default: getType4(),
});

export function useType4(): Type4 {
  return useRecoilValue(type4State);
}

export type Sex = "female" | "male";

export function invertSex(sex: Sex): Sex {
  if (sex === "female") {
    return "male";
  } else {
    return "female";
  }
}

export function useListenerSex(): Sex {
  const s = useQueryParams("s");
  if (s === "f") {
    return "female";
  } else if (s === "m") {
    return "male";
  } else {
    return "female";
  }
}

export function hostTypeName(type: Type1): string {
  switch (type) {
    case "cast":
      return "キャスト";
    case "host":
      return "ホスト";
    case "the-opposite-sex":
      return "異性";
    case "master1":
      return "ご主人さま";
    case "master2":
      return "マスター";
  }
}
