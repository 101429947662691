import { useCallback, useEffect, useState } from "react";

/**
 * gain を調整する機能
 */
function useGain(params: {
  context?: AudioContext | null;
  initialGain: number;
  audio?: HTMLMediaElement;
}) {
  const context = params.context;
  const audio = params.audio;
  const [gain, setGain] = useState(params.initialGain);
  const [gainNode, setGainNode] = useState<GainNode | null>(null);
  const [
    audioNode,
    setAudioNode,
  ] = useState<MediaElementAudioSourceNode | null>(null);

  // 初期化
  useEffect(() => {
    if (!audio || !context) {
      return;
    }
    console.log("useGain setup");
    const gainNode = context.createGain();
    const mediaNode = context.createMediaElementSource(audio);

    mediaNode.connect(gainNode);
    gainNode.connect(context.destination);

    setGainNode(gainNode);
    setAudioNode(mediaNode);
    return () => {
      console.log("useGain cleanup");
      gainNode.disconnect();
      mediaNode.disconnect();
      setGainNode(null);
      setAudioNode(null);
    };
  }, [audio, context]);

  useEffect(() => {
    if (gainNode) {
      gainNode.gain.value = Math.min(gain, gainNode.gain.maxValue);
    }
  }, [gain, gainNode]);

  return {
    gain,
    setGain,
    audioNode,
    outputNode: gainNode,
  };
}

/**
 * 指定した src を再生する Audio を扱えるようにする
 * また、 gain 調整が可能である
 */
export function useAudioWithGain(params: {
  src?: string;
  initialGain: number;
  context: AudioContext | null;
}) {
  const context = params.context;
  const [audio] = useState<HTMLAudioElement>(new Audio());
  const [playing, setPlaying] = useState(false);
  const { gain, setGain, audioNode, outputNode } = useGain({
    context: context,
    initialGain: params.initialGain,
    audio,
  });

  useEffect(() => {
    console.log("audio setup");
    audio.src = params.src ?? "";
    audio.loop = true;
    audio.muted = false;
    audio.volume = 1;
    audio.load();
    // これをやらないと cors のエラーが出る
    audio.crossOrigin = "anonymous";

    audio.addEventListener("pause", () => {
      setPlaying(false);
    });

    audio.addEventListener("play", () => {
      setPlaying(true);
    });

    audio.addEventListener("abort", () => {
      setPlaying(false);
    });
    audio.addEventListener("loadstart", () => {
      setPlaying(false);
    });

    return () => {
      console.log("audio cleanup ");
      audio.src = "";
    };
  }, [audio, params.src]);

  const play = useCallback(() => {
    audio.play().then();
    if (context) {
      context.resume().then();
    }
  }, [audio, context]);

  const pause = useCallback(() => {
    audio.pause();
  }, [audio]);

  return {
    audio,
    play,
    pause,
    gain,
    setGain,
    playing,
    audioNode,
    outputNode,
  };
}
