import React, { FC, useCallback } from "react";
import { PostPublicRequest } from "../../components/PostPublicRequest/PostPublicRequest";
import { useSaveRequest } from "../../hooks/lp2";
import { useHistory } from "react-router-dom";
import { voiceMessageLp2Step2Route } from "../VoiceMessageLp2Step2Page/VoiceMessageLp2Step2Page";
import { Sex3 } from "../../../common/api/api2";

interface Props {
  castGender: Sex3;
}

export const PostPublicRequestInLp: FC<Props> = ({ castGender }) => {
  const impl = useSaveRequest();
  const history = useHistory();
  const request = useCallback(
    async (request) => {
      impl(request);
      history.push(voiceMessageLp2Step2Route.makePath());
    },
    [history, impl]
  );

  return (
    <PostPublicRequest
      me={{
        listenerName: "",
        tags: [],
      }}
      onRequest={request}
      maxTopics={10}
      defaultCastGender={castGender}
    />
  );
};
