import React, { FC } from "react";
import styled from "styled-components";

interface Props {}

export const HorizontalCenter: FC<Props> = ({ children }) => {
  return <Root>{children}</Root>;
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
