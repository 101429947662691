import React, { FC } from "react";

import { HorizontalList } from "../../../../common/components/HorizontalList";
import { ApiMessage, OtherUser } from "../../../../api";
import { UserLink } from "../../components/UserLink";
import { TopicView } from "../../../components/TopicView";
import { Small } from "../../../../common/components/Small";
import { VerticalList } from "../../../../common/components/VerticalList";
import { Tag } from "antd";

interface RequestSummaryProps {
  user?: OtherUser;
  request: ApiMessage;
  thanks: ApiMessage[];
}

export const RequestSummary: FC<RequestSummaryProps> = ({
  user,
  request,
  thanks,
}) => {
  const topic = request.requestSupplement?.topic;

  const opacity =
    request.requestSupplement?.requestState === "RESPONDED" ? 0.3 : undefined;

  return (
    <VerticalList style={{ opacity }} margin={0}>
      <Small>{request.requestId}</Small>
      <HorizontalList>
        {topic && <TopicView topic={topic} size="small" hideQuestion />}
      </HorizontalList>
      <HorizontalList alignItems="center">
        <UserLink user={user} />

        <Small> {request.targetType}</Small>

        {request.requestSupplement?.requestState === "RESPONDED" && (
          <Tag>回答済</Tag>
        )}

        {thanks.length >= 1 && <Tag>お礼有</Tag>}
      </HorizontalList>
    </VerticalList>
  );
};
