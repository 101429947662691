import React, { FC } from "react";

import { featureNames } from "../../../common/api/user";
import { Checkbox } from "antd";
import { RequireLineSignIn } from "../../../auth/components/RequireLineSignIn";
import { AppLayout2 } from "../../../common/components/AppLayout2";
import { Section } from "../../../common/components/Section";
import {
  getLocalFeatures,
  setLocalFeatures,
} from "../../../common/hooks/feature";
import { usePageView } from "../../../common/hooks/usePageView";

export const devFeatureRoute = {
  path: "/dev/feature",
  makePath() {
    return `/dev/feature`;
  },
};

export const FeaturePage: FC = () => {
  usePageView("feature");
  return (
    <RequireLineSignIn>
      <Impl />
    </RequireLineSignIn>
  );
};

const Impl: FC = () => {
  const options = [...featureNames];
  const values = getLocalFeatures();

  return (
    <AppLayout2>
      <Section>
        <div>Local feature</div>
        <Checkbox.Group
          options={options}
          defaultValue={values}
          onChange={(checkedValue) => {
            setLocalFeatures(checkedValue as any);
          }}
        />
      </Section>
    </AppLayout2>
  );
};
