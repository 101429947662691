import React, { FC } from "react";
import { MessageTypeEnum, OtherUser } from "../../api";
import { HelpLink } from "../../common/components/HelpLink";
import { UploadVoiceUi } from "./UploadVoiceUi";
import { RequestId } from "../../common/api/types";

export interface UploadVoiceUiProps {
  targetUser: OtherUser;
  messageType: MessageTypeEnum;
  requestId: RequestId;
}

export const UploadVoiceUiLink: FC<UploadVoiceUiProps> = ({
  targetUser,
  messageType,
  requestId,
}) => {
  return (
    <HelpLink
      contentWidth="100%"
      scroll={true}
      track="help.recording-method3"
      help={
        <UploadVoiceUi
          targetUser={targetUser}
          messageType={messageType}
          requestId={requestId}
        />
      }
    >
      メッセージの贈り方(他の方法でうまくいかない場合)
    </HelpLink>
  );
};
