/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { User } from './user';

/**
 * 
 * @export
 * @interface VoiceMessageRequest
 */
export interface VoiceMessageRequest {
    /**
     * 
     * @type {User}
     * @memberof VoiceMessageRequest
     */
    cast?: User;
    /**
     * 
     * @type {string}
     * @memberof VoiceMessageRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof VoiceMessageRequest
     */
    talkType: VoiceMessageRequestTalkTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof VoiceMessageRequest
     */
    request?: string;
    /**
     * 
     * @type {string}
     * @memberof VoiceMessageRequest
     */
    targetSex: VoiceMessageRequestTargetSexEnum;
    /**
     * 
     * @type {Set<string>}
     * @memberof VoiceMessageRequest
     */
    listenerTags: Set<string>;
    /**
     * 
     * @type {string}
     * @memberof VoiceMessageRequest
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof VoiceMessageRequest
     */
    situation?: string;
    /**
     * 
     * @type {string}
     * @memberof VoiceMessageRequest
     */
    topicId?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum VoiceMessageRequestTalkTypeEnum {
    Polite = 'POLITE',
    NotPolite = 'NOT_POLITE'
}
/**
    * @export
    * @enum {string}
    */
export enum VoiceMessageRequestTargetSexEnum {
    Male = 'MALE',
    Female = 'FEMALE'
}



