import React, { FC, useState } from "react";

import { Button, Checkbox, Popover } from "antd";
import styled from "styled-components";
import { OtherFullUser } from "../../../api";
import { VerticalList } from "../../../common/components/VerticalList";
import { Help } from "../../../common/components/Help";
import { useMutation } from "react-query";
import { messageApi } from "../../../common/api/api2";
import { RequestId } from "../../../common/api/types";
import { useInvalidateTalk } from "../../hooks/message";
import { HorizontalList } from "../../../common/components/HorizontalList";
import { AppTextArea } from "../../../common/components/AppTextArea";
import { track } from "../../../common/track";

interface Props {
  requestId: RequestId;
  onSent: () => void;
  cast: OtherFullUser;
}

export const PostThanksByText: FC<Props> = ({ requestId, onSent, cast }) => {
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);

  const invalidate = useInvalidateTalk(cast.id);
  const send = useMutation(
    async (params: { message: string; open: boolean }) => {
      await messageApi.postRequestThanks(requestId, {
        message: params.message,
        open: params.open,
      });
    },
    {
      async onSuccess() {
        await invalidate();
        setMessage("");
        onSent();
      },
    }
  );

  return (
    <VerticalList align="center">
      <AppTextArea
        placeholder="ありがとうメッセージ"
        value={message}
        onChange={setMessage}
      />

      <Row>
        <Checkbox onChange={(e) => setOpen(e.target.checked)} checked={open}>
          みんなのありがとうへの掲載を許可する
        </Checkbox>
        <Help help="チェックをつけると、あなたのメッセージが、みんなのありがとうに匿名で掲載されることがあります" />
      </Row>

      <div>
        <Popover
          content={<Examples />}
          trigger="click"
          onVisibleChange={(b) => {
            if (b) {
              track({
                event: "voice-message.thanks.view",
                data: "",
              });
            }
          }}
        >
          <Button type="link">みんなのありがとうをみる</Button>
        </Popover>
      </div>

      <HorizontalList>
        <Button
          type="primary"
          disabled={!message.length}
          onClick={() => send.mutate({ message, open })}
          loading={send.isLoading}
        >
          テキストで送る
        </Button>
      </HorizontalList>
    </VerticalList>
  );
};

const Examples: FC = () => {
  return (
    <>
      <Example>
        返信が来たらラッキーって思ってメッセージを送りました。数日経って返信がきて正直驚きましたし嬉しかったです。雑談でお願いしていたのですが、今の状況を心配してくださって、それに合わせた話を雑談として聞かせてくださってとても嬉しかったです。食べて寝て学校に行っての繰り返しで、どうにも出来なくてちょっと辛いなぁって思ってたのですが、このボイスを聞いてちょっと勇気を出してこの状況を変えようかなって思いました。本当にありがとうございました。
      </Example>
      <Example>
        3回目もありがとうございます笑ハマってます笑
        始めた経緯教えてくれてありがとう！
        素敵な夢！叶えましょう！僕も夢があるから僕もがんばる！ほんまにありがとう！またよろしくー笑
      </Example>
      <Example>
        普通に泣きそうになりました。。
        少しだけ苦しいことも全部肯定してくれて、その上で励ましてくれて、ありがとうございます！
        また近況話したいです！頑張ります！
      </Example>
    </>
  );
};

const Example = styled.div`
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 8px;
  margin: 4px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
