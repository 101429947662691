import { useEffect, useState } from "react";

/**
 * Audio を生成し、扱えるようにする
 *
 * ボリュームはデフォルトで最大とする
 */
export function useAudio() {
  const [audio] = useState(new Audio());
  const [volume, setVolume] = useState(1);
  const [muted, setMuted] = useState(false);

  useEffect(() => {
    audio.volume = 1;
    setVolume(audio.volume);
    setMuted(audio.muted);

    audio.addEventListener("volumechange", () => {
      console.log("useAudio volumechange", audio.volume, audio.muted);
      setVolume(audio.volume);
      setMuted(audio.muted);
    });
  }, [audio]);

  useEffect(() => {
    if (audio.muted !== muted) {
      audio.muted = muted;
    }
  }, [audio, muted]);

  return {
    audio,
    muted,
    setMuted,
    volume,
  };
}
