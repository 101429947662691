import React, { FC } from "react";
import { HelpLink } from "../../../common/components/HelpLink";

interface Props {}

export const RequestContentHelp: FC<Props> = () => {
  return (
    <HelpLink
      help={<RequestHelp />}
      track="help.request-content"
      contentWidth="80vw"
    >
      リクエスト内容の心得！
    </HelpLink>
  );
};

const RequestHelp: FC = () => {
  return (
    <div>
      <p>ていねいなリクエストを心がけよう！</p>
      <p>
        内容は具体的にしよう。内容が具体的なほど、具体的な話をしてくれるかも！？
      </p>
      <p>
        シチュボや恋人風のメッセージをリクエストする時は、お気に入りの人を指名しよう！
      </p>
      <p>
        以下のような内容は送らないように注意！
        <br />
        住所・メールアドレス・LINE・TEL等の個人情報
        <br />
        誹謗やアダルト等の不快感を与える可能性のある内容
      </p>
    </div>
  );
};
