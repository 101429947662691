import React, { FC } from "react";
import { Sex2 } from "../../../common/api/types";
import { Tag } from "antd";

interface Props {
  value?: Sex2;
}

export const SexTag: FC<Props> = ({ value }) => {
  if (!value) {
    return <></>;
  } else if (value === "FEMALE") {
    return <Tag color="magenta">女性</Tag>;
  } else {
    return <Tag color="blue">男性</Tag>;
  }
};
