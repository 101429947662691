import React, { FC, useCallback } from "react";
import { Button } from "antd";
import styled from "styled-components";
import { postMessage } from "../../common/api/api";
import { eventListenerRegister } from "../../gtm";
import { usePageView } from "../../common/hooks/usePageView";
import { Sex, Type4 } from "../SimpleLiveLpPage/model";
import { AgeType } from "../../common/api/types";
import { AddLineButton } from "../../common/components/AddLineButton";

const surveyUrl =
  "https://docs.google.com/forms/d/e/1FAIpQLSeOw_KmWTyAHvoQViBE0Mo4VDeeoa8YSZEWiwoX06ueFCltlQ/viewform?usp=sf_link";

export type NoHostProps = {
  sessionId: string;
  lastType4: Type4 | null;
  firstAgeType: AgeType | null;
  firstHostSex: Sex | null;
};

export const NoHost: FC<NoHostProps> = ({
  sessionId,
  lastType4,
  firstAgeType,
  firstHostSex,
}) => {
  usePageView("no_host");
  const survey = useCallback(() => {
    eventListenerRegister();
    postMessage(sessionId, "登録をクリックしたよ").then();
    window.open(surveyUrl, "_blank");
  }, [sessionId]);

  const showLine = true;

  return (
    <CenterDescriptionBox>
      <Paragraph>いま空いているキャストが見つかりませんでした</Paragraph>

      {showLine && (
        <div>
          <Paragraph>
            Your Radio LINE 公式アカウントを友達追加すると、
            キャストからあなただけのボイスメッセージが届きますよ
          </Paragraph>
          <Paragraph>ぜひ追加していただけると、うれしいです！</Paragraph>
          <Paragraph>
            <AddLineButton />
          </Paragraph>
        </div>
      )}

      {!showLine && (
        <>
          <Paragraph>
            時間をあけて、また来ていただけると、うれしいです
          </Paragraph>
          <Paragraph>
            現在、対応可能なキャストの増員、サービスの改善に取り組んでいます
          </Paragraph>
          <Paragraph>
            キャストを増員した際や、サービスの改善時に優先的にご連絡いたしますので、
            ぜひ、以下のフォームからご登録いただけるとうれしいです！
          </Paragraph>
          <Button type="primary" onClick={survey}>
            ユーザー登録
          </Button>
        </>
      )}
    </CenterDescriptionBox>
  );
};

const margin = "20px";

const CenterDescriptionBox = styled.div`
  margin: 0 ${margin};
  text-align: center;
`;

const Paragraph = styled.div`
  font-size: 12px;
  margin: 0 0 12px;
`;
