import React, { FC } from "react";

import styled from "styled-components";
import { Logo } from "./Logo/Logo";
import { AppAvatar } from "../../voicemessage/components/AppAvatar";
import { useUserStatus } from "../hooks/user";
import { HorizontalList } from "./HorizontalList";
import { useGoto } from "../../lib/hooks/useGoto";
import { myPageRoute } from "../../voicemessage/pages/MyPagePage/MyPage";

interface Props {}

export const HeaderWithAvatar: FC<Props> = () => {
  const me = useUserStatus();

  const goMy = useGoto(myPageRoute.makePath());

  return (
    <Root>
      <HorizontalList
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Logo />
        <AppAvatar src={me.avatarUrl} size="small" onClick={goMy} />
      </HorizontalList>
    </Root>
  );
};

const Root = styled.div`
  background-color: #f5f5f5;
  padding: 8px 8px;
`;
