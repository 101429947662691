import React, { Suspense } from "react";
import "./App.less";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryParamProvider } from "use-query-params";

import "antd/dist/antd.less";

import { PersonalityLpPage } from "./common/pages/PersonalityLpPage/PersonalityLpPage";
import {
  liveRoute,
  SimpleLiveLpPage,
} from "./simplelive/SimpleLiveLpPage/SimpleLiveLpPage";
import { routes } from "./routes";
import { QueryClient, QueryClientProvider } from "react-query";

import { SimpleLiveHostPage } from "./simplelive/SimpleLiveHostPage/SimpleLiveHostPage";
import { SimpleLiveListenerPage } from "./simplelive/SimpleLiveListenerPage/SimpleLiveListenerPage";
import { WebRtcTesterPage } from "./common/pages/WebRtcTesterPage/WebRtcTesterPage";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { devRoutes } from "./dev/routes";
import { AuthPage, devAuthRoute } from "./dev/AuthPage";
import { initializeGtm } from "./gtm";
import { Loading } from "./common/components/Loading";
import { RecoilRoot } from "recoil";
import { VoiceMessagePage } from "./voicemessage/pages/VoiceMessagePage";
import { ErrorBoundary } from "react-error-boundary";

import { ErrorFallback } from "./common/components/ErrorFallback";
import { RootPage } from "./common/pages/RootPage/RootPage";
import { commonRoutes } from "./common/routes";
import {
  AdminUserInfoPage,
  adminUserInfoRoute,
} from "./voicemessage/admin/pages/AdminUserInfoPage/AdminUserInfoPage";

import {
  AdminVoiceMessageListPage,
  adminVoiceMessagesRoute,
} from "./voicemessage/admin/pages/AdminVoiceMessageListPage/AdminVoiceMessageListPage";
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";
import { RequireLineSignInPage } from "./dev/RequireLineSignInPage";
import { vmRoutes } from "./voicemessage/routes";
import {
  ListenerHomePage,
  listenerHomeRoute,
} from "./voicemessage/pages/ListenerHomePage";
import {
  RequestedPage,
  requestedRoute,
} from "./voicemessage/pages/RequestedPage/RequestedPage";
import {
  RandomRequestPage,
  randomRequestRoute,
} from "./voicemessage/pages/RandomRequestPage";

import {
  DevVoiceRecorderPage,
  devVoiceRecorderRoute,
} from "./dev/DevVoiceRecorderPage";
import { MediaRecorderPage, mediaRecorderRoute } from "./dev/MediaRecorderPage";
import {
  VisionPage,
  visionRoute,
} from "./voicemessage/pages/VisionPage/VisionPage";
import {
  RequestsPage,
  requestsRoute,
} from "./voicemessage/pages/CastNotAssignedRequestsPage/RequestsPage";
import { CastHomePage, castHomeRoute } from "./voicemessage/pages/CastHomePage";
import {
  AdminVoiceRecorderPage,
  adminVoiceRecorderRoute,
} from "./voicemessage/admin/pages/AdminVoiceRecorderPage/AdminVoiceRecorderPage";
import {
  CastAssignedActiveRequestsPage,
  castAssignedActiveRequestsRoute,
} from "./voicemessage/pages/CastAssignedActiveRequestsPage/CastAssignedActiveRequestsPage";
import {
  AdminUserListPage,
  adminUserListRoute,
} from "./voicemessage/admin/pages/AdminUserListPage/AdminUserListPage";
import {
  castProfileEditorRoute,
  CastProfilePage,
} from "./voicemessage/pages/CastProfileEditorPage/CastProfilePage";
import {
  VoiceMessageLpPage,
  voiceMessageLpRoute,
} from "./voicemessage/pages/VoiceMessageLpPage/VoiceMessageLpPage";
import {
  AdminSendNotificationPage,
  adminSendNotificationRoute,
} from "./voicemessage/admin/pages/AdminSendNotificationPage/AdminSendNotificationPage";
import {
  AdminPage,
  adminRoute,
} from "./voicemessage/admin/pages/AdminPage/AdminPage";
import { useLanding } from "./common/hooks/useLanding";
import {
  VoiceMessageCastLpPage,
  voiceMessageCastLpRoute,
} from "./voicemessage/pages/VoiceMessageCastLpPage/VoiceMessageCastLpPage";
import {
  Vision2Page,
  vision2Route,
} from "./voicemessage/pages/Vision2Page/Vision2Page";
import {
  StaffUserPage,
  staffUserRoute,
} from "./voicemessage/staff/pages/StaffUserPage/StaffUserPage";
import {
  CastTopicPage,
  castTopicRoute,
} from "./voicemessage/pages/CastTopicEditorPage/CastTopicPage";
import {
  StaffMessageListPage,
  staffMessageListRoute,
} from "./voicemessage/staff/pages/StaffMessageListPage/StaffMessageListPage";
import { TalkPage, talkRoute } from "./voicemessage/pages/TalkPage/TalkPage";
import { HomePage, homeRoute } from "./voicemessage/pages/HomePage/HomePage";
import {
  RelationshipPage,
  relationshipRoute,
} from "./voicemessage/pages/RelationshipPage/RelationshipPage";
import {
  OthersProfilePage,
  othersProfileRoute,
} from "./voicemessage/pages/OthersProfilePage/OthersProfilePage";
import { MyPage, myPageRoute } from "./voicemessage/pages/MyPagePage/MyPage";
import {
  devFeatureRoute,
  FeaturePage,
} from "./dev/pages/FeaturePage/FeaturePage";
import {
  PublicRequestPage,
  publicRequestRoute,
} from "./voicemessage/pages/PublicRequestPage/PublicRequestPage";
import { useInitializeAuth0 } from "./lib/auth0";
import {
  VoiceMessageLp2Page,
  voiceMessageLp2Route,
} from "./voicemessage/pages/VoiceMessageLp2Page/VoiceMessageLp2Page";
import {
  VoiceMessageLp2Step2Page,
  voiceMessageLp2Step2Route,
} from "./voicemessage/pages/VoiceMessageLp2Step2Page/VoiceMessageLp2Step2Page";
import {
  VoiceMessageLp2Step3Page,
  voiceMessageLp2Step3Route,
} from "./voicemessage/pages/VoiceMessageLp2Step3Page/VoiceMessageLp2Step3Page";

Amplify.configure(awsconfig);

Sentry.init({
  dsn:
    "https://677c3ea2970e4816a8fcc7d75ae9364f@o354380.ingest.sentry.io/5577055",
  autoSessionTracking: true,

  // error boundary つけているけど、こっちはいらないかな？
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_ENV,
});

initializeGtm();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      retry: 1,
      staleTime: 5 * 60 * 1000,
    },
  },
});

function App() {
  console.log("App: render");
  return (
    <RecoilRoot>
      <Sentry.ErrorBoundary fallback={ErrorFallback}>
        <Router>
          <QueryClientProvider client={queryClient}>
            <Suspense fallback={<Loading vh100 />}>
              <App1 />
            </Suspense>
            {process.env.NODE_ENV === "development" && (
              <ReactQueryDevtools initialIsOpen={true} />
            )}
          </QueryClientProvider>
        </Router>
      </Sentry.ErrorBoundary>
    </RecoilRoot>
  );
}

function App1() {
  return <App2 />;
}

function App2() {
  console.log("App2: render");

  const { isLoading, error } = useInitializeAuth0({
    domain: "youradio.jp.auth0.com",
    clientId: "TJmgmboMwtRl0ciVp9gjOCmN8Ye7Bd6C",
    audience: "https://api.youradio.life/",
  });

  if (error) {
    return <div>{error.message}</div>;
  }

  if (isLoading) {
    return <Loading vh100 />;
  }

  return <App3 />;
}

function App3() {
  useLanding();
  return (
    <QueryParamProvider ReactRouterRoute={Route}>
      <Switch>
        <Route path={commonRoutes.root.makePath()} exact>
          <RootPage />
        </Route>
        <Route path="/lp/personality" exact>
          <PersonalityLpPage />
        </Route>

        <Route path={liveRoute.path} exact>
          <SimpleLiveLpPage hostName="rinu" />
        </Route>

        {/*過去互換*/}
        <Route path="/lp/simple-live" exact>
          <SimpleLiveLpPage hostName="dummy" />
        </Route>

        {/*過去互換*/}
        <Route path="/lp/simple-live/rinu" exact>
          <SimpleLiveLpPage hostName="rinu" />
        </Route>

        <Route path={routes.hostSimpleLive.path} exact>
          <SimpleLiveHostPage />
        </Route>
        <Route path={routes.listenerSimpleLive.path} exact>
          <SimpleLiveListenerPage />
        </Route>
        <Route path={routes.webRtcTester.path} exact>
          <WebRtcTesterPage />
        </Route>

        <Route path={homeRoute.path} exact>
          <HomePage />
        </Route>

        <Route path={myPageRoute.path} exact>
          <MyPage />
        </Route>

        <Route path={relationshipRoute.path} exact>
          <RelationshipPage />
        </Route>

        <Route path={othersProfileRoute.path} exact>
          <OthersProfilePage />
        </Route>

        {/*順番大事*/}
        <Route path={randomRequestRoute.path} exact>
          <RandomRequestPage />
        </Route>
        <Route path={publicRequestRoute.path} exact>
          <PublicRequestPage />
        </Route>
        <Route path={requestedRoute.path} exact>
          <RequestedPage />
        </Route>
        <Route path={visionRoute.path} exact>
          <VisionPage />
        </Route>
        <Route path={vision2Route.path} exact>
          <Vision2Page />
        </Route>

        {/*順番大事*/}
        <Route path={voiceMessageLpRoute.path} exact>
          <VoiceMessageLpPage />
        </Route>
        {/*順番大事*/}
        <Route path={voiceMessageLp2Route.path} exact>
          <VoiceMessageLp2Page />
        </Route>
        <Route path={voiceMessageLp2Step2Route.path} exact>
          <VoiceMessageLp2Step2Page />
        </Route>
        <Route path={voiceMessageLp2Step3Route.path} exact>
          <VoiceMessageLp2Step3Page />
        </Route>
        {/* ubie */}
        <Route path={"/lp/lpubi"} exact>
          <VoiceMessageLpPage />
        </Route>
        {/* sun* */}
        <Route path={"/lp/lpsu"} exact>
          <VoiceMessageLpPage />
        </Route>
        <Route path={voiceMessageCastLpRoute.path} exact>
          <VoiceMessageCastLpPage />
        </Route>

        <Route path={vmRoutes.voiceMessage.path} exact>
          <VoiceMessagePage />
        </Route>
        <Route path={listenerHomeRoute.path} exact>
          <ListenerHomePage />
        </Route>

        <Route path={talkRoute.path} exact>
          <TalkPage />
        </Route>

        <Route path={castHomeRoute.path} exact>
          <CastHomePage />
        </Route>
        <Route path={requestsRoute.path} exact>
          <RequestsPage />
        </Route>
        <Route path={castAssignedActiveRequestsRoute.path} exact>
          <CastAssignedActiveRequestsPage />
        </Route>
        <Route path={castProfileEditorRoute.path} exact>
          <CastProfilePage />
        </Route>
        <Route path={castTopicRoute.path} exact>
          <CastTopicPage />
        </Route>

        <Route path={devFeatureRoute.path} exact>
          <FeaturePage />
        </Route>

        <Route path={adminVoiceRecorderRoute.path} exact>
          <AdminVoiceRecorderPage />
        </Route>

        <Route path={adminVoiceMessagesRoute.path} exact>
          <AdminVoiceMessageListPage />
        </Route>
        <Route path={adminUserInfoRoute.path} exact>
          <AdminUserInfoPage />
        </Route>

        <Route path={devAuthRoute.path} exact>
          <AuthPage />
        </Route>

        <Route path={devVoiceRecorderRoute.path} exact>
          <DevVoiceRecorderPage />
        </Route>

        <Route path={devRoutes.authLine.path} exact>
          <RequireLineSignInPage />
        </Route>

        <Route path={mediaRecorderRoute.path} exact>
          <MediaRecorderPage />
        </Route>

        <Route path={adminUserListRoute.path} exact>
          <AdminUserListPage />
        </Route>

        <Route path={adminSendNotificationRoute.path} exact>
          <AdminSendNotificationPage />
        </Route>

        <Route path={adminRoute.path} exact>
          <AdminPage />
        </Route>

        <Route path={staffUserRoute.path} exact>
          <StaffUserPage />
        </Route>

        <Route path={staffMessageListRoute.path} exact>
          <StaffMessageListPage />
        </Route>
      </Switch>
    </QueryParamProvider>
  );
}

export default App;
