import React, { FC } from "react";

import { Radio } from "antd";
import { Sex2 } from "../../common/api/types";

interface Props {
  value: Sex2;
  onChange: (value: Sex2) => void;
}

export const RequestTargetSexType: FC<Props> = ({ value, onChange }) => {
  return (
    <Radio.Group
      options={[
        { label: "男性にリクエスト", value: "MALE" as Sex2 },
        { label: "女性にリクエスト", value: "FEMALE" as Sex2 },
      ]}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      value={value}
      optionType="button"
    />
  );
};
