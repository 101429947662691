import { FC, useCallback, useState } from "react";
import styled from "styled-components";
import { Button, Select } from "antd";
import { VerticalList } from "../../common/components/VerticalList";
import { Listener } from "./Listener";
import { Reaction } from "../model";
import { ListenerOnlineStatus } from "./ListenerOnlineStatus";
import { Gauge } from "../../common/components/Gauge";
import { LargeToggle } from "../../common/components/LargeToggle";
import { Moment } from "moment";
import { StopWatch } from "./StopWatch";
import { ReactionBox } from "./ReactionBox";

export type CastingProps = {
  listenerName: string;
  message: string;
  listenerPresence: boolean;
  reactions: Reaction[];
  volume: number;
  onFinish: () => void;
  muted: boolean;
  setMuted: (muted: boolean) => void;
  startTime?: Moment;

  audioInputs: MediaDeviceInfo[];
  activeAudioInputId: string | undefined;
  chooseAudioInput: (deviceId: string) => void;
};

export const Casting: FC<CastingProps> = ({
  listenerName,
  message,
  listenerPresence,
  reactions,
  volume,
  onFinish,
  muted,
  setMuted,
  startTime,

  audioInputs,
  activeAudioInputId,
  chooseAudioInput,
}) => {
  const [choosingAudioInput, setChoosingAudioInput] = useState(false);

  const onChangeActiveAudioInput = useCallback(
    async (v: string) => {
      setChoosingAudioInput(true);
      try {
        await chooseAudioInput(v);
      } finally {
        setChoosingAudioInput(false);
      }
    },
    [chooseAudioInput]
  );

  return (
    <Root>
      <VerticalList align="center">
        <Listener listenerName={listenerName} message={message} />
        <div>
          <ListenerOnlineStatus listenerPresence={listenerPresence} />
        </div>
        <StopWatchBox>
          <StopWatch startTime={startTime} />
        </StopWatchBox>
        <LargeToggle
          checkedText="再生中"
          unCheckedText="ミュート中"
          checked={!muted}
          onChange={(a) => setMuted(!a)}
        />

        <Select
          value={activeAudioInputId}
          onChange={onChangeActiveAudioInput}
          loading={choosingAudioInput}
        >
          {audioInputs.map((a) => (
            <option key={a.deviceId} value={a.deviceId}>
              {a.label}
            </option>
          ))}
        </Select>

        <Gauge value={volume} />

        <ReactionBox reactions={reactions} />

        <Button type="primary" onClick={onFinish}>
          配信終了
        </Button>
      </VerticalList>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const StopWatchBox = styled.div`
  font-size: 20px;
`;
