import React, { FC } from "react";

import { useScrollToTopOnMount } from "../../../../common/hooks/useScrollToTopOnMount";
import { Section } from "../../../../common/components/Section";
import { AppLayout2 } from "../../../../common/components/AppLayout2";
import { VerticalList } from "../../../../common/components/VerticalList";
import { Card } from "antd";
import { useQuery } from "react-query";
import {
  isTextMessagePayload,
  isVoiceMessagePayload,
  staffApi,
} from "../../../../common/api/api2";
import { VoiceMessagePlayer } from "../../../admin/components/VoiceMessagePlayer";
import { ToClipboard } from "../../../../common/components/ToClipboard";
import { Small } from "../../../../common/components/Small";
import { useUserStatus } from "../../../../common/hooks/user";
import { UserLink } from "../../../admin/components/UserLink";
import { CensorControl } from "../../../admin/components/CensorControl";

export const staffMessageListRoute = {
  path: "/staff/messages",
  makePath() {
    return `/staff/messages`;
  },
};

export const StaffMessageListPage: FC = () => {
  useScrollToTopOnMount();
  const me = useUserStatus();
  const messages = useQuery(
    ["staff", "messages"],
    async () => await staffApi.getMessages()
  ).data?.data!;

  return (
    <AppLayout2 noVerticalMargin>
      <Section>
        <VerticalList align="normal" margin={16}>
          {messages.map((it) => (
            <Card key={it.id}>
              <VerticalList>
                <div>
                  <div>
                    <Small>
                      <ToClipboard data={it.id} />
                    </Small>
                  </div>

                  {me.isAdmin && (
                    <>
                      <div>
                        <Small>
                          from: <UserLink user={it.fromUserId} />
                        </Small>
                      </div>
                      <div>
                        <Small>
                          to: <UserLink user={it.toUserId} />
                        </Small>
                      </div>
                    </>
                  )}
                </div>

                {isTextMessagePayload(it.payload) && (
                  <div>{it.payload.text}</div>
                )}
                {isVoiceMessagePayload(it.payload) && (
                  <div>
                    <VoiceMessagePlayer url={it.payload.url} />
                  </div>
                )}

                <CensorControl type="MESSAGE" id={it.id} />
              </VerticalList>
            </Card>
          ))}
        </VerticalList>
      </Section>
    </AppLayout2>
  );
};
