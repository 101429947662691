import React, { FC, useCallback } from "react";
import { track } from "../track";
import { Button } from "antd";
import { lineUrl } from "./AddLineButton";

type Props = {};

export const OpenLineButton: FC<Props> = () => {
  const line = useCallback(() => {
    track({ event: "line_open" });
    window.open(lineUrl, "_blank");
  }, []);

  return <Button onClick={line}>LINE を開く</Button>;
};
