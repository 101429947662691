import {
  QueryFunction,
  QueryKey,
  useQueryClient,
  UseQueryOptions,
} from "react-query";

export const dummy = 1;

/**
 * useQuery に指定できる引数を生成する
 *
 * 型を守るためのもの
 */
export function queryParams<
  TData = unknown,
  TError = unknown,
  TQueryFnData = TData
>(
  queryKey: QueryKey,
  queryFn: QueryFunction<TQueryFnData | TData>,
  options?: UseQueryOptions<TData, TError, TQueryFnData>
): [
  QueryKey,
  QueryFunction<TQueryFnData | TData>,
  UseQueryOptions<TData, TError, TQueryFnData>?
] {
  return [queryKey, queryFn, options];
}

/**
 * queryParams から prefetchQuery 用の引数を作る
 */
function prefetchParams<
  TData = unknown,
  TError = unknown,
  TQueryFnData = TData
>(
  a: [
    QueryKey,
    QueryFunction<TQueryFnData | TData>,
    UseQueryOptions<TData, TError, TQueryFnData>?
  ]
): [queryKey: QueryKey, queryFn: QueryFunction] {
  return [a[0], a[1]];
}

export function usePrefetch<
  TData = unknown,
  TError = unknown,
  TQueryFnData = TData
>(
  params: [
    QueryKey,
    QueryFunction<TQueryFnData | TData>,
    UseQueryOptions<TData, TError, TQueryFnData>?
  ]
) {
  const queryClient = useQueryClient();
  queryClient.prefetchQuery(...prefetchParams(params)).then();
}
