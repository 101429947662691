import React, { FC } from "react";

import { VoiceMessageTo } from "./VoiceMessageTo";
import {
  useAdminAllVoiceMessages,
  useVoiceMessageFrom,
} from "../../hooks/useAdminAllVoiceMessages";
import { useParams } from "react-router-dom";
import { useScrollToTopOnMount } from "../../../../common/hooks/useScrollToTopOnMount";
import { AdminUserInfo } from "./AdminUserInfo";
import { Section } from "../../../../common/components/Section";
import { AppLayout2 } from "../../../../common/components/AppLayout2";
import { useQuery } from "react-query";
import { VerticalList } from "../../../../common/components/VerticalList";
import { useGetAdminUser } from "../../hooks/adminUser";
import { VoiceMessageFrom } from "./VoiceMessageFrom";
import { compareString } from "../../../../common/util/string";
import { AdminRoot } from "../../components/AdminRoot";
import { adminApi } from "../../../../common/api/api2";
import { useUserTopics } from "../../../hooks/topic";
import { AdminUserMessages } from "./AdminUserMessages";
import { AdminUserRequestSummaryInfo } from "./AdminUserRequestSummary";

export const adminUserInfoRoute = {
  path: "/admin/users/:userId",
  makePath(userId: string) {
    return `/admin/users/${userId}`;
  },
};

export const AdminUserInfoPage: FC = () => {
  return (
    <AdminRoot>
      <AdminUserInfoPageImpl />
    </AdminRoot>
  );
};

/**
 * 特定ユーザーの履歴
 * LINE ID は必ず持っている
 */
const AdminUserInfoPageImpl: FC = () => {
  useScrollToTopOnMount();
  const { userId } = useParams<{ userId: string }>();
  const voiceMessages = useAdminAllVoiceMessages();
  const user = useGetAdminUser(userId);
  const fromVoiceMessages = useVoiceMessageFrom(user.id);
  const topics = useUserTopics(userId);

  const lineUser =
    useQuery(
      ["line", userId],
      async () => {
        try {
          const res = await adminApi.getLineUser(user.lineId!);
          return res.data;
        } catch (e) {
          return null;
        }
      },
      { enabled: !!user.lineId }
    ).data ?? null;

  const toVoiceMessages = voiceMessages.filter((a) => a.listenerId === user.id);

  const userVoiceMessages = [
    ...toVoiceMessages,
    ...fromVoiceMessages,
  ].sort((a, b) => compareString(a.requestedAt, b.requestedAt));

  return (
    <AppLayout2 noVerticalMargin>
      <Section colored>
        <VerticalList align="normal">
          <AdminUserInfo lineUser={lineUser} user={user} topics={topics} />
          <AdminUserRequestSummaryInfo user={user} />
        </VerticalList>
      </Section>

      <Section colored2>
        <AdminUserMessages user={user} />
      </Section>

      <Section colored>
        <VerticalList>
          {userVoiceMessages.map((v) =>
            v.castId === user.id ? (
              <VoiceMessageFrom key={v.id} voiceMessage={v} />
            ) : (
              <VoiceMessageTo key={v.id} voiceMessage={v} />
            )
          )}
        </VerticalList>
      </Section>
    </AppLayout2>
  );
};
