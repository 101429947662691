import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from "react-query";
import {
  getUserStatus,
  getUserStatusLineFollow,
  MyUser,
  postUserSignOutAs,
} from "../api/user";
import { updateUserFeatures } from "./feature";
import { MyUser2, PersonalityId, userApi } from "../api/api2";
import { OtherUser, PutMyProfile } from "../../api";
import { AxiosResponse } from "axios";
import * as Sentry from "@sentry/react";

export function useSignOutAsMutation(): UseMutationResult<
  { data: any; kind: string },
  unknown,
  void
> {
  const invalidate = useInvalidateUserStatus();

  return useMutation(async () => await postUserSignOutAs(), {
    onSuccess: invalidate,
  });
}

/**
 * 副作用として、 feature toggle を更新する
 * @deprecated
 */
export function useUserStatus(): MyUser {
  return useQuery(
    ["user", "status"],
    async () => (await getUserStatus()).data,
    // ポイントなどあるので頻度高く更新
    {
      staleTime: 10,
      refetchOnMount: true,
      onSuccess(data) {
        updateUserFeatures(data.features);
        Sentry.setUser({
          id: data.id,
          username: data.name,
        });
      },
    }
  ).data!;
}

/**
 * 副作用として、 feature toggle を更新する
 */
export function useMe(): MyUser2 {
  return useQuery(
    ["user", "status"],
    async () => (await userApi.getUserStatus()).data,
    // ポイントなどあるので頻度高く更新
    {
      staleTime: 10,
      refetchOnMount: true,
      onSuccess(data) {
        updateUserFeatures(data.features as any);
        Sentry.setUser({
          id: data.id,
          username: data.name,
        });
      },
    }
  ).data!;
}

export function useUserStatusLineFollow(): boolean {
  return useQuery(
    ["user", "status", "line"],
    async () => (await getUserStatusLineFollow()).kind === "ok",
    {
      staleTime: 0,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
    }
  ).data!;
}

export function usePutUserStatus(): UseMutationResult<
  AxiosResponse<void>,
  unknown,
  PutMyProfile
> {
  const invalidate = useInvalidateUserStatus();

  return useMutation(
    async (p: PutMyProfile) => await userApi.putUserStatus(p),
    {
      onSuccess: invalidate,
    }
  );
}

export function useInvalidateUserStatus() {
  const queryClient = useQueryClient();
  return async () => {
    await queryClient.invalidateQueries(["user", "status"]);
  };
}

export function useOtherUser(personalityId: PersonalityId): OtherUser {
  return useQuery(
    ["user", personalityId],
    async () => (await userApi.getUser(personalityId)).data
  ).data!;
}

export function useSystemUser() {
  return useOtherUser(process.env.REACT_APP_SYSTEM_USER as string);
}
