import React, { FC } from "react";
import { Tag } from "antd";
import { Sex3 } from "../../common/api/api2";

interface Props {
  value?: Sex3;
}

export const PreferredGenderTag: FC<Props> = ({ value }) => {
  if (!value) {
    return <></>;
  } else if (value === "FEMALE") {
    return <Tag color="magenta">女性希望</Tag>;
  } else {
    return <Tag color="blue">男性希望</Tag>;
  }
};
