import { FC } from "react";
import { AppLayout } from "../../common/components/AppLayout";
import { LineButton } from "./LineButton/LineButton";
import { Vh100 } from "../../common/components/Vh100";
import { VerticalList } from "../../common/components/VerticalList";
import { useLogin } from "../auth";

export type SignInProps = {
  message?: string;
};

export const SignIn: FC<SignInProps> = ({ message }) => {
  const message2 = message ?? "LINE によるログインが必要です";
  const login = useLogin();

  return (
    <Vh100>
      <AppLayout>
        <VerticalList align="center">
          <div>{message2}</div>
          <LineButton onClick={login} />
        </VerticalList>
      </AppLayout>
    </Vh100>
  );
};
