import React, { FC, useCallback, useState } from "react";
import { Input, Radio } from "antd";
import { VerticalList } from "../../common/components/VerticalList";
import { useMe, usePutUserStatus } from "../../common/hooks/user";
import { useGotoIf } from "../../lib/hooks/useGotoIf";
import { Sex2 } from "../../common/api/types";
import { HelpLink } from "../../common/components/HelpLink";
import { HorizontalCenter } from "../../common/components/HorizontalCenter";
import { AppAvatar } from "./AppAvatar";
import { AppPopover } from "./AppPopover";
import { AvatarEditor } from "./AvatarEditor";
import { ImageFile, postImageFile } from "../../common/api/image";
import { HorizontalList } from "../../common/components/HorizontalList";
import { UserTagsEditor } from "./UserTagsEditor";
import styled from "styled-components";
import { OpenLineButton } from "../../common/components/OpenLineButton";
import { Link } from "../../common/components/Link";
import { useMutation } from "react-query";
import { AppButton } from "../../common/components/AppButton";
import { AppTextArea } from "../../common/components/AppTextArea";
import { getBlobFromImage } from "../../lib/image";
import { homeRoute } from "../pages/HomePage/HomePage";
import { SexTag } from "../admin/components/SexTag";

interface Props {
  defaultAvatar?: string;
}

export const ProfileEditor: FC<Props> = ({ defaultAvatar }) => {
  const myUser = useMe();
  const [sex, setSex] = useState(myUser.sex ?? "MALE");
  const [name, setName] = useState(myUser.name ?? "");
  const [message, setMessage] = useState(myUser.latestMessage ?? "");
  const putUserStatus = usePutUserStatus();
  const [tags, setTags] = useState(myUser.tags);
  const [avatarFileId, setAvatarFileId] = useState(myUser.latestAvatarFileId);
  const [tmpAvatarUrl, setTmpAvatarUrl] = useState(
    myUser.latestAvatarUrl || defaultAvatar
  );
  const [avatarEditorVisible, setAvatarEditorVisible] = useState(false);

  useGotoIf(homeRoute.makePath(), putUserStatus.isSuccess, {
    restoreLocation: true,
  });

  const saveDefaultAvatar = useCallback(async () => {
    if (!defaultAvatar) {
      throw new Error("状態不正");
    }
    const blob = await getBlobFromImage({
      url: defaultAvatar,
      width: 400,
      height: 400,
    });
    const res = (await postImageFile({ mime: blob.type, blob })).data;
    return res.id;
  }, [defaultAvatar]);

  const onSaveAvatar = useCallback((image: ImageFile) => {
    setAvatarFileId(image.id);
    setTmpAvatarUrl(image.url);
    setAvatarEditorVisible(false);
  }, []);

  const save = useMutation(async () => {
    let avatarFileId2 = avatarFileId;
    if (!avatarFileId2 && defaultAvatar) {
      avatarFileId2 = await saveDefaultAvatar();
      setAvatarFileId(avatarFileId2);
    }

    await putUserStatus.mutateAsync({
      name,
      sex: sex as any,
      tags,
      message,
      avatarFileId: avatarFileId2,
    });
  });

  return (
    <>
      <VerticalList align="stretch" margin={32}>
        <VerticalList align="center" margin={4}>
          <AppAvatar src={tmpAvatarUrl} />
          {myUser.isAvatarCensoring && <Notice>審査中</Notice>}
          <AppPopover
            visible={avatarEditorVisible}
            onVisibleChange={setAvatarEditorVisible}
            content={<AvatarEditor onSave={onSaveAvatar} />}
          >
            <Link>変更</Link>
          </AppPopover>
        </VerticalList>

        <div>
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="お名前 (相手に表示されます)"
            style={{ fontSize: 16 }}
          />
        </div>
        <div>
          <HorizontalCenter>
            {!myUser.sex && (
              <Radio.Group
                options={[
                  { label: "男性", value: "MALE" as Sex2 },
                  { label: "女性", value: "FEMALE" as Sex2 },
                ]}
                onChange={(e) => setSex(e.target.value)}
                value={sex}
                disabled={!!myUser.sex}
                optionType="button"
              />
            )}
            {!!myUser.sex && (
              <>
                <SexTag value={sex as any} />
                <HelpLink
                  help={
                    <VerticalList align="center">
                      <div>性別を変更したい場合はLINEにご連絡ください</div>
                      <div>
                        <OpenLineButton />
                      </div>
                    </VerticalList>
                  }
                >
                  性別を変更したい場合
                </HelpLink>
              </>
            )}
          </HorizontalCenter>
        </div>
        <div>
          <div>今のあなたに当てはまるものを選んでね</div>
          <UserTagsEditor value={tags} onChange={setTags} />
        </div>
        <div>
          <AppTextArea
            rows={5}
            placeholder="自己紹介や伝えたいこと"
            value={message}
            onChange={setMessage}
          />
          <HorizontalList justifyContent="space-between">
            {myUser.isMessageCensoring ? <Notice>審査中</Notice> : <div />}
            <HelpLink
              help={
                <div>
                  個人情報や外部サイトのリンク、読んだ人を不快にする文章は記載しないでください。
                  自己紹介文は審査の後に公開されます
                </div>
              }
            >
              注意
            </HelpLink>
          </HorizontalList>
        </div>

        <AppButton
          type="primary"
          disabled={name.length === 0}
          onClick={save.mutate}
          loading={save.isLoading}
        >
          設定
        </AppButton>
      </VerticalList>
    </>
  );
};

const Notice = styled.span`
  color: red;
  font-weight: bold;
`;
