import React, { FC } from "react";

import { AdminVoiceMessage } from "../../../../common/api/types";
import { DateTime } from "../../../../common/components/DateTime";
import { Card, Divider } from "antd";
import { RyNameOrRandom } from "../../components/ByNameOrRandom";
import { VoiceMessageStateTag } from "../../components/VoiceMessageStateTag";
import { Small } from "../../../../common/components/Small";
import { Pre } from "../../../../common/components/Pre";
import { ToClipboard } from "../../../../common/components/ToClipboard";
import { AdminVoiceMessageControls } from "../../components/AdminVoiceMessageControls";
import { SexTag } from "../../components/SexTag";
import { useAdminCast, useAdminCasts } from "../../hooks/useAdminCasts";
import { VoiceMessagePlayer } from "../../components/VoiceMessagePlayer";
import { UserLink } from "../../components/UserLink";

interface Props {
  voiceMessage: AdminVoiceMessage;
}

export const VoiceMessageTo: FC<Props> = ({ voiceMessage }) => {
  const casts = useAdminCasts();
  const cast = useAdminCast(voiceMessage.castId);

  return (
    <Card
      style={{
        width: "90%",
        alignSelf: "flex-start",
      }}
      title={
        <>
          <VoiceMessageStateTag state={voiceMessage.state} />
          {voiceMessage.request.name}
          <br />
          <div>
            <Small>
              <ToClipboard data={voiceMessage.id} />
            </Small>
          </div>
          <div>
            <Small>{voiceMessage.request.talkType}</Small>
          </div>
          <div>
            <Small>
              To <SexTag value={voiceMessage.request.targetSex} />
            </Small>
          </div>
          <div>
            <RyNameOrRandom cast={voiceMessage.request.cast ?? null} />
          </div>
        </>
      }
    >
      {voiceMessage.note && (
        <>
          <Pre>{voiceMessage.note}</Pre>
          <Divider />
        </>
      )}

      <Pre>
        <DateTime value={voiceMessage.requestedAt} />
      </Pre>
      <Pre>{voiceMessage.request.request}</Pre>
      <Pre>{voiceMessage.request.situation}</Pre>
      <Pre>{voiceMessage.request.message}</Pre>

      {voiceMessage.fileName && (
        <div>
          <Divider />
          <div>
            <DateTime value={voiceMessage.sentAt} />
          </div>
          <div>
            キャスト: <UserLink user={cast} />
          </div>
          <div>
            <VoiceMessagePlayer url={voiceMessage.url} />
          </div>
        </div>
      )}

      {voiceMessage.firstListenedAt && (
        <>
          <Divider />
          <DateTime value={voiceMessage.firstListenedAt} />
          <div>初回視聴</div>
        </>
      )}

      {voiceMessage.thanks && (
        <>
          <Divider />
          <DateTime value={voiceMessage.thanks.thankedAt} />
          <Pre>{voiceMessage.thanks?.message}</Pre>
        </>
      )}

      <AdminVoiceMessageControls voiceMessage={voiceMessage} casts={casts} />
    </Card>
  );
};
