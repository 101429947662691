import React, { FC, useCallback } from "react";
import { Slider } from "antd";

export type VolumeSliderProps = {
  value: number;
  onChange: (volume: number) => void;
};

export const VolumeSlider: FC<VolumeSliderProps> = ({ value, onChange }) => {
  const setVolume = useCallback(
    (value) => {
      onChange(value / 100);
    },
    [onChange]
  );

  return <Slider min={0} max={100} onChange={setVolume} value={value * 100} />;
};
