import React, { FC } from "react";
import { VerticalList } from "../../common/components/VerticalList";
import { RecordingContentHelp } from "./help/RecordingContentHelp";
import { RecordingMethod2Help } from "./help/RecordingMethod2Help";
import { RecordingMethod1Help } from "./help/RecordingMethod1Help";
import { RecordingInquiryHelp } from "./help/RecordingInquiryHelp";
import { RecordingNote } from "./help/RecordingNote";
import { RequestId } from "../../common/api/types";
import { Divider } from "antd";
import { MessageTypeEnum, OtherUser } from "../../api";
import { UploadVoiceUiLink } from "./UploadVoiceUiLink";

interface Props {
  requestId: RequestId;
  other: OtherUser;
}

export const Recording: FC<Props> = ({ requestId, other }) => {
  return (
    <>
      <VerticalList align="center">
        <RecordingMethod2Help />
        <RecordingMethod1Help />
        <UploadVoiceUiLink
          targetUser={other}
          messageType={MessageTypeEnum.Response}
          requestId={requestId}
        />

        <Divider />
        <RecordingContentHelp />
        <RecordingInquiryHelp />
      </VerticalList>
      <RecordingNote />
    </>
  );
};
