import React, { FC } from "react";
import { PersonalityId } from "../../common/api/api2";
import { Right } from "../../common/components/Right";
import { Link } from "../../common/components/Link";
import { talkRoute } from "../pages/TalkPage/TalkPage";

interface Props {
  personalityId: PersonalityId;
}

/**
 * トークリンクを配置するエリア
 */
export const TalkLinkBox: FC<Props> = ({ personalityId }) => {
  return (
    <Right>
      <Link to={talkRoute.makePath(personalityId)}>トークへ</Link>
    </Right>
  );
};
