export function compareString(a?: string, b?: string): number {
  if (!a) {
    return 1;
  }
  if (!b) {
    return -1;
  }
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }

  return 0;
}
