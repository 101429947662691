import React, { FC } from "react";

import { Button, Popconfirm } from "antd";
import { useMutation } from "react-query";
import { AdminVoiceMessage } from "../../../common/api/types";
import { useInvalidateRequests } from "../hooks/request";
import { deleteAdminVoiceMessage } from "../../../common/api/admin";

type Props = {
  voiceMessage: AdminVoiceMessage;
};

export const DeleteButton: FC<Props> = ({ voiceMessage }) => {
  const invalidate = useInvalidateRequests();

  const deleteVoiceMessage = useMutation(
    async () => await deleteAdminVoiceMessage(voiceMessage.id),
    {
      onSuccess: invalidate,
    }
  );

  return (
    <>
      {voiceMessage.state !== "DELETED" && (
        <Popconfirm
          title="削除してもよろしいですか"
          onConfirm={() => deleteVoiceMessage.mutate()}
          okText="はい"
          cancelText="いいえ"
        >
          <Button
            loading={deleteVoiceMessage.isLoading}
            disabled={deleteVoiceMessage.isSuccess}
          >
            削除
          </Button>
        </Popconfirm>
      )}
    </>
  );
};
