/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { TopicForUser } from '../model';
/**
 * TopicApi - axios parameter creator
 * @export
 */
export const TopicApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary サービスに存在する話題の一覧を取得します
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopics: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/topics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary リクエスト用の話題の一覧を取得します
         * @param {'MALE' | 'FEMALE'} castSex 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopicsForRequest: async (castSex: 'MALE' | 'FEMALE', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'castSex' is not null or undefined
            assertParamExists('getTopicsForRequest', 'castSex', castSex)
            const localVarPath = `/topics/for-request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (castSex !== undefined) {
                localVarQueryParameter['castSex'] = castSex;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザーの話題の一覧を取得します
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserTopics: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserTopics', 'userId', userId)
            const localVarPath = `/users/{userId}/topics`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TopicApi - functional programming interface
 * @export
 */
export const TopicApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TopicApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary サービスに存在する話題の一覧を取得します
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTopics(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TopicForUser>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTopics(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary リクエスト用の話題の一覧を取得します
         * @param {'MALE' | 'FEMALE'} castSex 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTopicsForRequest(castSex: 'MALE' | 'FEMALE', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TopicForUser>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTopicsForRequest(castSex, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ユーザーの話題の一覧を取得します
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserTopics(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TopicForUser>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserTopics(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TopicApi - factory interface
 * @export
 */
export const TopicApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TopicApiFp(configuration)
    return {
        /**
         * 
         * @summary サービスに存在する話題の一覧を取得します
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopics(options?: any): AxiosPromise<Array<TopicForUser>> {
            return localVarFp.getTopics(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary リクエスト用の話題の一覧を取得します
         * @param {'MALE' | 'FEMALE'} castSex 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopicsForRequest(castSex: 'MALE' | 'FEMALE', options?: any): AxiosPromise<Array<TopicForUser>> {
            return localVarFp.getTopicsForRequest(castSex, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザーの話題の一覧を取得します
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserTopics(userId: string, options?: any): AxiosPromise<Array<TopicForUser>> {
            return localVarFp.getUserTopics(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TopicApi - object-oriented interface
 * @export
 * @class TopicApi
 * @extends {BaseAPI}
 */
export class TopicApi extends BaseAPI {
    /**
     * 
     * @summary サービスに存在する話題の一覧を取得します
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TopicApi
     */
    public getTopics(options?: any) {
        return TopicApiFp(this.configuration).getTopics(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary リクエスト用の話題の一覧を取得します
     * @param {'MALE' | 'FEMALE'} castSex 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TopicApi
     */
    public getTopicsForRequest(castSex: 'MALE' | 'FEMALE', options?: any) {
        return TopicApiFp(this.configuration).getTopicsForRequest(castSex, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザーの話題の一覧を取得します
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TopicApi
     */
    public getUserTopics(userId: string, options?: any) {
        return TopicApiFp(this.configuration).getUserTopics(userId, options).then((request) => request(this.axios, this.basePath));
    }
}
