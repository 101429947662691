import React, { FC, ReactNode, useCallback } from "react";
import { OtherUser } from "../../api";
import styled from "styled-components";
import { AppAvatar } from "./AppAvatar";
import { HorizontalList } from "../../common/components/HorizontalList";
import { DateView } from "../../common/components/DateView";
import { VerticalList } from "../../common/components/VerticalList";
import { Small } from "../../common/components/Small";
import { Right } from "../../common/components/Right";
import { Strong } from "../../common/components/Strong";
import { useGoto } from "../../lib/hooks/useGoto";
import { othersProfileRoute } from "../pages/OthersProfilePage/OthersProfilePage";
import { BlurredDate } from "../../common/components/BlurredDate";
import { Sex3 } from "../../common/api/api2";
import { PreferredGenderTag } from "./PreferredGenderTag";
import { UserTags } from "./UserTags";

export interface MessageFrameProps {
  user?: OtherUser;

  systemAvatar?: boolean;

  sentAt?: string;

  label?: ReactNode;

  onAvatarClick?: (user: OtherUser) => void;

  /**
   * プロフィールへのリンクを有効にする
   */
  profileLink?: boolean;
  blurredDate?: boolean;
  castGender?: Sex3 | null;
}

/**
 * 1つのメッセージを表示するための箱
 */
export const MessageFrame: FC<MessageFrameProps> = ({
  user,
  systemAvatar,
  sentAt,
  label,
  onAvatarClick,
  profileLink,
  blurredDate,
  castGender,
  children,
}) => {
  const gotoProfile = useGoto(
    othersProfileRoute.makePath(user?.personalityId ?? "dummy")
  );
  const onAvatarClick2 = useCallback(() => {
    if (profileLink) {
      gotoProfile();
    } else if (onAvatarClick && user) {
      onAvatarClick(user);
    }
  }, [gotoProfile, onAvatarClick, profileLink, user]);

  return (
    <Root>
      <VerticalList margin={0}>
        <HorizontalList margin={8}>
          <AppAvatar
            src={user?.avatarUrl}
            system={systemAvatar}
            size="small"
            onClick={onAvatarClick2}
          />
          <VerticalList align="stretch" width="100%">
            <VerticalList margin={8}>
              <HorizontalList alignItems="center">
                {!systemAvatar && <Strong>{user?.name}</Strong>}
                {label}
                <PreferredGenderTag value={castGender as any} />
              </HorizontalList>
              {user?.tags && <UserTags tags={user?.tags} />}
            </VerticalList>

            <Box>{children}</Box>
          </VerticalList>
        </HorizontalList>
        <Right>
          <Small>
            {blurredDate ? (
              <BlurredDate now={Date.now()} value={sentAt} />
            ) : (
              <DateView value={sentAt} />
            )}
          </Small>
        </Right>
      </VerticalList>
    </Root>
  );
};

const Root = styled.div`
  background-color: white;
  padding: 8px;

  border-radius: 4px 4px 4px 4px;
  box-shadow: rgb(0 0 0 / 4%) 0 1px 0;
`;

const Box = styled.div``;
