import React, { FC } from "react";

import { RequireLineSignIn } from "../../../auth/components/RequireLineSignIn";
import { usePageView } from "../../../common/hooks/usePageView";
import { AppLayout2 } from "../../../common/components/AppLayout2";
import { HeaderWithBackButton } from "../../../common/components/HeaderWithBackButton";
import { useMe } from "../../../common/hooks/user";
import { Tabs } from "antd";
import { RequestsForPublic } from "./RequestsForPublic";
import { RequestsForYou } from "./RequestsForYou";
import { useScrollToTopOnMount } from "../../../common/hooks/useScrollToTopOnMount";
import { useMessagesToMe, usePrefetchMessagesToMe } from "../../hooks/message";
import { useQuery } from "react-query";
import { messageApi, MyUser2 } from "../../../common/api/api2";
import { ApiMessage } from "../../../api";
import { track } from "../../../common/track";
import { homeRoute } from "../HomePage/HomePage";

export const requestsRoute = {
  path: "/cast/not-assigned-requests",
  makePath: () => {
    return "/cast/not-assigned-requests";
  },
};

/**
 * アサインされていないリクエストを見るためのページ
 *
 * TODO URL 変える
 */
export const RequestsPage: FC = () => {
  useScrollToTopOnMount();
  return (
    <RequireLineSignIn>
      <Impl />
    </RequireLineSignIn>
  );
};

const Impl: FC = () => {
  usePageView("cast_not_assigned_requests", true);
  usePrefetchMessagesToMe();
  const me = useMe();
  const messagesForPublic = useQuery(
    ["requests-for-public"],
    async () => (await messageApi.getMessagesToPublic()).data
  ).data!.filter((it) => it.type === "REQUEST");

  const messagesForMe = useMessagesToMe().filter(
    (it) =>
      it.type === "REQUEST" &&
      it.requestSupplement?.requestState !== "RESPONDED"
  );

  return (
    <CastNotAssignedRequestsTemplate
      me={me}
      messagesForPublic={messagesForPublic}
      messagesForMe={messagesForMe}
    />
  );
};

export interface CastNotAssignedRequestsTemplateProps {
  me: MyUser2;
  messagesForPublic: ApiMessage[];
  messagesForMe: ApiMessage[];
}

export const CastNotAssignedRequestsTemplate: FC<CastNotAssignedRequestsTemplateProps> = ({
  me,
  messagesForPublic,
  messagesForMe,
}) => {
  return (
    <AppLayout2
      noVerticalMargin
      header={
        <HeaderWithBackButton
          title="リクエストに答える"
          backPath={homeRoute.makePath()}
        />
      }
    >
      <Tabs
        centered
        onChange={(activeKey) => {
          track({ event: activeKey as any });
        }}
      >
        <Tabs.TabPane
          tab="あなたへのリクエスト"
          key="not-assigned-requests.for-you"
        >
          <RequestsForYou me={me} messages={messagesForMe} />
        </Tabs.TabPane>

        <Tabs.TabPane
          tab="みんなへのリクエスト"
          key="not-assigned-requests.for-public"
        >
          <RequestsForPublic me={me} messages={messagesForPublic} />
        </Tabs.TabPane>
      </Tabs>
    </AppLayout2>
  );
};
