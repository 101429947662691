import React, { FC } from "react";

import { TalkType } from "../api/voicemessage/api";

type TalkTypeViewProps = {
  value: TalkType;
};

export const TalkTypeView: FC<TalkTypeViewProps> = ({ value }) => {
  if (value === "POLITE") {
    return <>ていねいな口調を希望</>;
  } else {
    return <>タメ口 OK</>;
  }
};
