import React, { FC } from "react";
import { UserTag } from "../../common/api/voicemessage/api";
import { Help } from "../../common/components/Help";
import { UserTagsEditor } from "./UserTagsEditor";
import styled from "styled-components";
import { SectionTitle } from "./SectionTitle";

interface Props {
  value: UserTag[];
  onChange: (value: UserTag[]) => void;
  center?: boolean;
}

export const RequestListenerTags: FC<Props> = ({ value, onChange, center }) => {
  const Label = center ? Text : Dummy;
  return (
    <div>
      <Label>
        <SectionTitle textAlign="center">
          いまのあなたに当てはまるものは
          <Help
            help={
              "今のあなたに当てはまるものを選んでね。 詳しく教えてもらえると、メッセージも具体的になるかもしれないよ"
            }
          />
        </SectionTitle>
      </Label>
      <UserTagsEditor value={value} onChange={onChange} />
    </div>
  );
};

const Text = styled.div`
  text-align: center;
`;

const Dummy = styled.div``;
