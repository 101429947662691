import React, { FC } from "react";
import { Button } from "antd";
import { UserForAdmin } from "../../../common/api/admin";
import { useAdminSignInAsUserMutation } from "../hooks/adminUser";

interface Props {
  user: UserForAdmin;
}

export const SignInAsButton: FC<Props> = ({ user }) => {
  const signInAs = useAdminSignInAsUserMutation();

  return (
    <Button
      onClick={() => signInAs.mutate(user.id)}
      loading={signInAs.isLoading}
      disabled={signInAs.isSuccess}
    >
      LogIn as
    </Button>
  );
};
