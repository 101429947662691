import React, { FC } from "react";
import { assertIsDefined, assertIsTrue } from "../../../common/util/assert";
import { ApiMessage } from "../../../api";
import { VerticalList } from "../../../common/components/VerticalList";
import { TopicRequestContent } from "./TopicRequestContent";
import { MultiLineEllipsis } from "../../../common/components/MultiLineEllipsis";
import { TalkTypeView } from "../../../common/components/TalkTypeView";
import { Small } from "../../../common/components/Small";
import { AdminRequestInfo } from "../AdminRequestInfo";
import { MyUser2 } from "../../../common/api/api2";

interface Props {
  message: ApiMessage;
  talkLink: boolean;
  me: MyUser2;
}

/**
 * ユーザーのリクエストを表示する
 */
export const RequestMessageContent: FC<Props> = ({ message, me }) => {
  assertIsTrue(message.type === "REQUEST");
  assertIsDefined(message.requestSupplement);

  if (message.requestSupplement?.topic) {
    return (
      <VerticalList margin={4}>
        <TopicRequestContent message={message} />
        <AdminRequestInfo
          isAdmin={me.isAdmin}
          userId={message.from.personalityId}
        />
      </VerticalList>
    );
  } else {
    return (
      <div>
        <MultiLineEllipsis rows={5}>{message.text}</MultiLineEllipsis>
        <Small>
          <TalkTypeView value={message.requestSupplement.talkType} />
        </Small>
        <AdminRequestInfo
          isAdmin={me.isAdmin}
          userId={message.from.personalityId}
        />
      </div>
    );
  }
};
