import React, { FC } from "react";

import moment from "moment";

type DateTimeProps = {
  value?: string;
};

export const DateTime: FC<DateTimeProps> = ({ value }) => {
  if (!value) {
    return <></>;
  }

  const dateTime = moment(value).format("YYYY-MM-DD HH:mm:ss");

  return <>{dateTime}</>;
};
