import React, { FC } from "react";
import { ReactComponent as PublicSvg } from "../../images/public_black_24dp.svg";
import Icon from "@ant-design/icons";
import { ReactComponent as HearingSvg } from "../../images/hearing_black_24dp.svg";
import { ReactComponent as PeopleSvg } from "../../images/people_black_24dp.svg";
import { ReactComponent as MusicNoteSvg } from "../../images/music_note_black_24dp.svg";

interface Props {
  size?: number;
}

export const Public: FC<Props> = (...rest) => {
  return <Icon component={PublicSvg} {...rest} />;
};

export const Hearing: FC<Props> = (...rest) => {
  return <Icon component={HearingSvg} {...rest} />;
};

export const People: FC<Props> = (...rest) => {
  return <Icon component={PeopleSvg} {...rest} />;
};

export const MusicNote: FC<Props> = (...rest) => {
  return <Icon component={MusicNoteSvg} {...rest} />;
};
