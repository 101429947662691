/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { ApiRequestSupplement } from './api-request-supplement';
import { OtherUser } from './other-user';

/**
 * 音声の場合は、別途 API で取得が必要
 * @export
 * @interface ApiMessage
 */
export interface ApiMessage {
    /**
     * 
     * @type {string}
     * @memberof ApiMessage
     */
    id: string;
    /**
     * 
     * @type {OtherUser}
     * @memberof ApiMessage
     */
    from: OtherUser;
    /**
     * 
     * @type {OtherUser}
     * @memberof ApiMessage
     */
    to?: OtherUser;
    /**
     * 
     * @type {string}
     * @memberof ApiMessage
     */
    type: ApiMessageTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiMessage
     */
    sentAt: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMessage
     */
    expiredAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMessage
     */
    requestId?: string;
    /**
     * テキストの場合に存在する
     * @type {string}
     * @memberof ApiMessage
     */
    text?: string;
    /**
     * 送信元が特殊な呼び名を持つ場合
     * @type {string}
     * @memberof ApiMessage
     */
    fromName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMessage
     */
    targetType: ApiMessageTargetTypeEnum;
    /**
     * 
     * @type {ApiRequestSupplement}
     * @memberof ApiMessage
     */
    requestSupplement?: ApiRequestSupplement;
}

/**
    * @export
    * @enum {string}
    */
export enum ApiMessageTypeEnum {
    Request = 'REQUEST',
    RequestAddition = 'REQUEST_ADDITION',
    Response = 'RESPONSE',
    Thanks = 'THANKS',
    ThanksForThanks = 'THANKS_FOR_THANKS'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiMessageTargetTypeEnum {
    Public = 'PUBLIC',
    Nominated = 'NOMINATED',
    SemiNominated = 'SEMI_NOMINATED'
}



