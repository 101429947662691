import React, { FC } from "react";
import styled from "styled-components";
import iosLineVoiceRecording from "../../../images/ios-line-voice-recording.png";
import { Strong } from "../../../common/components/Strong";
import { HelpLink } from "../../../common/components/HelpLink";

interface Props {}

export const RecordingMethod1Help: FC<Props> = () => {
  return (
    <HelpLink help={<Help />} scroll track="help.recording-method1">
      メッセージの贈り方 (お手軽編)
    </HelpLink>
  );
};

const Help: FC = () => {
  return (
    <>
      <Strong>LINE でレコーディング</Strong>

      <p>Your Radio の公式 LINE アカウントにボイスメッセージを送ってね</p>
      <p>
        <MyImg src={iosLineVoiceRecording} />
      </p>
      <p>
        送信後、しばらく待つと、 LINE
        上で相手の一覧が表示されるので、送りたい相手を選んでね
      </p>
      <p>これで送信は完了だよ</p>
    </>
  );
};

const MyImg = styled.img`
  width: 50%;
`;
