import { useHistory, useLocation } from "react-router-dom";
import { useCallback } from "react";
import { homeRoute } from "../voicemessage/pages/HomePage/HomePage";

const SAVED_LOCATION = "saved-location";

export function useSaveLocationToRestore() {
  const location = useLocation();
  return useCallback(() => {
    console.log("ページを保存", location);
    sessionStorage.setItem(SAVED_LOCATION, JSON.stringify(location));
  }, [location]);
}

export function useRestoreLocation() {
  const history = useHistory();
  return useCallback(() => {
    const location = sessionStorage.getItem(SAVED_LOCATION);
    const location2 = location ? JSON.parse(location) : homeRoute.makePath();

    console.log("useRestoreLocation", "ページ遷移します", location2);
    sessionStorage.removeItem(SAVED_LOCATION);
    history.replace(location2);
  }, [history]);
}
