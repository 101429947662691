import React, { FC, useState } from "react";
import { Col, Divider, Row } from "antd";
import { AppPopConfirm } from "../../../common/components/AppPopConfirm";
import { useMutation } from "react-query";
import { adminApi } from "../../../common/api/api2";
import { VoiceMessageData } from "../../../api";
import { BottomSheet } from "../../../common/components/BottomSheet";
import { AppButton } from "../../../common/components/AppButton";
import { UserId } from "../../../auth/auth";
import { useAdminUsers } from "../hooks/adminUser";
import moment from "moment";
import { AppAvatar } from "../../components/AppAvatar";
import { DateTime } from "../../../common/components/DateTime";
import { sortByStringDescending } from "../../../lib/array";
import { UserLink } from "./UserLink";
import { useInvalidateRequests } from "../hooks/request";
import { isOrphanRequest } from "../../helper/user";

interface Props {
  request: VoiceMessageData;
}

/**
 * ユーザーとリクエストをリンクする
 *
 * @param user
 * @constructor
 */
export const LinkRequestAndUserControl: FC<Props> = ({ request }) => {
  if (!isOrphanRequest(request)) {
    return <></>;
  }

  return <Impl request={request} />;
};

const Impl: FC<Props> = ({ request }) => {
  const invalidate = useInvalidateRequests();
  const [visible, setVisible] = useState(false);
  const { mutate, isLoading } = useMutation(
    async (listenerId: UserId) =>
      await adminApi.postRequestListener(request.id, {
        listenerId: listenerId,
      }),
    {
      async onSuccess() {
        await invalidate();
        setVisible(false);
      },
    }
  );

  const date = moment().add(-5, "days");
  const users = sortByStringDescending(
    useAdminUsers().filter(
      (it) => !it.listenerPersonality && moment(it.createdAt).isAfter(date)
    ),
    (it) => it.createdAt!
  );

  return (
    <>
      <BottomSheet onClose={setVisible} visible={visible}>
        {users.map((it) => (
          <div key={it.id}>
            <Row align="middle">
              <Col xs={3}>
                <AppAvatar src={it.castAvatarUrl} size="small" />
              </Col>
              <Col>
                <UserLink user={it} />
              </Col>
              <Col xs={20}>
                <DateTime value={it.createdAt} />
              </Col>
              <Col xs={4}>
                <AppPopConfirm
                  title="リンクしてもよろしいですか"
                  onConfirm={() => mutate(it.id)}
                >
                  <AppButton size="small" loading={isLoading}>
                    リンク
                  </AppButton>
                </AppPopConfirm>
              </Col>
            </Row>
            <Divider />
          </div>
        ))}
      </BottomSheet>
      <AppButton size="small" onClick={() => setVisible(true)}>
        ユーザー{"&"}リクエストをリンク
      </AppButton>
    </>
  );
};
