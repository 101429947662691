import React, { FC } from "react";

import styled from "styled-components";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import logo from "../../images/logo_icon.png";

type Size = "normal" | "small";

export interface AppAvatarProps {
  src?: string | null;
  size?: Size;
  system?: boolean;
  onClick?: () => void;
}

export const AppAvatar: FC<AppAvatarProps> = ({
  src,
  size,
  system,
  onClick,
}) => {
  const size2 = size === "small" ? 32 : 60;
  const src2 = system ? logo : src ?? null;
  if (src2) {
    return (
      <MyImage
        src={src2}
        style={{
          minWidth: size2,
          minHeight: size2,
          width: size2,
          height: size2,
        }}
        onClick={onClick}
      />
    );
  } else {
    return (
      <span onClick={onClick}>
        <Avatar
          size={size2}
          icon={<UserOutlined />}
          style={{ flexShrink: 0 }}
        />
      </span>
    );
  }
};

const MyImage = styled.img`
  border: 1px solid #cccccc;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  object-fit: cover;
`;
