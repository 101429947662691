import React, { FC } from "react";

import { Link } from "../../../common/components/Link";
import { adminUserInfoRoute } from "../pages/AdminUserInfoPage/AdminUserInfoPage";
import { UserId } from "../../../auth/auth";

interface IdAndName {
  id: string;
  name?: string;
}

interface Props {
  user?: IdAndName | null | UserId;
}

export const UserLink: FC<Props> = ({ user }) => {
  if (!user) {
    return <></>;
  }

  if (typeof user === "string") {
    return <Link to={adminUserInfoRoute.makePath(user)}>{user}</Link>;
  } else {
    return (
      <Link to={adminUserInfoRoute.makePath(user.id)}>
        {user.name || user.id}
      </Link>
    );
  }
};
