import React, { FC, useState } from "react";
import { OtherFullUser } from "../../../../api";
import { Button } from "antd";
import { VerticalList } from "../../../../common/components/VerticalList";
import { BottomSheet } from "../../../../common/components/BottomSheet";
import { PostRequestAdditionView } from "../../../components/PostRequestAdditionView";
import { RequestId } from "../../../../common/api/types";

interface Props {
  requestId: RequestId;
  cast: OtherFullUser;
  numOfRequestAdditionsLeft: number;
}

/**
 * 「リクエストの補足を送ろう」
 */
export const RequestRequestAdditionContent: FC<Props> = ({
  requestId,
  cast,
  numOfRequestAdditionsLeft,
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <BottomSheet visible={visible} onClose={setVisible}>
        <PostRequestAdditionView
          requestId={requestId}
          cast={cast}
          onComplete={() => setVisible(false)}
        />
      </BottomSheet>

      <VerticalList>
        <div>
          リクエストに追加して、メッセージを送ることができるよ
          <br />
          <br />
          補足や前回のお礼など、相手がうれしいメッセージを送ってみよう
          <br />
          <br />
          (あと{numOfRequestAdditionsLeft}回送れます)
        </div>

        <Button onClick={() => setVisible(true)}>メッセージを送る</Button>
      </VerticalList>
    </>
  );
};
