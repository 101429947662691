import { useEffect, useState } from "react";
import { useAudioPlayer } from "../../../common/hooks/useAudioPlayer";

export function useBlobPlayer(blob?: Blob) {
  const [url, setUrl] = useState<string | null>(null);

  const player = useAudioPlayer({ url });

  useEffect(() => {
    if (!blob) {
      return;
    }

    console.log("useBlobPlayer: url 生成");
    const url = URL.createObjectURL(blob);
    setUrl(url);

    return () => {
      console.log("useBlobPlayer: url 解放");
      URL.revokeObjectURL(url);
    };
  }, [blob]);

  return {
    ...player,
  };
}
