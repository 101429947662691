import { useCallback, useEffect, useState } from "react";
import { useVolume } from "../util/audio";
import { useAudioWithGain } from "./useAudioWithGain";
import { musicUrl } from "../../simplelive/model";
import { useAudioContext } from "./useAudioContext";

/**
 * 音声入力 & デバイスセレクト機能
 *
 * @deprecated したい
 */
export function useAudioInput() {
  const [stream, setStream] = useState<MediaStream>();
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [messages, setMessages] = useState<string[]>([]);
  const [tracks, setTracks] = useState<MediaStreamTrack[]>([]);
  const context = useAudioContext();
  const volume = useVolume(context, stream);
  const { gain, setGain, play } = useAudioWithGain({
    src: musicUrl,
    initialGain: 1,
    context,
  });

  async function updateDeviceList() {
    const devices = await navigator.mediaDevices.enumerateDevices();
    setDevices(devices);
  }

  function log(m: string) {
    setMessages((s) => [...s, m]);
  }

  useEffect(() => {
    (async () => {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });

      setStream(stream);

      navigator.mediaDevices.addEventListener("devicechange", async (ev) => {
        await updateDeviceList();
        log(ev.type);
      });
    })();
  }, []);

  // stream 変更時
  useEffect(() => {
    if (!stream) {
      return;
    }

    console.log("stream changed");
    setTracks(stream.getAudioTracks());

    updateDeviceList().then();
  }, [stream]);

  const startAudio = useCallback(async (deviceId: string) => {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: {
        deviceId,
      },
    });
    setStream(stream);
  }, []);

  return {
    devices,
    messages,
    tracks,
    startAudio,
    volume,
    gain,
    setGain,
    play,
    stream,
  };
}
