import React, { FC, ReactNode } from "react";
import styled from "styled-components";
import { VerticalList } from "../../common/components/VerticalList";
import { primaryLightBgColor } from "../../colors";
import { Strong } from "../../common/components/Strong";

interface Props {
  icon: ReactNode;
  onClick?: () => void;
}

export const IconButton: FC<Props> = ({ icon, onClick, children }) => {
  return (
    <Button onClick={onClick}>
      <VerticalList width="100%" margin={4}>
        <Icon>{icon}</Icon>
        <div>
          <Strong>{children}</Strong>
        </div>
      </VerticalList>
    </Button>
  );
};

const Button = styled.button`
  outline: none;
  color: #000;
  margin: 0;
  padding: 4px;
  width: 100%;

  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 0;
  appearance: none;

  border-radius: 4px;

  &:active {
    background-color: ${primaryLightBgColor};
  }

  transition: background-color 0.1s;
`;

const Icon = styled.span`
  font-size: 20px;
`;
