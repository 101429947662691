import React, { FC } from "react";

import { Divider } from "antd";
import {
  AdminVoiceMessage,
  CensorResult,
  VoiceMessageId,
} from "../../../../common/api/types";
import { useMutation } from "react-query";
import { Pre } from "../../../../common/components/Pre";
import { useInvalidateRequests } from "../../hooks/request";
import { putStaffVoiceMessageThanksCensor } from "../../../../common/api/staff";
import { CensorView } from "./CensorView";

type Props = {
  voiceMessage: AdminVoiceMessage;
};

export const ThanksView: FC<Props> = ({ voiceMessage }) => {
  const invalidate = useInvalidateRequests();
  const censorThanks = useMutation(
    async (params: {
      voiceMessageId: VoiceMessageId;
      result: CensorResult;
    }) => {
      await putStaffVoiceMessageThanksCensor(params);
    },
    {
      onSuccess: invalidate,
    }
  );

  return (
    <>
      {voiceMessage.thanks && (
        <>
          <Divider />
          <Pre>{voiceMessage.thanks.message}</Pre>
          {!voiceMessage.thanks.censor && (
            <CensorView
              name="お礼内容"
              voiceMessage={voiceMessage}
              mutation={censorThanks}
            />
          )}
        </>
      )}
    </>
  );
};
