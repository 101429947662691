import React from "react";
import { Main } from "./Main";
import { ErrorBoundary } from "react-error-boundary";
import { FatalError } from "./FatalError";

export function SimpleLiveHostPage() {
  return (
    <ErrorBoundary fallbackRender={({ error }) => <FatalError error={error} />}>
      <Main />
    </ErrorBoundary>
  );
}
