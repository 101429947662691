import React, { FC } from "react";
import { Card, Tag } from "antd";
import { Small } from "../../../../common/components/Small";
import { ToClipboard } from "../../../../common/components/ToClipboard";
import { AdminLineUser, UserForAdmin } from "../../../../common/api/admin";
import styled from "styled-components";
import { DateTime } from "../../../../common/components/DateTime";
import { SignInAsButton } from "../../components/SignInAsButton";
import { HorizontalList } from "../../../../common/components/HorizontalList";
import { VerticalList } from "../../../../common/components/VerticalList";
import { AppAvatar } from "../../../components/AppAvatar";
import { Pre } from "../../../../common/components/Pre";
import { UserTags } from "../../../components/UserTags";
import { TopicForUser } from "../../../../api";
import { TopicList } from "../../../components/TopicList";
import { UserLink } from "../../components/UserLink";

type AdminUserInfoProps = {
  user: UserForAdmin;
  lineUser: AdminLineUser | null;
  topics: TopicForUser[];
};

export const AdminUserInfo: FC<AdminUserInfoProps> = ({
  user,
  lineUser,
  topics,
}) => {
  return (
    <Card title={<span>{user.name}</span>}>
      <VerticalList>
        <div>
          <Small>
            登録日: <DateTime value={user.createdAt} />
          </Small>
        </div>
        {user.isDisabled && (
          <div>
            <Tag color="red">無効</Tag>
          </div>
        )}
        <div>
          <div>
            <Small>
              ID: <ToClipboard data={user.id} />
            </Small>
          </div>

          <div>
            <Small>
              LINE: <ToClipboard data={user.lineId} />
            </Small>
          </div>

          {user.hasDoublePersonality && (
            <div>
              <Small>
                Listener ID: <UserLink user={user.listenerId} />
              </Small>
            </div>
          )}

          {user.mainUserId && (
            <div>
              <Small>
                Main user ID: <UserLink user={user.mainUserId} />
              </Small>
            </div>
          )}
        </div>

        {lineUser ? (
          <>
            <div>{lineUser.displayName}</div>
            <div>{lineUser.statusMessage}</div>
            <div>
              <MyAvatar src={lineUser.pictureUrl} alt="" />
            </div>
          </>
        ) : (
          <div>
            <Tag color="red">LINE ユーザー不明</Tag>
          </div>
        )}

        <HorizontalList>
          <div>
            キャスト
            <AppAvatar src={user.castAvatarUrl} />
          </div>
          <div>
            リスナー
            <AppAvatar src={user.listenerAvatarUrl} />
          </div>
        </HorizontalList>
        <Pre>{user.message}</Pre>
        <UserTags tags={Array.from(user.tags)} />

        <TopicList topics={topics} />

        <HorizontalList>
          {((user.features as any) as string[]).map((it) => (
            <span>{it}</span>
          ))}
        </HorizontalList>

        <div>
          <SignInAsButton user={user} />
        </div>
      </VerticalList>
    </Card>
  );
};

const MyAvatar = styled.img`
  width: 150px;
  height: 150px;
`;
