import React, { FC } from "react";
import { ApiPointLog } from "../../../../api";
import { MyUser2 } from "../../../../common/api/api2";
import { PointIcon } from "../../../components/PointIcon";

interface Props {
  me: MyUser2;
  log: ApiPointLog;
}

export const PointMessageContent: FC<Props> = () => {
  return (
    <div>
      <PointIcon />
    </div>
  );
};
