import React, { FC } from "react";
import { ApiMessage } from "../../../api";
import { MyUser2 } from "../../../common/api/api2";
import styled from "styled-components";
import { MusicNote } from "../../../common/components/icons";

interface Props {
  me: MyUser2;
  message: ApiMessage;
}

/**
 * メッセージの種類「お礼」等を表示する
 * @param me
 * @param message
 * @constructor
 */
export const MessageTypeTag: FC<Props> = ({ me, message }) => {
  if (message.text) {
    switch (message.type) {
      case "REQUEST":
        switch (message.targetType) {
          case "NOMINATED":
            if (message.from.id === me.id) {
              return <Tag>指名リクエスト</Tag>;
            } else {
              return <Tag>あなた指名のリクエスト</Tag>;
            }
          case "SEMI_NOMINATED":
            if (message.from.id === me.id) {
              return <Tag>話題へのリクエスト</Tag>;
            } else {
              return <Tag>あなたの話題へのリクエスト</Tag>;
            }
          case "PUBLIC":
            if (message.requestSupplement?.requestState === "ASSIGNED") {
              return (
                <Tag>
                  みんなへのリクエスト
                  <br />
                  (あなたからの返信待ち)
                </Tag>
              );
            } else {
              return <Tag>みんなへのリクエスト</Tag>;
            }
          default:
            return <Tag>エラー</Tag>;
        }
      case "REQUEST_ADDITION":
        return <Tag>リクエストへの補足</Tag>;
      case "THANKS":
        return <Tag>お礼</Tag>;
      default:
        return <></>;
    }
  } else {
    switch (message.type) {
      case "RESPONSE":
        return (
          <Tag>
            <MusicNote /> 声のメッセージ
          </Tag>
        );
      case "THANKS":
        return (
          <Tag>
            <MusicNote /> 声のお礼
          </Tag>
        );
      default:
        return <></>;
    }
  }
};

const Tag = styled.div`
  font-weight: 600;
  font-size: 10px;
  color: #999;
`;
