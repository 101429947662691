import React, { FC } from "react";
import { message } from "antd";
import { postCastVoiceMessageAssignToMe } from "../../common/api/cast";
import { useHistory } from "react-router-dom";
import { AppPopConfirm } from "../../common/components/AppPopConfirm";
import { useMutation } from "react-query";
import { RequestId, VoiceMessageId } from "../../common/api/types";
import { useInvalidateRequests } from "../admin/hooks/request";
import { track } from "../../common/track";
import { AudioOutlined } from "@ant-design/icons";
import { AppButton } from "../../common/components/AppButton";
import { UserId } from "../../auth/auth";
import { talkRoute } from "../pages/TalkPage/TalkPage";

interface Props {
  requestId: RequestId;
  listenerId: UserId;
}

/**
 * 公開リクエストを自分にアサインする
 */
export const AssignToMeButton: FC<Props> = ({ requestId, listenerId }) => {
  const history = useHistory();
  const invalidate = useInvalidateRequests();

  const assignToMe = useMutation(
    async (voiceMessageId: VoiceMessageId) => {
      const res = await postCastVoiceMessageAssignToMe(voiceMessageId);
      if (res.kind === "conflict") {
        throw new Error("すでにアサインされています");
      }
    },
    {
      onSuccess: async () => {
        await invalidate();
        history.push(talkRoute.makePath(listenerId));
      },
      onError: (error: any) => {
        message.error(error?.message).then();
      },
    }
  );

  return (
    <AppPopConfirm
      title={`このリクエストにメッセージを送りますか`}
      onConfirm={() => assignToMe.mutate(requestId)}
      onCancel={() =>
        track({
          event: "voice-message.assign.cancel",
          data: requestId,
        })
      }
    >
      <AppButton
        type="primary"
        fullWidth
        loading={assignToMe.isLoading}
        disabled={assignToMe.isSuccess}
        icon={<AudioOutlined />}
      >
        声を贈る
      </AppButton>
    </AppPopConfirm>
  );
};
