import React, { FC, useEffect, useState } from "react";
import { Button } from "antd";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { State } from "./model/setup";
import { NoteBox } from "../../common/components/NoteBox";
import { Strong } from "../../common/components/Strong";
import { VerticalList } from "../../common/components/VerticalList";

export type ReadyProps = {
  play: () => void;
  subState: State;
};

export const Ready: FC<ReadyProps> = observer(({ play, subState }) => {
  const [showNote, setShowNote] = useState(false);
  useEffect(() => {
    const id = setInterval(() => {
      setShowNote(true);
    }, 5000);
    return () => clearInterval(id);
  }, []);

  return (
    <CenterDescriptionBox>
      <VerticalList align="center">
        <div>準備ができました</div>
        <Button
          onClick={() => play()}
          type="primary"
          loading={subState === "will-play"}
        >
          はじめる
        </Button>

        <div style={{ visibility: showNote ? "visible" : "hidden" }}>
          <NoteBox>
            iPhone をご利用中で、グルグルしたまま進まない場合、
            <Strong>"Safari を利用する"</Strong>、
            <Strong>"iOS のバージョンをあげる"</Strong>
            等の対策を行うと、改善することがあります。
          </NoteBox>
        </div>
      </VerticalList>
    </CenterDescriptionBox>
  );
});

const margin = "20px";

const CenterDescriptionBox = styled.div`
  margin: 0 ${margin};
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
