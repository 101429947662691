/**
 * 機能のトグル
 *
 *  - ユーザーに設定されている場合:
 *    user/status 取得ごとに状態は更新される
 *
 *  - ローカルに設定されている場合:
 *    一度設定すると再設定するまで更新されない
 */
import { FeatureName } from "../api/user";

const LOCAL_FEATURES = "local-features";

const USER_FEATURES = "user-features";

export function updateUserFeatures(featureNames: FeatureName[]) {
  localStorage.setItem(USER_FEATURES, JSON.stringify(featureNames));
}

export function updateLocalFeatures(featureNames: FeatureName[]) {
  localStorage.setItem(LOCAL_FEATURES, JSON.stringify(featureNames));
}

function getUserFeatures() {
  const a = localStorage.getItem(USER_FEATURES);
  if (!a) return [];
  return JSON.parse(a) as FeatureName[];
}

export function getLocalFeatures() {
  const a = localStorage.getItem(LOCAL_FEATURES);
  if (!a) return [];
  return JSON.parse(a) as FeatureName[];
}

export function setLocalFeatures(features: FeatureName[]) {
  localStorage.setItem(LOCAL_FEATURES, JSON.stringify(features));
}

/**
 * 最後に useUserStatus() した際の情報を利用する
 */
export function hasFeature(feature: FeatureName) {
  return (
    getLocalFeatures().indexOf(feature) !== -1 ||
    getUserFeatures().indexOf(feature) !== -1
  );
}

export function useFeatureToggle(feature: FeatureName): boolean {
  return hasFeature(feature);
}
