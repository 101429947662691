import React from "react";
import { RequireLineSignIn } from "../auth/components/RequireLineSignIn";
import { Link } from "../common/components/Link";
import { devAuthRoute } from "./AuthPage";

export function RequireLineSignInPage() {
  return (
    <RequireLineSignIn message="このページを表示するには LINE によるログインが必要です">
      <div>OK</div>
      <div>
        <Link to={devAuthRoute.makePath()}>認証確認</Link>
      </div>
    </RequireLineSignIn>
  );
}
