/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Listener } from './listener';
import { Thanks } from './thanks';
import { VoiceMessageRequest } from './voice-message-request';

/**
 * 
 * @export
 * @interface VoiceMessageData
 */
export interface VoiceMessageData {
    /**
     * 
     * @type {string}
     * @memberof VoiceMessageData
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof VoiceMessageData
     */
    state: VoiceMessageDataStateEnum;
    /**
     * 
     * @type {string}
     * @memberof VoiceMessageData
     */
    listenerId?: string;
    /**
     * 
     * @type {string}
     * @memberof VoiceMessageData
     */
    tmpLineUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof VoiceMessageData
     */
    requestedAt?: string;
    /**
     * 
     * @type {VoiceMessageRequest}
     * @memberof VoiceMessageData
     */
    request: VoiceMessageRequest;
    /**
     * 
     * @type {string}
     * @memberof VoiceMessageData
     */
    castId?: string;
    /**
     * 
     * @type {string}
     * @memberof VoiceMessageData
     */
    fileName?: string;
    /**
     * 
     * @type {string}
     * @memberof VoiceMessageData
     */
    sentAt?: string;
    /**
     * 
     * @type {string}
     * @memberof VoiceMessageData
     */
    firstListenedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof VoiceMessageData
     */
    expiredAt?: string;
    /**
     * 
     * @type {string}
     * @memberof VoiceMessageData
     */
    note?: string;
    /**
     * 
     * @type {Thanks}
     * @memberof VoiceMessageData
     */
    thanks?: Thanks;
    /**
     * 
     * @type {number}
     * @memberof VoiceMessageData
     */
    priority: number;
    /**
     * 
     * @type {string}
     * @memberof VoiceMessageData
     */
    recommendedCastId?: string;
    /**
     * 
     * @type {string}
     * @memberof VoiceMessageData
     */
    tag?: string;
    /**
     * 
     * @type {Listener}
     * @memberof VoiceMessageData
     */
    listener?: Listener;
    /**
     * 
     * @type {string}
     * @memberof VoiceMessageData
     */
    assignedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof VoiceMessageData
     */
    semiNominatedCastId?: string;
    /**
     * 
     * @type {string}
     * @memberof VoiceMessageData
     */
    originalRequestId?: string;
    /**
     * 
     * @type {string}
     * @memberof VoiceMessageData
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof VoiceMessageData
     */
    recordedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof VoiceMessageData
     */
    audioFileId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof VoiceMessageData
     */
    canThanks: boolean;
    /**
     * 
     * @type {string}
     * @memberof VoiceMessageData
     */
    requestTargetType: VoiceMessageDataRequestTargetTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof VoiceMessageData
     */
    hasValidVoiceMessage: boolean;
    /**
     * 
     * @type {Thanks}
     * @memberof VoiceMessageData
     */
    censoredThanks?: Thanks;
}

/**
    * @export
    * @enum {string}
    */
export enum VoiceMessageDataStateEnum {
    Requested = 'REQUESTED',
    RequestedCensorOk = 'REQUESTED_CENSOR_OK',
    RequestedCensorNg = 'REQUESTED_CENSOR_NG',
    Recorded = 'RECORDED',
    RecordedCensorNg = 'RECORDED_CENSOR_NG',
    Sent = 'SENT',
    Ignored = 'IGNORED',
    Deleted = 'DELETED',
    Duplicated = 'DUPLICATED'
}
/**
    * @export
    * @enum {string}
    */
export enum VoiceMessageDataRequestTargetTypeEnum {
    Public = 'PUBLIC',
    Nominated = 'NOMINATED',
    SemiNominated = 'SEMI_NOMINATED'
}



