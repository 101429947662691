import React, { FC } from "react";

import { Layout } from "antd";
import { BackButton } from "./BackButton";
import styled from "styled-components";
import { borderColor } from "../../colors";

export type HeaderWithBackButtonProps = {
  title: string;
  backPath?: string;
};

export const HeaderWithBackButton: FC<HeaderWithBackButtonProps> = ({
  title,
  backPath,
}) => {
  return (
    <MyHeader>
      <ButtonWrap>
        <BackButton backPath={backPath} />
      </ButtonWrap>
      <Title>{title}</Title>
    </MyHeader>
  );
};

const ButtonWrap = styled.div`
  position: absolute;
  left: 8px;
  top: 0;
`;

const Title = styled.div`
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
`;

const MyHeader = styled(Layout.Header)`
  background-color: #fff;
  text-align: center;
  border-bottom: 1px solid ${borderColor};
`;
