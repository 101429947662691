import React, { FC, useCallback } from "react";
import { usePageView } from "../../../common/hooks/usePageView";
import { Section } from "../../../common/components/Section";
import { AppLayout2 } from "../../../common/components/AppLayout2";
import { useMe, useSystemUser } from "../../../common/hooks/user";
import { VerticalList } from "../../../common/components/VerticalList";
import { RequireLineSignIn } from "../../../auth/components/RequireLineSignIn";
import { TopNavigation } from "./TopNavigation";
import { SetProfile } from "./message/SetProfile";
import { MessageFrame } from "../../components/MessageFrame";
import { Vision } from "./message/Vision";
import { SetTopic } from "./message/SetTopic";
import {
  useMyTopics,
  usePrefetchMyTopics,
} from "../../../common/api/hooks/voiceMessage";
import { useMessagesToMe, usePrefetchMessagesToMe } from "../../hooks/message";
import { ApiMessage, OtherUser } from "../../../api";
import { compareString } from "../../../common/util/string";
import { UserMessage } from "./message/UserMessage";
import { RecordMessage } from "./message/RecordMessage";
import { useHistory } from "react-router-dom";
import { othersProfileRoute } from "../OthersProfilePage/OthersProfilePage";
import { HeaderWithAvatar } from "../../../common/components/HeaderWithAvatar";
import { MyUser2 } from "../../../common/api/api2";
import moment from "moment";
import { MessageTypeTag } from "../../components/message/MessageTypeTag";
import { AppHeadroom } from "../../../common/components/AppHeadroom";

export const homeRoute = {
  path: "/home",
  makePath: () => {
    return "/home";
  },
};

export const HomePage: FC = () => {
  usePageView("home", true);

  return (
    <RequireLineSignIn>
      <Impl />
    </RequireLineSignIn>
  );
};

const Impl: FC = () => {
  usePrefetchMessagesToMe();
  usePrefetchMyTopics();
  const me = useMe();
  const topics = useMyTopics();
  const messages = useMessagesToMe();
  const assignedRequests = messages.filter(
    (it) =>
      it.type === "REQUEST" && it.requestSupplement?.requestState === "ASSIGNED"
  );
  const systemUser = useSystemUser();

  return (
    <HomeTemplate
      me={me}
      numOfMyTopics={topics.length}
      numOfAssignedActiveRequests={assignedRequests.length}
      messages={messages}
      systemUser={systemUser}
    />
  );
};

export interface HomeTemplateProps {
  me: MyUser2;
  numOfMyTopics: number;
  numOfAssignedActiveRequests: number;
  messages: ApiMessage[];
  systemUser: OtherUser;
}

export const HomeTemplate: FC<HomeTemplateProps> = ({
  me,
  numOfMyTopics,
  numOfAssignedActiveRequests,
  messages,
  systemUser,
}) => {
  const messages2 = messages
    .filter(
      (it) =>
        it.type === "RESPONSE" || it.type === "THANKS" || it.type === "REQUEST"
    )
    .sort((a, b) => compareString(b.sentAt, a.sentAt));

  const now = moment().toISOString();
  const history = useHistory();
  const onAvatarClick = useCallback(
    (user: OtherUser) => {
      history.push(othersProfileRoute.makePath(user.id));
    },
    [history]
  );

  return (
    <AppLayout2
      noVerticalMargin
      header={
        <AppHeadroom>
          <HeaderWithAvatar />
          <TopNavigation />
        </AppHeadroom>
      }
    >
      <Section colored>
        <VerticalList>
          {!me.hasProfile && (
            <MessageFrame
              sentAt={now}
              user={systemUser}
              onAvatarClick={onAvatarClick}
            >
              <SetProfile />
            </MessageFrame>
          )}

          {numOfAssignedActiveRequests >= 1 && (
            <MessageFrame
              sentAt={now}
              user={systemUser}
              onAvatarClick={onAvatarClick}
            >
              <RecordMessage
                me={me}
                numOfRequests={numOfAssignedActiveRequests}
              />
            </MessageFrame>
          )}

          {numOfMyTopics === 0 && (
            <MessageFrame
              sentAt={now}
              user={systemUser}
              onAvatarClick={onAvatarClick}
            >
              <SetTopic />
            </MessageFrame>
          )}

          {messages2.map((it) => (
            <MessageFrame
              key={it.id}
              user={it.from}
              sentAt={it.sentAt}
              label={<MessageTypeTag me={me} message={it} />}
              onAvatarClick={onAvatarClick}
            >
              <UserMessage message={it} me={me} />
            </MessageFrame>
          ))}

          <MessageFrame
            sentAt={me.createdAt}
            user={systemUser}
            onAvatarClick={onAvatarClick}
          >
            <Vision />
          </MessageFrame>
        </VerticalList>
      </Section>
    </AppLayout2>
  );
};
