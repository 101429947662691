/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface RequestSupplement
 */
export interface RequestSupplement {
    /**
     * 
     * @type {string}
     * @memberof RequestSupplement
     */
    topicId?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestSupplement
     */
    requestState?: RequestSupplementRequestStateEnum;
    /**
     * 
     * @type {string}
     * @memberof RequestSupplement
     */
    talkType: RequestSupplementTalkTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof RequestSupplement
     */
    castGender?: RequestSupplementCastGenderEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum RequestSupplementRequestStateEnum {
    Assigned = 'ASSIGNED',
    Responded = 'RESPONDED'
}
/**
    * @export
    * @enum {string}
    */
export enum RequestSupplementTalkTypeEnum {
    Polite = 'POLITE',
    NotPolite = 'NOT_POLITE'
}
/**
    * @export
    * @enum {string}
    */
export enum RequestSupplementCastGenderEnum {
    Male = 'MALE',
    Female = 'FEMALE'
}



