/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { MessagePayload } from './message-payload';
import { RequestSupplement } from './request-supplement';

/**
 * 
 * @export
 * @interface Message
 */
export interface Message {
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    fromUserId: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    toUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    type: MessageTypeEnum;
    /**
     * 
     * @type {MessagePayload}
     * @memberof Message
     */
    payload: MessagePayload;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    sentAt: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    censorResult?: MessageCensorResultEnum;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    expiredAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    requestId?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    fromName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Message
     */
    isLegacy: boolean;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    targetType: MessageTargetTypeEnum;
    /**
     * 
     * @type {RequestSupplement}
     * @memberof Message
     */
    requestSupplement?: RequestSupplement;
    /**
     * 
     * @type {boolean}
     * @memberof Message
     */
    publicRequestWaitingToBeRecorded: boolean;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    legacyId?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    fromUserRole: MessageFromUserRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    toUserRole: MessageToUserRoleEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Message
     */
    castToListener: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Message
     */
    listenerToCast: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Message
     */
    request: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Message
     */
    requestWaitingToBeRecorded: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum MessageTypeEnum {
    Request = 'REQUEST',
    RequestAddition = 'REQUEST_ADDITION',
    Response = 'RESPONSE',
    Thanks = 'THANKS',
    ThanksForThanks = 'THANKS_FOR_THANKS'
}
/**
    * @export
    * @enum {string}
    */
export enum MessageCensorResultEnum {
    Ok = 'OK',
    Ng = 'NG'
}
/**
    * @export
    * @enum {string}
    */
export enum MessageTargetTypeEnum {
    Public = 'PUBLIC',
    Nominated = 'NOMINATED',
    SemiNominated = 'SEMI_NOMINATED'
}
/**
    * @export
    * @enum {string}
    */
export enum MessageFromUserRoleEnum {
    Listener = 'LISTENER',
    Cast = 'CAST'
}
/**
    * @export
    * @enum {string}
    */
export enum MessageToUserRoleEnum {
    Listener = 'LISTENER',
    Cast = 'CAST'
}



