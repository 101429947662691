import { FC } from "react";
import styled from "styled-components";

export type GaugeProps = {
  value: number;
};

export const Gauge: FC<GaugeProps> = ({ value }) => {
  const num = Math.floor(value / 0.1);
  return (
    <Root>
      {[...Array(10)].map((_, i) => (
        <Item key={i} active={i < num} />
      ))}
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid red;
  //justify-content: space-between;
  height: 12px;
`;

type ItemProps = {
  active: boolean;
};
const Item = styled.div<ItemProps>`
  background-color: ${(props) => (props.active ? "#52c41a" : "white")};
  border: 1px solid ${(props) => (props.active ? "black" : "#aaa")};
  height: 100%;
  min-width: 8px;
  margin: 0 0.5px;
  flex-grow: 1;

  transition: background-color 0.1s;
`;
