import React, { FC } from "react";
import { Section } from "../../../common/components/Section";
import { Strong } from "../../../common/components/Strong";
import { Button } from "antd";
import { useGoto } from "../../../lib/hooks/useGoto";
import { castProfileEditorRoute } from "../CastProfileEditorPage/CastProfilePage";
import { castTopicRoute } from "../CastTopicEditorPage/CastTopicPage";

interface Props {}

export const RequestsForYoHelp: FC<Props> = () => {
  const goProfile = useGoto(castProfileEditorRoute.makePath(), {
    saveLocation: true,
  });

  const goTopic = useGoto(castTopicRoute.makePath(), {
    saveLocation: true,
  });

  return (
    <>
      <Section textAlign="center">
        <p>リクエストを受け取ろう！</p>
        <p>ここにはあなた宛てのリクエストが表示されるよ</p>
        <p>リクエストを受け取るために、3ステップやってみよう</p>
      </Section>

      <Section colored textAlign="center">
        <p>
          <Strong>ステップ1</Strong>
        </p>
        <p>まずはプロフィールを設定しよう！</p>
        <p>魅力的なプロフィールを設定すると、リクエストも増えるよ♪</p>
        <p>
          <Button onClick={goProfile} style={{ width: "100%" }}>
            プロフィールを設定
          </Button>
        </p>
      </Section>

      <Section textAlign="center">
        <p>
          <Strong>ステップ2</Strong>
        </p>
        <p>話したい話題を設定しよう！</p>
        <p>
          面白そうな話題を設定すると、その話題を聞きたいユーザーから声のリクエストが来るよ
        </p>

        <Button onClick={goTopic} style={{ width: "100%" }}>
          話題を設定
        </Button>
      </Section>

      <Section textAlign="center" colored>
        <p>
          <Strong>ステップ3</Strong>
        </p>
        <p>
          あとは待っていればリクエストが届くけど、せっかくだから自分からリクエストを探してみよう♪
        </p>
        <p>
          「みんなへのリクエスト」には、あなたからの声を待っているリクエストがあるよ！
        </p>
        <p>
          相手があなたのことを気に入ってくれたら、「指名リクエスト」をもらえるかも！
        </p>
      </Section>
    </>
  );
};
