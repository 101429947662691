import React, { FC } from "react";
import { usePageView } from "../../../common/hooks/usePageView";
import { AppLayout2 } from "../../../common/components/AppLayout2";
import { VerticalList } from "../../../common/components/VerticalList";
import { HeaderWithBackButton } from "../../../common/components/HeaderWithBackButton";
import { homeRoute } from "../HomePage/HomePage";

export const Registered: FC = () => {
  usePageView("requested");

  return <Template />;
};

export type TemplateProps = {};

export const Template: FC<TemplateProps> = () => {
  return (
    <AppLayout2
      header={
        <HeaderWithBackButton
          title="登録完了"
          backPath={homeRoute.makePath()}
        />
      }
    >
      <VerticalList align="center">
        <p>登録が完了しました</p>
        <p>ご協力いただきありがとうございます！</p>
        <p>
          数日中にご登録いただいている LINE
          にご連絡差し上げますので、お待ちいただけますと幸いです
        </p>
      </VerticalList>
    </AppLayout2>
  );
};
