import React, { FC, Suspense, useCallback, useState } from "react";
import { OtherUser, TopicForUser } from "../../../../api";
import { Button, Skeleton } from "antd";
import { VerticalList } from "../../../../common/components/VerticalList";
import { OthersProfile } from "../../../components/OthersProfile/OthersProfile";
import { BottomSheet } from "../../../../common/components/BottomSheet";
import { track } from "../../../../common/track";

interface Props {
  other: OtherUser;
  showRequest: (topic: TopicForUser) => void;
}

/**
 * 「プロフィールを確認してみよう」
 */
export const RequestViewProfileContent: FC<Props> = ({
  other,
  showRequest,
}) => {
  const [visible, setVisible] = useState(false);

  const showRequest2 = useCallback(
    (topic: TopicForUser) => {
      showRequest(topic);
      setVisible(false);
    },
    [showRequest]
  );

  return (
    <>
      <BottomSheet onClose={setVisible} visible={visible}>
        <Suspense fallback={<Skeleton />}>
          <OthersProfile
            personalityId={other.id}
            noTalk
            onSelectTopic={showRequest2}
          />
        </Suspense>
      </BottomSheet>

      <VerticalList>
        <div>
          {other.name}さんからメッセージをもらったよ <br />
          プロフィールを見てみよう
        </div>
        <Button
          onClick={() => {
            track({ event: "talk.profile", data2: other.id });
            setVisible(true);
          }}
        >
          プロフィールを見る
        </Button>
      </VerticalList>
    </>
  );
};
