import React, { FC } from "react";

import { NoteBox } from "../../common/components/NoteBox";

export type RequestNoteProps = {};

/**
 * 指名・公開両方に共通の注意書き
 * @constructor
 */
export const RequestNote: FC<RequestNoteProps> = () => {
  return (
    <NoteBox>
      <p>状況や内容によってはリクエストにお答えできないことがあります</p>
    </NoteBox>
  );
};
