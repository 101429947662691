import React, { FC, useState, Suspense } from "react";

import { Button, Skeleton, Spin } from "antd";
import { AdminVoiceMessage } from "../../../../common/api/types";
import { useMutation, useQuery } from "react-query";
import { UserForAdmin } from "../../../../common/api/admin";
import { UserId } from "../../../../auth/auth";
import { useInvalidateRequests } from "../../hooks/request";
import { staffApi } from "../../../../common/api/api2";
import { BottomSheet } from "../../../../common/components/BottomSheet";
import { UserLink } from "../../components/UserLink";
import { AppPopConfirm } from "../../../../common/components/AppPopConfirm";
import { HorizontalList } from "../../../../common/components/HorizontalList";
import { distinctBy } from "../../../../lib/array";

interface Props {
  request: AdminVoiceMessage;
  casts: UserForAdmin[];
}

export const TopicControls: FC<Props> = ({ ...rest }) => {
  return (
    <Suspense fallback={<Skeleton />}>
      <Impl {...rest} />
    </Suspense>
  );
};

const Impl: FC<Props> = ({ request }) => {
  const [visible, setVisible] = useState(false);

  const invalidate = useInvalidateRequests();

  const topicAndCasts = useQuery(
    ["topics-casts"],
    async () => (await staffApi.getTopicsCasts()).data
  ).data!;

  const semiNominate = useMutation(
    async (castId: UserId) => {
      await staffApi.postRequestSemiNominate(request.id, {
        castId,
      });
    },
    {
      async onSuccess() {
        await invalidate();
        setVisible(false);
      },
    }
  );

  if (!request.request.topicId) {
    return <></>;
  }

  const topicAndCasts2 = topicAndCasts.filter(
    (it) =>
      it.cast.isAdmin ||
      (it.topic.id === request.request.topicId &&
        request.request.targetSex === (it.cast.sex as any) &&
        !it.cast.isDisabled &&
        request.listenerId !== it.cast.id)
  );

  const topic = topicAndCasts2.length >= 1 ? topicAndCasts2[0].topic : null;
  const casts = distinctBy(
    topicAndCasts2.map((it) => it.cast),
    (it) => it.id
  );

  const castId = request.request.cast?.id ?? request.semiNominatedCastId;
  const cast = castId ? casts.find((it) => it.id === castId) : null;

  return (
    <>
      <BottomSheet onClose={setVisible} visible={visible} destroyOnClose>
        <Spin spinning={semiNominate.isLoading}>
          {casts.map((it) => (
            <HorizontalList key={it.id}>
              <UserLink user={it} />
              <AppPopConfirm
                title="アサインしてもよろしいですか"
                onConfirm={() => semiNominate.mutate(it.id)}
              >
                <Button>アサイン</Button>
              </AppPopConfirm>
            </HorizontalList>
          ))}
        </Spin>
      </BottomSheet>

      <div>
        <div>{topic?.title}</div>

        {castId ? (
          <UserLink user={cast} />
        ) : (
          <Button onClick={() => setVisible(true)}>
            話題へのリクエストをアサイン
          </Button>
        )}
      </div>
    </>
  );
};
