import React, { FC, useState } from "react";
import { OtherUser } from "../../../../api";
import { VerticalList } from "../../../../common/components/VerticalList";
import { BottomSheet } from "../../../../common/components/BottomSheet";
import { RequestId } from "../../../../common/api/types";
import { Recording } from "../../../components/Recording";
import { AppButton } from "../../../../common/components/AppButton";
import { AudioOutlined } from "@ant-design/icons";
import { track } from "../../../../common/track";

interface Props {
  listener: OtherUser;
  requestId: RequestId;
}

/**
 * 「収録しよう」
 */
export const RequestResponseContent: FC<Props> = ({ listener, requestId }) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <BottomSheet visible={visible} onClose={setVisible}>
        <Recording requestId={requestId} other={listener} />
      </BottomSheet>

      <VerticalList>
        <div>
          {listener.name}さんからリクエストを受け取ったよ
          <br />
          <br />
          収録しよう！
        </div>
        <AppButton
          icon={<AudioOutlined />}
          type="primary"
          onClick={() => {
            track({ event: "talk.response", data2: listener.id });
            setVisible(true);
          }}
        >
          収録する
        </AppButton>
      </VerticalList>
    </>
  );
};
