import { HttpSuccess } from "./types";
import axios from "axios";
import { apiUrl } from "./api";
import { Topic } from "./voicemessage/api";

export async function getTopics(): Promise<HttpSuccess<Topic[]>> {
  const res = await axios.get<Topic[]>(`${apiUrl}/topics`);
  console.log(res);
  return { kind: "ok", data: res.data };
}
