import { ApiMessage } from "../../api";
import React, { FC } from "react";
import { useGoto } from "../../lib/hooks/useGoto";
import { talkRoute } from "../pages/TalkPage/TalkPage";
import { TalkLinkBox } from "./TalkLinkBox";
import { AppButton } from "../../common/components/AppButton";
import { AudioOutlined } from "@ant-design/icons";

interface Props {
  request: ApiMessage;
}

/**
 * 自分宛のリクエストに対して収録するボタン
 */
export const RespondButton: FC<Props> = ({ request }) => {
  const gotoTalk = useGoto(talkRoute.makePath(request.from.personalityId));

  if (request.requestSupplement?.requestState === "RESPONDED") {
    return <TalkLinkBox personalityId={request.from.personalityId} />;
  } else {
    return (
      <AppButton
        type="primary"
        onClick={gotoTalk}
        fullWidth
        icon={<AudioOutlined />}
      >
        声を贈る
      </AppButton>
    );
  }
};
