import React, { CSSProperties, FC, ReactNode } from "react";
import { EventType } from "../api/types";
import { AppPopover } from "../../voicemessage/components/AppPopover";
import { HelpIcon } from "./HelpIcon";
import styled from "styled-components";
import { Link } from "./Link";

interface Props {
  help: ReactNode;
  track?: EventType;

  /**
   * スクロールさせるか
   */
  scroll?: boolean;
  contentWidth?: CSSProperties["width"];
}

/**
 * ヘルプをポップアップできるリンク
 */
export const HelpLink: FC<Props> = ({
  help,
  track,
  scroll,
  contentWidth,
  children,
}) => {
  const content = scroll ? <ScrollContainer>{help}</ScrollContainer> : help;

  return (
    <AppPopover content={content} track={track} contentWidth={contentWidth}>
      <Link>
        {children}
        <HelpIcon />
      </Link>
    </AppPopover>
  );
};

const ScrollContainer = styled.div`
  overflow: scroll;
  height: 45vh;
`;
