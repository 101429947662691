import axios from "axios";
import { apiUrl } from "./api";
import { HttpSuccess } from "./types";

export type AudioFileId = string;
type AudioFileStatus = "ERROR" | "PROCESSING" | "FINISHED";

export interface AudioFile {
  id: AudioFileId;
  createdAt: string;
  status: AudioFileStatus;
}

export async function postAudioFile(params: {
  blob: Blob;
  mime: string;
}): Promise<HttpSuccess<AudioFile>> {
  let formData = new FormData();

  formData.append("file", params.blob);
  formData.append("mime", params.mime);

  const res = await axios.post(`${apiUrl}/audio-files`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return { kind: "ok", data: res.data };
}

export async function getAudioFile(
  id: AudioFileId
): Promise<HttpSuccess<AudioFile>> {
  const res = await axios.get(`${apiUrl}/audio-files/${id}`);

  return { kind: "ok", data: res.data };
}
