/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiMessage } from '../model';
// @ts-ignore
import { ApiVoiceMessage } from '../model';
// @ts-ignore
import { PostMessage } from '../model';
// @ts-ignore
import { PostRequestAddition } from '../model';
// @ts-ignore
import { PostThanks } from '../model';
// @ts-ignore
import { Talk } from '../model';
/**
 * MessagingApi - axios parameter creator
 * @export
 */
export const MessagingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * semi-nominated や assigned も含む
         * @summary 自分宛のメッセージを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessagesToMe: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/@me/messages/to-me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary public 向けのメッセージを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessagesToPublic: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/@me/messages/to-public`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 特定のユーザーとのやりとりを取得する
         * @param {string} toPersonalityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTalk: async (toPersonalityId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'toPersonalityId' is not null or undefined
            assertParamExists('getTalk', 'toPersonalityId', toPersonalityId)
            const localVarPath = `/@me/talks/{toPersonalityId}`
                .replace(`{${"toPersonalityId"}}`, encodeURIComponent(String(toPersonalityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * legacy requestId は指定できない
         * @summary リスナーがボイスメッセージの情報を取得する
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVoiceMessage: async (messageId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('getVoiceMessage', 'messageId', messageId)
            const localVarPath = `/voice-messages/{messageId}`
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostMessage} postMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMessage1: async (postMessage: PostMessage, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postMessage' is not null or undefined
            assertParamExists('postMessage1', 'postMessage', postMessage)
            const localVarPath = `/messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postMessage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary リクエスト追加情報を送る
         * @param {string} requestId 
         * @param {PostRequestAddition} postRequestAddition 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRequestAddition: async (requestId: string, postRequestAddition: PostRequestAddition, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            assertParamExists('postRequestAddition', 'requestId', requestId)
            // verify required parameter 'postRequestAddition' is not null or undefined
            assertParamExists('postRequestAddition', 'postRequestAddition', postRequestAddition)
            const localVarPath = `/requests/{requestId}/additions`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postRequestAddition, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary テキストでお礼をする
         * @param {string} requestId 
         * @param {PostThanks} postThanks 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRequestThanks: async (requestId: string, postThanks: PostThanks, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            assertParamExists('postRequestThanks', 'requestId', requestId)
            // verify required parameter 'postThanks' is not null or undefined
            assertParamExists('postRequestThanks', 'postThanks', postThanks)
            const localVarPath = `/requests/{requestId}/thanks`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postThanks, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MessagingApi - functional programming interface
 * @export
 */
export const MessagingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MessagingApiAxiosParamCreator(configuration)
    return {
        /**
         * semi-nominated や assigned も含む
         * @summary 自分宛のメッセージを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessagesToMe(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiMessage>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessagesToMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary public 向けのメッセージを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessagesToPublic(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiMessage>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessagesToPublic(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 特定のユーザーとのやりとりを取得する
         * @param {string} toPersonalityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTalk(toPersonalityId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Talk>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTalk(toPersonalityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * legacy requestId は指定できない
         * @summary リスナーがボイスメッセージの情報を取得する
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVoiceMessage(messageId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiVoiceMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVoiceMessage(messageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostMessage} postMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postMessage1(postMessage: PostMessage, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postMessage1(postMessage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary リクエスト追加情報を送る
         * @param {string} requestId 
         * @param {PostRequestAddition} postRequestAddition 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRequestAddition(requestId: string, postRequestAddition: PostRequestAddition, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRequestAddition(requestId, postRequestAddition, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary テキストでお礼をする
         * @param {string} requestId 
         * @param {PostThanks} postThanks 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRequestThanks(requestId: string, postThanks: PostThanks, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRequestThanks(requestId, postThanks, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MessagingApi - factory interface
 * @export
 */
export const MessagingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MessagingApiFp(configuration)
    return {
        /**
         * semi-nominated や assigned も含む
         * @summary 自分宛のメッセージを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessagesToMe(options?: any): AxiosPromise<Array<ApiMessage>> {
            return localVarFp.getMessagesToMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary public 向けのメッセージを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessagesToPublic(options?: any): AxiosPromise<Array<ApiMessage>> {
            return localVarFp.getMessagesToPublic(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 特定のユーザーとのやりとりを取得する
         * @param {string} toPersonalityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTalk(toPersonalityId: string, options?: any): AxiosPromise<Talk> {
            return localVarFp.getTalk(toPersonalityId, options).then((request) => request(axios, basePath));
        },
        /**
         * legacy requestId は指定できない
         * @summary リスナーがボイスメッセージの情報を取得する
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVoiceMessage(messageId: string, options?: any): AxiosPromise<ApiVoiceMessage> {
            return localVarFp.getVoiceMessage(messageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostMessage} postMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMessage1(postMessage: PostMessage, options?: any): AxiosPromise<string> {
            return localVarFp.postMessage1(postMessage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary リクエスト追加情報を送る
         * @param {string} requestId 
         * @param {PostRequestAddition} postRequestAddition 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRequestAddition(requestId: string, postRequestAddition: PostRequestAddition, options?: any): AxiosPromise<void> {
            return localVarFp.postRequestAddition(requestId, postRequestAddition, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary テキストでお礼をする
         * @param {string} requestId 
         * @param {PostThanks} postThanks 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRequestThanks(requestId: string, postThanks: PostThanks, options?: any): AxiosPromise<void> {
            return localVarFp.postRequestThanks(requestId, postThanks, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MessagingApi - object-oriented interface
 * @export
 * @class MessagingApi
 * @extends {BaseAPI}
 */
export class MessagingApi extends BaseAPI {
    /**
     * semi-nominated や assigned も含む
     * @summary 自分宛のメッセージを取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagingApi
     */
    public getMessagesToMe(options?: any) {
        return MessagingApiFp(this.configuration).getMessagesToMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary public 向けのメッセージを取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagingApi
     */
    public getMessagesToPublic(options?: any) {
        return MessagingApiFp(this.configuration).getMessagesToPublic(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 特定のユーザーとのやりとりを取得する
     * @param {string} toPersonalityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagingApi
     */
    public getTalk(toPersonalityId: string, options?: any) {
        return MessagingApiFp(this.configuration).getTalk(toPersonalityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * legacy requestId は指定できない
     * @summary リスナーがボイスメッセージの情報を取得する
     * @param {string} messageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagingApi
     */
    public getVoiceMessage(messageId: string, options?: any) {
        return MessagingApiFp(this.configuration).getVoiceMessage(messageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostMessage} postMessage 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagingApi
     */
    public postMessage1(postMessage: PostMessage, options?: any) {
        return MessagingApiFp(this.configuration).postMessage1(postMessage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary リクエスト追加情報を送る
     * @param {string} requestId 
     * @param {PostRequestAddition} postRequestAddition 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagingApi
     */
    public postRequestAddition(requestId: string, postRequestAddition: PostRequestAddition, options?: any) {
        return MessagingApiFp(this.configuration).postRequestAddition(requestId, postRequestAddition, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary テキストでお礼をする
     * @param {string} requestId 
     * @param {PostThanks} postThanks 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagingApi
     */
    public postRequestThanks(requestId: string, postThanks: PostThanks, options?: any) {
        return MessagingApiFp(this.configuration).postRequestThanks(requestId, postThanks, options).then((request) => request(this.axios, this.basePath));
    }
}
