import React from "react";
import { Button } from "antd";
import { Link } from "../common/components/Link";
import { devRoutes } from "./routes";
import { AppAvatar } from "../voicemessage/components/AppAvatar";
import { useAuth, useRawUser } from "../auth/auth";
import { useUserStatusLineFollow } from "../common/hooks/user";

export const devAuthRoute = {
  path: "/dev/auth",
  makePath: () => {
    return `/dev/auth`;
  },
};

export function AuthPage() {
  console.log("aaa");
  // const [token, setToken] = useState("");
  // const user = useUserOrGuestUser();
  // const idToken = useIdToken();
  //
  // const refresh = useMutation(async () => {
  //   await refreshToken();
  // });

  const { login, logout } = useAuth();

  const user = useRawUser();

  console.log("user", user);

  return (
    <div>
      {/*<div>isAuthenticated: {isAuthenticated ? "true" : "false"}</div>*/}
      <div>front user id: {user?.name}</div>
      <AppAvatar src={user?.picture} />
      {/*<div>token: {token}</div>*/}
      <div>
        <Button onClick={() => login()}>Login</Button>
      </div>
      <div>
        <Button onClick={() => logout()}>Logout</Button>
      </div>
      <div>
        <Link to={devRoutes.authLine.makePath()}>LINE 必須</Link>
      </div>
      {user && <LoggedIn />}
    </div>
  );
}

export function LoggedIn() {
  const follow = useUserStatusLineFollow();

  return <div>{follow ? "フォロー中" : "フォローしていない"}</div>;
}
