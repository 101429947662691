import React, { FC } from "react";

import { useParams } from "react-router-dom";
import { useScrollToTopOnMount } from "../../../../common/hooks/useScrollToTopOnMount";
import { Section } from "../../../../common/components/Section";
import { AppLayout2 } from "../../../../common/components/AppLayout2";
import { VerticalList } from "../../../../common/components/VerticalList";
import {
  useGetAdminUser,
  useInvalidateAdminUsers,
} from "../../../admin/hooks/adminUser";
import { AppAvatar } from "../../../components/AppAvatar";
import { Button, Card } from "antd";
import { useMutation } from "react-query";
import { putStaffUserProfileCensor } from "../../../../common/api/staff";
import { HorizontalList } from "../../../../common/components/HorizontalList";
import { Pre } from "../../../../common/components/Pre";
import { Strong } from "../../../../common/components/Strong";

export const staffUserRoute = {
  path: "/staff/users/:userId",
  makePath(userId: string) {
    return `/staff/users/${userId}`;
  },
};

/**
 * 特定ユーザーの情報
 *
 * 目的
 * - 審査
 */
export const StaffUserPage: FC = () => {
  useScrollToTopOnMount();
  const { userId } = useParams<{ userId: string }>();
  // TODO staff
  const user = useGetAdminUser(userId);
  const invalidate = useInvalidateAdminUsers();

  const censor = useMutation(
    async () => await putStaffUserProfileCensor({ userId, result: "OK" }),
    {
      onSuccess: invalidate,
    }
  );

  return (
    <AppLayout2 noVerticalMargin>
      <Section>
        <VerticalList align="normal" margin={16}>
          <Card>
            <Strong>今</Strong>
            <HorizontalList>
              <div>
                キャスト
                <AppAvatar src={user.castAvatarUrl} />
              </div>
              <div>
                リスナー
                <AppAvatar src={user.listenerAvatarUrl} />
              </div>
            </HorizontalList>
            <Pre>{user.message}</Pre>
          </Card>
          <Card>
            <VerticalList>
              <Strong>審査待ち</Strong>
              <Pre>{user.messageForCensor}</Pre>
              {user.avatarForCensor && (
                <AppAvatar src={user.avatarForCensor?.url} />
              )}
              {(user.avatarForCensor || user.messageForCensor) && (
                <div>
                  <Button
                    onClick={() => censor.mutate()}
                    loading={censor.isLoading}
                  >
                    プロフィール審査 OK
                  </Button>
                </div>
              )}
            </VerticalList>
          </Card>
        </VerticalList>
      </Section>
    </AppLayout2>
  );
};
