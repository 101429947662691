import React, { FC } from "react";

import moment from "moment";

export type StopWatchViewProps = {
  timeMills: number;
};

export const StopWatchView: FC<StopWatchViewProps> = ({ timeMills }) => {
  const d = moment.duration(timeMills, "millisecond");
  const m = `${d.minutes()}`;
  const s = `${d.seconds()}`.padStart(2, "0");

  return (
    <span>
      {m}:{s}
    </span>
  );
};
