import React, { FC } from "react";

import { Button } from "antd";

import { useMutation } from "react-query";
import { AdminVoiceMessage } from "../../../common/api/types";
import { postAdminVoiceMessageIgnore } from "../../../common/api/admin";
import { AppPopConfirm } from "../../../common/components/AppPopConfirm";
import { useInvalidateRequests } from "../hooks/request";

type Props = {
  voiceMessage: AdminVoiceMessage;
};

export const IgnoreButton: FC<Props> = ({ voiceMessage }) => {
  const invalidate = useInvalidateRequests();

  const ignore = useMutation(
    async () => await postAdminVoiceMessageIgnore(voiceMessage.id),
    {
      onSuccess: invalidate,
    }
  );

  return (
    <>
      {voiceMessage.state !== "IGNORED" && (
        <AppPopConfirm
          title="無視してもよろしいですか"
          onConfirm={() => ignore.mutate()}
        >
          <Button loading={ignore.isLoading} disabled={ignore.isSuccess}>
            無視
          </Button>
        </AppPopConfirm>
      )}
    </>
  );
};
