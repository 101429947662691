import * as React from "react";
import { FC } from "react";
import styled from "styled-components";

interface Props {}

export const IconButtonList: FC<Props> = ({ children }) => {
  if (!(children instanceof Array)) {
    throw new Error("");
  }

  return (
    <Root>
      {children.map((it, i) => (
        <Wrap key={i} last={i === children.length - 1}>
          {it}
        </Wrap>
      ))}
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: row;
  background-color: white;
`;

const Wrap1 = styled.div`
  border-right: 1px solid #eee;
  width: 100%;
`;

const Wrap2 = styled.div`
  width: 100%;
`;

interface WrapProps {
  last: boolean;
}

const Wrap: FC<WrapProps> = ({ last, children }) => {
  if (last) {
    return <Wrap2>{children}</Wrap2>;
  } else {
    return <Wrap1>{children}</Wrap1>;
  }
};
