import React, { FC } from "react";

type SectionProps = {
  odd?: boolean;
};

export const Section: FC<SectionProps> = ({ odd, children }) => {
  const bgColor = "white";
  return (
    <div
      style={{
        backgroundColor: bgColor,
      }}
    >
      {children}
    </div>
  );
};
