import { useQuery, useQueryClient } from "react-query";
import { PersonalityId, pointApi } from "../../common/api/api2";
import { ApiPointLog } from "../../api";
import { useCallback } from "react";
import { queryParams, usePrefetch } from "../../common/util/reactQuery";

function pointUserLogsOptions(other: PersonalityId) {
  return queryParams(
    ["point-logs", other],
    async () => (await pointApi.getUserPointLogs(other)).data
  );
}

export function useUserPointLogs(other: PersonalityId): ApiPointLog[] {
  return useQuery(...pointUserLogsOptions(other)).data!;
}

export function usePrefetchUserPointLogs(other: PersonalityId) {
  usePrefetch(pointUserLogsOptions(other));
}

export function useInvalidatePointLogs(otherPersonalityId: PersonalityId) {
  const client = useQueryClient();
  return useCallback(async () => {
    await client.invalidateQueries(["point-logs", otherPersonalityId]);
  }, [client, otherPersonalityId]);
}
