import React, { FC } from "react";

import img from "../../images/sozai_image_151472.png";

import styled from "styled-components";

// const aspectRatio = 1010 / 1176;

type Hero1Props = {
  mainMessage?: string;
};

export const Hero1: FC<Hero1Props> = ({ mainMessage }) => {
  const mainMessage2 = (
    mainMessage ?? "いまこの瞬間\n自分だけに向けられた\n異性の声が聞きたい"
  ).split("\n");

  return (
    <Root>
      <ImageWrap>
        <MyImg src={img} />
      </ImageWrap>
      <MainMessage>
        <MainMessageInner>
          {mainMessage2.map((m) => (
            <MyParagraph key={m}>
              <span>{m}</span>
            </MyParagraph>
          ))}
        </MainMessageInner>
      </MainMessage>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #ddd;
  border-radius: 4px;

  margin: 0;
`;

const MyImg = styled.img`
  width: 100%;
  object-fit: contain;
`;

/**
 * これをいれるのは、 safari で height の制御がうまくされないため
 */
const ImageWrap = styled.div`
  width: 40%;
`;

const MainMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  flex-grow: 1;
`;

const MainMessageInner = styled.div`
  margin: auto;

  font-weight: bold;
  font-size: 14px;

  text-align: center;

  & *:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const MyParagraph = styled.p`
  margin: 0;
`;
