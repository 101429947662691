import React, { FC } from "react";

import logo from "./logo_transparent_background.png";
import styled from "styled-components";
import { primaryColor } from "../../../colors";
import { HorizontalList } from "../HorizontalList";

type LogoProps = {
  width?: number;
  onClick?: () => void;
};

export const Logo: FC<LogoProps> = ({ width, onClick }) => {
  const width2 = width ?? 100;
  return (
    <Root onClick={onClick}>
      <HorizontalList margin={4} alignItems="center">
        <img
          src={logo}
          alt=""
          style={{
            width: width2,
          }}
        />
        <Beta>β Ver.</Beta>
      </HorizontalList>
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  width: min-content;
  height: min-content;
  line-height: 1;
`;

const Beta = styled.div`
  font-weight: bold;
  font-size: 12px;
  color: ${primaryColor};
  width: max-content;

  // ロゴと揃えるために
  padding-top: 11px;

  // https://anyushu.com/web/fonts-osusume/
  font-family: "Lato", "Noto Sans JP", "ヒラギノ角ゴ ProN",
    "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "ＭＳ Ｐゴシック",
    "MS PGothic", sans-serif;
`;
