import { MyUser2 } from "../../common/api/api2";
import { VoiceMessageData } from "../../api";

export function isMe(user: MyUser2, id: string): boolean {
  return user.id === id || user.listenerId === id;
}

/**
 * リスナーとして利用するユーザー ID を取得する
 * @param user
 */
export function getListenerId(user: MyUser2) {
  if (user.hasDoublePersonality) {
    return user.listenerId;
  } else {
    return user.id;
  }
}

export const temporaryUserId = process.env.REACT_APP_TEMPORARY_USER as string;

export function isOrphanRequest(request: VoiceMessageData): boolean {
  return request.listenerId === temporaryUserId;
}
