/**
 * 自動生成した API とプログラム間の調整を行う
 */

import {
  AdminControllerApi,
  AudioApi,
  CastApi,
  Configuration,
  ImageApi,
  ListenerApi,
  MessagingApi,
  MyUser,
  PointApi,
  PublicApi,
  RelationshipApi,
  StaffApi,
  TextMessagePayload,
  TopicApi,
  TrackApi,
  UserApi,
  UserSexEnum,
  VoiceMessagePayload,
} from "../../api";
import { ByNameRequestForCastRequestTargetTypeEnum } from "../../api/model/by-name-request-for-cast";

const baseUrl = process.env.REACT_APP_API_URL as string;

function apiConfig(): [Configuration, string] {
  return [new Configuration(), baseUrl];
}

export const userApi = new UserApi(...apiConfig());
export const topicApi = new TopicApi(...apiConfig());
export const castApi = new CastApi(...apiConfig());
export const listenerApi = new ListenerApi(...apiConfig());
export const adminApi = new AdminControllerApi(...apiConfig());
export const staffApi = new StaffApi(...apiConfig());
export const messageApi = new MessagingApi(...apiConfig());
export const pointApi = new PointApi(...apiConfig());
export const relationshipApi = new RelationshipApi(...apiConfig());
export const trackApi = new TrackApi(...apiConfig());
export const publicApi = new PublicApi(...apiConfig());
export const audioApi = new AudioApi(...apiConfig());
export const imageApi = new ImageApi(...apiConfig());

export function isTextMessagePayload(a: any): a is TextMessagePayload {
  return a.type === "TEXT";
}

export function isVoiceMessagePayload(a: any): a is VoiceMessagePayload {
  return a.type === "VOICE";
}

export type PersonalityId = string;

export type Sex3 = UserSexEnum;

// そのうち 2 を取る
export type MyUser2 = MyUser;

export type RequestTargetType = ByNameRequestForCastRequestTargetTypeEnum;

export const audioFileUploadUrl = `${baseUrl}/audio-files`;
