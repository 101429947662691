import React, { FC } from "react";

import { VerticalList } from "../../../common/components/VerticalList";
import { Section } from "../../../common/components/Section";
import { AppLayout2 } from "../../../common/components/AppLayout2";
import { RequireLineSignIn } from "../../../auth/components/RequireLineSignIn";
import { usePageView } from "../../../common/hooks/usePageView";
import { useMe } from "../../../common/hooks/user";
import { homeRoute } from "../HomePage/HomePage";
import { HeaderWithBackButton } from "../../../common/components/HeaderWithBackButton";
import { Relationship } from "./Relationship";
import { UserRelationship } from "../../../api";
import { MyUser2, relationshipApi } from "../../../common/api/api2";
import { useQuery } from "react-query";
import { sortByNumberDescending } from "../../../lib/array";
import { Point } from "../../components/Point";
import { HorizontalList } from "../../../common/components/HorizontalList";
import { PointIcon } from "../../components/PointIcon";
import styled from "styled-components";
import { useScrollToTopOnMount } from "../../../common/hooks/useScrollToTopOnMount";
import { Divider } from "antd";

export const relationshipRoute = {
  path: "/relationship",
  makePath: () => {
    return `/relationship`;
  },
};

export const RelationshipPage: FC = () => {
  usePageView("relationship", true);

  return (
    <RequireLineSignIn>
      <Impl />
    </RequireLineSignIn>
  );
};

const Impl: FC = () => {
  useScrollToTopOnMount();
  const relationships = sortByNumberDescending(
    useQuery(
      ["user-relationship"],
      async () => (await relationshipApi.getRelationships()).data
    ).data!,
    (it) => it.pointsFromMe * 100000 + it.pointsToMe
  );

  const me = useMe();

  return <RelationshipPageTemplate me={me} relationships={relationships} />;
};

export interface RelationshipPageTemplateProps {
  me: MyUser2;
  relationships: UserRelationship[];
}

export const RelationshipPageTemplate: FC<RelationshipPageTemplateProps> = ({
  relationships,
}) => {
  const totalPoints = relationships
    .map((it) => it.pointsToMe)
    .reduce((a, b) => a + b, 0);

  return (
    <AppLayout2
      noVerticalMargin
      header={
        <HeaderWithBackButton
          title="つながり"
          backPath={homeRoute.makePath()}
        />
      }
    >
      {relationships.length === 0 ? (
        <Section>
          <div>
            <p>ここには声をもらったり、声を贈ったユーザーが表示されます</p>
          </div>
        </Section>
      ) : (
        <>
          <Section>
            <HorizontalList justifyContent="flex-end">
              <Box>
                <div>
                  これまでにもらった累計
                  <PointIcon />
                </div>
                <Point point={totalPoints} />
              </Box>
            </HorizontalList>
            <Divider />
          </Section>

          <Section>
            <VerticalList>
              {relationships.map((a) => (
                <div>
                  <Relationship key={a.user.personalityId} relationship={a} />
                  <Divider />
                </div>
              ))}
            </VerticalList>
          </Section>
        </>
      )}
    </AppLayout2>
  );
};

const Box = styled.div`
  background-color: #fafafa;
  padding: 12px;
  border-radius: 4px;
`;
