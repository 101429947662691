import React from "react";
import { Gauge } from "../../components/Gauge";
import { Button, Slider } from "antd";
import { useAudioInput } from "../../hooks/useAudioInput";

export function WebRtcTesterPage() {
  const {
    devices,
    messages,
    tracks,
    startAudio,
    volume,
    play,
    gain,
    setGain,
  } = useAudioInput();

  const audios = devices.filter((d) => d.kind === "audioinput");

  return (
    <div>
      <div>audio device</div>
      <select onChange={(e) => startAudio(e.target.value)}>
        {audios.map((d) => (
          <option key={d.deviceId} value={d.deviceId}>
            {d.label} - {d.deviceId.substring(0, 5)}
          </option>
        ))}
      </select>
      <table>
        <tbody>
          {tracks.map((d) => (
            <tr key={d.id}>
              <td>{d.kind}</td>
              <td>{d.label}</td>
              <td>{d.isolated}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Gauge value={volume} />

      <Button
        onClick={() => {
          play();
        }}
      >
        BGM 再生
      </Button>
      <Slider
        min={0}
        max={100}
        onChange={(v: number) => {
          setGain(v / 100);
        }}
        value={gain * 100}
      />

      <div>
        {messages.map((m, i) => (
          <div key={i}>{m}</div>
        ))}
      </div>
    </div>
  );
}
