/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Message } from '../model';
// @ts-ignore
import { PostNotification } from '../model';
// @ts-ignore
import { PostRequestSemiNominate } from '../model';
// @ts-ignore
import { PutCensorResult } from '../model';
// @ts-ignore
import { PutPriority } from '../model';
// @ts-ignore
import { PutRecommendedCast } from '../model';
// @ts-ignore
import { StaffTopicAndCast } from '../model';
/**
 * StaffApi - axios parameter creator
 * @export
 */
export const StaffApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary メッセージ一覧を取得する (現状未審査のものだけを返す)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessages: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/staff/messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 話題リクエストへ答えられるユーザー一覧を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopicsCasts: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/staff/topics/casts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostNotification} postNotification 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postNotification: async (postNotification: PostNotification, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postNotification' is not null or undefined
            assertParamExists('postNotification', 'postNotification', postNotification)
            const localVarPath = `/staff/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postNotification, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary リクエストを指定のキャストに準指名する
         * @param {string} requestId 
         * @param {PostRequestSemiNominate} postRequestSemiNominate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRequestSemiNominate: async (requestId: string, postRequestSemiNominate: PostRequestSemiNominate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            assertParamExists('postRequestSemiNominate', 'requestId', requestId)
            // verify required parameter 'postRequestSemiNominate' is not null or undefined
            assertParamExists('postRequestSemiNominate', 'postRequestSemiNominate', postRequestSemiNominate)
            const localVarPath = `/staff/requests/{requestId}/semi-nominate`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postRequestSemiNominate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} voiceMessageId 
         * @param {PutCensorResult} putCensorResult 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postStaff: async (voiceMessageId: string, putCensorResult: PutCensorResult, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'voiceMessageId' is not null or undefined
            assertParamExists('postStaff', 'voiceMessageId', voiceMessageId)
            // verify required parameter 'putCensorResult' is not null or undefined
            assertParamExists('postStaff', 'putCensorResult', putCensorResult)
            const localVarPath = `/staff/voice-messages/{voiceMessageId}/censor`
                .replace(`{${"voiceMessageId"}}`, encodeURIComponent(String(voiceMessageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putCensorResult, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} voiceMessageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postStaffVoiceMessageSend: async (voiceMessageId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'voiceMessageId' is not null or undefined
            assertParamExists('postStaffVoiceMessageSend', 'voiceMessageId', voiceMessageId)
            const localVarPath = `/staff/voice-messages/{voiceMessageId}/send`
                .replace(`{${"voiceMessageId"}}`, encodeURIComponent(String(voiceMessageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} contentId 
         * @param {'MESSAGE' | 'TOPIC'} contentType 
         * @param {PutCensorResult} putCensorResult 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putStaffCensor: async (contentId: string, contentType: 'MESSAGE' | 'TOPIC', putCensorResult: PutCensorResult, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contentId' is not null or undefined
            assertParamExists('putStaffCensor', 'contentId', contentId)
            // verify required parameter 'contentType' is not null or undefined
            assertParamExists('putStaffCensor', 'contentType', contentType)
            // verify required parameter 'putCensorResult' is not null or undefined
            assertParamExists('putStaffCensor', 'putCensorResult', putCensorResult)
            const localVarPath = `/staff/censors/{contentType}/{contentId}/result`
                .replace(`{${"contentId"}}`, encodeURIComponent(String(contentId)))
                .replace(`{${"contentType"}}`, encodeURIComponent(String(contentType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putCensorResult, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {PutCensorResult} putCensorResult 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putStaffProfileCensorResult: async (userId: string, putCensorResult: PutCensorResult, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('putStaffProfileCensorResult', 'userId', userId)
            // verify required parameter 'putCensorResult' is not null or undefined
            assertParamExists('putStaffProfileCensorResult', 'putCensorResult', putCensorResult)
            const localVarPath = `/staff/users/{userId}/profile/censor`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putCensorResult, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} voiceMessageId 
         * @param {PutPriority} putPriority 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putStaffVoiceMessagePriority: async (voiceMessageId: string, putPriority: PutPriority, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'voiceMessageId' is not null or undefined
            assertParamExists('putStaffVoiceMessagePriority', 'voiceMessageId', voiceMessageId)
            // verify required parameter 'putPriority' is not null or undefined
            assertParamExists('putStaffVoiceMessagePriority', 'putPriority', putPriority)
            const localVarPath = `/staff/voice-messages/{voiceMessageId}/priority`
                .replace(`{${"voiceMessageId"}}`, encodeURIComponent(String(voiceMessageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putPriority, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} voiceMessageId 
         * @param {PutRecommendedCast} putRecommendedCast 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putStaffVoiceMessageRecommendedCast: async (voiceMessageId: string, putRecommendedCast: PutRecommendedCast, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'voiceMessageId' is not null or undefined
            assertParamExists('putStaffVoiceMessageRecommendedCast', 'voiceMessageId', voiceMessageId)
            // verify required parameter 'putRecommendedCast' is not null or undefined
            assertParamExists('putStaffVoiceMessageRecommendedCast', 'putRecommendedCast', putRecommendedCast)
            const localVarPath = `/staff/voice-messages/{voiceMessageId}/recommended-cast`
                .replace(`{${"voiceMessageId"}}`, encodeURIComponent(String(voiceMessageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putRecommendedCast, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StaffApi - functional programming interface
 * @export
 */
export const StaffApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StaffApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary メッセージ一覧を取得する (現状未審査のものだけを返す)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessages(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Message>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessages(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 話題リクエストへ答えられるユーザー一覧を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTopicsCasts(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StaffTopicAndCast>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTopicsCasts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostNotification} postNotification 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postNotification(postNotification: PostNotification, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postNotification(postNotification, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary リクエストを指定のキャストに準指名する
         * @param {string} requestId 
         * @param {PostRequestSemiNominate} postRequestSemiNominate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRequestSemiNominate(requestId: string, postRequestSemiNominate: PostRequestSemiNominate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRequestSemiNominate(requestId, postRequestSemiNominate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} voiceMessageId 
         * @param {PutCensorResult} putCensorResult 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postStaff(voiceMessageId: string, putCensorResult: PutCensorResult, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postStaff(voiceMessageId, putCensorResult, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} voiceMessageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postStaffVoiceMessageSend(voiceMessageId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postStaffVoiceMessageSend(voiceMessageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} contentId 
         * @param {'MESSAGE' | 'TOPIC'} contentType 
         * @param {PutCensorResult} putCensorResult 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putStaffCensor(contentId: string, contentType: 'MESSAGE' | 'TOPIC', putCensorResult: PutCensorResult, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putStaffCensor(contentId, contentType, putCensorResult, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {PutCensorResult} putCensorResult 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putStaffProfileCensorResult(userId: string, putCensorResult: PutCensorResult, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putStaffProfileCensorResult(userId, putCensorResult, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} voiceMessageId 
         * @param {PutPriority} putPriority 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putStaffVoiceMessagePriority(voiceMessageId: string, putPriority: PutPriority, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putStaffVoiceMessagePriority(voiceMessageId, putPriority, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} voiceMessageId 
         * @param {PutRecommendedCast} putRecommendedCast 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putStaffVoiceMessageRecommendedCast(voiceMessageId: string, putRecommendedCast: PutRecommendedCast, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putStaffVoiceMessageRecommendedCast(voiceMessageId, putRecommendedCast, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StaffApi - factory interface
 * @export
 */
export const StaffApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StaffApiFp(configuration)
    return {
        /**
         * 
         * @summary メッセージ一覧を取得する (現状未審査のものだけを返す)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessages(options?: any): AxiosPromise<Array<Message>> {
            return localVarFp.getMessages(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 話題リクエストへ答えられるユーザー一覧を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopicsCasts(options?: any): AxiosPromise<Array<StaffTopicAndCast>> {
            return localVarFp.getTopicsCasts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostNotification} postNotification 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postNotification(postNotification: PostNotification, options?: any): AxiosPromise<string> {
            return localVarFp.postNotification(postNotification, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary リクエストを指定のキャストに準指名する
         * @param {string} requestId 
         * @param {PostRequestSemiNominate} postRequestSemiNominate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRequestSemiNominate(requestId: string, postRequestSemiNominate: PostRequestSemiNominate, options?: any): AxiosPromise<void> {
            return localVarFp.postRequestSemiNominate(requestId, postRequestSemiNominate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} voiceMessageId 
         * @param {PutCensorResult} putCensorResult 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postStaff(voiceMessageId: string, putCensorResult: PutCensorResult, options?: any): AxiosPromise<string> {
            return localVarFp.postStaff(voiceMessageId, putCensorResult, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} voiceMessageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postStaffVoiceMessageSend(voiceMessageId: string, options?: any): AxiosPromise<string> {
            return localVarFp.postStaffVoiceMessageSend(voiceMessageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} contentId 
         * @param {'MESSAGE' | 'TOPIC'} contentType 
         * @param {PutCensorResult} putCensorResult 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putStaffCensor(contentId: string, contentType: 'MESSAGE' | 'TOPIC', putCensorResult: PutCensorResult, options?: any): AxiosPromise<string> {
            return localVarFp.putStaffCensor(contentId, contentType, putCensorResult, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {PutCensorResult} putCensorResult 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putStaffProfileCensorResult(userId: string, putCensorResult: PutCensorResult, options?: any): AxiosPromise<string> {
            return localVarFp.putStaffProfileCensorResult(userId, putCensorResult, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} voiceMessageId 
         * @param {PutPriority} putPriority 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putStaffVoiceMessagePriority(voiceMessageId: string, putPriority: PutPriority, options?: any): AxiosPromise<string> {
            return localVarFp.putStaffVoiceMessagePriority(voiceMessageId, putPriority, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} voiceMessageId 
         * @param {PutRecommendedCast} putRecommendedCast 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putStaffVoiceMessageRecommendedCast(voiceMessageId: string, putRecommendedCast: PutRecommendedCast, options?: any): AxiosPromise<string> {
            return localVarFp.putStaffVoiceMessageRecommendedCast(voiceMessageId, putRecommendedCast, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StaffApi - object-oriented interface
 * @export
 * @class StaffApi
 * @extends {BaseAPI}
 */
export class StaffApi extends BaseAPI {
    /**
     * 
     * @summary メッセージ一覧を取得する (現状未審査のものだけを返す)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffApi
     */
    public getMessages(options?: any) {
        return StaffApiFp(this.configuration).getMessages(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 話題リクエストへ答えられるユーザー一覧を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffApi
     */
    public getTopicsCasts(options?: any) {
        return StaffApiFp(this.configuration).getTopicsCasts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostNotification} postNotification 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffApi
     */
    public postNotification(postNotification: PostNotification, options?: any) {
        return StaffApiFp(this.configuration).postNotification(postNotification, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary リクエストを指定のキャストに準指名する
     * @param {string} requestId 
     * @param {PostRequestSemiNominate} postRequestSemiNominate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffApi
     */
    public postRequestSemiNominate(requestId: string, postRequestSemiNominate: PostRequestSemiNominate, options?: any) {
        return StaffApiFp(this.configuration).postRequestSemiNominate(requestId, postRequestSemiNominate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} voiceMessageId 
     * @param {PutCensorResult} putCensorResult 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffApi
     */
    public postStaff(voiceMessageId: string, putCensorResult: PutCensorResult, options?: any) {
        return StaffApiFp(this.configuration).postStaff(voiceMessageId, putCensorResult, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} voiceMessageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffApi
     */
    public postStaffVoiceMessageSend(voiceMessageId: string, options?: any) {
        return StaffApiFp(this.configuration).postStaffVoiceMessageSend(voiceMessageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} contentId 
     * @param {'MESSAGE' | 'TOPIC'} contentType 
     * @param {PutCensorResult} putCensorResult 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffApi
     */
    public putStaffCensor(contentId: string, contentType: 'MESSAGE' | 'TOPIC', putCensorResult: PutCensorResult, options?: any) {
        return StaffApiFp(this.configuration).putStaffCensor(contentId, contentType, putCensorResult, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {PutCensorResult} putCensorResult 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffApi
     */
    public putStaffProfileCensorResult(userId: string, putCensorResult: PutCensorResult, options?: any) {
        return StaffApiFp(this.configuration).putStaffProfileCensorResult(userId, putCensorResult, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} voiceMessageId 
     * @param {PutPriority} putPriority 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffApi
     */
    public putStaffVoiceMessagePriority(voiceMessageId: string, putPriority: PutPriority, options?: any) {
        return StaffApiFp(this.configuration).putStaffVoiceMessagePriority(voiceMessageId, putPriority, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} voiceMessageId 
     * @param {PutRecommendedCast} putRecommendedCast 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffApi
     */
    public putStaffVoiceMessageRecommendedCast(voiceMessageId: string, putRecommendedCast: PutRecommendedCast, options?: any) {
        return StaffApiFp(this.configuration).putStaffVoiceMessageRecommendedCast(voiceMessageId, putRecommendedCast, options).then((request) => request(this.axios, this.basePath));
    }
}
