import React, { FC } from "react";

import { OtherUser, TopicForUser } from "../../../api";
import { AppAvatar } from "../AppAvatar";
import { UserTags } from "../UserTags";
import { Strong } from "../../../common/components/Strong";
import { Pre } from "../../../common/components/Pre";
import { VerticalList } from "../../../common/components/VerticalList";
import { HorizontalList } from "../../../common/components/HorizontalList";
import { talkRoute } from "../../pages/TalkPage/TalkPage";
import { Button, Skeleton } from "antd";
import { useGoto } from "../../../lib/hooks/useGoto";
import { TopicList } from "../TopicList";
import { NoteBox } from "../../../common/components/NoteBox";
import { useUserTopicsQuery } from "../../hooks/topic";
import { useOtherUser } from "../../../common/hooks/user";
import { PersonalityId } from "../../../common/api/api2";
import { SectionTitle } from "../SectionTitle";

interface Props {
  personalityId: PersonalityId;
  noTalk?: boolean;
  noTopics?: boolean;
  onSelectTopic?: (topic: TopicForUser) => void;
}

export const OthersProfile: FC<Props> = ({
  personalityId,
  noTalk,
  noTopics,
  onSelectTopic,
}) => {
  const other = useOtherUser(personalityId);
  const topicsQuery = useUserTopicsQuery(other.id, { enabled: other.isCast });

  return (
    <OthersProfileTemplate
      other={other}
      topics={topicsQuery.isLoading ? null : topicsQuery.data ?? []}
      onSelectTopic={onSelectTopic}
      noTalk={noTalk}
      noTopics={noTopics}
    />
  );
};

export interface OthersProfileTemplateProps {
  other: OtherUser;
  /**
   * loading 中は null
   */
  topics?: TopicForUser[] | null;
  onSelectTopic?: (topic: TopicForUser) => void;
  noTalk?: boolean;
  noTopics?: boolean;
}

export const OthersProfileTemplate: FC<OthersProfileTemplateProps> = ({
  other,
  topics,
  onSelectTopic,
  noTalk,
  noTopics,
}) => {
  const go = useGoto(talkRoute.makePath(other.id));

  return (
    <VerticalList>
      <HorizontalList>
        <AppAvatar src={other.avatarUrl} />
        <VerticalList>
          <Strong>{other.name}</Strong>
          <UserTags tags={other.tags} />
        </VerticalList>
      </HorizontalList>
      {other.message && <Pre>{other.message}</Pre>}

      {!noTopics && (
        <>
          {topics === null && <Skeleton />}
          {topics && topics.length >= 1 && (
            <VerticalList align="center">
              <SectionTitle>リクエストを受け付けている話題</SectionTitle>
              <TopicList
                topics={topics}
                hideQuestion
                onSelect={onSelectTopic}
              />
              <NoteBox>※ 話題をタップすると声をリクエストできるよ</NoteBox>
            </VerticalList>
          )}
        </>
      )}

      {!noTalk && <Button onClick={go}>トークへ</Button>}
    </VerticalList>
  );
};
