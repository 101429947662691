import React, { FC } from "react";
import { ApiMessage } from "../../../../api";
import { AjaxVoicePlayer } from "../../../components/AjaxVoicePlayer/AjaxVoicePlayer";
import { Pre } from "../../../../common/components/Pre";
import { VerticalList } from "../../../../common/components/VerticalList";
import { hasFeature } from "../../../../common/hooks/feature";
import { ToClipboard } from "../../../../common/components/ToClipboard";
import { Small } from "../../../../common/components/Small";
import { MessageTypeTag } from "../../../components/message/MessageTypeTag";
import { MyUser2 } from "../../../../common/api/api2";
import { TopicRequestContent } from "../../../components/message/TopicRequestContent";
import { TalkTypeView } from "../../../../common/components/TalkTypeView";
import { MusicNote } from "../../../../common/components/icons";
import moment from "moment";

interface Props {
  me: MyUser2;
  message: ApiMessage;
  onGetVoiceMessageSuccess?: () => void;
}

/**
 * ユーザーのメッセージを表示する
 */
export const UserMessageContent: FC<Props> = ({
  me,
  message,
  onGetVoiceMessageSuccess,
}) => {
  const mine = message.from.id === me.id;

  const expired = message.expiredAt
    ? moment().isAfter(message.expiredAt)
    : false;

  if (message.requestSupplement?.topic) {
    return (
      <VerticalList>
        <div>
          <MessageTypeTag me={me} message={message} />
        </div>
        <TopicRequestContent message={message} rows={100} />
      </VerticalList>
    );
  }

  if (message.text) {
    return (
      <VerticalList>
        {(message.type === "REQUEST" ||
          message.type === "THANKS" ||
          message.type === "REQUEST_ADDITION") && (
          <div>
            <MessageTypeTag me={me} message={message} />
          </div>
        )}

        <Pre>{message.text}</Pre>

        {message.type === "REQUEST" && (
          <Small>
            <TalkTypeView value={message.requestSupplement!.talkType} />
          </Small>
        )}

        {hasFeature("SHOW_ID") && (
          <Small>
            <ToClipboard data={message.id} />
          </Small>
        )}
      </VerticalList>
    );
  }

  return (
    <>
      <VerticalList>
        {mine || expired ? (
          <div style={{ fontSize: 20 }}>
            <MusicNote />
          </div>
        ) : (
          <VerticalList width={250}>
            <div>
              <MessageTypeTag me={me} message={message} />
            </div>
            <AjaxVoicePlayer
              messageId={message.id}
              onGetVoiceMessageSuccess={onGetVoiceMessageSuccess}
              expiredAt={message.expiredAt}
            />
          </VerticalList>
        )}
        {hasFeature("SHOW_ID") && (
          <Small>
            <ToClipboard data={message.id} />
          </Small>
        )}
      </VerticalList>
    </>
  );
};
