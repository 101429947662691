import React from "react";
import { AppLayout } from "../../common/components/AppLayout";
import { useModel } from "./model";
import { Connected } from "./Connected";
import { Casting } from "./Casting";
import { Listener } from "./Listener";

export function Main() {
  const {
    state,
    messages,
    volume,
    muted,
    setMuted,
    start,
    finish,
    listenerPresence,
    listenerName,
    message,
    reactions,
    startTime,

    audioInputs,
    activeAudioInputId,

    chooseAudioInput,
  } = useModel();

  return (
    <AppLayout>
      {state === "connecting" && (
        <div>
          <Listener listenerName={listenerName} message={message} />
        </div>
      )}
      {(state === "connected" || state === "will-casting") && (
        <Connected
          listenerName={listenerName}
          message={message}
          onStart={start}
        />
      )}
      {state === "casting" && (
        <Casting
          listenerName={listenerName}
          message={message}
          listenerPresence={listenerPresence}
          reactions={reactions}
          volume={volume}
          muted={muted}
          setMuted={setMuted}
          onFinish={finish}
          startTime={startTime}
          audioInputs={audioInputs}
          activeAudioInputId={activeAudioInputId}
          chooseAudioInput={chooseAudioInput}
        />
      )}

      {state}

      {messages.map((a, i) => (
        <div key={i}>{a}</div>
      ))}
    </AppLayout>
  );
}
