import React, { FC } from "react";

import styled from "styled-components";
import { UserForAdmin } from "../../../common/api/admin";

type ItemProps = {
  cast: UserForAdmin | null;
};

export const RyNameOrRandom: FC<ItemProps> = ({ cast }) => {
  return <Small>to {cast?.name ?? "ランダム"}</Small>;
};

const Small = styled.p`
  font-size: 8px;
`;
