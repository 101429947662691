import React, { FC, ReactNode } from "react";

import { Popconfirm } from "antd";

export type AppPopConfirmProps = {
  title: ReactNode;
  onConfirm: () => void;
  onCancel?: () => void;
};

export const AppPopConfirm: FC<AppPopConfirmProps> = ({
  title,
  onConfirm,
  onCancel,
  children,
}) => {
  return (
    <Popconfirm
      title={title}
      onConfirm={onConfirm}
      onCancel={onCancel}
      okText="はい"
      cancelText="いいえ"
    >
      {children}
    </Popconfirm>
  );
};
