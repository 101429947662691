import React, { FC } from "react";

import { AdminVoiceMessage } from "../../../../common/api/types";
import { DateTime } from "../../../../common/components/DateTime";
import { Button, Card, Divider } from "antd";
import { RyNameOrRandom } from "../../components/ByNameOrRandom";
import { VoiceMessageStateTag } from "../../components/VoiceMessageStateTag";
import { Small } from "../../../../common/components/Small";
import { ToClipboard } from "../../../../common/components/ToClipboard";
import { VoiceMessagePlayer } from "../../components/VoiceMessagePlayer";
import { useGoto } from "../../../../lib/hooks/useGoto";
import { adminUserInfoRoute } from "./AdminUserInfoPage";
import { Pre } from "../../../../common/components/Pre";

interface Props {
  voiceMessage: AdminVoiceMessage;
}

export const VoiceMessageFrom: FC<Props> = ({ voiceMessage }) => {
  const go = useGoto(adminUserInfoRoute.makePath(voiceMessage.listenerId!));
  return (
    <Card
      style={{
        width: "90%",
        alignSelf: "flex-end",
      }}
      title={
        <>
          <VoiceMessageStateTag state={voiceMessage.state} />
          <br />
          <Button type="link" onClick={go}>
            to {voiceMessage.request.name}
          </Button>
          <br />
          <div>
            <Small>
              <ToClipboard data={voiceMessage.listenerId} />
            </Small>
          </div>
          <div>
            <Small>
              <ToClipboard data={voiceMessage.id} />
            </Small>
          </div>
          <div>
            <RyNameOrRandom cast={voiceMessage.request.cast ?? null} />
          </div>
        </>
      }
    >
      <div>
        <DateTime value={voiceMessage.sentAt} />
      </div>
      <VoiceMessagePlayer url={voiceMessage.url} />
      {voiceMessage.thanks && (
        <>
          <Divider />
          <Pre>{voiceMessage.thanks.message}</Pre>
        </>
      )}
    </Card>
  );
};
