import axios from "axios";
import { apiUrl } from "./api";
import { HttpSuccess, NotFound, Sex2 } from "./types";
import { UserId } from "../../auth/auth";
import { UserTag } from "./voicemessage/api";

export const featureNames = ["QUESTIONNAIRE1", "SHOW_ID"] as const;

export type FeatureName = typeof featureNames[number];

export interface MyUser {
  id: UserId;
  name?: string;
  sex?: Sex2;
  avatarUrl?: string;
  isCast: boolean;
  isAdmin: boolean;
  isProxying: boolean;
  level: number | null;
  point: number | null;
  listenerAvatarUrl?: string;
  features: FeatureName[];
  tags: UserTag[];
  noteForListener?: string;

  isAvatarCensoring: boolean;
  isMessageCensoring: boolean;

  latestMessage?: string;
  latestAvatarFileId?: string;
  latestAvatarUrl?: string;

  listenerName?: string;
  listenerId: string;
  hasProfile: boolean;
}

export async function getUserStatus(): Promise<HttpSuccess<MyUser>> {
  const res = await axios.get<MyUser>(`${apiUrl}/@me/status`);
  console.log(res);
  return { kind: "ok", data: res.data };
}

export async function getUserStatusLineFollow(): Promise<
  HttpSuccess<void> | NotFound
> {
  try {
    const res = await axios.get(`${apiUrl}/@me/status/line/follow`);
    console.log(res);
    return { kind: "ok", data: res.data };
  } catch (e) {
    if (e.response?.status === 404) {
      return { kind: "not-found" };
    } else {
      throw e;
    }
  }
}

export interface PutUserStatus {
  sex: Sex2;
  name: string;
  avatarFileId?: string;
  message: string;
  tags: UserTag[];
}

export async function putUserStatus(params: PutUserStatus) {
  const res = await axios.put(`${apiUrl}/@me/status`, params);
  console.log(res);
  return { kind: "ok", data: res.data };
}

export async function postUserRegisterCast(): Promise<void> {
  const res = await axios.post(`${apiUrl}/@me/register-cast`);
  console.log(res);
}

export async function postUserSignOutAs() {
  const res = await axios.post(`${apiUrl}/@me/sign-out-as`);
  console.log(res);
  return { kind: "ok", data: res.data };
}
