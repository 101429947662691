import React, { CSSProperties, FC, ReactNode } from "react";

import { Popover, PopoverProps } from "antd";
import { EventType } from "../../common/api/types";
import { track } from "../../common/track";

export type HelpProps = {
  visible?: boolean;
  content: ReactNode;
  track?: EventType;
  onVisibleChange?: (visible: boolean) => void;
  contentWidth?: CSSProperties["width"];
  placement?: PopoverProps["placement"];
};

export const AppPopover: FC<HelpProps> = ({
  visible,
  content,
  track: track2,
  onVisibleChange,
  placement,
  contentWidth,
  children,
}) => {
  const content2 = contentWidth ? (
    <div style={{ width: contentWidth }}>{content}</div>
  ) : (
    content
  );

  return (
    <Popover
      {...(visible !== undefined ? { visible: visible } : {})}
      content={content2}
      placement={placement}
      trigger="click"
      onVisibleChange={(visible) => {
        if (track2 && visible) {
          track({
            event: track2,
            data: "",
          });
        }
        if (onVisibleChange) {
          onVisibleChange(visible);
        }
      }}
    >
      {children}
    </Popover>
  );
};
