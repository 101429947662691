import React, { FC } from "react";

import { Card } from "antd";
import { Small } from "../../../../common/components/Small";
import { UserForAdmin } from "../../../../common/api/admin";
import { DateTime } from "../../../../common/components/DateTime";
import { HorizontalList } from "../../../../common/components/HorizontalList";
import { VerticalList } from "../../../../common/components/VerticalList";
import { AppAvatar } from "../../../components/AppAvatar";
import { adminApi } from "../../../../common/api/api2";
import { useQuery } from "react-query";
import { compareString } from "../../../../common/util/string";
import { UserLink } from "../../components/UserLink";

interface AdminUserInfoProps {
  user: UserForAdmin;
}

export const AdminUserMessages: FC<AdminUserInfoProps> = ({ user }) => {
  const toMessages = useQuery(
    ["admin-user-requests-to", user.id],
    async () => (await adminApi.getAdminUserMessagesToUser(user.id)).data
  ).data!;

  const fromMessages = useQuery(
    ["admin-user-requests-from", user.id],
    async () => (await adminApi.getAdminUserMessagesFromUser(user.id)).data
  ).data!;

  const messages = [...toMessages, ...fromMessages].sort((a, b) =>
    compareString(b.sentAt, a.sentAt)
  );

  return (
    <VerticalList>
      {messages.map((it) => (
        <Card size="small">
          <VerticalList>
            <HorizontalList>
              <Small>{it.id}</Small>
              <Small>
                <DateTime value={it.sentAt} />
              </Small>
            </HorizontalList>

            <HorizontalList alignItems="center">
              <AppAvatar src={it.from.avatarUrl} size="small" />
              <UserLink user={it.from} /> <span>{"=>"}</span>
              <AppAvatar src={it.to?.avatarUrl} size="small" />
              <UserLink user={it.to} />
              <span>{it.targetType}</span>
            </HorizontalList>

            <div>{it.type}</div>
            <div>{it.text}</div>
          </VerticalList>
        </Card>
      ))}
    </VerticalList>
  );
};
