import React, { FC } from "react";
import { Strong } from "../../../common/components/Strong";
import { HelpLink } from "../../../common/components/HelpLink";

interface Props {}

export const RecordingContentHelp: FC<Props> = () => {
  return (
    <HelpLink help={<Help />} scroll track="help.recording-content">
      どんなメッセージを贈ればいいの
    </HelpLink>
  );
};

const Help: FC = () => {
  return (
    <div>
      <p>
        <Strong>何よりも大事なこと❗</Strong>
      </p>
      <p>相手の気持を考えて、優しいメッセージ💌を贈ろう</p>
      <p>
        喜んでもらえて、あなたのファンになってもらえるような、そんなメッセージを贈ろう💪
      </p>

      <p>
        <Strong>こんなメッセージが喜んでもらえるよ</Strong>
      </p>
      <p>
        長さは<Strong>1分前後</Strong>が多いよ🎵
        <br />
        もちろん、もっとなが〜いメッセージも大歓迎だよ❗
      </p>
      <p>親身で、ポジティブなメッセージを贈ってあげよう</p>
      <p>相手の名前を呼んであげよう</p>
      <p>
        リスナーさんに逆質問したり、「いつでもリクエストを待ってるよ」って伝えてあげると、
        リスナーさんも気軽に指名リクエストをくれるかも💕
      </p>
    </div>
  );
};
