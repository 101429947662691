import React, { FC } from "react";
import { useGoto } from "../../../lib/hooks/useGoto";
import { talkRoute } from "../TalkPage/TalkPage";
import { AppAvatar } from "../../components/AppAvatar";
import { othersProfileRoute } from "../OthersProfilePage/OthersProfilePage";
import { UserRelationship } from "../../../api";
import { Point } from "../../components/Point";
import { AppButton } from "../../../common/components/AppButton";
import { HorizontalList } from "../../../common/components/HorizontalList";
import { VerticalList } from "../../../common/components/VerticalList";
import { Strong } from "../../../common/components/Strong";

interface Props {
  relationship: UserRelationship;
}

export const Relationship: FC<Props> = ({ relationship }) => {
  const user = relationship.user;
  const gotoProfile = useGoto(othersProfileRoute.makePath(user.personalityId));

  const gotoTalk = useGoto(talkRoute.makePath(user.personalityId));

  return (
    <HorizontalList alignItems="center" justifyContent="space-between">
      <HorizontalList alignItems="center">
        <AppAvatar src={user.avatarUrl ?? null} onClick={gotoProfile} />
        <VerticalList margin={0}>
          <Strong>{user.name}</Strong>
          <Point point={relationship.pointsToMe} />
        </VerticalList>
      </HorizontalList>

      <div>
        <AppButton onClick={gotoTalk}>
          {user.isCast ? "声をリクエスト" : "トークへ"}
        </AppButton>
      </div>
    </HorizontalList>
  );
};
