import axios, { AxiosInstance } from "axios";
import {
  AccessDenied,
  AlreadyClosed,
  EventType,
  GetSessionResponse,
  HttpSuccess,
  PostHostSessionStartChimeResponse,
  PostSessionResponse,
  VoiceMessageId,
} from "./types";
import { SentVoiceMessageForListener } from "./voicemessage/api";
import { getToken, isAuthenticated } from "../../lib/auth0";
import { getGuestId } from "../../auth/guest";

export const apiUrl = process.env.REACT_APP_API_URL as string;

addInterceptor(axios);

export async function postTrack(params: {
  event: EventType;
  data?: string;
  data2?: string;
}): Promise<void> {
  const res = await axios.post(`${apiUrl}/track`, {
    event: params.event,
    data: params.data,
    data2: params.data2,
  });
  console.log(res);
  return res.data;
}

export async function postVoiceMessageThanksMessage(params: {
  voiceMessageId: VoiceMessageId;
  message: string;
  open: boolean;
}): Promise<void> {
  const res = await axios.post(
    `${apiUrl}/listener/voice-messages/${params.voiceMessageId}/thanks`,
    {
      message: params.message,
      open: params.open,
    }
  );
  console.log(res);
  return res.data;
}

export async function postSession(params: {
  hostName: string;
  listenerName: string;
  message: string;
}): Promise<PostSessionResponse> {
  const res = await axios.post(`${apiUrl}/sessions`, {
    listenerName: params.listenerName,
    message: params.message,
    hostName: params.hostName,
  });
  console.log(res);
  return res.data;
}

export async function getListenerSession(
  sessionId: string
): Promise<GetSessionResponse> {
  const res = await axios.get(`${apiUrl}/listener/sessions/${sessionId}`);
  console.log(res);
  return res.data;
}

export async function postMessage(
  sessionId: string,
  message: string
): Promise<void> {
  const res = await axios.post(`${apiUrl}/sessions/${sessionId}/messages`, {
    message: message,
  });
  console.log(res);
  return res.data;
}

export async function postHostSessionStartChime(
  sessionId: string,
  hostSecret: string
): Promise<HttpSuccess<PostHostSessionStartChimeResponse> | AlreadyClosed> {
  try {
    const res = await axios.post(
      `${apiUrl}/host/sessions/${sessionId}/start-chime`,
      {},
      {
        params: {
          hostSecret: hostSecret,
        },
      }
    );
    console.log(res);
    return { kind: "ok", data: res.data };
  } catch (e) {
    if (e.response?.status === 409) {
      return {
        kind: "already-closed",
      };
    } else {
      throw e;
    }
  }
}

export async function getSessionClose(sessionId: string): Promise<void> {
  const res = await axios.get(`${apiUrl}/sessions/${sessionId}/close`);
  console.log(res);
  return res.data;
}

export async function getHostSessionStarted(sessionId: string): Promise<void> {
  const res = await axios.get(`${apiUrl}/host/sessions/${sessionId}/started`);
  console.log(res);
  return res.data;
}

export async function getVoiceMessage(
  voiceMessageId: VoiceMessageId
): Promise<HttpSuccess<SentVoiceMessageForListener> | AccessDenied> {
  try {
    const res = await axios.get(
      `${apiUrl}/listener/voice-messages/${voiceMessageId}`
    );
    console.log(res);
    return { kind: "ok", data: res.data };
  } catch (e) {
    if (e.response?.status === 403) {
      return {
        kind: "access-denied",
      };
    } else {
      throw e;
    }
  }
}

// ----------

function addInterceptor(axios: AxiosInstance) {
  axios.interceptors.request.use(async (config) => {
    if (await isAuthenticated()) {
      const token = await getToken();
      config.headers["Authorization"] = `Bearer ${token}`;
    } else {
      config.headers["yr-guest-id"] = getGuestId();
    }
    return config;
  });

  // axios.interceptors.response.use(
  //   (response) => {
  //     return response;
  //   },
  //   (error) => {
  //     // sentry に送る用
  //     try {
  //       console.log(
  //         "communication error",
  //         JSON.stringify(error?.config?.data),
  //         JSON.stringify(error?.response?.data)
  //       );
  //     } catch (e) {
  //       // ignore
  //     }
  //     return Promise.reject(error);
  //   }
  // );
}
