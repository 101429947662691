import React, { FC } from "react";

import { Item } from "./Item";
import {
  useAdminAllVoiceMessages,
  useLineIdToCount,
} from "../../hooks/useAdminAllVoiceMessages";
import { Section } from "../../../../common/components/Section";
import { Select } from "antd";
import { useUrlState } from "../../../../common/hooks/useUrlState";
import { AppLayout2 } from "../../../../common/components/AppLayout2";
import { CardList } from "../../components/CardList";
import { AdminVoiceMessage } from "../../../../common/api/types";
import { compareString } from "../../../../common/util/string";
import { useAdminCasts } from "../../hooks/useAdminCasts";
import { VoiceMessageState } from "../../../../common/api/voicemessage/api";
import { sortByNumberDescending } from "../../../../lib/array";

export const adminVoiceMessagesRoute = {
  path: "/admin/voice-messages",
  makePath() {
    return `/admin/voice-messages`;
  },
};

type State =
  | VoiceMessageState
  | "PUBLIC_TOPIC"
  | "NOT_ASSIGNED"
  | "ASSIGNED"
  | "THANKS"
  | "THANKS_CENSOR_OK"
  | "PRIORITY"
  | "LATEST"
  | "LATEST_SENT";

/**
 * アクティブなボイスメッセージの管理画面
 *
 * @constructor
 */
export const AdminVoiceMessageListPage: FC = () => {
  const casts = useAdminCasts();
  const [state, setState] = useUrlState<State>("state", "NOT_ASSIGNED");
  let voiceMessages = getList(useAdminAllVoiceMessages(), state);

  const lineIdToCount = useLineIdToCount();
  const states: [State, string][] = [
    ["REQUESTED", "リクエスト (検閲待ち)"],
    ["RECORDED", "収録済み (検閲待ち)"],
    ["THANKS", "お礼 (検閲待ち)"],
    ["PUBLIC_TOPIC", "トピック (公開リクエスト)"],
    ["NOT_ASSIGNED", "収録待ち (アサイン待ち)"],
    ["ASSIGNED", "収録待ち (アサイン済)"],
    ["PRIORITY", "優先度あげているもの"],
    ["LATEST", "最近のもの"],
    ["LATEST_SENT", "最近送ったもの"],
    ["REQUESTED_CENSOR_OK", "リクエスト (検閲 OK)"],
    ["THANKS_CENSOR_OK", "お礼 (検閲 OK)"],
  ];

  return (
    <AppLayout2 noVerticalMargin>
      <Section colored>
        <CardList>
          <div>
            <Select
              style={{ width: "100%" }}
              value={state}
              onChange={(v) => {
                setState(v);
              }}
            >
              {states.map((it) => (
                <Select.Option key={it[0]} value={it[0]}>
                  {it[1]}
                </Select.Option>
              ))}
            </Select>

            <div>件数: {voiceMessages.length}</div>
          </div>

          {voiceMessages.map((v) => (
            <Item
              key={v.id}
              voiceMessage={v}
              casts={casts}
              userRequestCount={lineIdToCount.get(v.tmpLineUserId!)!}
            />
          ))}
        </CardList>
      </Section>
    </AppLayout2>
  );
};

function getList(all: AdminVoiceMessage[], state: State) {
  switch (state) {
    case "REQUESTED":
      return all.filter((it) => it.state === "REQUESTED");
    case "REQUESTED_CENSOR_OK":
      return all.filter((it) => it.state === "REQUESTED_CENSOR_OK");
    case "RECORDED":
      return all.filter((it) => it.state === "RECORDED");

    case "PUBLIC_TOPIC":
      return all
        .filter(
          (it) =>
            (it.state === "REQUESTED_CENSOR_OK" || it.state === "REQUESTED") &&
            !it.request.cast?.id &&
            it.request.topicId
        )
        .sort((a, b) => compareString(b.requestedAt, a.requestedAt))
        .slice(0, 50);

    case "NOT_ASSIGNED":
      return all
        .filter(
          (it) => it.state === "REQUESTED_CENSOR_OK" && it.castId === null
        )
        .sort((a, b) => compareString(b.requestedAt, a.requestedAt))
        .slice(0, 50);
    case "ASSIGNED":
      return all.filter(
        (it) =>
          (it.state === "REQUESTED_CENSOR_OK" || it.state === "RECORDED") &&
          it.castId !== null
      );
    case "THANKS":
      return all.filter(
        (it) => it.state === "SENT" && it.thanks && !it.thanks.censor
      );
    case "THANKS_CENSOR_OK":
      return all.filter(
        (it) =>
          it.state === "SENT" && it.thanks && it.thanks.censor?.result === "OK"
      );
    case "PRIORITY":
      return sortByNumberDescending(
        all.filter(
          (it) => it.state === "REQUESTED_CENSOR_OK" && it.requestedAt
        ),
        (it) => it.priority
      ).slice(0, 100);
    case "LATEST":
      return all
        .filter((it) => it.state !== "DUPLICATED" && it.requestedAt)
        .sort((a, b) => compareString(b.requestedAt, a.requestedAt))
        .slice(0, 100);
    case "LATEST_SENT":
      return all
        .filter((it) => it.state === "SENT")
        .sort((a, b) => compareString(b.sentAt, a.sentAt))
        .slice(0, 100);
    default:
      throw new Error("BUG");
  }
}
