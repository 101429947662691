import { Drawer, DrawerProps } from "antd";
import React, { FC, Suspense, useCallback } from "react";
import { Loading } from "./Loading";

interface Props {
  title?: string;
  onClose: (visible: boolean) => void;
  visible: boolean;
  destroyOnClose?: boolean;
  height?: DrawerProps["height"];

  /**
   * TODO
   */
  confirmation?: string;
}

/**
 * 表示中も操作できる
 *
 * 閉じた後は children を unmount する
 */
export const BottomSheet: FC<Props> = ({
  title,
  onClose,
  visible,
  destroyOnClose,
  height = "40vh",
  children,
}) => {
  const onClose2 = useCallback(() => {
    console.log("on close");
    onClose(false);
  }, [onClose]);

  return (
    <>
      <Drawer
        title={title}
        placement="bottom"
        closable={false}
        onClose={onClose2}
        visible={visible}
        height={height}
        destroyOnClose={destroyOnClose}
      >
        <Suspense fallback={<Loading />}>{children}</Suspense>
      </Drawer>
    </>
  );
};
