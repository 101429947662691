import React, { FC } from "react";

import styled from "styled-components";
import { bgColor2, primaryLightBgColor } from "../../colors";

type SectionProps = {
  colored?: boolean;
  colored2?: boolean;
  textAlign?: string;
};

export const Section: FC<SectionProps> = ({
  colored,
  colored2,
  textAlign,
  children,
}) => {
  return (
    <Root colored={colored} colored2={colored2} textAlign={textAlign}>
      {children}
    </Root>
  );
};

type RootProps = {
  colored?: boolean;
  colored2?: boolean;
  textAlign?: string;
};

export const sectionPadding = 8;

const Root = styled.div<RootProps>`
  width: 100%;
  margin: 0 0;
  padding: 24px ${sectionPadding}px;
  text-align: ${(p) => p.textAlign};
  ${(p) => p.colored && `background-color: ${primaryLightBgColor}`};
  ${(p) => p.colored2 && `background-color: ${bgColor2}`};
`;
