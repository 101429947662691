import React, { FC } from "react";
import { VerticalList } from "../../../common/components/VerticalList";
import { Section } from "../../../common/components/Section";
import { RequestsForYoHelp } from "./RequestsForYouHelp";
import { ApiMessage } from "../../../api";
import { MessageFrame } from "../../components/MessageFrame";
import { RequestMessageContent } from "../../components/message/RequestMessageContent";
import { RespondButton } from "../../components/RespondButton";
import { MessageTypeTag } from "../../components/message/MessageTypeTag";
import { MyUser2 } from "../../../common/api/api2";

interface Props {
  me: MyUser2;
  messages: ApiMessage[];
}

export const RequestsForYou: FC<Props> = ({ me, messages }) => {
  return (
    <>
      {messages.length === 0 && <RequestsForYoHelp />}

      {messages.length >= 1 && (
        <>
          <Section textAlign="center">
            <p>ここにはあなた宛てのリクエストが表示されるよ</p>
            <p>
              苦手なリクエストや答えにくいリクエストには答えなくて大丈夫だよ！
              <br />
              (リクエストは1週間程度で、自動的に取り消されるよ)
            </p>
          </Section>

          <Section colored>
            <VerticalList align="center">
              {messages.length >= 1 && (
                <VerticalList width="100%">
                  {messages.map((it) => (
                    <MessageFrame
                      key={it.id}
                      user={it.from}
                      sentAt={it.sentAt}
                      profileLink
                      label={<MessageTypeTag me={me} message={it} />}
                    >
                      <VerticalList>
                        <RequestMessageContent
                          message={it}
                          talkLink={false}
                          me={me}
                        />
                        <RespondButton request={it} />
                      </VerticalList>
                    </MessageFrame>
                  ))}
                </VerticalList>
              )}
            </VerticalList>
          </Section>
        </>
      )}
    </>
  );
};
