import { useEffect } from "react";
import { EventType } from "../api/types";
import { track } from "../track";

/**
 * rerender は発生させない
 */
export default function useTrackScroll(event: EventType) {
  useEffect(() => {
    let currentY = window.scrollY;
    let previousY = window.scrollY;

    const id = setInterval(() => {
      previousY = currentY;
      currentY = window.scrollY;
      if (previousY !== currentY) {
        console.log(previousY, currentY);
        track({ event, data: `${currentY}` });
      }
    }, 2000);

    return () => {
      clearInterval(id);
    };
  }, [event]);
}
