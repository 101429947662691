import React, { FC, useState } from "react";
import { VerticalList } from "../../../../common/components/VerticalList";
import { BottomSheet } from "../../../../common/components/BottomSheet";
import { track } from "../../../../common/track";
import { RequestGivePoint } from "../useSystemMessages";
import { GivePoint } from "../../../components/GivePoint/GivePoint";
import { PointIcon } from "../../../components/PointIcon";
import { MyUser2 } from "../../../../common/api/api2";
import { AppButton } from "../../../../common/components/AppButton";

interface Props {
  me: MyUser2;
  message: RequestGivePoint;
}

/**
 * 「ハートを送ろう」
 */
export const RequestGivePointContent: FC<Props> = ({
  me,
  message: { numOfLeft, other },
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <BottomSheet visible={visible} onClose={setVisible}>
        <GivePoint me={me} other={other} onSent={() => setVisible(false)} />
      </BottomSheet>

      <VerticalList>
        <AppButton
          onClick={() => {
            track({ event: "talk.give-point", data2: other.personalityId });
            setVisible(true);
          }}
        >
          <PointIcon />
          を贈ろう (あと{numOfLeft}回まで)
        </AppButton>
      </VerticalList>
    </>
  );
};
