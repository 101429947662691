import React, { FC } from "react";

import { RequireLineSignIn } from "../../../auth/components/RequireLineSignIn";
import { usePageView } from "../../../common/hooks/usePageView";
import { AppLayout2 } from "../../../common/components/AppLayout2";
import { VerticalList } from "../../../common/components/VerticalList";
import { HeaderWithBackButton } from "../../../common/components/HeaderWithBackButton";
import { useGoto } from "../../../lib/hooks/useGoto";
import { Button } from "antd";
import { useUserStatusLineFollow } from "../../../common/hooks/user";
import { AddLineButton } from "../../../common/components/AddLineButton";
import { NoteBox } from "../../../common/components/NoteBox";
import { homeRoute } from "../HomePage/HomePage";

export const requestedRoute = {
  path: "/voice-messages/requested",
  makePath() {
    return "/voice-messages/requested";
  },
};

export const RequestedPage: FC = () => {
  return (
    <RequireLineSignIn>
      <Impl />
    </RequireLineSignIn>
  );
};

const Impl: FC = () => {
  usePageView("requested");
  const isLineFollow = useUserStatusLineFollow();

  return <Template isLineFollow={isLineFollow} />;
};

export interface TemplateProps {
  isLineFollow: boolean;
}

export const Template: FC<TemplateProps> = ({ isLineFollow }) => {
  const goHome = useGoto(homeRoute.makePath());

  return (
    <AppLayout2
      header={
        <HeaderWithBackButton
          title="声のリクエスト完了"
          backPath={homeRoute.makePath()}
        />
      }
    >
      <VerticalList align="center" textAlign="center">
        <p>リクエストしたよ♪</p>

        {!isLineFollow && (
          <div>
            <p>
              メッセージは Your Radio の LINE アカウントから通知されるので、
              まだ友だち追加していなければ、追加してね！
            </p>
            <AddLineButton />
          </div>
        )}

        <p>
          <Button onClick={goHome}>ホームへ</Button>
        </p>

        <NoteBox>
          <p>メッセージは追加いただいた LINE にお届けします</p>
          <p>
            Your Radio
            のアカウントをブロックするとメッセージを届けることができないため、ご注意ください
          </p>
        </NoteBox>
      </VerticalList>
    </AppLayout2>
  );
};
