import React, { FC } from "react";
import { VerticalList } from "../../../common/components/VerticalList";
import { Section } from "../../../common/components/Section";
import { Hero1 } from "../../../common/components/Hero1";
import { Strong } from "../../../common/components/Strong";
import { P } from "../../../common/components/P";

interface Props {}

export const ShortServiceDescription3: FC<Props> = () => {
  return (
    <>
      <Hero1
        mainMessage={"いまこの瞬間\n自分だけに向けられた\n異性の声が聴きたい"}
      />
      <Section>
        <VerticalList align="center" textAlign="center" margin={16}>
          <P>夜、さみしくて眠れないことがありませんか</P>

          <P>
            部活や仕事で落ち込んだとき、やさしい言葉をききたい時がありませんか
          </P>
        </VerticalList>
      </Section>

      <Section colored>
        <VerticalList align="center" textAlign="center" margin={24}>
          <P>
            Your Radioはユーザー同士が
            <br />
            やさしい <Strong>声のメッセージ</Strong> を贈りあうサービスです
            <br />
          </P>
          <P>あなたも聴いてみませんか</P>
        </VerticalList>
      </Section>
    </>
  );
};
