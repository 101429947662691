import React, { FC } from "react";
import { Button } from "antd";
import { AppPopConfirm } from "../../../common/components/AppPopConfirm";
import { useMutation } from "react-query";
import { staffApi } from "../../../common/api/api2";
import { PutCensorResultResultEnum } from "../../../api";

interface Props {
  type: "TOPIC" | "MESSAGE";
  id: string;
}

/**
 * 審査結果を登録するためのコントロール
 *
 * @param user
 * @constructor
 */
export const CensorControl: FC<Props> = ({ type, id }) => {
  const mutation = useMutation(
    async (result: PutCensorResultResultEnum) =>
      await staffApi.putStaffCensor(id, type, {
        result: result,
      })
  );

  return (
    <div>
      <AppPopConfirm
        title="OK でよろしいですか"
        onConfirm={() => mutation.mutate(PutCensorResultResultEnum.Ok)}
      >
        <Button
          type="primary"
          loading={mutation.isLoading}
          disabled={mutation.isSuccess}
        >
          審査 OK
        </Button>
      </AppPopConfirm>

      <AppPopConfirm
        title="NG でよろしいですか"
        onConfirm={() => mutation.mutate(PutCensorResultResultEnum.Ng)}
      >
        <Button loading={mutation.isLoading} disabled={mutation.isSuccess}>
          審査 NG
        </Button>
      </AppPopConfirm>
    </div>
  );
};
