import { FC } from "react";
import { useIsLineUserSignedIn } from "../../../auth/auth";
import { SignIn } from "../../../auth/components/SignIn";
import { useUserStatus } from "../../../common/hooks/user";

interface Props {
  message?: string;
}

export const RequireAdmin: FC<Props> = ({ message, children }) => {
  const signedIn = useIsLineUserSignedIn();
  const userStatus = useUserStatus();

  if (!signedIn) {
    return <SignIn message={message} />;
  }

  if (!userStatus.isAdmin) {
    return <>error</>;
  }

  return <>{children}</>;
};
