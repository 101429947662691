import { useQuery, useQueryClient } from "react-query";
import { messageApi, PersonalityId } from "../../common/api/api2";
import { ApiMessage, Talk } from "../../api";
import { useCallback } from "react";
import { queryParams, usePrefetch } from "../../common/util/reactQuery";

export function useTalk(personalityId: PersonalityId): Talk {
  return useQuery(
    ["messages", personalityId],
    async () => (await messageApi.getTalk(personalityId)).data,
    {
      staleTime: 0,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
    }
  ).data!;
}

const messagesToMeOptions = queryParams(
  ["messages", "to-me"],
  async () => (await messageApi.getMessagesToMe()).data,
  {
    staleTime: 0,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  }
);

export function useMessagesToMe(): ApiMessage[] {
  return useQuery(...messagesToMeOptions).data!;
}

export function usePrefetchMessagesToMe() {
  usePrefetch(messagesToMeOptions);
}

export function useInvalidateTalk(personalityId: PersonalityId) {
  const client = useQueryClient();
  return useCallback(async () => {
    await client.invalidateQueries(["messages", personalityId]);
  }, [client, personalityId]);
}
