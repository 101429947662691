import { useEffect, useState } from "react";

/**
 */
export function useMediaStreamAudioSourceNode({
  mediaStream,
  audioContext,
}: {
  mediaStream: MediaStream | null;
  audioContext: AudioContext | null;
}): MediaStreamAudioSourceNode | null {
  const [inputNode, setInputNode] = useState<MediaStreamAudioSourceNode | null>(
    null
  );
  useEffect(() => {
    if (!mediaStream || !audioContext) {
      return;
    }
    const inputNode = audioContext.createMediaStreamSource(mediaStream);
    setInputNode(inputNode);
  }, [audioContext, mediaStream]);

  return inputNode;
}
