import React, { CSSProperties, FC } from "react";

import styled from "styled-components";
import { primaryColor4 } from "../../colors";

interface Props {
  textAlign?: CSSProperties["textAlign"];
}

export const SectionTitle: FC<Props> = ({ textAlign, children }) => {
  return <Root textAlign={textAlign}>{children}</Root>;
};

const Root = styled.h2<Props>`
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  margin-bottom: 12px;

  text-decoration: underline;
  text-decoration-color: ${primaryColor4};
  text-decoration-thickness: 3px;
  text-underline-offset: 2px;

  ${(p) => p.textAlign === "center" && `text-align ${p.textAlign}`}
`;
