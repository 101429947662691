import React, { FC, useCallback } from "react";

import { usePageView } from "../../../common/hooks/usePageView";
import { Button } from "antd";
import styled from "styled-components";
import { Section } from "../../../common/components/Section";
import { AppLayout2 } from "../../../common/components/AppLayout2";

import circle from "./circle.png";
import { NoteBox } from "../../../common/components/NoteBox";
import { useRegisterPartner } from "../../../common/hooks/useRegisterPartner";
import { useScrollToTopOnMount } from "../../../common/hooks/useScrollToTopOnMount";
import { Hero1 } from "../../../common/components/Hero1";
import { AppPopConfirm } from "../../../common/components/AppPopConfirm";
import { Registered } from "./Registered";
import { postUserRegisterCast } from "../../../common/api/user";
import { useMutation } from "react-query";
import { eventPartnerRegister } from "../../../gtm";
import { track } from "../../../common/track";
import { useIsLineUserSignedIn } from "../../../auth/auth";

export const visionRoute = {
  path: "/voice-messages/vision",
  makePath() {
    return "/voice-messages/vision";
  },
};

export const VisionPage: FC = () => {
  usePageView("vision", true);
  const isLineUserSignedIn = useIsLineUserSignedIn();
  const { registerPartner } = useRegisterPartner();

  const registerByLine = useMutation(async () => {
    eventPartnerRegister();
    await postUserRegisterCast();
  });

  const registerByLineMutate = registerByLine.mutate;
  const register = useCallback(async () => {
    if (isLineUserSignedIn) {
      registerByLineMutate();
    } else {
      registerPartner();
    }
  }, [isLineUserSignedIn, registerByLineMutate, registerPartner]);

  return (
    <>
      {registerByLine.isSuccess ? (
        <Registered />
      ) : (
        <Template
          isLineSignedIn={isLineUserSignedIn}
          onRegister={register}
          registering={registerByLine.isLoading}
        />
      )}
    </>
  );
};

export type TemplateProps = {
  isLineSignedIn: boolean;
  onRegister: () => void;
  registering: boolean;
};

export const Template: FC<TemplateProps> = ({
  isLineSignedIn,
  onRegister,
  registering,
}) => {
  useScrollToTopOnMount();

  return (
    <AppLayout2 noVerticalMargin>
      <Hero1 mainMessage="実現したい世界" />
      <ContentRoot>
        <MySection>
          <Paragraph>夜、さみしくて一人で眠れないときがありませんか</Paragraph>

          <Paragraph>
            部活や仕事で落ち込んだとき、ただやさしい言葉をかけてほしいときがありませんか
          </Paragraph>

          <Paragraph>
            癒やしを求めた SNS
            で、心無い言葉をみかけて、心が痛むことがありませんか
          </Paragraph>
        </MySection>

        <MySection colored>
          <MyTitle>Your Radio の実現したい世界</MyTitle>

          <Paragraph>
            Your Radio
            は安心して誰かに癒やされ、そして癒やすことのできる世界を作りたいと思っています
          </Paragraph>

          <Paragraph>
            他の誰かがあなたを癒し、そして、あなたがまた他の誰かを癒やす
          </Paragraph>

          <Paragraph>そんな世界を作りたいと思っています</Paragraph>

          <ImgBox>
            <MyImg src={circle} />
          </ImgBox>
        </MySection>

        <MySection>
          <MyTitle>あなたにお願いしたいこと</MyTitle>
          <Paragraph>
            あなたの心に癒しが必要なとき、ぜひ Your Radio の視聴者 (リスナー)
            として、あなた宛てのメッセージを聴いて、心の疲れを癒やしてほしいです
          </Paragraph>

          <Paragraph>
            そして、あなたに心の余裕があるとき、Your Radio の発信者 (キャスト)
            として、他の癒しを必要としている人に、優しい言葉をかけてあげてほしいです
          </Paragraph>

          <Paragraph>
            もしこの世界に共感いただき、リスナーとして、そして、キャストとしても参加していただける方がいましたら、ぜひ以下から参加をお願いいたします
          </Paragraph>
        </MySection>

        <MySection>
          <Paragraph>
            {isLineSignedIn ? (
              <AppPopConfirm
                title="登録してもよろしいですか"
                onConfirm={onRegister}
                onCancel={() =>
                  track({
                    event: "partner_register.cancel",
                    data: "",
                  })
                }
              >
                <Button type="primary" loading={registering}>
                  キャストとして配信・開発等に協力する
                </Button>
              </AppPopConfirm>
            ) : (
              <Button type="primary" onClick={onRegister}>
                キャストとして配信・開発等に協力する
              </Button>
            )}
          </Paragraph>

          <NoteBox>
            <Paragraph>
              ※
              配信前には改めて説明をさせていただきますので、そのタイミングで配信への参加をご検討いただいて構いません。
              まずはお気軽にご参加いただけるとうれしいです。
            </Paragraph>
          </NoteBox>
        </MySection>

        <MySection colored>
          <MyTitle>大事にしていること</MyTitle>
          <Paragraph>
            Your Radio では、リスナー (視聴者) ・キャスト (発信者)
            の心を癒やすことを第一に考えています
          </Paragraph>
          <Paragraph>
            そのため、心無い一言で傷つけ合うことがないように、配信内容・テキストメッセージともに、以下を心がけていただきたいです
          </Paragraph>

          <Paragraph>
            相手を傷つけるような内容ではない
            <br />
            アダルトな内容等、不快感を感じる可能性のある内容ではない
            <br />
            個人情報等、現実世界でのトラブルに発展しうる内容ではない
            <br />
          </Paragraph>
        </MySection>
      </ContentRoot>
    </AppLayout2>
  );
};

const ContentRoot = styled.div`
  text-align: center;
`;

const Paragraph = styled.p``;

const MySection = styled(Section)``;

const MyTitle = styled.h2`
  margin: 12px;
  font-size: 20px;
  font-weight: bold;
`;

const MyImg = styled.img`
  width: 100%;
`;

const ImgBox = styled.div`
  padding: 12px;
  border-radius: 40px;
  background-color: white;
  margin: 12px 0;
`;
