import React, { FC } from "react";

import { Button, Popconfirm, Space } from "antd";

import {
  CaretRightOutlined,
  DownloadOutlined,
  PauseOutlined,
} from "@ant-design/icons";
import { AudioProgress } from "../../common/components/AudioProgress";
import styled from "styled-components";
import { useBlobLink } from "../../common/hooks/useBlobLink";
import { useBlobPlayer } from "./VoiceRecorder/useBlobPlayer";
import { Voice } from "../hooks/useVoiceList";

type RecordingDataProps = {
  id?: string;

  voice: Voice;
  /**
   * 拡張子は含まない
   */
  fileName: string;

  /**
   * くるくるマークを出す
   */
  processing?: boolean;

  sendToListenerDisabled: boolean;

  onSendToListener: (id: string) => void;
};

/**
 * 録音された音声データ
 */
export const RecordedVoice: FC<RecordingDataProps> = ({
  id,
  voice,
  fileName,
  processing,
  sendToListenerDisabled,
  onSendToListener,
}) => {
  const player = useBlobPlayer(voice.blob);
  const url = useBlobLink(voice.blob);

  return (
    <Root>
      <Space direction="vertical">
        <Space direction="horizontal">
          {player.playing ? (
            <Button
              shape="circle"
              icon={<PauseOutlined />}
              onClick={() => player.audio.pause()}
            />
          ) : (
            <Button
              shape="circle"
              icon={<CaretRightOutlined />}
              onClick={player.play}
              loading={!player.canPlay}
            />
          )}
          <Button
            shape="circle"
            icon={<DownloadOutlined />}
            loading={!url}
            href={url!!}
            download={fileName + ".webm"}
          />

          <Popconfirm
            title="この音声をリスナーへ送信してもよろしいですか"
            onConfirm={() => onSendToListener(id!)}
          >
            <Button
              loading={processing || !id}
              disabled={sendToListenerDisabled}
            >
              リスナーへ送信
            </Button>
          </Popconfirm>
        </Space>

        <div>
          <AudioProgress
            audio={player.audio}
            currentTime={player.currentTime}
          />
        </div>
      </Space>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
