import {
  getHostSessionStarted,
  getSessionClose,
  postHostSessionStartChime,
} from "../../common/api/api";
import {
  AudioProfile,
  ConsoleLogger,
  DefaultDeviceController,
  DefaultMeetingSession,
  EventAttributes,
  EventName,
  LogLevel,
  MeetingSession,
  MeetingSessionConfiguration,
} from "amazon-chime-sdk-js";
import { useQuery } from "react-query";
import { useQueryParams } from "../../common/hooks/useQueryParams";
import { useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { Reaction } from "../model";
import { getMusics, MusicId } from "../../common/data/music";
import moment, { Moment } from "moment";
import { useChimeDevice, useChimeMute } from "../../common/util/chime";

type State =
  | "connecting"
  | "connected"
  | "will-casting"
  | "casting"
  | "finishing"
  | "finished";

export function useModel() {
  let hostSecret = useQueryParams("hostSecret")!!;
  const { sessionId } = useParams<{ sessionId: string }>();
  const [messages, setMessages] = useState<string[]>([]);
  const [volume, setVolume] = useState(0);
  const [state, setState] = useState<State>("connecting");
  const [meetingSession, setMeetingSession] = useState<MeetingSession>();
  const [listenerPresence, setListenerPresence] = useState(false);
  const [listenerName, setListenerName] = useState("");
  const [message, setMessage] = useState("");
  const [reactions, setReactions] = useState<Reaction[]>([]);
  const [musicId, setMusicId] = useState<MusicId>(getMusics()[0].id);
  const [startTime, setStartTime] = useState<Moment>();
  const { muted, setMuted } = useChimeMute(meetingSession);
  const {
    audioInputs,
    activeAudioInputId,
    chooseDefaultAudioInput,
    chooseAudioInput,
  } = useChimeDevice(meetingSession);

  const res = useQuery(
    `post-host-session-start-live-${sessionId}`,
    async () => {
      const res = await postHostSessionStartChime(sessionId, hostSecret);
      if (res.kind === "already-closed") {
        throw Error("この配信はすでに終了しています");
      }
      return res.data;
    },
    {}
  ).data!;

  function log(l: string) {
    setMessages((s) => [...s, l]);
  }

  useEffect(() => {
    setListenerName(res.listenerName);
    setMessage(res.message);
  }, [res.listenerName, res.message]);

  const start = useCallback(async () => {
    if (!meetingSession) {
      throw new Error();
    }
    console.log("start");
    setState("will-casting");
    setState("casting");

    await chooseDefaultAudioInput();
    await getHostSessionStarted(sessionId);
  }, [chooseDefaultAudioInput, meetingSession, sessionId]);

  const finish = useCallback(async () => {
    setState("finishing");
    console.log("finish");
    meetingSession?.audioVideo.stop();
    await getSessionClose(sessionId);
  }, [meetingSession?.audioVideo, sessionId]);

  useEffect(() => {
    (async () => {
      const logger = new ConsoleLogger("SDK", LogLevel.INFO);
      const configuration = new MeetingSessionConfiguration(
        res.chimeMeeting,
        res.chimeHostAttendee
      );

      const deviceController = new DefaultDeviceController(logger, {
        enableWebAudio: false,
      });

      const meetingSession = new DefaultMeetingSession(
        configuration,
        logger,
        deviceController
      );
      setMeetingSession(meetingSession);

      meetingSession.audioVideo.addObserver({
        audioVideoDidStart() {
          console.log("audio video did start");
          setState("connected");
        },
        audioVideoDidStop() {
          log("connectionDidBecomeGood");
          setState("finished");
        },
        audioVideoDidStartConnecting() {
          console.log("audio video did start connecting");
        },
        connectionDidBecomeGood() {
          log("connectionDidBecomeGood");
        },
        connectionDidBecomePoor() {
          log("connectionDidBecomePoor");
        },
        // connectionHealthDidChange(p: ConnectionHealthData) {
        //   setMessages((s) => [...s, `connectionHealthDidChange`]);
        // },
        eventDidReceive(name: EventName, attributes: EventAttributes) {
          log(`eventDidReceive ${name} ${attributes.meetingId}`);
        },
      });

      meetingSession.audioVideo.realtimeSubscribeToReceiveDataMessage(
        "message",
        (dataMessage) => {
          const reaction = dataMessage.json() as Reaction;
          setReactions((s) => [...s, reaction]);
        }
      );

      meetingSession.audioVideo.setAudioProfile(
        AudioProfile.fullbandSpeechMono()
      );

      meetingSession.audioVideo.realtimeSubscribeToFatalError((e) => {
        log(`fatal ${e.message}`);
      });

      meetingSession.audioVideo.realtimeSubscribeToVolumeIndicator(
        res.chimeHostAttendee.AttendeeId,
        (attendeeId, volume) => {
          if (volume !== null) {
            setVolume(volume);
          }
        }
      );

      meetingSession.audioVideo.realtimeSubscribeToAttendeeIdPresence(
        (attendeeId, present, externalUserId, dropped) => {
          console.log(attendeeId, present, dropped);
          if (attendeeId === res.chimeHostAttendee.AttendeeId) {
            console.log("自分");
            return;
          }
          if (present) {
            // 初回だけ設定する
            setStartTime((s) => (s ? s : moment()));
          }
          setListenerPresence(present);
        }
      );

      // audioVideo は設定していないため、配信は始まらない
      meetingSession?.audioVideo.start();
    })();
  }, [res.chimeHostAttendee, res.chimeMeeting]);

  return {
    state,
    messages,
    volume,
    setMuted,
    muted,
    start,
    finish,
    listenerPresence,
    listenerName,
    message,
    reactions,
    musicId,
    setMusicId,
    musics: getMusics(),
    startTime,

    audioInputs,
    activeAudioInputId,

    chooseAudioInput,
    chooseDefaultAudioInput,
  };
}
