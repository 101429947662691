import { useCallback, useEffect, useState } from "react";
import moment, { Moment } from "moment";

export function useStopWatch() {
  const [startTime, setStartTime] = useState<Moment | null>(null);
  const [currentTime, setCurrentTime] = useState<Moment | null>(null);

  const startStopWatch = useCallback(() => {
    const now = moment();
    setStartTime(now);
    setCurrentTime(now);
  }, []);

  const stopStopWatch = useCallback(() => {
    setStartTime(null);
  }, []);

  useEffect(() => {
    if (!startTime) {
      return;
    }

    const id = setInterval(() => {
      setCurrentTime(moment());
    }, 200);
    return () => {
      clearInterval(id);
    };
  }, [startTime]);

  return {
    timeAfterStartMillis:
      currentTime && startTime ? currentTime.diff(startTime) : 0,
    startStopWatch,
    stopStopWatch,
  };
}
