import React, { FC, useCallback } from "react";

import { VoiceRecorder } from "../voicemessage/components/VoiceRecorder/VoiceRecorder";
import { RecordedVoice } from "../voicemessage/components/RecordedVoice";
import { useVoiceList } from "../voicemessage/hooks/useVoiceList";

export const devVoiceRecorderRoute = {
  path: "/dev/voice-recorder",
  makePath() {
    return "/dev/voice-recorder";
  },
};

export const DevVoiceRecorderPage: FC = () => {
  const { voices, add } = useVoiceList();

  const onRecorded = useCallback(
    (blob: Blob) => {
      add(blob);
    },
    [add]
  );

  return (
    <div>
      <VoiceRecorder onRecorded={onRecorded} saving={false} />

      <div>
        {voices.map((v) => (
          <RecordedVoice
            key={v.id}
            voice={v}
            fileName={"voice"}
            sendToListenerDisabled
            onSendToListener={() => {}}
          />
        ))}
      </div>
    </div>
  );
};
