import {
  ConsoleLogger,
  DefaultActiveSpeakerPolicy,
  DefaultDeviceController,
  DefaultMeetingSession,
  EventAttributes,
  EventName,
  LogLevel,
  MeetingSession,
  MeetingSessionConfiguration,
} from "amazon-chime-sdk-js";
import { getListenerSession, postMessage } from "../../../common/api/api";
import {
  connectionQualityState,
  logsState,
  mutedState,
  serverStateState,
  stateState,
} from "./setup";
import { runInAction } from "mobx";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useCallback, useEffect, useRef } from "react";
import { assertIsDefined } from "../../../common/util/assert";
import { useAudio } from "../../../common/hooks/useAudio";

/**
 * serverStateState が "started" のときのみ処理を行う
 *
 * 各種 recoil の値に依存する
 * @param sessionId
 */
export function useChime(sessionId: string) {
  const [state, setSubState] = useRecoilState(stateState);
  const meetingSessionRef = useRef<MeetingSession>();
  const setConnectionQuality = useSetRecoilState(connectionQualityState);
  const serverState = useRecoilValue(serverStateState);
  const outputAudio = useAudio();
  const [muted, setMuted] = useRecoilState(mutedState);
  const logs = useSetRecoilState(logsState);
  const safety = useRef(0);

  const sendMessage = useCallback(
    (message: string) => {
      if (safety.current > 1000) {
        console.log("これ以上通信できません");
        return;
      }
      safety.current++;

      const meetingSession = meetingSessionRef.current;
      meetingSession?.audioVideo.realtimeSendDataMessage("message", {
        text: message,
      });
      postMessage(sessionId, message).then();
    },
    [sessionId]
  ); // 依存関係が追加された場合、注意

  const log = useCallback(
    (m: string) => {
      logs((a) => [...a, m]);
      console.log(m);
      sendMessage(m);
    },
    [logs, sendMessage]
  );

  // state 変更時に通知
  useEffect(() => {
    sendMessage(`state-change: ${state}`);
  }, [sendMessage, state]);

  useEffect(() => {
    sendMessage(`muted: ${muted} volume: ${outputAudio.volume}`);
  }, [muted, outputAudio.volume, sendMessage]);

  //
  const serverStateStarted = serverState === "started";
  useEffect(() => {
    if (!serverStateStarted) {
      return;
    }
    let meetingSession2: MeetingSession;

    (async () => {
      setSubState("server-started");
      console.log("meeting session setup");

      const res = await getListenerSession(sessionId);
      if (res.liveMode !== "chime") {
        throw new Error("");
      }

      const logger = new ConsoleLogger("SDK", LogLevel.ERROR);
      const configuration = new MeetingSessionConfiguration(
        res.chimeMeeting,
        res.chimeListenerAttendee
      );

      const deviceController = new DefaultDeviceController(logger, {
        enableWebAudio: false,
      });

      console.log("接続", configuration);
      meetingSession2 = new DefaultMeetingSession(
        configuration,
        logger,
        deviceController
      );
      meetingSessionRef.current = meetingSession2;

      meetingSession2.audioVideo.subscribeToActiveSpeakerDetector(
        new DefaultActiveSpeakerPolicy(),
        (attendeeIds: any) => {
          if (attendeeIds.length) {
            const msg = `${attendeeIds[0]} is the most active speaker`;
            console.log(msg);
          }
        }
      );

      meetingSession2.audioVideo.realtimeSubscribeToFatalError((error) => {
        log(`${error}`);
      });

      meetingSession2.audioVideo.addObserver({
        audioVideoDidStart() {
          log("audio video did start");
          setSubState("playing");
        },
        audioVideoDidStartConnecting() {
          log("audio video did start connecting");
        },
        audioVideoDidStop() {
          log("audio video did stop");
        },
        connectionDidBecomeGood() {
          setConnectionQuality("good");
          log("connection did become good");
        },
        connectionDidBecomePoor() {
          runInAction(() => {
            setConnectionQuality("poor");
          });

          log("connection did become poor");
        },
        eventDidReceive(name: EventName, attributes: EventAttributes) {
          log(`eventDidReceive ${name} ${attributes.meetingId}`);
        },
      });

      await meetingSession2.audioVideo.bindAudioElement(outputAudio.audio);

      // ユーザー操作がないため、ここでは接続できない
      setSubState("client-started");
    })();
    return () => {
      console.log("meeting session cleanup");
      meetingSession2.audioVideo.stop();
      meetingSession2.audioVideo.unbindAudioElement();
      meetingSessionRef.current = undefined;
    };
  }, [
    log,
    outputAudio.audio,
    serverStateStarted,
    sessionId,
    setConnectionQuality,
    setSubState,
  ]);

  const clientStarted = state === "client-started";

  useSync(muted, setMuted, outputAudio.muted, outputAudio.setMuted);

  const playByUser = useCallback(() => {
    if (!clientStarted) {
      return;
    }
    const meetingSession = meetingSessionRef.current;
    assertIsDefined(meetingSession);

    const audio = outputAudio.audio;
    audio.volume = 1;
    audio.muted = false;
    meetingSession.audioVideo.realtimeUnmuteLocalAudio();
    meetingSession.audioVideo.start();
    setSubState("will-play");
  }, [outputAudio.audio, clientStarted, setSubState]);

  return {
    playByUser,
    sendMessage,
  };
}

function useSync<T>(
  v1: T,
  setV1: (v: T) => void,
  v2: T,
  setV2: (v: T) => void
) {
  useEffect(() => {
    setV1(v2);
  }, [setV1, v2]);
  useEffect(() => {
    setV2(v1);
  }, [setV2, v1]);
}
