import React, { FC } from "react";

import { Slider } from "antd";

interface Props {
  audio: HTMLAudioElement;
  currentTime: number;
  onChange: (time: number) => void;
}

export const AudioSeekBar: FC<Props> = ({ audio, currentTime, onChange }) => {
  return (
    <Slider
      step={0.1}
      value={currentTime}
      max={audio.duration}
      style={{ width: "100%" }}
      onChange={onChange}
    />
  );
};
