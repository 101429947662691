/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface PutMyProfile
 */
export interface PutMyProfile {
    /**
     * 
     * @type {string}
     * @memberof PutMyProfile
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PutMyProfile
     */
    sex: PutMyProfileSexEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof PutMyProfile
     */
    tags: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PutMyProfile
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof PutMyProfile
     */
    avatarFileId?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PutMyProfileSexEnum {
    Male = 'MALE',
    Female = 'FEMALE'
}



