import React, { FC, ReactNode } from "react";
import { Layout } from "antd";
import styled from "styled-components";
import { Logo } from "./Logo/Logo";
import { textColor } from "../../colors";
import { AppFooter } from "./AppFooter";
import { useGoto } from "../../lib/hooks/useGoto";
import { homeRoute } from "../../voicemessage/pages/HomePage/HomePage";

const { Header, Content } = Layout;

type AppLayout2Props = {
  header?: ReactNode;
  noVerticalMargin?: boolean;
};

/**
 * TODO 1 とマージ
 */
export const AppLayout2: FC<AppLayout2Props> = ({
  header,
  noVerticalMargin,
  children,
}) => {
  const gotoHome = useGoto(homeRoute.makePath());
  const header2 = header ?? (
    <MyHeader>
      <Logo onClick={gotoHome} />
    </MyHeader>
  );

  return (
    <Layout>
      {header2}

      <Content>
        <MyContent noVerticalMargin={noVerticalMargin}>{children}</MyContent>
      </Content>
      <AppFooter />
    </Layout>
  );
};

type MyContentProps = {
  noVerticalMargin?: boolean;
};

/**
 *
 */
const MyContent = styled.div<MyContentProps>`
  min-height: 280px;

  ${(p) => (p.noVerticalMargin ? "padding: 0 0;" : "padding: 24px 0;")}

  background: #fff;

  font-size: 14px;

  color: ${textColor};

  // https://anyushu.com/web/fonts-osusume/
  font-family: "Lato", "Noto Sans JP", "ヒラギノ角ゴ ProN",
    "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "ＭＳ Ｐゴシック",
    "MS PGothic", sans-serif;
`;

const MyHeader = styled(Header)`
  display: flex;
  align-items: center;

  background-color: #f5f5f5;
  text-align: center;
  padding-left: 10px;
`;
