import React, { FC } from "react";

import moment from "moment";

export interface BlurredDateProps {
  now: number;
  value?: string;
}

export const BlurredDate: FC<BlurredDateProps> = ({ now, value }) => {
  if (!value) {
    return <></>;
  }

  const diff = now - moment(value).valueOf();
  if (diff < 24 * 60 * 60 * 1000) {
    return <>1日以内</>;
  } else if (diff < 5 * 24 * 60 * 60 * 1000) {
    return <>最近</>;
  } else {
    return <>だいぶ前</>;
  }
};
