import React, { FC } from "react";
import { useGotoIf } from "../../lib/hooks/useGotoIf";
import { publicRequestRoute } from "./PublicRequestPage/PublicRequestPage";

export const randomRequestRoute = {
  path: "/voice-messages/random-request",
};

export const RandomRequestPage: FC = () => {
  useGotoIf(publicRequestRoute.makePath(), true, {
    replace: true,
  });

  return <div />;
};
