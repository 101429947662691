import React, { FC } from "react";

import { QuestionCircleOutlined } from "@ant-design/icons";

interface Props {
  onClick?: () => void;
}

export const HelpIcon: FC<Props> = ({ onClick }) => {
  return <QuestionCircleOutlined onClick={onClick} />;
};
