import React, { CSSProperties, FC, ReactNode } from "react";
import { EventType } from "../api/types";
import { HelpIcon } from "./HelpIcon";
import { track } from "../track";
import { AppPopover } from "../../voicemessage/components/AppPopover";

export type HelpProps = {
  help: ReactNode;
  track?: EventType;
  contentWidth?: CSSProperties["width"];
};

/**
 * ヘルプアイコンとポップアップ
 */
export const Help: FC<HelpProps> = ({
  help,
  track: track2,
  contentWidth = "50vw",
}) => {
  // https://github.com/ant-design/ant-design/issues/7233
  return (
    <span onClick={(e) => e.stopPropagation()}>
      <AppPopover
        content={help}
        contentWidth={contentWidth}
        onVisibleChange={(visible) => {
          if (track2 && visible) {
            track({
              event: track2,
              data: "",
            });
          }
        }}
      >
        <HelpIcon />
      </AppPopover>
    </span>
  );
};
