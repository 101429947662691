import { useEffect, useState } from "react";

export function useAudioMerge(params: {
  context: AudioContext | null;
  input0: AudioNode | null;
  input1: AudioNode | null;
}) {
  const [output, setOutput] = useState<MediaStream | null>(null);
  const { input0, input1, context } = params;

  useEffect(() => {
    if (!context || !input0 || !input1) {
      return;
    }

    const dest = context.createMediaStreamDestination();

    const merger = context.createChannelMerger();

    // https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Basic_concepts_behind_Web_Audio_API#up-mixing_and_down-mixing

    input0.connect(merger, 0, 0);
    input0.connect(merger, 0, 1);

    if (input1.numberOfOutputs === 1) {
      input1.connect(merger, 0, 2);
      input1.connect(merger, 0, 3);
    }

    merger.connect(dest);
    setOutput(dest.stream);
  }, [context, input0, input1]);

  return {
    output: output,
  };
}
