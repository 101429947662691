import { useCallback, useDebugValue, useEffect, useState } from "react";
import { getListenerSession, postMessage } from "../../../common/api/api";
import { useParams } from "react-router-dom";
import { useChime } from "./chime";
import { useQuery } from "react-query";
import { useAudioWithGain } from "../../../common/hooks/useAudioWithGain";
import {
  GetSessionResponse,
  Host,
  SessionState,
} from "../../../common/api/types";
import { getMusic } from "../../../common/data/music";
import { atom, useRecoilState } from "recoil";
import { useAudioContext } from "../../../common/hooks/useAudioContext";

const TIMEOUT_SEC = 15;

export type State =
  | "server-started"
  | "client-started"
  | "will-play"
  | "playing"
  | "error"
  | "no-host"
  | "dummy";

export type ConnectionQuality = "good" | "poor";

export const stateState = atom({
  key: "state",
  default: "connecting" as State,
});

export const serverStateState = atom({
  key: "server-state",
  default: "initial" as SessionState,
});

export const connectionQualityState = atom({
  key: "connectionQuality",
  default: "good" as ConnectionQuality,
});

export const mutedState = atom({
  key: "muted",
  default: false,
});

/**
 * 接続状態等のログ
 */
export const logsState = atom({
  key: "logs",
  default: [] as string[],
});

export function useSetup2() {
  // それ以降は chime, ivs の接続者が処理する
  const { sessionId } = useParams<{ sessionId: string }>();
  const [state, setSubState] = useRecoilState(stateState);
  const [serverState, setServerState] = useRecoilState(serverStateState);
  const [host, setHost] = useState<Host>();
  const [, setDidTimeout] = useState(false);
  const context = useAudioContext();
  const bgm = useAudioWithGain({
    src: getMusic("6").url,
    initialGain: 0.05,
    context,
  });

  useDebugValue(`${serverState} / ${state}`);

  const res = useQuery(
    sessionId,
    async () => {
      return await getListenerSession(sessionId);
    },
    {
      refetchInterval: 5000,
    }
  ).data! as GetSessionResponse;

  useEffect(() => {
    setServerState(res.state);
  }, [res.state, setServerState]);

  useEffect(() => {
    if (serverState !== "started") {
      setSubState("dummy");
    }
  }, [serverState, setSubState]);

  useEffect(() => {
    setHost(res.host);
  }, [res.host]);

  useEffect(() => {
    const id = setInterval(() => {
      postMessage(sessionId, "まだいるよ").then();
    }, 5000);

    return () => {
      clearInterval(id);
    };
  }, [sessionId]);

  //
  const serverInitial = serverState === "initial";
  useEffect(() => {
    if (!serverInitial) {
      return;
    }
    console.log("start timeout");
    const id = setTimeout(() => {
      postMessage(sessionId, "対応可能なホストがいなかったよ").then();
      setTimeout(true);
      setSubState("no-host");
      setDidTimeout(true);
    }, TIMEOUT_SEC * 1000);
    return () => {
      console.log("clear timeout");
      clearTimeout(id);
    };
  }, [serverInitial, sessionId, setSubState]);

  const chime = useChime(sessionId);
  const chimePlayByUser = chime.playByUser;

  const playByUser = useCallback(() => {
    chimePlayByUser();
    bgm.play();
  }, [bgm, chimePlayByUser]);

  const serverClosed = serverState === "closed";
  useEffect(() => {
    if (!serverClosed) {
      return;
    }
    bgm.pause();
  }, [bgm, serverClosed]);

  return {
    serverState: serverState,
    subState: state,
    play: playByUser,
    sendMessage: chime.sendMessage,
    host: host,
    bgmVolume: bgm.gain,
    setBgmVolume: bgm.setGain,
  };
}
