import React, { FC } from "react";

import { Card, Divider } from "antd";
import { AdminVoiceMessage } from "../../../../common/api/types";
import { DateTime } from "../../../../common/components/DateTime";
import { UserForAdmin } from "../../../../common/api/admin";
import { VoiceMessageStateTag } from "../../components/VoiceMessageStateTag";
import { Pre } from "../../../../common/components/Pre";
import { Link } from "../../../../common/components/Link";
import { RyNameOrRandom } from "../../components/ByNameOrRandom";
import { Small } from "../../../../common/components/Small";
import { ToClipboard } from "../../../../common/components/ToClipboard";
import { AdminVoiceMessageControls } from "../../components/AdminVoiceMessageControls";
import { ThanksView } from "./ThanksView";
import { SexTag } from "../../components/SexTag";
import { adminUserInfoRoute } from "../AdminUserInfoPage/AdminUserInfoPage";
import { AppAvatar } from "../../../components/AppAvatar";
import { HorizontalList } from "../../../../common/components/HorizontalList";
import { UserLink } from "../../components/UserLink";
import { VerticalList } from "../../../../common/components/VerticalList";

type ItemProps = {
  voiceMessage: AdminVoiceMessage;
  casts: UserForAdmin[];
  userRequestCount: number;
};

export const Item: FC<ItemProps> = ({
  voiceMessage,
  casts,
  userRequestCount,
}) => {
  const assignedCast = casts.find((it) => it.id === voiceMessage.castId);

  return (
    <Card
      title={
        <VerticalList>
          <HorizontalList>
            <VoiceMessageStateTag state={voiceMessage.state} />
            <DateTime value={voiceMessage.requestedAt} />
          </HorizontalList>

          <div>
            <div>
              <Small>
                <ToClipboard data={voiceMessage.id} />
              </Small>
            </div>
            <div>
              <Small>
                <ToClipboard data={voiceMessage.listenerId} />
              </Small>
            </div>
          </div>
        </VerticalList>
      }
    >
      <VerticalList>
        <div>
          <HorizontalList alignItems="center" margin={4}>
            <Link to={adminUserInfoRoute.makePath(voiceMessage.listenerId!)}>
              <HorizontalList alignItems="center" margin={4}>
                <AppAvatar
                  src={voiceMessage.listener?.avatarUrl}
                  size="small"
                />
                <span>
                  {voiceMessage.request.name}({userRequestCount}回)
                </span>
              </HorizontalList>
            </Link>
            ⇦ <UserLink user={assignedCast} />
          </HorizontalList>
        </div>

        <div>
          ターゲット:
          {voiceMessage.request.cast ? (
            <UserLink user={voiceMessage.request.cast} />
          ) : (
            <SexTag value={voiceMessage.request.targetSex} />
          )}
        </div>
        <Pre>{voiceMessage.request.request}</Pre>
        <Pre>{voiceMessage.request.situation}</Pre>
        <Pre>{voiceMessage.request.message}</Pre>

        {voiceMessage.thanks && (
          <>
            <Divider />
            <ThanksView voiceMessage={voiceMessage} />
          </>
        )}
      </VerticalList>

      <AdminVoiceMessageControls voiceMessage={voiceMessage} casts={casts} />
    </Card>
  );
};
