import React, { FC, useCallback, useRef, useState } from "react";
import AvatarEditor2 from "react-avatar-editor";
import { Button, Slider } from "antd";
import { VerticalList } from "../../common/components/VerticalList";
import { ImageFile, postImageFile } from "../../common/api/image";
import { useMutation } from "react-query";
import { NoteBox } from "../../common/components/NoteBox";

interface Props {
  onSave: (imagefile: ImageFile) => void;
}

/**
 * 保存まで行う
 * @constructor
 */
export const AvatarEditor: FC<Props> = ({ onSave }) => {
  const [file, setFile] = useState<File | null>(null);
  const [scale, setScale] = useState(1);
  const ref = useRef<AvatarEditor2>(null);

  const save = useMutation(
    async (blob: Blob) => (await postImageFile({ blob, mime: blob.type })).data,
    {
      onSuccess(data) {
        onSave(data);
      },
    }
  );

  const onSave2 = useCallback(() => {
    if (ref.current) {
      const canvas = ref.current.getImageScaledToCanvas();
      canvas.toBlob(
        (blob) => {
          save.mutate(blob!);
        },
        "image/png",
        0.8
      );
    }
  }, [save]);

  return (
    <>
      <VerticalList>
        {file && (
          <>
            <AvatarEditor2
              ref={ref}
              image={file}
              width={200}
              height={200}
              border={50}
              color={[255, 255, 255, 0.9]} // RGBA
              scale={scale}
              rotate={0}
              borderRadius={100}
            />
            <Slider
              value={scale}
              onChange={setScale}
              min={1}
              max={3}
              step={0.1}
            />
          </>
        )}
        <input
          type="file"
          onChange={(e) => setFile(e.target.files ? e.target.files[0] : null)}
        />

        {file && (
          <>
            <Button onClick={onSave2} loading={save.isLoading}>
              決定
            </Button>
            <NoteBox>アバター画像は審査後に公開されます</NoteBox>
          </>
        )}
      </VerticalList>
    </>
  );
};
