import React, { FC, useEffect, useState } from "react";
import { AudioFileResponse } from "../../api";
import { Spin, Upload } from "antd";
import { audioFileUploadUrl } from "../../common/api/api2";
import { getToken } from "../../lib/auth0";
import { UploadOutlined } from "@ant-design/icons";
import { AppButton } from "../../common/components/AppButton";

export interface UploadVoiceProps {
  onComplete: (fileId: string) => void;
}

/**
 * 声をファイルとしてアップロードする
 */
export const UploadVoice: FC<UploadVoiceProps> = ({ onComplete }) => {
  const [token, setToken] = useState("");

  useEffect(() => {
    (async () => {
      // TODO unmount 後に処理する可能性がありそう
      setToken(await getToken());
    })();

    const id = setInterval(() => {
      (async () => {
        // TODO unmount 後に処理する可能性がありそう
        setToken(await getToken());
      })();
    }, 5000);
    return () => {
      clearInterval(id);
    };
  }, []);

  return (
    <>
      {token ? (
        <Upload
          name="file"
          action={audioFileUploadUrl}
          headers={{
            authorization: `Bearer ${token}`,
          }}
          maxCount={1}
          // audio/* だと m4a を ios safari で選択できなかった
          accept="audio/aac,audio/x-m4a,audio/mp3,audio/mpeg3,audio/mpeg,audio/webm,audio/wav"
          onChange={(e) => {
            if (e.file.status === "done") {
              const res = e.file.response as AudioFileResponse;
              onComplete(res.id);
            }
          }}
        >
          <AppButton type="primary" icon={<UploadOutlined />}>
            音声ファイルをアップロード
          </AppButton>
        </Upload>
      ) : (
        <Spin />
      )}
    </>
  );
};
