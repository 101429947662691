import React, { FC, useCallback } from "react";
import { Topic } from "../../common/api/voicemessage/api";
import styled from "styled-components";
import { primaryColor, primaryColor4, primaryLightBgColor } from "../../colors";
import { VerticalList } from "../../common/components/VerticalList";
import { HorizontalList } from "../../common/components/HorizontalList";
import { Small } from "../../common/components/Small";
import { Strong } from "../../common/components/Strong";

interface Props {
  topic: Topic;
  onClick?: (topic: Topic) => void;
  hideQuestion?: boolean;
  size?: "small";
  active?: boolean;
}

export const TopicView: FC<Props> = ({
  topic,
  onClick,
  hideQuestion,
  size,
  active,
}) => {
  const onClick2 = useCallback(() => {
    if (onClick) {
      onClick(topic);
    }
  }, [onClick, topic]);

  const Root = size === "small" ? RootSmall : RootNormal;

  return (
    <Root onClick={onClick2} active={active}>
      <HorizontalList margin={2} alignItems="center">
        <VerticalList justifyContent="center" margin={2} align="center">
          <div>
            <Strong>{topic.title}</Strong>
          </div>
          {topic.question && !hideQuestion && (
            <Small>事前に質問したいこと: {topic.question}</Small>
          )}
        </VerticalList>
      </HorizontalList>
    </Root>
  );
};

interface RootProps {
  active?: boolean;
}

const RootNormal = styled.div<RootProps>`
  width: fit-content;

  border-radius: 20px;
  background-color: ${(p) => (p.active ? primaryLightBgColor : "#fcfcfc")};
  padding: 8px 8px;
  border: 1px solid ${primaryColor};
  box-shadow: rgb(0 0 0 / 10%) 0 2px 0;
`;

const RootSmall = styled.div<RootProps>`
  width: fit-content;

  border-radius: 4px;
  background-color: ${(p) => (p.active ? primaryLightBgColor : "#fcfcfc")};
  padding: 0 4px;
  border: 1px solid ${primaryColor4};
  box-shadow: rgb(0 0 0 / 4%) 0 1px 0;
`;
