import React, { FC, useState } from "react";
import { OtherFullUser } from "../../../../api";
import { Button } from "antd";
import { VerticalList } from "../../../../common/components/VerticalList";
import { BottomSheet } from "../../../../common/components/BottomSheet";
import { PostThanks } from "../../../components/PostThanks/PostThanks";
import { RequestId } from "../../../../common/api/types";
import { track } from "../../../../common/track";

interface Props {
  requestId: RequestId;
  cast: OtherFullUser;
  numOfThanksLeft: number;
}

/**
 * 「お礼をしよう」
 */
export const RequestThanksContent: FC<Props> = ({
  requestId,
  cast,
  numOfThanksLeft,
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <BottomSheet visible={visible} onClose={setVisible}>
        <PostThanks
          requestId={requestId}
          onSent={() => setVisible(false)}
          cast={cast}
        />
      </BottomSheet>

      <VerticalList>
        <div>
          {cast.name}
          さんは、きっとあなたに喜んでもらいたいと思ってメッセージを贈ってくれたよ！
          <br />
          <br />
          やさしいお礼のメッセージをお返ししてあげてほしいんだよ＞＜！
        </div>
        <Button
          onClick={() => {
            track({ event: "talk.thanks", data2: requestId });
            setVisible(true);
          }}
        >
          お礼をする (あと{numOfThanksLeft}通まで)
        </Button>
      </VerticalList>
    </>
  );
};
