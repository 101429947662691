/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { LineUser } from '../model';
// @ts-ignore
import { MyUser } from '../model';
// @ts-ignore
import { OtherUser } from '../model';
// @ts-ignore
import { PutMyProfile } from '../model';
/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary とりあえず、今は誰の情報でも取れる
         * @param {string} personalityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (personalityId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'personalityId' is not null or undefined
            assertParamExists('getUser', 'personalityId', personalityId)
            const localVarPath = `/users/{personalityId}`
                .replace(`{${"personalityId"}}`, encodeURIComponent(String(personalityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserStatus: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/@me/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LineUser} me 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserStatusLine: async (me: LineUser, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'me' is not null or undefined
            assertParamExists('getUserStatusLine', 'me', me)
            const localVarPath = `/@me/status/line/follow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (me !== undefined) {
                localVarQueryParameter['me'] = me;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminSignOutAs: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/@me/sign-out-as`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PutMyProfile} putMyProfile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserStatus: async (putMyProfile: PutMyProfile, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'putMyProfile' is not null or undefined
            assertParamExists('putUserStatus', 'putMyProfile', putMyProfile)
            const localVarPath = `/@me/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putMyProfile, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary とりあえず、今は誰の情報でも取れる
         * @param {string} personalityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(personalityId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OtherUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(personalityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserStatus(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LineUser} me 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserStatusLine(me: LineUser, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserStatusLine(me, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAdminSignOutAs(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAdminSignOutAs(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PutMyProfile} putMyProfile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putUserStatus(putMyProfile: PutMyProfile, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putUserStatus(putMyProfile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary とりあえず、今は誰の情報でも取れる
         * @param {string} personalityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(personalityId: string, options?: any): AxiosPromise<OtherUser> {
            return localVarFp.getUser(personalityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserStatus(options?: any): AxiosPromise<MyUser> {
            return localVarFp.getUserStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LineUser} me 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserStatusLine(me: LineUser, options?: any): AxiosPromise<string> {
            return localVarFp.getUserStatusLine(me, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAdminSignOutAs(options?: any): AxiosPromise<string> {
            return localVarFp.postAdminSignOutAs(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PutMyProfile} putMyProfile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserStatus(putMyProfile: PutMyProfile, options?: any): AxiosPromise<void> {
            return localVarFp.putUserStatus(putMyProfile, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary とりあえず、今は誰の情報でも取れる
     * @param {string} personalityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUser(personalityId: string, options?: any) {
        return UserApiFp(this.configuration).getUser(personalityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserStatus(options?: any) {
        return UserApiFp(this.configuration).getUserStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LineUser} me 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserStatusLine(me: LineUser, options?: any) {
        return UserApiFp(this.configuration).getUserStatusLine(me, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public postAdminSignOutAs(options?: any) {
        return UserApiFp(this.configuration).postAdminSignOutAs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PutMyProfile} putMyProfile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public putUserStatus(putMyProfile: PutMyProfile, options?: any) {
        return UserApiFp(this.configuration).putUserStatus(putMyProfile, options).then((request) => request(this.axios, this.basePath));
    }
}
