import { useEffect } from "react";
import { eventPageView } from "../../gtm";
import { track } from "../track";

/**
 */
export function usePageView(
  name: string,
  andTrack: boolean = false,
  data2?: string
) {
  useEffect(() => {
    eventPageView(name);
  }, [name]);

  useEffect(() => {
    if (andTrack) {
      track({
        event: "view",
        data: name,
        data2,
      });
    }
  }, [andTrack, data2, name]);
}
