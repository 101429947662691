import React, { FC, memo } from "react";

import moment from "moment";

export type TimeProps = {
  seconds: number;
};

export const Time: FC<TimeProps> = memo(({ seconds }) => {
  const d = moment.duration(seconds, "s");
  const m = d.minutes();
  const s = d.seconds();

  return (
    <span>
      {m >= 1 && m + "分"}
      {s >= 1 && s + "秒"}
    </span>
  );
});
