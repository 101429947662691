/**
 * auth0-react が、通信ロジックからの暗黙的な get token ができなそうなので、自前で
 */
import createAuth0Client, { Auth0Client } from "@auth0/auth0-spa-js";
import { useEffect, useState } from "react";
import { Location } from "history";
import { useHistory } from "react-router-dom";

// auth0-react から
const CODE_RE = /[?&]code=[^&]+/;
const STATE_RE = /[?&]state=[^&]+/;
const ERROR_RE = /[?&]error=[^&]+/;

export const hasAuthParams = (searchParams = window.location.search): boolean =>
  (CODE_RE.test(searchParams) && STATE_RE.test(searchParams)) ||
  ERROR_RE.test(searchParams);

let auth0Client: Auth0Client | null = null;

interface AppState {
  location: Location;
}

export function useInitializeAuth0(p: {
  domain: string;
  clientId: string;
  audience: string;
}) {
  const history = useHistory();
  const [error, setError] = useState<Error>();
  const [isLoading, setLoading] = useState(true);

  console.log("useInitializeAuth0");
  useEffect(() => {
    (async () => {
      try {
        console.log("useInitializeAuth0", "useEffect");
        const actualAuth0 = await createAuth0Client({
          domain: p.domain,
          client_id: p.clientId,
          audience: p.audience,

          // safari で 3rd party cookie の影響で、毎回ログインが必要なため
          useRefreshTokens: true,
          cacheLocation: "localstorage",
        });
        auth0Client = actualAuth0;

        if (hasAuthParams()) {
          const {
            appState,
          }: {
            appState?: AppState;
          } = await actualAuth0.handleRedirectCallback();

          if (appState?.location) {
            history.push(appState.location);
          }
        }

        console.log("useInitializeAuth0", "initialized");
        setLoading(false);
      } catch (e) {
        console.log(e);
        setError(e);
      }
    })();
  }, [history, p.audience, p.clientId, p.domain]);

  return {
    isLoading,
    error,
  };
}

export function useAuth0Client(): Auth0Client {
  return auth0Client!;
}

export async function getToken() {
  const auth0 = auth0Client!;
  return await auth0.getTokenSilently();
}

export async function isAuthenticated() {
  return auth0Client?.isAuthenticated();
}
