import React, { FC } from "react";

import styled from "styled-components";
import { primaryColor3 } from "../../colors";

type PrimaryButtonProps = {
  onClick: () => void;
  disabled?: boolean;
};

export const PrimaryButton: FC<PrimaryButtonProps> = ({
  onClick,
  disabled,
  children,
}) => {
  return (
    <MyButton disabled={disabled} onClick={onClick}>
      {children}
    </MyButton>
  );
};

const MyButton = styled.button`
  border: none;

  background: linear-gradient(to right, ${primaryColor3} 20%, ${primaryColor3});
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  padding: 12px 40px;
  border-radius: 36px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  outline: 0;

  :disabled {
    opacity: 0.5;
  }
`;
