import React, { FC } from "react";

import { Tag } from "antd";
import { TagProps } from "antd/lib/tag";
import { VoiceMessageState } from "../../../common/api/voicemessage/api";

type VoiceMessageStateTagProps = {
  state: VoiceMessageState;
};

export const VoiceMessageStateTag: FC<VoiceMessageStateTagProps> = ({
  state,
}) => {
  return <Tag color={color(state)}>{state}</Tag>;
};

function color(state: VoiceMessageState): TagProps["color"] {
  switch (state) {
    case "DUPLICATED":
      return "red";
    case "SENT":
      return "geekblue";
    case "RECORDED":
      return "cyan";
    case "REQUESTED":
      return "green";
  }
}
