import React, { FC, useEffect, useState } from "react";

import { Voice } from "../hooks/useVoiceList";
import { RecordedVoice } from "./RecordedVoice";
import { useMutation, useQuery } from "react-query";
import { getAudioFile, postAudioFile } from "../../common/api/audiofile";

type RecordingDataProps = {
  voice: Voice;
  /**
   * 拡張子は含まない
   */
  fileName: string;

  sendToListenerDisabled: boolean;

  onSendToListener: (id: string) => void;
};

type State = "initial" | "saving" | "checking" | "done";

/**
 * 録音された音声データ
 *
 * サーバーへの保存処理も行う
 */
export const SmartRecordedVoice: FC<RecordingDataProps> = ({
  voice,
  fileName,
  sendToListenerDisabled,
  onSendToListener,
}) => {
  const blob = voice.blob;
  const [id, setId] = useState<string | null>(null);
  const [state, setState] = useState("initial" as State);

  const save = useMutation(
    async () => {
      return (await postAudioFile({ blob, mime: blob.type })).data;
    },
    {
      mutationKey: ["smart-recorded-voice", voice.id],
      onSuccess: (data) => {
        setId(data.id);
        setState("checking");
      },
    }
  );

  useQuery(
    ["smart-recorder-voice", id],
    async () => (await getAudioFile(id!)).data,
    {
      suspense: false,
      refetchInterval: 2000,
      enabled: state === "checking",
      onSuccess: (data) => {
        if (data.status === "FINISHED") {
          console.log("SmartRecordedVoice: done");
          setState("done");
        }
      },
    }
  );

  // save 自体は毎回変わるので、 useEffect の deps にはいれちゃだめ
  const mutate = save.mutate;

  useEffect(() => {
    // hot-reload 時にサイド実行してしまわないように
    if (state !== "initial") {
      return;
    }
    console.log("SmartRecordedVoice: save");
    mutate();
  }, [mutate, state]);

  return (
    <RecordedVoice
      id={id ?? undefined}
      voice={voice}
      fileName={fileName}
      processing={state !== "done"}
      sendToListenerDisabled={sendToListenerDisabled}
      onSendToListener={onSendToListener}
    />
  );
};
