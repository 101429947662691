import React, { FC } from "react";
import { VerticalList } from "../../../../common/components/VerticalList";
import { useGoto } from "../../../../lib/hooks/useGoto";
import { castAssignedActiveRequestsRoute } from "../../CastAssignedActiveRequestsPage/CastAssignedActiveRequestsPage";
import { MyUser2 } from "../../../../common/api/api2";
import { AppBadge } from "../../../components/AppBadge";
import { AppButton } from "../../../../common/components/AppButton";

interface Props {
  me: MyUser2;
  numOfRequests: number;
}

export const RecordMessage: FC<Props> = ({ me, numOfRequests }) => {
  const go = useGoto(castAssignedActiveRequestsRoute.makePath(), {
    saveLocation: true,
  });

  return (
    <VerticalList>
      <div>
        収録待ちのリクエストがあるよ！
        <br />
        <br />
        声を送ってあげると、きっと喜んでくれるよ
      </div>
      <AppBadge count={numOfRequests}>
        <AppButton onClick={go} fullWidth>
          収録待ち一覧をみる
        </AppButton>
      </AppBadge>
    </VerticalList>
  );
};
